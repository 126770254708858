// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNotNil, mergeRight } from 'ramda';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';

// MUI
import { Container, Link } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';

// Core
import {
    ParameterResource,
    ParameterValues,
    paramRepository,
    ResourceTextApplication,
} from '../../core/resources';
import { dayFormat } from '../../core/constants';

// Common
import { ADataGrid, ADataGridActions, AGridColumns } from '../../common/dataGrid';
import { ADefaultIconButton, ASearchIconButton } from '../../common/buttons';

// Interfaces
import { IAnimalPayloadItem } from '../../interfaces/IAnimal';
import { EmptyGUID } from '../../interfaces/types';
import { ICompanyDetails } from '../../interfaces/IBusinessEntities';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import printingApi from '../../api/printingApi';
import companyApi from '../../api/companyApi';

// Feature - Company
import CompanyInfoDialog from '../../companies/CompanyInfoDialog';
import { TextWithTooltip } from '../../common/typography';

const disabledColor = '#C0C0C0';

export const openCompanyLink = {
    '&[disabled]': {
        color: disabledColor,
        pointerEvents: 'none',
        textDecorationColor: disabledColor,
    },
};

export interface SearchObj {
    searchTerm: string;
    searthType: string;
}

export interface AnimalPayloadsViewGridProps {
    isLoading: boolean;
    onPayloadClick: (payloadNumber: number) => void;
    payloadData: IAnimalPayloadItem[];
    searchObj: SearchObj | null;
}

export const AnimalPayloadsViewGrid = (props: AnimalPayloadsViewGridProps) => {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaAnimalAnnouncement',
        'AnelmaAnimalPayload',
        'AnelmaCompany',
        'AnelmaGeneral',
    ]);

    const userData = useAppSelector((state) => state.userData.data);

    const [animalAnnouncementTypeParams, setAnimalAnnouncementTypeParams] =
        useState<ParameterValues | null>(null);
    const [animalPayloadBatchStatusParams, setAnimalPayloadBatchStatusParams] =
        useState<ParameterValues | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [paramsInit, setParamsInit] = useState<boolean>(false);
    const [farmResources, setFarmResources] = useState<ParameterResource>({});
    const [displayCompanyDialog, setDisplayCompanyDialog] = useState<boolean>(false);
    const [companyDetails, setCompanyDetails] = useState<ICompanyDetails>();

    useEffect(() => {
        const promises = [
            paramRepository.load([
                'AnelmaAnimalAnnouncement',
                'AnelmaAnimalPayload',
                'AnelmaCompany',
                'AnelmaGeneral',
            ]),
        ];
        Promise.all(promises).then(
            () => {
                let params: ParameterResource = {};
                params['feedSupplier'] = paramRepository.resource('AnelmaCompany', 'FeedSupplier');
                params['dairy'] = paramRepository.resource('AnelmaCompany', 'Dairy');

                setFarmResources(params);
                setParamsInit(true);
            },
            () => {
                setParamsInit(false);
                // enqueueSnackbar(t('AnelmaGeneral:1020'), {
                //     variant: 'error',
                // });
            }
        );
    }, []);

    useEffect(() => {
        if (isNotNil(companyDetails)) setDisplayCompanyDialog(true);
    }, [companyDetails]);

    const getCompany = (id: string) => {
        if (isNotNil(id)) {
            setLoading(true);
            companyApi.getCompanyByGuid(id).then((response) => {
                if (!response) {
                    enqueueSnackbar(t('AnelmaGeneral:1020'), {
                        variant: 'error',
                    });
                    setLoading(false);
                    return;
                }

                setCompanyDetails(response.Entity);
                setLoading(false);
            });
        }
    };

    const renderCompanyName = (payload: IAnimalPayloadItem) => {
        const companyName =
            payload?.CompanyInfo?.Name ?? payload?.Batches[0]?.CompanyInfo?.Name ?? '';

        if (userData.IsSnellmanUser || userData.IsDriver || userData.IsTrafficContractor) {
            return (
                <Link
                    component={'button'}
                    onClick={() =>
                        getCompany(
                            payload?.CompanyInfo?.Guid ??
                                payload?.Batches[0]?.CompanyInfo?.Guid ??
                                ''
                        )
                    }
                    disabled={loading}
                    sx={openCompanyLink}
                >
                    {companyName}
                </Link>
            );
        }

        return <TextWithTooltip text={companyName} />;
    };

    const columns: AGridColumns = [
        {
            field: 'CompanyName',
            headerName: t('AnelmaAnimalPayload:1034'),
            renderCell: (params) => renderCompanyName(params.row as IAnimalPayloadItem),
            width: 150,
        },
        {
            field: 'ProducerNumber',
            headerName: t('AnelmaAnimalPayload:1036'),
            valueGetter: (params, row) => {
                return (
                    (row as IAnimalPayloadItem)?.CompanyInfo?.ProducerNumber ??
                    (row as IAnimalPayloadItem)?.Batches[0]?.CompanyInfo?.ProducerNumber ??
                    ''
                );
            },
            width: 130,
        },
        {
            field: 'AnimalPayloadNumber',
            headerName: t('AnelmaAnimalPayload:1002'),
            valueGetter: (params, row) => {
                return (row as IAnimalPayloadItem).AnimalPayloadNumber;
            },
            width: 125,
        },
        {
            field: 'AnnouncementType',
            headerName: t('AnelmaAnimalPayload:1028'),
            renderCell: (params) => {
                if (!animalAnnouncementTypeParams) return '';

                const paramFound = animalAnnouncementTypeParams.find(
                    (p) => Number(p.code) === params.formattedValue
                );
                return paramFound ? paramFound.text : '';
            },
            valueGetter: (params, row) => {
                return (
                    (row as IAnimalPayloadItem)?.AnnouncementInfo?.AnnouncementType ??
                    (row as IAnimalPayloadItem)?.Batches[0]?.AnnouncementInfo?.AnnouncementType ??
                    ''
                );
            },
            width: 150,
        },
        {
            field: 'PayloadStatus',
            headerName: t('AnelmaAnimalPayload:1001'),
            renderCell: (params) => {
                if (!animalPayloadBatchStatusParams) return '';

                const paramFound = animalPayloadBatchStatusParams.find(
                    (p) => Number(p.code) === params.formattedValue
                );
                return paramFound ? paramFound.text : '';
            },
            width: 150,
        },
        {
            field: 'AnimalCountFromBatches',
            headerName: t('AnelmaAnimalPayload:1008'),
            valueGetter: (params, row) => {
                const batches = (row as IAnimalPayloadItem).Batches;

                if (!batches) return '';

                let animalCount: number = 0;

                batches.forEach((b) => (animalCount += b.AnimalCount));

                return animalCount;
            },
        },
        {
            field: 'CollectYearWeek',
            headerName: t('AnelmaAnimalPayload:1003'),
            valueGetter: (params, row) => {
                const collectWeekData = (row as IAnimalPayloadItem).CollectWeek;

                if (!collectWeekData?.Year || !collectWeekData?.Week) return '';

                return `${collectWeekData.Year}-${collectWeekData.Week}`;
            },
        },
        {
            field: 'CollectDay',
            headerName: t('AnelmaAnimalPayload:1004'),
            type: 'date',
            renderCell: (params) => {
                if (!(params.row as IAnimalPayloadItem)?.CollectDay) return '';

                const day = moment(new Date((params.row as IAnimalPayloadItem).CollectDay)).format(
                    dayFormat
                );

                return day ? day : '';
            },
            valueGetter: (params, row) => {
                if (!(row as IAnimalPayloadItem)?.CollectDay) return '';

                return new Date((row as IAnimalPayloadItem).CollectDay);
            },
        },
    ];

    useEffect(() => {
        if (paramsInit) {
            const statusParams = paramRepository.resource(
                'AnelmaAnimalPayload',
                'AnimalBatchStatus'
            );
            if (statusParams) setAnimalPayloadBatchStatusParams(statusParams);

            const announcementTypeParams = paramRepository.resource(
                'AnelmaAnimalAnnouncement',
                'AnimalAnnouncementType'
            );

            if (announcementTypeParams) setAnimalAnnouncementTypeParams(announcementTypeParams);
        }
    }, [paramsInit]);

    const dataRows = props.payloadData.map((i) => mergeRight(i, { id: i.AnimalPayloadNumber }));

    const closeCompanyInfo = () => {
        setDisplayCompanyDialog(false);
        setCompanyDetails(undefined);
    };

    const isCompanyInfoDialogInReadonlyMode = () => {
        if (userData.IsSnellmanUser) return false;
        else if (userData.IsTrafficContractor || userData.IsDriver) return true;

        return false;
    };

    const actions: ADataGridActions = [
        (params) => {
            const payloadNumber = (params.row as IAnimalPayloadItem).AnimalPayloadNumber;

            return (
                <ASearchIconButton
                    key={`${payloadNumber}-search-btn`}
                    onClick={() => props.onPayloadClick(payloadNumber)}
                    disabled={loading}
                />
            );
        },
        (params) => {
            return (
                <ADefaultIconButton
                    key={`${params.row.AnimalPayloadNumber}-pdf-btn`}
                    icon={<PictureAsPdf />}
                    onClick={() => {
                        let payload = params.row as IAnimalPayloadItem;
                        printingApi.printAnimalPayload(
                            payload.AnimalPayloadNumber,
                            payload.CompanyInfo?.Guid ?? EmptyGUID
                        );
                    }}
                    tooltip=''
                    disabled={loading}
                />
            );
        },
    ];

    return (
        <Container id='anelma-animals-animal-payloads-view-grid'>
            <ADataGrid
                actions={actions}
                enableRowNumbers
                columns={columns}
                loading={props.isLoading}
                rows={!dataRows ? [] : dataRows}
            />

            {displayCompanyDialog && companyDetails && (
                <CompanyInfoDialog
                    mode={'modify'}
                    onClose={() => closeCompanyInfo()}
                    companyType={companyDetails.CompanyType}
                    companyId={companyDetails.Id ?? ''}
                    farmResources={farmResources}
                    readonly={isCompanyInfoDialogInReadonlyMode()}
                />
            )}
        </Container>
    );
};
