// Libraries
import { isNotNil } from 'ramda';

// MUI
// Core
// Common
// Interfaces
import { IFeedback } from '../../../interfaces/communication/feedback/IFeedback';
import { IFeedbackSummary } from '../../../interfaces/communication/feedback/IFeedbackSummary';

// Store
// API
// Feature - Feedback

export const mapToFeedbackSummaries = (feedback: IFeedback): IFeedbackSummary => {
    return {
        Id: feedback.Id,
        CreatedAt: feedback.CreatedAt,
        SenderCompany: feedback.SenderCompany,
        Topic: feedback.Topic,
        HandlerPerson: feedback.HandlerPerson,
        Status: feedback.Status,
        HasAttachments:
            isNotNil(feedback.Attachments) && feedback.Attachments.length > 0 ? true : false,
        Participants: feedback.Participants,
        Reason: feedback.Reason,
    };
};
