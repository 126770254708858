// Libraries
import { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { isNotNil } from 'ramda';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import FormValidator from '../../core/FormValidator';
import auth from '../../core/authorization';
import { ParameterValues, ResourceTextApplication, paramRepository } from '../../core/resources';

// Common
import {
    ADialogButtonsProp,
    ADialog,
    ADialogTitle,
    ADialogContent,
    ADialogActions,
} from '../../common/dialog';
import { AAutocomplete, ALabel, ANumberInput, ATextarea } from '../../common/inputs';
import AttachmentDropzone from '../../common/AttachmentDropzone';

// Interfaces
import { FormMode } from '../../interfaces/IForm';
import {
    FeedbackCommandEnum,
    FeedbackParticipantRoleEnum,
    ReadTypeEnum,
} from '../../interfaces/enums';
import { IFeedback, IParticipant } from '../../interfaces/communication/feedback/IFeedback';
import {
    IFeedbackRequest,
    IPayloadFeedbackRequest,
    IReclamationFeedbackRequest,
} from '../../interfaces/communication/feedback/IFeedbackRequest';
import { IUpdateFeedbackRequest } from '../../interfaces/communication/feedback/IUpdateFeedbackRequest';
import { IFeedbackMessageRequest } from '../../interfaces/communication/feedback/IFeedbackMessageRequest';
import { IApiResult } from '../../interfaces/IGeneral';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import feedbackApi from '../../api/messaging/feedbackApi';
import companyApi from '../../api/companyApi';
import utilityApi from '../../api/utilityApi';

// Feature - Documents
import config from '../../documents/addDialog/documentAddDialogConfigs';

// Feature - Communication general
import MessageList from '../general/components/MessageList';

// Feature - Feedback
import { FeedbackViewContext } from '../context/feedback/IFeedbackViewContext';
import {
    findUniqueParticipants,
    mapContactPersonsAsParticipants,
    mapPersonDetailsToContactPerson,
    mapSnellmanGroupUsersAsParticipants,
} from './helpers/participantHelper';
import { SelectorConstants } from './constants/SelectorConstants';
import CompanySelection from './CompanySelection';
import IContactPersonRecipient from '../general/interfaces/IContactPersonRecipient';
import { getContactPersons } from '../../publicContactPersons/publicContactPersonsApi';
import { getSnellmanContactPersonTitle } from '../general/helpers/helperFunctions';
import { FeedbackDialogContext } from '../context/feedback/IFeedbackDialogContext';
import { mapFeedbackMessagesToMessageList } from './helpers/feedbackMessageHelper';
import FeedbackAttachments from './FeedbackAttachments';
import { getRoleName } from './helpers/roleNameHelper';
import ReclamationFeedback from './ReclamationFeedback';
import { ReclamationFeedbackContext } from '../context/feedback/IReclamationFeedbackContext';

export interface ICreateFeedbackProps {
    onClose: () => void;
    formMode: FormMode;
}

export default function FeedbackDialog({ onClose, formMode }: ICreateFeedbackProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCommunication',
        'AnelmaLayout',
        'AnelmaSolmuErrors',
    ]);
    const formValidator = new FormValidator();

    const attachmentsConfig = config('default');
    const { enqueueSnackbar } = useSnackbar();
    const userData = useAppSelector((state) => state.userData.data);
    const loginStatus = useAppSelector((state) => state.loginStatus.data);
    const configuration = useAppSelector((state) => state.configuration);
    const {
        farmDetails,
        setToSummaries,
        updateToSummaries,
        preSelectedBovines,
        preSelectedSubject,
        payloadData,
    } = useContext(FeedbackViewContext);
    const { feedback } = useContext(FeedbackDialogContext);

    const [communicationStatusParameters, setCommunicationStatusParameters] =
        useState<ParameterValues>([]);
    const [communicationSubjectParameters, setCommunicationSubjectParameters] =
        useState<ParameterValues>([]);
    const [snellmanContactPersons, setSnellmanContactPersons] = useState<IContactPersonRecipient[]>(
        []
    );
    const [snellmanGroupUsers, setSnellmanGroupUsers] = useState<IParticipant[]>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCompany, setSelectedCompany] = useState<string>('');
    const [selectedSubject, setSelectedSubject] = useState<string>(
        formMode === 'create' ? '' : feedback?.Topic.toString() ?? ''
    );
    const [attachments, setAttachments] = useState<File[]>([]);
    const [messageBody, setMessageBody] = useState<string>('');
    const [participants, setParticipants] = useState<IParticipant[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string | null>(
        formMode === 'create' ? '' : feedback?.Status.toString().replace(/^0+/, '') ?? ''
    );
    const [selectedHandlerPerson, setSelectedHandlerPerson] = useState<string | null>(
        formMode === 'create' ? '' : feedback?.HandlerPerson?.Id ?? ''
    );

    // AN-2426
    const [selectedReason, setSelectedReason] = useState<string | null>(
        formMode === 'create' ? '' : feedback?.Reason?.toString().replace(/^0+/, '') ?? ''
    );
    const [bovineOptions, setBovineOptions] = useState<string[]>(
        formMode === 'create' ? [] : feedback?.BovineIdentifier?.map((_) => _.EUIdentifier) ?? []
    );
    const [euIdentifiers, setEuIdentifiers] = useState<string[]>(
        formMode === 'create' ? [] : feedback?.BovineIdentifier?.map((_) => _.EUIdentifier) ?? []
    );

    // AN-2431
    const [payloadNumber, setPayloadNumber] = useState<number>(
        formMode === 'create' ? 0 : feedback?.PayloadNumber ?? 0
    );

    useEffect(() => {
        initializeParameters();
        initializePreSelectedBovines();
        initializePreSelectedSubject();
        initializePreSelectedPayload();
        getSnellmanContactPersons();
        getSnellmanAccessRoleUsers();
    }, []);

    /** Sort of preliminary hook that sets user's farm's contact persons as participants for the new feedback item when conditions apply.
     *  This can be overridden when the user has the possibility to select a company from the dropdown.
     */
    useEffect(() => {
        if (farmDetails && farmDetails.ContactPersons.length > 0) {
            setParticipants((state) => [
                ...state,
                ...mapContactPersonsAsParticipants(farmDetails.ContactPersons),
            ]);
        }
    }, [farmDetails]);

    useEffect(() => {
        if (selectedCompany.length > 0) setCompanyContactPersonsAsParticipants(selectedCompany);
    }, [selectedCompany]);

    const initializeParameters = () => {
        paramRepository.load(['AnelmaCompany', 'AnelmaCommunication']).then(() => {
            setCommunicationSubjectParameters(
                paramRepository.resource('AnelmaCommunication', 'CommunicationSubject')
            );
            setCommunicationStatusParameters(
                paramRepository.resource('AnelmaCommunication', 'CommunicationStatus')
            );
        });
    };

    /** Initialize subject state as '4' which states for reclamation feedback. Can be used for similar features that are connected to preselecting subject. */
    const initializePreSelectedSubject = () => {
        if (isNotNil(preSelectedSubject)) setSelectedSubject(preSelectedSubject.toString());
    };

    /** When rendering this dialog from AnimalListGrid check if there are any preselected bovines. In case there are apply the bovines to state. */
    const initializePreSelectedBovines = () => {
        if (isNotNil(preSelectedBovines)) {
            const euIds = preSelectedBovines.map((_) => _.EuIdentifier);

            setBovineOptions(euIds);
            setEuIdentifiers(euIds);
        }
    };

    /** When rendering this dialog from AnimalPayloadsBatchForm try to set payload number to the state.
     *  In case logged in user is Snellman user there is no rule for 'preSelectedSubject' and it's set undefined from AnimalPayloadsBatchForm.tsx file therefore the check against 'preSelectedSubject'.
     *  I.e. payload number should be empty when logged in user is a Snellman user. */
    const initializePreSelectedPayload = () => {
        if (isNotNil(preSelectedSubject) && isNotNil(payloadData))
            setPayloadNumber(payloadData.AnimalPayloadNumber);
    };

    const getSnellmanAccessRoleUsers = () => {
        if (formMode === 'modify') return;

        setLoading(true);

        utilityApi.resolveRoleMembers(getRoleName()).then((response) => {
            if (!response) {
                enqueueSnackbar(t('AnelmaSolmuErrors:1000'), {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }
            setLoading(false);
            setSnellmanGroupUsers(mapSnellmanGroupUsersAsParticipants(response.Items));
        });
    };

    const getSnellmanContactPersons = () => {
        if (formMode === 'create' && !auth.canCreateFeedbackAdmin) return;

        setLoading(true);

        getContactPersons(!!loginStatus.loggedIn).then(
            (data) => {
                const actualPersons = data.Items.filter((_) => _.Name !== null && _.Name !== '');

                setSnellmanContactPersons(
                    actualPersons.map((item) => ({
                        Id: item.PersonGUID,
                        Name: item.Name,
                        EmailAddress: item.EmailAddress,
                        CompanyId: item.CompanyGUID,
                        Title: getSnellmanContactPersonTitle(
                            item,
                            configuration.data.culture.supportedCultures.find((c) => c.selected)
                                ?.value
                        ),
                        ProfilePicture: item.PictureUrl,
                        CompanyName: 'Snellmanin Lihanjalostus Oy',
                    }))
                );
                setLoading(false);
            },
            () => {
                enqueueSnackbar(t('AnelmaCommunication:1109'), {
                    variant: 'error',
                });
                setLoading(false);
            }
        );
    };

    /** Fetch company by id (UUID) and sets retrieved contact persons as participants.
     *  NOTE! Keep in mind that this will override the user's farm's contact persons as the user selected a new company that the feedback belongs to.
     * @param id
     */
    const setCompanyContactPersonsAsParticipants = (id: string) => {
        setLoading(true);

        companyApi.getCompanyByGuid(id).then((response) => {
            if (!response) {
                enqueueSnackbar(t('AnelmaSolmuErrors:10009'), {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }

            setParticipants(mapContactPersonsAsParticipants(response.Entity.ContactPersons));
            setLoading(false);
        });
    };

    const getDialogTitle = () => {
        return formMode === 'create'
            ? t('AnelmaCommunication:1086')
            : t('AnelmaCommunication:1117');
    };

    const onSave = () => {
        switch (formMode) {
            case 'create':
                createFeedback();
                break;
            case 'modify':
                updateFeedback();
                break;
        }
    };

    const createFeedback = () => {
        setLoading(true);

        if (formValidator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            setLoading(false);
            return;
        }

        const payload = getFeedbackPayload();

        if (isReclamationFeedback())
            createReclamationFeedback(payload as IReclamationFeedbackRequest);
        else if (isPayloadFeedback()) createPayloadFeedback(payload as IPayloadFeedbackRequest);
        else createSingleLevelFeedback(payload);
    };

    const getFeedbackPayload = () => {
        if (isReclamationFeedback()) {
            if (auth.canCreateFeedbackAdmin && userData.IsSnellmanUser)
                return getReclamationFeedbackAdminPayload();
            return getReclamationFeedbackProducerPayload();
        } else if (isPayloadFeedback()) {
            if (auth.canCreateFeedbackAdmin && userData.IsSnellmanUser)
                return getPayloadFeedbackAdminPayload();
            return getPayloadFeedbackProducerPayload();
        }
        return auth.canCreateFeedbackAdmin && userData.IsSnellmanUser
            ? getFeedbackAdminPayload()
            : getFeedbackProducerPayload();
    };

    const createPayloadFeedback = (payload: IPayloadFeedbackRequest) => {
        feedbackApi
            .createPayloadFeedback(payload)
            .then((response) => handleNewFeedbackResponse(response));
    };

    const createReclamationFeedback = (payload: IReclamationFeedbackRequest) => {
        feedbackApi
            .createReclamationFeedback(payload)
            .then((response) => handleNewFeedbackResponse(response));
    };

    const createSingleLevelFeedback = (payload: IFeedbackRequest) => {
        feedbackApi.createFeedback(payload).then((response) => handleNewFeedbackResponse(response));
    };

    const createFeedbackPayloadBase = (
        commandType: FeedbackCommandEnum,
        includeStatus: boolean = false,
        includeHandler: boolean = false
    ) => {
        const basePayload: any = {
            Reason: selectedReason !== null && selectedReason !== '' ? parseInt(selectedReason) : 0,
            BovineIdentifiers: euIdentifiers,
            CommandType: commandType,
            SenderCompanyId: selectedCompany,
            Topic: parseInt(selectedSubject),
            Attachments: attachments?.map((attachment) => ({ File: attachment })),
            Messages: [
                {
                    MessageSenderId: userData.Id ?? '',
                    Body: messageBody.trim(),
                    Archived: false,
                    Deleted: false,
                },
            ],
            Participants: checkValidityOfParticipants(),
        };

        if (includeStatus) {
            basePayload.Status =
                selectedStatus !== null && selectedStatus !== '' ? parseInt(selectedStatus) : 0;
        }

        if (includeHandler) {
            basePayload.HandlerPersonId =
                selectedHandlerPerson !== null ? selectedHandlerPerson : '';
        }

        return basePayload;
    };

    const getPayloadFeedbackAdminPayload = (): IPayloadFeedbackRequest => ({
        PayloadNumber: payloadNumber,
        ...createFeedbackPayloadBase(FeedbackCommandEnum.CreatePayloadFeedbackAdmin, true, true),
    });

    const getPayloadFeedbackProducerPayload = (): IPayloadFeedbackRequest => ({
        PayloadNumber: payloadNumber,
        ...createFeedbackPayloadBase(FeedbackCommandEnum.CreatePayloadFeedbackProducer),
    });

    const getReclamationFeedbackProducerPayload = (): IReclamationFeedbackRequest =>
        createFeedbackPayloadBase(FeedbackCommandEnum.CreateReclamationFeedbackProducer);

    const getReclamationFeedbackAdminPayload = (): IReclamationFeedbackRequest =>
        createFeedbackPayloadBase(FeedbackCommandEnum.CreateReclamationFeedbackAdmin, true, true);

    const getFeedbackAdminPayload = (): IFeedbackRequest =>
        createFeedbackPayloadBase(FeedbackCommandEnum.CreateFeedbackAdmin, true, true);

    const getFeedbackProducerPayload = (): IFeedbackRequest =>
        createFeedbackPayloadBase(FeedbackCommandEnum.CreateFeedbackProducer);

    const handleNewFeedbackResponse = (response: IApiResult<IFeedback> | null) => {
        if (!response) {
            enqueueSnackbar(`${t('AnelmaCommunication:1099')}, 'error'`);
            setLoading(false);
            return;
        }

        enqueueSnackbar(t('AnelmaCommunication:1100'), { variant: 'success' });
        setToSummaries(response.Entity);
        setLoading(false);
        onClose();
    };

    /** Snellman user (admin) is able to actually update some of the fields of the selected feedback item, while producer user can 'only' send a new message and add attachments. */
    const updateFeedback = () => {
        setLoading(true);

        if (formValidator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            setLoading(false);
            return;
        }

        if (auth.canCreateFeedbackAdmin && userData.IsSnellmanUser) {
            feedbackApi
                .updateFeedbackAdmin(constructFeedbackAdminUpdatePayload())
                .then((response) => {
                    if (!response) {
                        enqueueSnackbar(`${t('AnelmaCommunication:1099')}, 'error'`);
                        setLoading(false);
                        return;
                    }

                    enqueueSnackbar(t('AnelmaCommunication:1100'), { variant: 'success' });
                    updateToSummaries(response.Entity);
                    setLoading(false);
                    onClose();
                });
        } else {
            feedbackApi
                .createFeedbackMessage(constructFeedbackMessagesPayload())
                .then((response) => {
                    if (!response) {
                        enqueueSnackbar(`${t('AnelmaCommunication:1099')}, 'error'`);
                        setLoading(false);
                        return;
                    }

                    enqueueSnackbar(t('AnelmaCommunication:1100'), { variant: 'success' });
                    updateToSummaries(response.Entity);
                    setLoading(false);
                    onClose();
                });
        }
    };

    const constructFeedbackAdminUpdatePayload = (): IUpdateFeedbackRequest => {
        return {
            FeedbackId: feedback?.Id ?? 0, // checked on validateFeedbackData function
            Topic: parseInt(selectedSubject),
            Status: selectedStatus !== null && selectedStatus !== '' ? parseInt(selectedStatus) : 0,
            HandlerPersonId: selectedHandlerPerson !== null ? selectedHandlerPerson : '',
            Attachments: attachments?.map((attachment) => {
                return { File: attachment };
            }),
            Messages:
                messageBody.length > 0
                    ? [
                          {
                              FeedbackId: feedback?.Id ?? 0, // checked on validateFeedbackData function
                              MessageSenderId: userData.Id ?? '', // checked on validateFeedbackData function
                              Body: messageBody.trim(),
                              Archived: false,
                              Deleted: false,
                          },
                      ]
                    : [],
            Reason: selectedReason !== null && selectedReason !== '' ? parseInt(selectedReason) : 0,
            BovineIdentifiers: isNotNil(euIdentifiers) ? euIdentifiers : undefined,
        };
    };

    const constructFeedbackMessagesPayload = (): IFeedbackMessageRequest => {
        return {
            FeedbackId: feedback?.Id ?? 0, // checked on validateFeedbackData function
            Body: messageBody.trim(),
            MessageSenderId: userData.Id ?? '', // checked on validateFeedbackData function
            Attachments: attachments?.map((attachment) => {
                return { File: attachment };
            }),
        };
    };

    /** Combines current participants state array and Snellman group users and filters duplicate values out.
     *  Also checks if logged in user is part of combined participants.
     *  In case not, then apply the user to the array and set values to participants state.
     *
     * @returns IParticipant[]
     */
    const checkValidityOfParticipants = (): IParticipant[] => {
        const combinedParticipants = findUniqueParticipants(participants, snellmanGroupUsers);

        if (userData.Id && userData.FirstName && userData.LastName)
            return appendLoggedInUserToParticipants(combinedParticipants);

        return combinedParticipants;
    };

    const appendLoggedInUserToParticipants = (
        combinedParticipants: IParticipant[]
    ): IParticipant[] => {
        const loggedInUserIsParticipant = combinedParticipants.find((_) => _.Id === userData.Id);

        if (!loggedInUserIsParticipant) {
            combinedParticipants.concat(
                ...mapContactPersonsAsParticipants(
                    [mapPersonDetailsToContactPerson(userData)],
                    FeedbackParticipantRoleEnum.OriginalSender,
                    ReadTypeEnum.Read
                )
            );
        } else if (loggedInUserIsParticipant) {
            const userIdx = combinedParticipants.findIndex((_) => _.Id === userData.Id);

            if (userIdx !== -1) {
                combinedParticipants[userIdx].ParticipantRole =
                    FeedbackParticipantRoleEnum.OriginalSender;
                combinedParticipants[userIdx].ReadType = ReadTypeEnum.Read;
            }
        }

        setParticipants(combinedParticipants);

        return combinedParticipants;
    };

    const validateFeedbackData = (): boolean => {
        if (formValidator.invalid || loading) return true;
        else if (formMode === 'create') {
            if (
                selectedCompany === '' ||
                selectedSubject === '' ||
                selectedSubject === null ||
                messageBody.length <= 0 ||
                participants.length <= 0 ||
                userData.Id === null ||
                userData.Id === '' ||
                (isReclamationSubjectSelected() && selectedReason === '') ||
                selectedReason === null
            )
                return true;
        } else if (formMode === 'modify' && userData.IsSnellmanUser) {
            if (
                feedback?.Id === 0 ||
                !feedback?.Id ||
                selectedCompany === '' ||
                selectedSubject === '' ||
                selectedSubject === null ||
                userData.Id === null ||
                userData.Id === '' ||
                !auth.canModifyFeedbackAdmin
            )
                return true;
        } else if (formMode === 'modify' && !userData.IsSnellmanUser) {
            if (
                feedback?.Id === 0 ||
                !feedback?.Id ||
                userData.Id === null ||
                userData.Id === '' ||
                messageBody.length <= 0
            )
                return true;
        }
        return false;
    };

    const handleBovineSave = (euIds: string[]) => {
        setBovineOptions((prevItems) => {
            const uniqueState = new Set(prevItems.map((item) => item));
            const euIdsToAdd = euIds.filter((item) => !uniqueState.has(item));
            return [...prevItems, ...euIdsToAdd];
        });
        setEuIdentifiers((prevItems) => {
            const uniqueState = new Set(prevItems.map((item) => item));
            const euIdsToAdd = euIds.filter((item) => !uniqueState.has(item));
            return [...prevItems, ...euIdsToAdd];
        });
    };

    const isReclamationFeedback = () => {
        if (parseInt(selectedSubject) === 4) return true;
        return false;
    };

    const isReclamationSubjectSelected = () => {
        if (parseInt(selectedSubject) === 4 || isPayloadFeedback()) return true;
        return false;
    };

    const isPayloadFeedback = () => {
        if (parseInt(selectedSubject) === 7 || parseInt(selectedSubject) === 10) return true;
        return false;
    };

    /** In some cases disable AAutocomplete for topic/subject component.
     *  More information from suunnitteludokumentti.
     */
    const isTopicAutocompleteDisabled = () => {
        if (formMode === 'modify' && !userData.IsSnellmanUser) return true;
        if (
            formMode === 'create' &&
            (auth.hasDriverAccess || auth.hasTransportOperatorAccess) &&
            selectedSubject === '10'
        )
            return true;

        return false;
    };

    /** Filter certain topic from params array depending on what "access" the user has.
     *  More information from suunnitteludokumentti.
     * @param params - ParameterValues
     * @returns
     */
    const getTopicAutocompleteItems = (params: ParameterValues) => {
        if (auth.hasDriverAccess || auth.hasTransportOperatorAccess) {
            return params
                .map((_) => ({
                    text: _.text,
                    value: _.code,
                }))
                .filter((_) => _.value === '10');
        } else if (userData.IsSnellmanUser) {
            return params.map((_) => ({
                text: _.text,
                value: _.code,
            }));
        }

        return params
            .map((_) => ({
                text: _.text,
                value: _.code,
            }))
            .filter((_) => _.value !== '10');
    };

    /** If selectedSubject is reclamation feedback display all status parameters as items for the autocomplete. Otherwise omit maksettu from parameters.
     *  More information from suunnitteludokumentti.
     *
     * @param params - ParameterValues
     * @returns
     */
    const getCommunicationStatusItems = (params: ParameterValues) => {
        if (parseInt(selectedSubject) === 4) {
            return params.map((_) => ({
                text: _.text,
                value: _.code,
            }));
        }

        return params
            .map((_) => ({
                text: _.text,
                value: _.code,
            }))
            .filter((_) => _.value !== '4');
    };

    const footerActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: onClose,
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () => onSave(),
                type: 'send',
                disabled: validateFeedbackData(),
            },
        ],
    };

    return (
        <>
            <ADialog data-robot-id={SelectorConstants.FeedbackDialog} open onClose={onClose}>
                <ADialogTitle>{getDialogTitle()}</ADialogTitle>
                <ADialogContent size='md' isLoading={loading}>
                    <Grid container direction='column'>
                        <CompanySelection
                            onSelectedCompanyChange={(_: string) => setSelectedCompany(_)}
                            formMode={formMode}
                        />

                        <Grid item sm={12}>
                            {communicationSubjectParameters.length > 0 && (
                                <AAutocomplete
                                    id={`${SelectorConstants.FeedbackDialog}-topic-autocomplete`}
                                    label={t('AnelmaCommunication:1001')}
                                    onChange={(_) => setSelectedSubject(_ as string)}
                                    value={selectedSubject}
                                    items={getTopicAutocompleteItems(
                                        communicationSubjectParameters
                                    )}
                                    validator={formValidator}
                                    required={formMode !== 'modify'}
                                    disabled={isTopicAutocompleteDisabled()}
                                />
                            )}
                        </Grid>

                        {isReclamationSubjectSelected() && (
                            <ReclamationFeedbackContext.Provider
                                value={{
                                    loading: loading,
                                    formMode: formMode,
                                    selectedReason: selectedReason,
                                    bovineOptions: bovineOptions,
                                    euIdentifiers: euIdentifiers,
                                    setSelectedReason: (_) => setSelectedReason(_),
                                    setEuIdentifiers: (_) => setEuIdentifiers(_),
                                    selectedCompany: selectedCompany,
                                    selectedSubject: parseInt(selectedSubject),
                                }}
                            >
                                <ReclamationFeedback onSave={(_) => handleBovineSave(_)} />
                            </ReclamationFeedbackContext.Provider>
                        )}
                        {isPayloadFeedback() && (
                            <Grid item xs={8} sm={8} md={8} lg={8}>
                                <ANumberInput
                                    id={`${SelectorConstants.FeedbackDialog}-payload-number-input`}
                                    label={t('AnelmaCommunication:1114')}
                                    mode='int'
                                    onChange={(v) => setPayloadNumber(v as number)}
                                    value={payloadNumber === 0 ? null : payloadNumber}
                                    validator={formValidator}
                                    min={1}
                                    required
                                    error={payloadNumber < 0}
                                    disabled={formMode === 'modify'}
                                />
                            </Grid>
                        )}

                        {formMode === 'create' && !auth.canCreateFeedbackAdmin ? (
                            <></>
                        ) : (
                            snellmanContactPersons.length > 0 &&
                            communicationStatusParameters.length > 0 && (
                                <>
                                    <Grid item sm={12}>
                                        <AAutocomplete
                                            id={`${SelectorConstants.FeedbackDialog}-status-autocomplete`}
                                            label={t('AnelmaCommunication:1038')}
                                            onChange={(_) => setSelectedStatus(_ as string)}
                                            value={selectedStatus}
                                            items={getCommunicationStatusItems(
                                                communicationStatusParameters
                                            )}
                                            validator={formValidator}
                                            disabled={!auth.canCreateFeedbackAdmin}
                                            required={
                                                isNotNil(selectedHandlerPerson) &&
                                                selectedHandlerPerson.length > 0
                                            }
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <AAutocomplete
                                            id={`${SelectorConstants.FeedbackDialog}-handler-person-autocomplete`}
                                            label={t('AnelmaCommunication:1111')}
                                            onChange={(_) => setSelectedHandlerPerson(_ as string)}
                                            value={selectedHandlerPerson}
                                            items={snellmanContactPersons.map((_) => ({
                                                text: _.Name,
                                                value: _.Id,
                                            }))}
                                            validator={formValidator}
                                            disabled={!auth.canCreateFeedbackAdmin}
                                            required={
                                                isNotNil(selectedStatus) &&
                                                selectedStatus.length > 0
                                            }
                                        />
                                    </Grid>
                                </>
                            )
                        )}

                        <Grid item sm={12}>
                            <Grid
                                data-robot-id={`${SelectorConstants.FeedbackDialog}-attachments-dropzone`}
                                item
                                sm={12}
                                sx={{ paddingLeft: '40px', paddingRight: '40px' }}
                            >
                                <ALabel>{t('AnelmaCommunication:1115')}</ALabel>
                                <AttachmentDropzone
                                    acceptedFiles={attachmentsConfig.acceptedFiles}
                                    maxFileSize={attachmentsConfig.maxFileSize}
                                    filesLimit={attachmentsConfig.filesLimit}
                                    Text={t('AnelmaCommunication:1116')}
                                    onAttachmentsChanged={(_) => setAttachments(_)}
                                />
                            </Grid>
                            {formMode === 'modify' && <FeedbackAttachments />}
                        </Grid>
                        <Grid item sm={12}>
                            <ATextarea
                                id={`${SelectorConstants.FeedbackDialog}-message-body`}
                                data-robot-id={`${SelectorConstants.FeedbackDialog}-message-body`}
                                label={t('AnelmaCommunication:1069')}
                                onChange={(_) => setMessageBody(_)}
                                value={messageBody}
                                lengthBoundaryName={'AnelmaCommunication:MessageBodyLength'}
                                validator={formValidator}
                                required={formMode !== 'modify'}
                            />
                        </Grid>

                        {formMode === 'modify' && feedback && (
                            <MessageList
                                messages={mapFeedbackMessagesToMessageList(feedback)}
                                loggedInUserId={userData?.Id}
                            />
                        )}
                    </Grid>
                </ADialogContent>

                <ADialogActions buttons={footerActionButtons} />
            </ADialog>
        </>
    );
}
