/**
 * Nothing fancy here could have been number.toFixed(2) in use
 * try out for class
 */
class InvoiceMathHelper {
    private locale: string = 'fi-FI';

    valueIsInValid(_: number) {
        if (_ === null || _ === undefined || _ === 0 || isNaN(_)) return true;
        return false;
    }

    round(_: number, fractionDigits: number): string {
        return (Math.round(_ * 100) / 100).toLocaleString(this.locale, {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            });
    }

    twoleadingZeros(value: number) {
        if (Number.isInteger(value)) return value.toFixed(2);
        return this.round(value, 2)
    }

    roundUpValue(value: number) {
        if (this.valueIsInValid(value)) return '';
        return this.round(value, 2);
    }

    roundThousandLeading(value: number) {
        if (this.valueIsInValid(value)) return '';
        return this.round(value, 2);
    }

    thousandLeading(value: number) {
        if (this.valueIsInValid(value)) return '';
        return this.round(value, 2);
    }
}

export default new InvoiceMathHelper();
