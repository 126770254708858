// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, InputAdornment, Tooltip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

// Core
import defaultData from '../../core/defaultData';
import { ResourceTextApplication } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';
import utils from '../../core/utils';

// Common
import { ADropdown, AInputContainer, APhoneNumberInput, ADropdownValue } from '../../common/inputs';
import { AAddButton, ADeleteIconButton } from '../../common/buttons';

// Interfaces
import { PhoneNumberTypeEnum } from '../../interfaces/enums';
import { IPhoneNumber } from '../../interfaces/IBusinessEntities';

// Feature
import './phoneNumbersInputList.scss';

export interface APhoneNumbersInputListProps {
    defaultPhoneNumberType: PhoneNumberTypeEnum;
    id: string;
    data: IPhoneNumber[];
    phoneNumberTypeParam: string;
    onChange: (phoneNumbers: IPhoneNumber[]) => void;
    required?: boolean;
    validator: IFormValidator;
    disabled?: boolean;
    hideAdditionalElements?: boolean;
}

export default function APhoneNumbersInputList(props: APhoneNumbersInputListProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { data } = props;

    const [uniqueIds, setUniqueIds] = useState<string[]>([]);
    const [phoneNumbers, setPhoneNumbers] = useState<IPhoneNumber[]>([]);

    useEffect(() => {
        // Add unique ids for validation
        data.forEach((a: any) => {
            if (!a._i) a._i = utils.generateUniqueId();
        });

        // Remove validation states for unused ids
        const newIds = data.map((a: any) => a._i as string);
        const unused = uniqueIds.filter((u) => !newIds.includes(u));
        unused.forEach((u) => {
            props.validator.removeState(phoneNumberId(u));
        });

        setUniqueIds(newIds);
        setPhoneNumbers(data);
    }, [data]);

    enum PhoneNumberInputTypeEnum {
        PhoneNumber = 'PhoneNumber',
        Type = 'Type',
    }

    const addPhoneNumber = () => {
        let newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers.push(defaultData.phoneNumber(props.defaultPhoneNumberType, false));
        props.onChange(newPhoneNumbers);
    };

    const setDefault = (defaultIndex: number): void => {
        const oldData = [...phoneNumbers];
        const newData = oldData.map((phoneNumber, index) => {
            let newPhoneNumber = { ...phoneNumber };
            newPhoneNumber.IsDefault = index === defaultIndex;
            return newPhoneNumber;
        });
        props.onChange(newData);
    };

    const updatePhoneNumber = (
        value: IPhoneNumber | ADropdownValue,
        index: number,
        property: string
    ) => {
        const newPhoneNumbers = [...phoneNumbers];
        if (!newPhoneNumbers[index]) return;

        switch (property) {
            case PhoneNumberInputTypeEnum.PhoneNumber:
                newPhoneNumbers[index] = value as IPhoneNumber;
                break;
            case PhoneNumberInputTypeEnum.Type:
                newPhoneNumbers[index].Type = value as PhoneNumberTypeEnum;
                break;
            default:
                break;
        }
        props.onChange(newPhoneNumbers);
    };

    const deletePhoneNumber = (i: number, id: string) => {
        const newNumbers = phoneNumbers.filter((p, index) => i !== index);
        if (newNumbers.length === 0) {
            newNumbers.push(defaultData.phoneNumber(props.defaultPhoneNumberType, true));
        } else if (!newNumbers.find((n) => n.IsDefault)) {
            newNumbers[0].IsDefault = true;
        }
        props.onChange(newNumbers);
    };

    const getInputAdornment = (isDefault: boolean, updateIsDefault: Function) => {
        return isDefault ? (
            <InputAdornment position='start'>
                <Tooltip title={t('AnelmaGeneral:1011') as string}>
                    <CheckCircleOutlineIcon />
                </Tooltip>
            </InputAdornment>
        ) : (
            <InputAdornment position='start' onClick={() => updateIsDefault()}>
                <Tooltip title={t('AnelmaGeneral:1021') as string}>
                    <RadioButtonUncheckedIcon />
                </Tooltip>
            </InputAdornment>
        );
    };

    const phoneNumberId = (id: string): string => `${props.id}-phone-number-${id}`;

    const phoneNumbersElement = phoneNumbers.map((p, i) => {
        const id = (p as any)._i;
        return (
            <Grid container key={i}>
                <Grid item xs={12}>
                    <div className='an-phone-numbers'>
                        <div className='an-phone-numbers-phone-number'>
                            <APhoneNumberInput
                                defaultSelector={getInputAdornment(p.IsDefault, () =>
                                    setDefault(i)
                                )}
                                id={phoneNumberId(id)}
                                onChange={(v) =>
                                    updatePhoneNumber(v, i, PhoneNumberInputTypeEnum.PhoneNumber)
                                }
                                label={i === 0 ? t('AnelmaGeneral:1016') : undefined}
                                placeholder={t('AnelmaGeneral:1015')}
                                required={props.required}
                                validator={props.validator}
                                value={p}
                                disabled={props.disabled}
                            />
                        </div>

                        <div className='an-phone-numbers-type'>
                            <ADropdown
                                id={`phone-number-type-${i}`}
                                onChange={(v) =>
                                    updatePhoneNumber(v, i, PhoneNumberInputTypeEnum.Type)
                                }
                                parameterName={props.phoneNumberTypeParam}
                                validator={props.validator}
                                value={p.Type || props.defaultPhoneNumberType}
                                disabled={props.disabled}
                            />
                        </div>

                        {props.hideAdditionalElements ? (
                            <></>
                        ) : (
                            <div className='an-phone-numbers-delete'>
                                <ADeleteIconButton
                                    onClick={() => deletePhoneNumber(i, id)}
                                    disabled={props.disabled}
                                />
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
        );
    });

    return (
        <>
            {phoneNumbersElement}

            {props.hideAdditionalElements ? (
                <></>
            ) : (
                <Grid container>
                    <Grid item sm={12}>
                        <AInputContainer>
                            <AAddButton
                                onClick={addPhoneNumber}
                                size='small'
                                disabled={props.disabled}
                            >
                                {t('AnelmaGeneral:1005')}
                            </AAddButton>
                        </AInputContainer>
                    </Grid>
                </Grid>
            )}
        </>
    );
}
