// Libraries
import { TFunction } from 'i18next';

// MUI
// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
// Interfaces
import { INotification, NotificationTypeEnum } from '../../interfaces/INotification';

// Store
// API
// Feature - Notifications

/** Construct resource text
 *
 * @param t tfunction
 * @param notification INotification
 * @param sender string
 * @param topic string
 * @returns notification
 */
export const getConstructedMessageNotification = (
    t: TFunction<ResourceTextApplication[], ResourceTextApplication[]>,
    notification: INotification,
    sender: string,
    topic: string
) => {
    switch (notification.TypeId) {
        case NotificationTypeEnum.NewMessageInNewConversation:
            notification.ConversationResourceTxt = t('AnelmaNotifications:1004')
                .replace('{SenderUser}', sender)
                .replace('{Subject}', topic);
            return notification;
        case NotificationTypeEnum.NewMessageInOldConversation:
            notification.ConversationResourceTxt = t('AnelmaNotifications:1005')
                .replace('{SenderUser}', sender)
                .replace('{Subject}', topic);
            return notification;
        default:
            return notification;
    }
};

export const getUniqueParticipants = (participants: string[]) => {
    return participants.filter((_, idx, arr) => arr.indexOf(_) === idx);
};
