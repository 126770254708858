import { IContactPersonRole, IContactPersonSummary, IProductionLine } from './IBusinessEntities';

export enum SearchGroup {
    Unknown = 0,
    Place = 1,
    Bovine = 2,
    Pig = 3,
    Person = 4,
}

export enum SearchType {
    Unknown = 0,
    County = 1,
    Municipality = 2,
    ReturnExpiredOnes = 3,
    Species = 4,
    AllowedIncommingMessages = 5, // postituslupa
    Language = 6,
    FeedSupplier = 7,
    Dairy = 8,
    /** FarmingType Generic*/
    FarmingTypeGeneric = 9,

    PersonLanguage = 101,
    PersonTitle = 102,
    PersonPhoneNumberType = 103,
}

export interface ISearchParameter {
    Group: SearchGroup;
    Type: SearchType;
    Value: string[];
}

export interface IPredefinedSearch {
    GUID: string;
    Name: string;
    Modificator: string;
    ModificationDate: Date;
    Parameters: ISearchParameter[];
}

export interface IPredefinedSearchFarmResult {
    FarmGuid: string;
    ProducerId: string;
    FarmId: string;
    Name: string;
    ContactPersons: IContactPersonSummary[];
    ProductionLines: IProductionLine[];
    RecipientName?: string;
    CompanyPhoneNumber?: string;
    Roles?: IContactPersonRole[];
    RecipientTitle?: string[];
    UserId?: string;
    CommunicationGroup?: string;
}

export interface IPredefinedSearchQuery {
    PredefinedSearchGuid: string;
    WithContactPersons: boolean | null;
    IncludeWithoutContactPersons: boolean | null;
}
