// Libraries
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { isNotNil } from 'ramda';

// MUI
import { Grid, Typography } from '@mui/material';

// Core
import { ResourceTextApplication } from '../core/resources';
import defaultData from '../core/defaultData';
import { dayFormat } from '../core/constants';
import { IFormValidator } from '../core/FormValidator';
import auth from '../core/authorization';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogTitle,
    ADialogSubHeading,
    ADialogContent,
    ConfirmDeletionDialog,
} from '../common/dialog';
import { AEmailAddressInput, APhoneNumberInput, ATextInput } from '../common/inputs';
import { InfoBlock } from '../common/typography';
import { AAddButton, ADeleteIconButton } from '../common/buttons';

// Interfaces
import { IFarmDetails, IPersonSummary, IPhoneNumber } from '../interfaces/IBusinessEntities';
import { IEvent, IRegistrationForm } from '../interfaces/IEvent';
import { EventDialogMode } from '../interfaces/enums';

// Store
import { useAppSelector } from '../store/hooks';

// API
import eventsApi from '../api/eventsApi';
import companyApi from '../api/companyApi';

// Feature - Content Editor
import RenderContent from '../editableContent/RenderContent';

export interface EventRegistrationDialogProps {
    admin: boolean;
    data: IEvent;
    user: IPersonSummary | null;
    onClose: () => void;
    onSave: (data: IRegistrationForm[], mode: EventDialogMode) => any;
    validator: IFormValidator;
    mode: EventDialogMode;
    loading?: boolean;
    publicSite?: boolean;
}

export default function EventRegistrationDialog(props: EventRegistrationDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaEvents']);

    const context = useAppSelector((state) => state.context.data);
    const userData = useAppSelector((state) => state.userData.data);
    const loginStatus = useAppSelector((state) => state.loginStatus.data);
    const langConfig = useAppSelector((state) => state.configuration.selectedCulture);

    const { enqueueSnackbar } = useSnackbar();
    const [showConfirmDeletion, setShowConfirmDeletion] = useState<boolean>(false);
    const [showConfirmDeleteSingle, setShowConfirmDeleteSingle] = useState<boolean>(false);
    const [mode, setMode] = useState<EventDialogMode>(props.mode);
    const [eventValidFrom] = useState<moment.Moment>(moment(props.data.EventValidFrom));
    const [eventValidDue] = useState<moment.Moment>(moment(props.data.EventValidDue));
    const [registrationValidFrom] = useState<moment.Moment>(
        moment(props.data.RegistrationValidFrom)
    );
    const [registrationValidDue] = useState<moment.Moment>(moment(props.data.RegistrationValidDue));
    const [personsToSave, setPersonsToSave] = useState<IRegistrationForm[]>([]);
    const [idForDeletion, setIdForDeletion] = useState<string>();
    const [attendeesEdit, setAttendeesEdit] = useState<boolean>(false);
    const [targetFarmId, setTargetFarmId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(props.loading ?? false);

    useEffect(() => {
        if (props.user !== null) initializeRegistrationData();
        else {
            if (props.data.Registrants.length > 0) {
                setMode(EventDialogMode.Edit);
                setAttendeesEdit(true);
                setPersonsToSave(props.data.Registrants);
            } else {
                setMode(EventDialogMode.Save);
                setPersonsToSave([defaultData.registrationForm(props.data.Id, userData.Id)]);
            }
        }
    }, []);

    useEffect(() => {
        if (!props.publicSite) {
            if (userData.CompanyRelations.length > 1) {
                if (context.currentContext) {
                    setLoading(true);
                    companyApi
                        .getCompanyByContext(context.currentContext?.context)
                        .then((response) => {
                            if (!response) {
                                setLoading(false);
                                enqueueSnackbar(t('AnelmaSolmuErrors:10009'), {
                                    variant: 'error',
                                });
                                return;
                            }
                            setTargetFarmId((response.Entity as IFarmDetails).Id ?? '');
                            setLoading(false);
                        });
                }
            } else setTargetFarmId(userData.CompanyRelations[0].CompanyId);
        }
    }, [context, userData]);

    /** Get registration related data and initialize necessary states.
     * Implementation was moved to it's own function and straightlined when working on ticket: AN-1348 */
    const initializeRegistrationData = () => {
        setLoading(true);

        eventsApi.checkRegistration(userData.Id, props.data.Id).then((response) => {
            if (!response) {
                enqueueSnackbar(t('AnelmaEvents:1058'), {
                    variant: 'error',
                });
                setPersonsToSave([defaultData.registrationForm(props.data.Id, userData.Id)]);
                setLoading(false);
                return;
            }

            setMode(EventDialogMode.Edit);
            setPersonsToSave(response.Items);
            setLoading(false);
        });
    };

    const checkReadOnly = () => {
        if (props.admin === true) {
            return false;
        }
        let today = moment();
        let from = moment(props.data.RegistrationValidFrom).startOf('day');
        let to = moment(props.data.RegistrationValidDue).endOf('day');
        if (from <= today && to >= today) {
            return false;
        } else {
            return true;
        }
    };

    const deleteDisabled = () => {
        return checkReadOnly() || personsToSave.length <= 1 || loading;
    };

    const cancelDisabled = () => {
        if (props.admin === true && mode !== EventDialogMode.Edit && auth.canDeleteEventEnrolment) {
            return true;
        } else {
            return checkReadOnly() || mode === EventDialogMode.Save;
        }
    };

    const setRegistrationTime = () => {
        let tempArray = [...personsToSave];
        let currentTime = moment().format('YYYY-MM-DDTHH:mm:ss');
        for (let i = 0; i < tempArray.length; i++) {
            tempArray[i].RegistrationTime = currentTime;
        }
    };

    const prepareSave = (modeChange: string) => {
        setLoading(true);

        if (props.validator.invalid && modeChange !== EventDialogMode.Delete) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            setLoading(false);
            return;
        }

        setRegistrationTime();

        let saveableMode = mode;

        if (modeChange === EventDialogMode.Delete) {
            saveableMode = modeChange;

            const newValues = [...personsToSave];
            newValues.forEach((x) => (x.Flags = 1));
            setPersonsToSave(newValues);
        }

        const currentRegistree = [...personsToSave];

        currentRegistree.forEach((x) => {
            if (x.Flags === -1) {
                x.Flags = 0;
                x.Id = '';
            }
            x.FarmId = targetFarmId;
        });

        props.onSave(currentRegistree, saveableMode);
    };

    const setBodyText = () => {
        if (langConfig === 'fi') {
            return props.data.BodyTextFI;
        } else if (langConfig === 'sv-SE') {
            return props.data.BodyTextSE;
        } else if (langConfig === 'en-GB') {
            return props.data.BodyTextEN;
        }
    };

    const setHeader = () => {
        if (langConfig === 'fi') {
            return props.data.HeaderFI;
        } else if (langConfig === 'sv-SE') {
            return props.data.HeaderSE;
        } else if (langConfig === 'en-GB') {
            return props.data.HeaderEN;
        }
    };

    const addNewPerson = () => {
        setPersonsToSave((previousState) => [
            ...previousState,
            defaultData.registrationForm(props.data.Id, userData.Id),
        ]);
    };

    const prepareDelete = (id: string) => {
        setIdForDeletion(id);
        setShowConfirmDeleteSingle(true);
    };

    const deletePerson = () => {
        const currentRegistree = [...personsToSave];

        let index = currentRegistree.findIndex((x) => x.Id === idForDeletion);

        if (currentRegistree.findIndex((x) => x.Id === idForDeletion && x.Flags !== -1) === -1) {
            currentRegistree.splice(index, 1);
        } else {
            currentRegistree[index].Flags = 1;
        }

        setPersonsToSave(currentRegistree);

        props.validator.removeState('first-name ' + idForDeletion);
        props.validator.removeState('last-name ' + idForDeletion);
        props.validator.removeState('phone-number ' + idForDeletion);
        props.validator.removeState('email ' + idForDeletion);
        props.validator.removeState('diet ' + idForDeletion);
        props.validator.removeState('additional-info ' + idForDeletion);
    };

    const getRegistrationDate = () => {
        let invalid = moment.invalid().toString();
        if (
            registrationValidFrom.format(dayFormat) !== invalid &&
            registrationValidDue.format(dayFormat) !== invalid
        ) {
            return `${moment(registrationValidFrom).format(dayFormat)} - ${moment(
                registrationValidDue
            ).format(dayFormat)}`;
        } else {
            return '-';
        }
    };

    const updateFirstName = (id: string, value: string) => {
        const newValues = [...personsToSave];

        let index = newValues.findIndex((x) => x.Id === id);
        newValues[index].FirstName = value;
        setPersonsToSave(newValues);
    };

    const updateLastName = (id: string, value: string) => {
        const newValues = [...personsToSave];

        let index = newValues.findIndex((x) => x.Id === id);
        newValues[index].LastName = value;
        setPersonsToSave(newValues);
    };

    const updatePhoneNumber = (id: string, value: IPhoneNumber) => {
        const newValues = [...personsToSave];

        let index = newValues.findIndex((x) => x.Id === id);
        newValues[index].PhoneNumber = value.NormalizedNumber;
        setPersonsToSave(newValues);
    };

    const updateEmail = (id: string, value: string) => {
        const newValues = [...personsToSave];

        let index = newValues.findIndex((x) => x.Id === id);
        newValues[index].Email = value;
        setPersonsToSave(newValues);
    };

    const updateDiet = (id: string, value: string) => {
        const newValues = [...personsToSave];

        let index = newValues.findIndex((x) => x.Id === id);
        newValues[index].Diet = value;
        setPersonsToSave(newValues);
    };

    const updateAdditionalInformation = (id: string, value: string) => {
        const newValues = [...personsToSave];

        let index = newValues.findIndex((x) => x.Id === id);
        newValues[index].AdditionalInformation = value;
        setPersonsToSave(newValues);
    };

    return (
        <ADialog open onClose={() => props.onClose()}>
            <ADialogTitle>{t('AnelmaEvents:1011')}</ADialogTitle>

            <ADialogContent size='md' isLoading={loading}>
                <ADialogSubHeading>{setHeader()}</ADialogSubHeading>

                <Grid container style={{ paddingLeft: '40px' }}>
                    <Grid item sm={4}>
                        <InfoBlock
                            label={t('AnelmaEvents:1010')}
                            value={`${eventValidFrom.format(dayFormat)} - ${eventValidDue.format(
                                dayFormat
                            )}`}
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <InfoBlock label={t('AnelmaEvents:1040')} value={getRegistrationDate()} />
                    </Grid>

                    <Grid item sm={4}>
                        <InfoBlock
                            label={t('AnelmaEvents:1004')}
                            value={`${props.data.Location}`}
                        />
                    </Grid>
                </Grid>

                <Grid container style={{ padding: '40px' }}>
                    <RenderContent publicSite={!loginStatus.loggedIn} id={props.data.ContentId} />

                    {setBodyText()}
                </Grid>

                {personsToSave
                    .filter((x) => x.Flags !== 1)
                    .map((item, index) => {
                        return (
                            <Grid container key={item.Id} style={{ marginBottom: '20px' }}>
                                <Grid item sm={11}>
                                    <Typography variant='h5' style={{ paddingLeft: '40px' }}>
                                        {`${t('AnelmaEvents:1042')} ${index + 1}`}
                                    </Typography>
                                    <Grid container>
                                        <Grid item sm={6}>
                                            <ATextInput
                                                id={`first-name ${item.Id}`}
                                                label={t('AnelmaEvents:1031')}
                                                value={item.FirstName}
                                                onChange={(v) => updateFirstName(item.Id, v)}
                                                validator={props.validator}
                                                disabled={checkReadOnly() || loading}
                                                minLength={2}
                                                maxLength={64}
                                                required
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <ATextInput
                                                id={`last-name ${item.Id}`}
                                                label={t('AnelmaEvents:1032')}
                                                value={item.LastName}
                                                onChange={(v) => updateLastName(item.Id, v)}
                                                validator={props.validator}
                                                disabled={checkReadOnly() || loading}
                                                minLength={2}
                                                maxLength={64}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item sm={6}>
                                            <APhoneNumberInput
                                                id={`phone-number ${item.Id}`}
                                                label={t('AnelmaGeneral:1015')}
                                                value={{
                                                    IsDefault: true,
                                                    NormalizedNumber: item.PhoneNumber,
                                                    Type: 53,
                                                }}
                                                onChange={(v) => updatePhoneNumber(item.Id, v)}
                                                disabled={checkReadOnly() || loading}
                                                validator={props.validator}
                                                required
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <AEmailAddressInput
                                                id={`email ${item.Id}`}
                                                label={t('AnelmaGeneral:1013')}
                                                value={item.Email}
                                                onChange={(v) => updateEmail(item.Id, v)}
                                                disabled={checkReadOnly() || loading}
                                                validator={props.validator}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item sm={6}>
                                            <ATextInput
                                                id={`diet ${item.Id}`}
                                                label={t('AnelmaGeneral:1157')}
                                                value={item.Diet}
                                                onChange={(v) => updateDiet(item.Id, v)}
                                                disabled={checkReadOnly() || loading}
                                                validator={props.validator}
                                                maxLength={8000}
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <ATextInput
                                                id={`additional-info ${item.Id}`}
                                                label={t('AnelmaGeneral:1137')}
                                                value={item.AdditionalInformation}
                                                disabled={checkReadOnly() || loading}
                                                onChange={(v) =>
                                                    updateAdditionalInformation(item.Id, v)
                                                }
                                                validator={props.validator}
                                                maxLength={8000}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={1} style={{ paddingTop: '110px' }}>
                                    <ADeleteIconButton
                                        key={item.Id}
                                        disabled={deleteDisabled()}
                                        onClick={() => prepareDelete(item.Id)}
                                    ></ADeleteIconButton>
                                </Grid>

                                <ConfirmDeletionDialog
                                    open={showConfirmDeletion}
                                    onClose={() => setShowConfirmDeletion(false)}
                                    onDelete={() => prepareSave('delete')}
                                    confirmation={t('AnelmaEvents:1037')}
                                />

                                <ConfirmDeletionDialog
                                    key={item.Id}
                                    open={showConfirmDeleteSingle}
                                    onClose={() => setShowConfirmDeleteSingle(false)}
                                    onDelete={() => deletePerson()}
                                    confirmation={t('AnelmaEvents:1039')}
                                />
                            </Grid>
                        );
                    })}
                <Grid container style={{ paddingRight: '40px', justifyContent: 'flex-end' }}>
                    <Grid item>
                        <AAddButton
                            size='small'
                            onClick={() => addNewPerson()}
                            disabled={checkReadOnly() || attendeesEdit || loading}
                        >
                            {t('AnelmaEvents:1030')}
                        </AAddButton>
                    </Grid>
                </Grid>
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            enabledWhileLoading: true,
                            label: t('AnelmaGeneral:1009'),
                            onClick: props.onClose,
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            label: t('AnelmaEvents:1026'),
                            disabled: cancelDisabled() || loading,
                            onClick: () => {
                                setShowConfirmDeletion(true);
                            },
                            type: 'delete',
                        },
                        {
                            label: t('AnelmaGeneral:1001'),
                            disabled: checkReadOnly() || loading,
                            onClick: () => {
                                prepareSave('');
                            },
                            type: 'save',
                        },
                    ],
                }}
            ></ADialogActions>
        </ADialog>
    );
}
