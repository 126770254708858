import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Type } from 'typescript';
import { ResourceTextApplication } from '../../../core/resources';
import {
    IFarmTrend,
    IProductionPlan,
    IProductionPlanAccess,
} from '../../../interfaces/IProductionPlan';
import EditProductionAnimalDataDialog from '../data-grid/EditProductionAnimalDataDialog';
import ProductionPlanAnimalsDataGrid from '../data-grid/ProductionPlanAnimalDataGrid';
import { BuildFarmTrends, IProductionPlanDataGrid } from '../data-grid/ProductionPlanDataGrids';
import SectionTitle from '../data-grid/SectionTitle';
import AColorizedDialogSubHeader from '../../../common/dialog/ADialogColorizedSubHeader';

interface IProps {
    data: IProductionPlan;
    access: IProductionPlanAccess;
    readonly?: boolean;
    farmTrendsError: boolean;
    farmTrendsOpened: (b: boolean) => void;
    isNew: boolean;
}

export default function FarmTrends({
    data,
    access,
    readonly,
    farmTrendsError,
    farmTrendsOpened,
    isNew,
}: IProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaLayout',
        'AnelmaCattleProductionPlan',
    ]);

    const [farmTrends, setFarmTrends] = useState<IProductionPlanDataGrid>();
    const [editData, setEditData] = useState<IProductionPlanDataGrid>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const trendYears = [data.TargetYear + 1, data.TargetYear + 2, data.TargetYear + 3];

    const defaultFarmTrend = (year: number) => {
        return {
            Year: year,
            PlannedTotalAnimalAmount: calculateTotalAnimals(),
            PlannedDairyCowAmount:
                data.AnimalCapacities.find((i) => i.AnimalType === 1)?.Animals || 0,
            PlannedBreedingCowAmount:
                data.AnimalCapacities.find((i) => i.AnimalType === 2)?.Animals || 0,
            PlannedBeefBullAmount:
                data.AnimalCapacities.find((i) => i.AnimalType === 3)?.Capacity || 0,
            PlannedBeefHeiferAmount:
                data.AnimalCapacities.find((i) => i.AnimalType === 4)?.Capacity || 0,
            PlannedTransmissionCalfAmount:
                data.AnimalCapacities.find((i) => i.AnimalType === 5)?.Capacity || 0,
        };
    };

    const emptyTrend = (year: number) => {
        return {
            Year: year,
            PlannedTotalAnimalAmount: 0,
            PlannedDairyCowAmount: 0,
            PlannedBreedingCowAmount: 0,
            PlannedBeefBullAmount: 0,
            PlannedBeefHeiferAmount: 0,
            PlannedTransmissionCalfAmount: 0,
        };
    };

    useEffect(() => {
        setIsLoading(true);
        if (!data.FarmTrends) data.FarmTrends = [];
        if (isNew) {
            setFarmTrends(initializeFarmTrends());
        } else {
            setFarmTrends(BuildFarmTrends(access, data.FarmTrends, trendYears));
        }
        setIsLoading(false);
    }, []);

    const initializeFarmTrends = () => {
        let farmTrendsInitialization: IFarmTrend[] = [];

        for (let year of trendYears) {
            farmTrendsInitialization.push(defaultFarmTrend(year));
        }

        return BuildFarmTrends(access, farmTrendsInitialization, trendYears, true, true);
    };

    const calculateTotalAnimals = () => {
        let totalNumber = 0;
        for (var i = 1; i < 6; i++) {
            var item = data.AnimalCapacities.find((a) => a.AnimalType === i);
            if (item) {
                totalNumber = totalNumber + item.Animals || 0;
            }
        }
        return totalNumber;
    };

    const onSave = (newData: IProductionPlanDataGrid) => {
        const rows: Type[] = [];
        for (const row of newData.rows) {
            let counter = 0;
            for (let index = 0; index < trendYears.length; index++) {
                if (row[trendYears[index]] > 0) counter++;
                rows.push(row);
                break;
            }
        }
        newData.rows = rows;
        for (let row of newData.rows) {
            let trends: IFarmTrend[] = [];
            const field: keyof IFarmTrend = row.field;
            for (let year of trendYears) {
                let farmTrend = data.FarmTrends.find((x) => x.Year === year);
                if (!farmTrend) {
                    farmTrend = emptyTrend(year);
                    farmTrend[field] = row[farmTrend.Year.toString()] || 0;
                } else {
                    farmTrend[field] = row[farmTrend.Year.toString()] || 0;
                }
                trends.push(farmTrend);
            }
            data.FarmTrends = trends;
        }
        let farmTrnds = BuildFarmTrends(access, data.FarmTrends, trendYears, true, true);
        setFarmTrends(farmTrnds);
    };

    return (
        <>
            <Grid container style={{ border: farmTrendsError ? 'solid red' : 'none' }}>
                <Grid container direction='row' style={{ paddingLeft: 40 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SectionTitle
                            title={t('AnelmaLayout:1071')}
                            onEdit={() => {
                                if (isNew) {
                                    setEditData(farmTrends);
                                } else {
                                    setEditData(
                                        BuildFarmTrends(access, data.FarmTrends, trendYears, true)
                                    );
                                }
                            }}
                            readonly={readonly}
                        />
                    </Grid>
                    {data.TargetYear > 2024 && (
                        <AColorizedDialogSubHeader color='#FF5733'>
                            {t('AnelmaLayout:1087')}
                        </AColorizedDialogSubHeader>
                    )}

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ProductionPlanAnimalsDataGrid data={farmTrends} />
                    </Grid>
                </Grid>
                {editData && !readonly && isLoading === false && (
                    <Grid>
                        <EditProductionAnimalDataDialog
                            title={t('AnelmaLayout:1071')}
                            data={editData}
                            onSave={onSave}
                            onClose={() => {
                                setEditData(undefined);
                                farmTrendsOpened(true);
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
}
