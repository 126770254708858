// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import { ParameterValues, ResourceTextApplication, IParameterValue } from '../../core/resources';
import FormValidator from '../../core/FormValidator';

// Common
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../../common/dialog';
import { ASwitch } from '../../common/inputs';

// Interfaces
import { IContactPerson, IContactPersonRole } from '../../interfaces/IBusinessEntities';

export interface AccessRightsDialogProps {
    companyRoles: ParameterValues;
    onClose: () => void;
    onSave: (roles: IContactPersonRole[]) => void;
    roles: IContactPersonRole[];
    contactPerson: IContactPerson | null;
    readonly?: boolean;
}

export default function RolesDialog(props: AccessRightsDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaAccessRights',
        'AnelmaCompany',
        'AnelmaGeneral',
    ]);
    const validator = new FormValidator();

    const [personRoles, setPersonRoles] = useState<IContactPersonRole[]>(props.roles);

    const onRoleChange = (checked: boolean, role: IParameterValue) => {
        const foundRole = props.companyRoles.find((r) => Number(r.code) === Number(role.code));
        if (foundRole) {
            let newRoles = [...personRoles];

            if (checked) {
                newRoles.push({
                    ContactHash: props.contactPerson?.Hash,
                    ContactPersonId: props.contactPerson?.PersonId,
                    Name: foundRole.text,
                    Type: Number(foundRole.code),
                } as IContactPersonRole);
            } else {
                newRoles = newRoles.filter((r) => Number(r.Type) !== Number(foundRole.code));
            }

            setPersonRoles(newRoles);
        }
    };

    const isRoleChecked = (role: string) => {
        return personRoles.find((r) => r.Type === Number(role)) != undefined;
    };

    return (
        <ADialog open={true}>
            <ADialogTitle>{t('AnelmaCompany:1015')}</ADialogTitle>

            <ADialogContent size='md'>
                <Grid container>
                    {props.companyRoles.map((role) => {
                        return (
                            <Grid item sm={6} key={`company-contact-person-role-${role.code}`}>
                                <ASwitch
                                    checked={isRoleChecked(role.code)}
                                    id={`roles-${role.code}`}
                                    label={role.text}
                                    onChange={(v) => onRoleChange(v, role as IParameterValue)}
                                    validator={validator}
                                    disabled={props.readonly}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: () => props.onClose(),
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            onClick: () => {
                                props.onSave(personRoles);
                                props.onClose();
                            },
                            type: 'ok',
                            disabled: props.readonly,
                        },
                    ],
                }}
            />
        </ADialog>
    );
}
