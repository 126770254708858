// Libraries
import { useState, useEffect } from 'react';
import { mergeRight } from 'ramda';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import { IFormValidator } from '../../core/FormValidator';
import { ResourceTextApplication } from '../../core/resources';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../common/dialog';
import { AAddIconButton } from '../../common/buttons';
import { ADataGrid, AGridColumns } from '../../common/dataGrid';

// Interfaces
import { IContactRow } from '../../interfaces/IBusinessEntities';
import { IPredefinedSearch, IPredefinedSearchFarmResult } from '../../interfaces/ISearchParameters';

// Store
// API
import searchParametersApi from '../../api/searchParametersApi';

// Feature - SMS
import CompaniesGroupDialog from './GroupReceiversDialog';
import { isUsingPersonsConnectedToTheFarm } from '../../searchParameters/Helpers/general';

export interface SearchMessageGroupDialogProps {
    onClose: Function;
    onSelect: (item: IContactRow[]) => void;
    validator: IFormValidator;
    includeContactPersons?: boolean;
    includeFarmsWithoutContactPersons?: boolean;
    showPhoneNumbers?: boolean;
}
/*
!!!!!!!!!!!!!!!!!!
    This component is used in SendMessageDialog and BulletinForm
!!!!!!!!!!!!!!!!!!!!
*/
export default function SearchMessageGroupDialog(props: SearchMessageGroupDialogProps) {
    const { validator } = props;
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCompany',
        'AnelmaCommunication',
        'AnelmaGeneral',
        'AnelmaLayout',
    ]);

    const [selectedGroupDialog, setSelectedGroupDialog] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [groupName, setGroupName] = useState<string>('');
    const [searchGuid, setSearchGuid] = useState<string>('');
    const [usePersonsConnectedToTheFarm, setUsePersonsConnectedToTheFarm] =
        useState<boolean>(false);
    const [autosuggestion, setAutoSuggestion] = useState<IPredefinedSearch[]>([]);
    const [companyDataRows, setCompanyDataRows] = useState<IPredefinedSearchFarmResult[]>([]);
    const [selectedReceiverData, setSelectedReceiverData] = useState<IContactRow[]>([]);

    useEffect(() => {
        setIsLoading(true);

        const getGroups = searchParametersApi.getAllPredefinedSearches().then((response) => {
            if (!response) return;
            setAutoSuggestion(response.Items);
        });
        Promise.all([getGroups]).then(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        //To close dialog and return to parent dialog
        if (selectedReceiverData.length > 0) {
            props.onSelect(selectedReceiverData);
            props.onClose();
        }
    }, [selectedReceiverData]);

    const columns: AGridColumns = [
        { field: 'Name', headerName: t('AnelmaCommunication:1036'), type: 'string', width: 200 },
    ];

    const rows = autosuggestion.map((i, index) =>
        mergeRight(i, {
            id: index,
            usePersonsConnectedToTheFarm: isUsingPersonsConnectedToTheFarm(i.Parameters),
        })
    );

    const footerButtons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => props.onClose(),
            },
        ],
        right: [
            {
                type: 'ok',
                onClick: () => {
                    props.onSelect(selectedReceiverData);
                    props.onClose();
                },
            },
        ],
    };

    const onClickHandler = (groupName: any) => {
        setGroupName(groupName.Name);
        setSearchGuid(groupName.GUID);
        setUsePersonsConnectedToTheFarm(groupName.usePersonsConnectedToTheFarm);
        setIsLoading(true);

        searchParametersApi
            .runPredefinedSearch({
                PredefinedSearchGuid: groupName.GUID,
                WithContactPersons:
                    groupName.usePersonsConnectedToTheFarm || props.includeContactPersons,
                IncludeWithoutContactPersons: !!props.includeFarmsWithoutContactPersons,
            })
            .then((response) => {
                if (!response) return;
                setCompanyDataRows(response.Items);
                setSelectedGroupDialog(!selectedGroupDialog);
                setIsLoading(false);
            });
    };

    return (
        <div>
            <ADialog open onClose={() => props.onClose()} disableEnforceFocus>
                <ADialogTitle>{t('AnelmaCommunication:1098')}</ADialogTitle>
                <ADialogContent isLoading={isLoading} size='md'>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={11} lg={11}>
                            <ADataGrid
                                actions={[
                                    (params) => {
                                        return (
                                            <AAddIconButton
                                                key={params.row.Guid}
                                                onClick={() => {
                                                    onClickHandler(
                                                        params.row as unknown as IPredefinedSearch
                                                    );
                                                }}
                                            />
                                        );
                                    },
                                ]}
                                columns={columns}
                                rows={rows}
                                enableFiltering
                                enableRowNumbers
                                enablePagination
                            />
                        </Grid>
                    </Grid>
                </ADialogContent>
                <ADialogActions buttons={footerButtons} isLoading={isLoading} />
            </ADialog>

            {selectedGroupDialog && (
                <CompaniesGroupDialog
                    validator={validator}
                    groupName={groupName}
                    searchGuid={searchGuid}
                    usePersonsConnectedToTheFarm={usePersonsConnectedToTheFarm}
                    data={companyDataRows}
                    onClose={() => {
                        setSelectedGroupDialog(!selectedGroupDialog);
                    }}
                    onSelect={(items) => {
                        setSelectedReceiverData([...selectedReceiverData, ...items]);
                    }}
                    showPhoneNumbers={props.showPhoneNumbers}
                ></CompaniesGroupDialog>
            )}
        </div>
    );
}
