import { useState, useEffect } from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';

//Core
import { ResourceTextApplication, paramRepository, ParameterValues } from '../../core/resources';

// Interfaces
import { IRows } from '../../interfaces/IInvoice';

// Common

import { ADataGrid, AGridColumns } from '../../common/dataGrid';
import { TextsWithTooltip, TextWithTooltip } from '../../common/typography';

//Feature
import InvoiceMathHelper from './InvoiceMathHelper';

export interface InvoiceDetailGridProps {
    data: IRows[];
    animalInvoiceType: number;
}

export enum animalInvoiceTypeEnums {
    CreditMemo = 0,
    Bovine = 1,
    PigBatch = 2,
}

export default function InvoiceDetailsGrid(props: InvoiceDetailGridProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaInvoice',
        'AnelmaLayout',
        'AnelmaCompany',
    ]);

    const columnsBovine: AGridColumns = [
        {
            field: 'ExternalIdentifier',
            sortable: false,
            description: t('AnelmaInvoice:1006'),
            headerName: t('AnelmaInvoice:1006'),
            type: 'string',
            width: 150,
        },
        {
            field: 'ProductCode',
            sortable: false,
            description: t('AnelmaInvoice:1009'),
            headerName: t('AnelmaInvoice:1009'),
            type: 'string',
            width: 130,
            renderCell: (params) => {
                const foundParam = productCodeParams.find(
                    (p) => Number(p.code) === Number(params.value)
                );
                return <TextWithTooltip text={foundParam?.text ?? '-'} />;
            },
        },
        {
            field: 'Breed',
            sortable: false,
            description: t('AnelmaInvoice:1018'),
            headerName: t('AnelmaInvoice:1018'),
            type: 'number',
            width: 70,
            renderCell: (params) => {
                const foundParam = breedShortParams.find(
                    (p) => Number(p.code) === Number(params.value)
                );
                return <TextWithTooltip text={foundParam?.text ?? '-'} />;
            },
        },
        {
            field: 'Weight',
            sortable: false,
            description: t('AnelmaInvoice:1043'),
            headerName: t('AnelmaInvoice:1007'),
            type: 'number',
            width: 80,
            renderCell: (params) => (
                <TextWithTooltip text={InvoiceMathHelper.roundUpValue(params.value as number)} />
            ),
        },
        {
            field: 'DailyGrowth',
            sortable: false,
            description: t('AnelmaInvoice:1042'),
            headerName: t('AnelmaInvoice:1021'),
            type: 'number',
            width: 80,
            renderCell: (params) => (
                <TextWithTooltip text={InvoiceMathHelper.roundUpValue(params.value as number)} />
            ),
        },
        {
            field: 'PriceListBasePrice',
            sortable: false,
            description: t('AnelmaInvoice:1044'),
            headerName: t('AnelmaInvoice:1010'),
            type: 'number',
            width: 90,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
        {
            field: 'BreedAllowance',
            sortable: false,
            description: t('AnelmaInvoice:1045'),
            headerName: t('AnelmaInvoice:1008'),
            type: 'number',
            width: 80,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
        {
            field: 'DailyGrowthPrice',
            sortable: false,
            description: t('AnelmaInvoice:1046'),
            headerName: t('AnelmaInvoice:1019'),
            type: 'number',
            width: 80,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
        {
            field: 'BovineAllowance',
            sortable: false,
            description: t('AnelmaInvoice:1047'),
            headerName: t('AnelmaInvoice:1020'),
            type: 'number',
            width: 90,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
        {
            field: 'QualityAllowance',
            sortable: false,
            description: t('AnelmaInvoice:1060'),
            headerName: t('AnelmaInvoice:1060'),
            type: 'number',
            width: 80,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
            align: 'left',
            headerAlign: 'left',
        },

        {
            field: 'LevelAllowance',
            sortable: false,
            description: t('AnelmaInvoice:1061'),
            headerName: t('AnelmaInvoice:1061'),
            type: 'number',
            width: 80,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },

        {
            field: 'PriceBeforeTaxes',
            sortable: false,
            description: t('AnelmaInvoice:1048'),
            headerName: t('AnelmaInvoice:1013'),
            type: 'number',
            width: 100,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
    ];

    const columnsPigBatch: AGridColumns = [
        {
            field: 'ExternalIdentifier',
            sortable: false,
            headerName: t('AnelmaInvoice:1036'),
            type: 'string',
            width: 80,
        },
        {
            field: 'ProductCode',
            sortable: false,
            description: t('AnelmaInvoice:1009'),
            headerName: t('AnelmaInvoice:1009'),
            type: 'string',
            width: 130,
            renderCell: (params) => {
                const foundParam = productCodeParams.find(
                    (p) => Number(p.code) === Number(params.value)
                );
                return <TextWithTooltip text={foundParam?.text ?? '-'} />;
            },
        },
        {
            field: 'Amount',
            sortable: false,
            description: t('AnelmaInvoice:1058'),
            headerName: t('AnelmaInvoice:1037'),
            type: 'number',
            width: 60,
        },
        {
            field: 'Weight',
            sortable: false,
            description: t('AnelmaInvoice:1043'),
            headerName: t('AnelmaInvoice:1007'),
            type: 'number',
            width: 80,
            renderCell: (params) => (
                <TextWithTooltip text={InvoiceMathHelper.roundUpValue(params.value as number)} />
            ),
        },
        {
            field: 'WeightAllowance',
            sortable: false,
            description: t('AnelmaInvoice:1055'),
            headerName: t('AnelmaInvoice:1050'),
            type: 'number',
            width: 100,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
        {
            field: 'AdditionalOrLowerKilos',
            sortable: false,
            headerName: t('AnelmaInvoice:1051'),
            type: 'number',
            width: 120,
            renderCell: (params) => {
                return InvoiceMathHelper.roundUpValue(params.value as number);
            },
        },
        {
            field: 'QualityAllowance',
            sortable: false,
            description: t('AnelmaInvoice:1056'),
            headerName: t('AnelmaInvoice:1052'),
            type: 'number',
            width: 120,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
        {
            field: 'QualityClass',
            sortable: false,
            description: t('AnelmaInvoice:1056'),
            headerName: t('AnelmaInvoice:1053'),
            type: 'number',
            width: 100,
        },
        {
            field: 'ItemPriceBeforeTaxes',
            sortable: false,
            description: t('AnelmaInvoice:1057'),
            headerName: t('AnelmaInvoice:1054'),
            type: 'number',
            width: 100,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
        {
            field: 'PriceListBasePrice',
            sortable: false,
            description: t('AnelmaInvoice:1044'),
            headerName: t('AnelmaInvoice:1010'),
            type: 'number',
            width: 90,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },

        {
            field: 'PriceBeforeTaxes',
            sortable: false,
            description: t('AnelmaInvoice:1048'),
            headerName: t('AnelmaInvoice:1013'),
            type: 'number',
            width: 100,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
    ];

    const columnsCreditMemo: AGridColumns = [
        {
            field: 'AnimalIdentifier',
            sortable: false,
            description: t('AnelmaInvoice:1036'),
            headerName: t('AnelmaInvoice:1036'),
            type: 'string',
            width: 120,
        },
        {
            field: 'ProductCode',
            sortable: false,
            description: t('AnelmaInvoice:1009'),
            headerName: t('AnelmaInvoice:1009'),
            type: 'string',
            width: 160,
            renderCell: (params) => {
                const foundParam = productCodeParams.find(
                    (p) => Number(p.code) === Number(params.value)
                );
                return <TextWithTooltip text={foundParam?.text ?? '-'} />;
            },
        },
        {
            field: 'Amount',
            sortable: false,
            description: t('AnelmaInvoice:1062'),
            headerName: t('AnelmaInvoice:1062'),
            type: 'number',
            width: 100,
            renderCell: (params) => {
                return InvoiceMathHelper.roundUpValue(params.value as number);
            },
        },
        {
            field: 'ItemPriceBeforeTaxes',
            sortable: false,
            headerName: t('AnelmaInvoice:1054'),
            description: t('AnelmaInvoice:1063'),
            type: 'number',
            width: 100,
            renderCell: (params) => {
                return InvoiceMathHelper.roundThousandLeading(params.value as number);
            },
        },
        {
            field: 'PriceBeforeTaxes',
            sortable: false,
            headerName: t('AnelmaInvoice:1064'),
            description: t('AnelmaInvoice:1064'),
            type: 'number',
            width: 120,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
        {
            field: 'VATAmount',
            sortable: false,
            headerName: t('AnelmaInvoice:1011'),
            type: 'number',
            width: 80,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
        {
            field: 'VATRate',
            sortable: false,
            headerName: t('AnelmaInvoice:1012'),
            description: t('AnelmaInvoice:1012'),
            type: 'number',
            width: 80,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
        {
            field: 'PriceAfterTaxes',
            sortable: false,
            headerName: t('AnelmaInvoice:1014'),
            description: t('AnelmaInvoice:1046'),
            type: 'number',
            width: 120,
            valueGetter: (value, row) => {
                const val = Number(value);
                return InvoiceMathHelper.roundThousandLeading(val);
            },
        },
    ];

    enum AllowanceEnums {
        Unknown = '0',
        QualityAllowance = '1',
        BreedAllowance = '2',
        WeightAllowance = '3',
        BatchAllowance = '4',
        BreedingContractAllowance = '5',
        ProducerAllowance = '6',
        OtherAllowance = '7',

        LevelDifference = '8',
        AdditionalOrLowerKilos = '9',
        BovineWeightAllowance = '10',
        BovineDailyGrowthAllowance = '11',
        BovineUnknown = '12',

        // BovineAllowance = '8',
        // BovineDailyGrowthAllowance = '9',
        // BovineUnknown = '10',
    }

    const allowanceRows: IRows[] = [];

    //Bovine & PigBatch data extraction from ItemPriceDetails condition:
    if (props.animalInvoiceType === animalInvoiceTypeEnums.PigBatch) {
        props.data.forEach((element, index) => {
            const row = { ...element };
            const weightAllowance = row.ItemPriceDetails.find(
                (e) => e.Name === AllowanceEnums.WeightAllowance
            );
            const qualityAllowance = row.ItemPriceDetails.find(
                (e) => e.Name === AllowanceEnums.QualityAllowance
            );

            if (weightAllowance || qualityAllowance) {
                allowanceRows.push({
                    ...row,
                    id: index,
                    WeightAllowance: weightAllowance?.PriceBeforeTaxes,
                    QualityAllowance: qualityAllowance?.PriceBeforeTaxes,
                } as any);
            }
        });
    } else if (props.animalInvoiceType === animalInvoiceTypeEnums.Bovine) {
        props.data.forEach((element) => {
            const row = { ...element };
            const dailyGrowth = row.ItemPriceDetails.find(
                (e) => e.Name === AllowanceEnums.BovineDailyGrowthAllowance
            );
            const breedAllowance = row.ItemPriceDetails.find(
                (e) => e.Name === AllowanceEnums.BreedAllowance
            );
            const bovineAllowance = row.ItemPriceDetails.find(
                (e) => e.Name === AllowanceEnums.BovineWeightAllowance
            );
            const qualityAllowance = row.ItemPriceDetails.find(
                (e) => e.Name === AllowanceEnums.QualityAllowance
            );
            const levelAllowance = row.ItemPriceDetails.find(
                (e) => e.Name === AllowanceEnums.LevelDifference
            );
            if (dailyGrowth || breedAllowance || bovineAllowance) {
                allowanceRows.push({
                    ...row,
                    id: element.ExternalIdentifier,
                    DailyGrowth: dailyGrowth?.Amount,
                    DailyGrowthPrice: dailyGrowth?.PriceBeforeTaxes as number,
                    BreedAllowance: InvoiceMathHelper.twoleadingZeros(
                        breedAllowance?.PriceBeforeTaxes as number
                    ),
                    BovineAllowance: InvoiceMathHelper.twoleadingZeros(
                        bovineAllowance?.PriceBeforeTaxes as number
                    ),
                    QualityAllowance: InvoiceMathHelper.twoleadingZeros(
                        qualityAllowance?.PriceBeforeTaxes as number
                    ),
                    LevelAllowance: InvoiceMathHelper.twoleadingZeros(
                        levelAllowance?.PriceBeforeTaxes as number
                    ),
                } as any);
            }
        });
    }

    const rows = allowanceRows.length
        ? allowanceRows.map((i) => mergeRight(i, { id: i.RowNumber }))
        : props.data.map((i) => mergeRight(i, { id: i.RowNumber }));

    const [breedShortParams, setBreedShortParams] = useState<ParameterValues>([]);
    const [productCodeParams, setProductCodeParams] = useState<ParameterValues>([]);

    useEffect(() => {
        paramRepository.load(['AnelmaInvoice']).then(() => {
            setBreedShortParams(
                paramRepository.resource('AnelmaInvoice', 'BreedShort').map((resourceParam) => {
                    return {
                        ...resourceParam,
                    };
                })
            );
            setProductCodeParams(paramRepository.resource('AnelmaInvoice', 'ProductCode'));
        });
    }, []);

    return (
        <div>
            {props.animalInvoiceType === Number(animalInvoiceTypeEnums.PigBatch) &&
                rows.length > 0 && <ADataGrid columns={columnsPigBatch} rows={rows}></ADataGrid>}

            {props.animalInvoiceType === Number(animalInvoiceTypeEnums.Bovine) &&
                rows.length > 0 && <ADataGrid columns={columnsBovine} rows={rows}></ADataGrid>}

            {props.animalInvoiceType === Number(animalInvoiceTypeEnums.CreditMemo) &&
                rows.length > 0 && <ADataGrid columns={columnsCreditMemo} rows={rows}></ADataGrid>}
        </div>
    );
}
