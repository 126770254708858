// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil, isNotNil, mergeRight } from 'ramda';
import { enqueueSnackbar } from 'notistack';

// MUI
import { Container, Grid, Link } from '@mui/material';

// Core
import {
    ParameterResource,
    ParameterValues,
    paramRepository,
    ResourceTextApplication,
} from '../../core/resources';
import utils from '../../core/utils';
import auth from '../../core/authorization';
import DateUtils from '../../core/utils.date';

// Common
import { ADataGrid, AGridColumns } from '../../common/dataGrid';
import { TextWithTooltip } from '../../common/typography';
import AAttachmentIconButton from '../../common/buttons/AAttachmentIconButton';
import AWarningIconButton from '../../common/buttons/AWarningIconButton';
import ADoneIconButton from '../../common/buttons/ADoneIconButton';
import APriorityHighIconButton from '../../common/buttons/APriorityHighIconButton';
import AInfoButtonProps from '../../common/buttons/AInfoButton';

// Interfaces
import {
    IAnimalPayloadItem,
    IBatch,
    IGetPayloadMessage,
    INasevaSpecialSituation,
    IPayloadMessageResult,
} from '../../interfaces/IAnimal';
import {
    IAnimalAnnouncementAnimal,
    IAnimalAnnouncementBatch,
    ITiltuAnnouncement,
} from '../../interfaces/IAnnouncement';
import { EmptyGUID, GUIDType } from '../../interfaces/types';
import { AnnouncementTypeEnum } from '../../interfaces/enums';
import { ICompanyDetails } from '../../interfaces/IBusinessEntities';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import chainInformationApi from '../../api/chainInformationApi';
import animalListApi from '../../api/animalListApi';
import animalAnnouncementApi from '../../api/animalAnnouncementApi';
import companyApi from '../../api/companyApi';
import ShortMessageApi from '../../api/shortMessageApi';

// Feature - Chain Information
import {
    IChainInformationData,
    IChainInformationResult,
} from '../chain-information/IChainInformationDialog';
import ChainInformationDialog from '../chain-information/components/ChainInformationDialog';

// Feature - Animal Announcements
import { areUpdateRulesValid } from '../announcements/helpers/data';
import { addDashToEuIdentifier } from '../announcements/helpers/euIdentifier';

// Feature - Company
import CompanyInfoDialog from '../../companies/CompanyInfoDialog';
import { openCompanyLink } from './AnimalPayloadsViewGrid';

export interface AnimalPayloadsBatchesGridProps {
    payload: IAnimalPayloadItem;
    batches: IBatch[];
}

export const AnimalPayloadsBatchesGrid = (props: AnimalPayloadsBatchesGridProps) => {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaAnimalPayload',
        'AnelmaBovine',
    ]);

    const userData = useAppSelector((state) => state.userData.data);

    const [loading, setLoading] = useState<boolean>(false);
    const [animalAnnouncementTypeParams, setAnimalAnnouncementTypeParams] =
        useState<ParameterValues | null>(null);
    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues | null>(null);
    const [bovineSalesTypeParams, setBovineSalesTypeParams] = useState<ParameterValues | null>(
        null
    );
    const [chainInformationDialogVisible, setChainInformationDialogVisible] =
        useState<boolean>(false);
    const [announcementId, setAnnouncementId] = useState<GUIDType>('');
    const [announcementNumber, setAnnouncementNumber] = useState<string>('');
    const [animalsForChainInformation, setAnimalsForChainInformation] = useState<
        IAnimalAnnouncementAnimal[]
    >([]);
    const [chainInformations, setChainInformations] = useState<IChainInformationData[]>([]);
    const [companyName, setCompanyName] = useState<string>('');
    const [producerNumber, setProducerNumber] = useState<string>('');
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [farmResources, setFarmResources] = useState<ParameterResource>({});
    const [displayCompanyDialog, setDisplayCompanyDialog] = useState<boolean>(false);
    const [companyDetails, setCompanyDetails] = useState<ICompanyDetails>();
    const [latestPayloadMessages, setLatestPayloadMessages] = useState<IPayloadMessageResult[]>([]);

    const CHAIN_INFO_FIELD = 'ChainInfo';

    useEffect(() => {
        initializeParameters();
    }, []);

    useEffect(() => {
        if (props.batches.length > 0) {
            loadAnnouncementRelatedChainInformationData(
                props.batches
                    .filter((b) => b.AnnouncementInfo.AnnouncementId !== EmptyGUID)
                    .flatMap((batch) => batch.AnnouncementInfo.AnnouncementId)
                    .filter((_, idx, batches) => batches.indexOf(_) === idx)
            );
            getSmsMessages(
                props.batches
                    .map((b) => {
                        return {
                            AnnouncementNumber: b.AnnouncementInfo.AnnouncementNumber,
                            FarmGUID: b.CompanyInfo.Guid,
                            PayloadNumber: props.payload.AnimalPayloadNumber,
                        } as IGetPayloadMessage;
                    })
                    .filter((f) => !utils.isGuidEmpty(f.FarmGUID))
            );
        }
    }, [props.batches]);

    useEffect(() => {
        if (isNotNil(companyDetails)) setDisplayCompanyDialog(true);
    }, [companyDetails]);

    const initializeParameters = () => {
        paramRepository
            .load([
                'AnelmaAnimalAnnouncement',
                'AnelmaAnimalPayload',
                'AnelmaBovine',
                'AnelmaCompany',
            ])
            .then(() => {
                setSalesTypeParams(paramRepository.resource('AnelmaAnimalPayload', 'SalesType'));
                setAnimalAnnouncementTypeParams(
                    paramRepository.resource('AnelmaAnimalAnnouncement', 'AnimalAnnouncementType')
                );
                setBovineSalesTypeParams(paramRepository.resource('AnelmaBovine', 'SalesType'));

                let params: ParameterResource = {};
                params['feedSupplier'] = paramRepository.resource('AnelmaCompany', 'FeedSupplier');
                params['dairy'] = paramRepository.resource('AnelmaCompany', 'Dairy');

                setFarmResources(params);
            });
    };

    const getSmsMessages = (payload: IGetPayloadMessage[]) => {
        ShortMessageApi.getPayloadMessages(payload).then((response) => {
            if (response?.Items) setLatestPayloadMessages(response.Items);
        });
    };

    const loadAnnouncementRelatedChainInformationData = (announcementIds: string[]) => {
        if (announcementIds.length > 0)
            chainInformationApi.loadMultipleChainInformations(announcementIds).then((response) => {
                if (response)
                    setChainInformations(
                        getChainInformationDataFromAnnouncementBatch(response.Items)
                    );
            });
    };

    const getChainInformationDataFromAnnouncementBatch = (
        chainInformationData: IChainInformationResult[]
    ) => {
        return chainInformationData.map((_) => ({
            AnnouncementId: _.AnnouncementId,
            MedicatedFeed: _.MedicatedFeed,
            HealthInformation: _.HealthInformation,
            AuthorityRegulations: _.AuthorityRegulations,
            ForeignObject: _.ForeignObject,
            GmFree: _.GmFree,
            MedicalAccounting: _.MedicalAccounting,
            WaitingTimeOver: _.WaitingTimeOver,
            NasevaInfo: _.NasevaInfo,
            SlaughterHouseInfo: _.SlaughterHouseInfo,
            MedicationInfo: _.MedicationInfo,
            Anomalies: _.Anomalies,
            Regulations: _.Regulations,
            SamplingOrder: _.SamplingOrder,
            VetName: _.VetName,
            AdditionalInfo: _.AdditionalInfo,
            ForeignObjectInfo: _.ForeignObjectInfo,
            AnimalSymptoms: _.Symptoms,
            OtherSymptom: _.OtherSymptom,
            AnimalDiseases: _.Diseases,
            OtherDisease: _.OtherDisease,
            MedicatedAnimals: _.Medications,
            FormConfirmation: _.FormConfirmation,
            Signature: _.Signature,
            Signatory: _.Signatory,
            SignatureDate: _.SignatureDate,
        }));
    };

    const checkChainInfoFound = (announcementId: GUIDType) => {
        let chainInfoFound = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoFound) {
            return false;
        } else {
            return true;
        }
    };

    const checkChainInfoConfirmed = (announcementId: GUIDType) => {
        let chainInfoToCheck = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoToCheck?.FormConfirmation) {
            return true;
        } else {
            return false;
        }
    };

    const checkChainInfoNotify = (announcementId: GUIDType) => {
        let chainInfoToCheck = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoToCheck) {
            if (
                chainInfoToCheck.MedicatedFeed === true ||
                chainInfoToCheck.HealthInformation === true ||
                chainInfoToCheck.MedicalAccounting === true ||
                chainInfoToCheck.AuthorityRegulations === true ||
                chainInfoToCheck.ForeignObject === true ||
                chainInfoToCheck.GmFree === true ||
                chainInfoToCheck.AdditionalInfo.length > 0 ||
                chainInfoToCheck.MedicatedAnimals.length > 0
            ) {
                return true;
            } else {
                return false;
            }
        }
    };

    const openChainInformationDialog = (data: any) => {
        let animalArray: IAnimalAnnouncementAnimal[] = [];
        let euIds: string[] = [];
        setAnnouncementId(data.AnnouncementInfo.AnnouncementId);
        setAnnouncementNumber(data.AnnouncementInfo.AnnouncementNumber);
        setCompanyName(data.CompanyInfo.Name);
        setProducerNumber(data.CompanyInfo.ProducerNumber);

        animalAnnouncementApi
            .getAnimalAnnouncementFromTiltu(data.AnnouncementInfo.AnnouncementNumber)
            .then((response) => {
                if (response) {
                    var result = (response as unknown as any)?.Result as ITiltuAnnouncement;

                    checkReadOnly(result);
                    for (let i = 0; (i < result.Batches.length) as any; i++) {
                        var euId = addDashToEuIdentifier(result.Batches[i].EUIdentifier);
                        euIds.push(euId);
                    }
                }
            });

        animalListApi.getAnimals(data.CompanyInfo.Guid).then((response) => {
            if (response) {
                for (let i = 0; i < response.Items.length; i++) {
                    let animal: IAnimalAnnouncementAnimal = {
                        AnimalId: response.Items[i].Id,
                        BirthDate: response.Items[i].BirthDate
                            ? response.Items[i].BirthDate.toString()
                            : '',
                        Breed: response.Items[i].Breed,
                        EuIdentifier: response.Items[i].EuIdentifier,
                        SalesType: response.Items[i].SalesType,
                        Sex: response.Items[i].Sex,
                    };
                    if (euIds.includes(response.Items[i].EuIdentifier)) {
                        animalArray.push(animal);
                    }
                }
                setAnimalsForChainInformation(animalArray);
                setChainInformationDialogVisible(true);
            }
        });
    };

    const checkReadOnly = (announcement: ITiltuAnnouncement) => {
        if (announcement.Batches.filter((i) => i.AnnouncementBatchStatus !== 'LO').length > 0) {
            setReadOnly(true);
        }
        if (announcement) {
            let batchesToCheck: IAnimalAnnouncementBatch[] = [];
            for (let i = 0; i < announcement.Batches.length; i++) {
                let batch: IAnimalAnnouncementBatch = {
                    Id: '',
                    BatchNumber: announcement.Batches[i].AnnouncementBatchNumber,
                    BatchStatus: announcement.Batches[i].AnnouncementBatchStatus,
                    CollectWeekStart: announcement.Batches[i].CollectWeekStart,
                    CollectWeekEnd: announcement.Batches[i].CollectWeekEnd,
                    CollectWeekDay: announcement.Batches[i].CollectWeekDay1,
                    Amount: 0,
                    BatchInfo: announcement.Batches[i].AddInfo,
                    Animals: [],
                };
                batchesToCheck.push(batch);
            }
            if (areUpdateRulesValid(batchesToCheck, false, 1) === false) {
                setReadOnly(true);
            }
        }
    };

    const columnPicker = (type: number) => {
        switch (type) {
            case AnnouncementTypeEnum.Slaughter:
            case AnnouncementTypeEnum.ManualBovineSlaughter:
            case AnnouncementTypeEnum.SlaughterWithChainInformation:
                return columns;
            default:
                return columns.filter((_) => _.field !== CHAIN_INFO_FIELD);
        }
    };

    const getCompany = (id: string) => {
        if (isNotNil(id)) {
            setLoading(true);
            companyApi.getCompanyByGuid(id).then((response) => {
                if (!response) {
                    enqueueSnackbar(t('AnelmaGeneral:1020'), {
                        variant: 'error',
                    });
                    setLoading(false);
                    return;
                }

                setCompanyDetails(response.Entity);
                setLoading(false);
            });
        }
    };

    const renderCompanyName = (batch: IBatch) => {
        if (userData.IsSnellmanUser || userData.IsDriver || userData.IsTrafficContractor) {
            return (
                <Link
                    component={'button'}
                    onClick={() => getCompany(batch.CompanyInfo.Guid)}
                    disabled={loading}
                    sx={openCompanyLink}
                >
                    {batch.CompanyInfo?.Name}
                </Link>
            );
        }

        return <TextWithTooltip text={batch.CompanyInfo.Name} />;
    };

    const getNasevaSpecialSituations = (situations: INasevaSpecialSituation[]) => {
        if (situations.length === 0) return '';

        return situations
            .filter((_) => _.CompletionDate === null)
            .map((_) => _.Content)
            .join(', ');
    };

    const getHoldingSite = (batch: IBatch) => {
        const holdingSite = batch.HoldingSite;

        if (isNil(holdingSite)) return '';

        const normalizedAddress = holdingSite.Address.NormalizedAddress;

        if (isNil(normalizedAddress)) {
            const address = holdingSite.Address;
            return `${address.Street} ${address.Zip} ${address.City}`;
        }

        return holdingSite.Address.NormalizedAddress;
    };

    const getAgeInDays = (batch: IBatch) => {
        const birthDate = batch.BirthDate;

        if (isNil(birthDate)) return '';

        return utils.calculateAgeInDays(birthDate);
    };

    const renderNasevaSituations = (value: string) => {
        return (
            <AInfoButtonProps
                onClick={() => {}}
                tooltip={`${t('AnelmaAnimalPayload:1049')} ${value}`}
                style={{ pointerEvents: 'none' }}
            />
        );
    };

    const columns: AGridColumns = [
        {
            field: 'CompanyName',
            headerName: t('AnelmaAnimalPayload:1034'),
            renderCell: (params) => renderCompanyName(params.row as IBatch),
            width: 150,
        },
        { field: 'AnnouncementNumber', headerName: t('AnelmaAnimalPayload:1027') },
        {
            field: 'HoldingSite',
            headerName: t('AnelmaAnimalPayload:1054'),
            renderCell: (params) => <TextWithTooltip text={getHoldingSite(params.row as IBatch)} />,
            width: 130,
        },
        {
            field: 'AnnouncementType',
            headerName: t('AnelmaAnimalPayload:1028'),
            valueGetter: (params, row) => {
                const announcementInfo = (row as IBatch).AnnouncementInfo;

                if (!animalAnnouncementTypeParams) return '';

                const paramFound = animalAnnouncementTypeParams.find(
                    (p) => Number(p.code) === announcementInfo?.AnnouncementType
                );
                return paramFound ? paramFound.text : '';
            },
            width: 110,
        },
        {
            field: CHAIN_INFO_FIELD,
            headerName: t('AnelmaAnimalPayload:1035'),
            renderCell: (params) => {
                return (
                    params.row.AnnouncementInfo.AnnouncementType !==
                        AnnouncementTypeEnum.Intermediation && (
                        <span>
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <AAttachmentIconButton
                                    disabled={!auth.canViewChainInformation}
                                    key={`${params.id}-attachment`}
                                    onClick={() => {
                                        openChainInformationDialog(params.row);
                                    }}
                                    tooltip={t('AnelmaBovine:1143')}
                                />
                                {checkChainInfoFound(
                                    params.row.AnnouncementInfo.AnnouncementId
                                ) && (
                                    <AWarningIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1144')}
                                    />
                                )}
                                {checkChainInfoConfirmed(
                                    params.row.AnnouncementInfo.AnnouncementId
                                ) && (
                                    <ADoneIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1145')}
                                    />
                                )}
                                {checkChainInfoNotify(
                                    params.row.AnnouncementInfo.AnnouncementId
                                ) && (
                                    <APriorityHighIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1146')}
                                    />
                                )}
                            </Grid>
                        </span>
                    )
                );
            },
            filterable: false,
        },
        {
            field: 'SalesType',
            headerName: t('AnelmaAnimalPayload:1029'),
            renderCell: (params) => {
                const text = (params?.formattedValue as string) ?? '';
                return <TextWithTooltip text={text} />;
            },
            valueGetter: (params, row) => {
                const rowValue = (row as IBatch).SalesType;

                if (!rowValue || !salesTypeParams) return '';

                const foundParam = salesTypeParams.find((p) => Number(p.code) === rowValue);
                return foundParam?.text ?? '';
            },
        },
        {
            field: 'AgeInDays',
            headerName: t('AnelmaAnimalPayload:1056'),
            renderCell: (params) => (
                <TextWithTooltip text={getAgeInDays(params.row as IBatch).toString()} />
            ),
            width: 80,
        },
        {
            field: 'AnimalCount',
            headerName: t('AnelmaAnimalPayload:1008'),
        },
        {
            field: 'PlannedTime',
            headerName: t('AnelmaAnimalPayload:1030'),
        },
        {
            field: 'EUNumber',
            headerName: t('AnelmaAnimalPayload:1042'),
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            valueGetter: (params, row) => {
                const euNumber = (row as IBatch)?.EUNumber;
                const salesType = (row as IBatch)?.SalesType;

                if (!euNumber || !salesType) return '';

                return bovineSalesTypeParams?.find((p) => Number(p.code) === salesType)
                    ? utils.formatEuIdentifier(euNumber)
                    : euNumber;
            },
            width: 150,
        },
        {
            field: 'LatestTextMessage',
            headerName: t('AnelmaAnimalPayload:1052'),
            renderCell: (params) => {
                if (!params.formattedValue) return '';

                <TextWithTooltip
                    text={DateUtils.formatUtcString(
                        params.formattedValue as string,
                        'DD.MM.YYYY HH:mm'
                    )}
                />;
            },
            valueGetter: (params, row) =>
                getLatestTxtMessage(
                    (row as IBatch).CompanyInfo.Guid,
                    (row as IBatch).AnnouncementNumber
                ),
            width: 120,
        },
    ];

    if (userData.IsTrafficContractor || userData.IsDriver) {
        columns.splice(1, 1);
        columns.splice(1, 0, {
            field: 'NasevaSpecialSituation',
            headerName: t('AnelmaAnimalPayload:1048'),
            valueGetter: (params, row) =>
                getNasevaSpecialSituations((row as IBatch).NasevaSpecialSituations),
            renderCell: (params) => {
                if (params.formattedValue.length === 0) return <></>;
                return renderNasevaSituations(params.formattedValue as string);
            },
            width: 100,
        });
    }

    const getLatestTxtMessage = (companyId: string, announcementNumber: number) => {
        const msg = latestPayloadMessages.find(
            (_) =>
                announcementNumber &&
                _.PayloadNumber === props.payload.AnimalPayloadNumber &&
                _.FarmGUID === companyId
        );

        return isNotNil(msg)
            ? DateUtils.formatUtcString(msg.MessageDeliveryTime.toString(), 'DD.MM.YYYY HH:mm')
            : '';
    };

    const dataRows = props.batches.map((i, n) => mergeRight(i, { id: n }));

    const closeCompanyInfo = () => {
        setDisplayCompanyDialog(false);
        setCompanyDetails(undefined);
    };

    const isCompanyInfoDialogInReadonlyMode = () => {
        if (userData.IsSnellmanUser) return false;
        else if (userData.IsTrafficContractor || userData.IsDriver) return true;

        return false;
    };

    return (
        <Container disableGutters id='anelma-animals-animal-payloads-batches-grid'>
            <ADataGrid
                columns={columnPicker(props.batches[0].AnnouncementInfo.AnnouncementType)}
                enablePagination
                enableRowNumbers
                rows={!dataRows ? [] : dataRows}
                enableFiltering
                loading={loading}
            />

            {chainInformationDialogVisible && (
                <ChainInformationDialog
                    onClose={() => {
                        setChainInformationDialogVisible(false);
                    }}
                    forceReadOnly={readOnly}
                    announcementData={null}
                    announcementId={announcementId}
                    announcementNumber={announcementNumber}
                    animals={animalsForChainInformation}
                    companyName={companyName}
                    producerNumber={producerNumber}
                />
            )}

            {displayCompanyDialog && companyDetails && (
                <CompanyInfoDialog
                    mode={'modify'}
                    onClose={() => closeCompanyInfo()}
                    companyType={companyDetails.CompanyType}
                    companyId={companyDetails.Id ?? ''}
                    farmResources={farmResources}
                    readonly={isCompanyInfoDialogInReadonlyMode()}
                />
            )}
        </Container>
    );
};
