// Libraries
import moment from 'moment';

// Interfaces
import { IAddress, ILogin, IPhoneNumber } from '../../interfaces/IBusinessEntities';

// Core
import utils, { UiFormats } from '../../core/utils';

import { AGridColumn } from './ADataGrid';
import { TextWithTooltip } from '../typography';
import { isNotNil } from 'ramda';

class GridColumns {
    address(headerName: string, field: string = 'Address'): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => {
                let address = this.getNormalizedAddress(params.value);
                if (address.substring(-4) === ', FI')
                    address = address.substring(0, address.length - 4);
                return <TextWithTooltip text={address} />;
            },
            width: 300,
        };
    }

    private getNormalizedAddress(value: any): string {
        if (value === null) return '';
        if (typeof value === 'string') return value;
        const obj = value as IAddress;
        if (obj.NormalizedAddress) return obj.NormalizedAddress;
        if (!obj.Street && !obj.Zip && !obj.City && !obj.CountryShortCode) return '';
        const arr = [obj.Street, `${obj.Zip} ${obj.City}`, obj.CountryShortCode];
        return arr.join(', ');
    }

    businessCode(headerName: string, field: string = 'BusinessCode'): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            width: 110,
        };
    }

    companyName(headerName: string, field: string = 'Name'): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            width: 250,
        };
    }

    county(data: any[], headerName: string, field: string = 'County'): AGridColumn {
        return this.singleSelectText(field, data, headerName, 160);
    }

    /** KM 15.02.2022
     * optional width size given
     * used on Tilitykset /Accounting as grid columns are over 10
     */
    dateTime(field: string, headerName: string, format: UiFormats, width?: number): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => {
                const date = utils.date.utcStringToLocalDate(params.value);
                return date ? moment(date as Date).format(format) : '';
            },
            type: 'date',
            width: width ?? 160,
            valueFormatter: (value, row, column) => moment(value).format(format),
            valueGetter: (params) => isNotNil(params) ? new Date(params) : '',
        };
    }

    dateTimeNonUtc(
        field: string,
        headerName: string,
        format: UiFormats,
        width?: number
    ): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => {
                const date = utils.date.stringToDate(params.value as string);
                return isNotNil(date) ? moment(date as Date).format(format) : '';
            },
            type: 'date',
            width: width ?? 160,
            valueFormatter: (value, row, column) => moment(value).format(format),
            valueGetter: (params) => isNotNil(params) ? new Date(params) : '',
        };
    }

    emailAddress(headerName: string, field: string = 'EmailAddress'): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            width: 250,
        };
    }

    euIdentifier(headerName: string, field: string = 'EuIdentifier'): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            width: 150,
        };
    }

    farmId(headerName: string, field: string = 'FarmId'): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            width: 120,
        };
    }

    login(headerName: string, field: string = 'Logins'): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => {
                const login: ILogin | string = (params.value as any)[0];
                if (!login) return '';
                if (typeof login === 'string') return login;

                return <TextWithTooltip text={login.Login ?? ''} />;
            },
            width: 150,
        };
    }

    municipality(data: any[], headerName: string, field: string = 'Municipality'): AGridColumn {
        return this.singleSelectText(field, data, headerName, 160);
    }

    personName(headerName: string, field: string = 'Name', width: number = 200): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            width,
        };
    }

    phoneNumber(headerName: string, field: string = 'PhoneNumber'): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => (
                <TextWithTooltip text={this.getNormalizedPhoneNumber(params.value)} />
            ),
            width: 160,
        };
    }

    private getNormalizedPhoneNumber(value: any): string {
        if (value === null) return '';
        if (typeof value === 'string') return value;
        const obj = value as IPhoneNumber;
        if (obj.NormalizedNumber) return obj.NormalizedNumber;
        return '';
    }

    producerNumber(
        headerName: string,
        field: string = 'ProducerNumber',
        width?: number
    ): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            type: 'number',
            width: width ?? 160,
        };
    }

    /** KM 15.02.2022
     * optional description given
     * used on Tilitykset /Accounting
     * Reference: https://mui.com/components/data-grid/columns/#headers
     */
    singleSelectText(
        field: string,
        data: any[],
        headerName: string,
        width: number,
        description?: string
    ): AGridColumn {
        return {
            field,
            headerName,
            description: description ?? '',
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            type: 'singleSelect',
            valueOptions: this.getUnigueOptions(field, data),
            width,
        };
    }

    withTooltip(column: Omit<AGridColumn, 'renderCell'>): AGridColumn {
        const def = column as AGridColumn;
        def.renderCell = (params) => <TextWithTooltip text={params.value as string} />;
        return def;
    }

    zipAndCity(headerName: string, field: string = 'Address'): AGridColumn {
        return {
            field,
            headerName,
            renderCell: (params) => {
                let address = params.value as string;
                const parts = address.split(', ');
                return <TextWithTooltip text={parts.length === 3 ? parts[1] : ''} />;
            },
            width: 160,
        };
    }

    private getUnigueOptions(field: string, data: any[]): any[] {
        const values = data.map((d) => d[field]);
        const uniques = values.filter((v, i, s) => s.indexOf(v) === i);
        uniques.sort();
        return uniques;
    }
}

export default new GridColumns();
