export class AnimalAnnouncementsListViewStrings {
    static readonly Container = 'anelma-animal-announcements-list-view';
    static readonly Refiner = 'anelma-animal-announcement-list-view-refiner';
    static readonly RefinerFromDate = 'anelma-animal-announcements-valid-from-date';
    static readonly RefinerToDate = 'anelma-animal-announcements-valid-to-date';
    static readonly ControlButtonsRadio = 'anelma';

    // data grid
    static readonly Status = 'Status';
    static readonly AnnouncementNumberField = 'Number';
    static readonly TypeField = 'Type';
    static readonly CreatedField = 'Created';
    static readonly BatchesFields = 'Batches';
    static readonly CollectWeekDayField = 'CollectWeekDay';
    static readonly ChainInformationField = 'ChainInformation';
    static readonly AmountField = 'Amount';
    static readonly SalesTypeField = 'SalesType';
    static readonly HoldingSiteField = 'HoldingSiteId';
    static readonly InfoField = 'Info';
    static readonly AttachmentsField = 'Attachments';
    static readonly HasFiles = 'HasFiles';

    // other
    static readonly AnimalAnnouncementListViewBtns = 'AnnouncementListViewBtns';
}
