// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// MUI
import { CircularProgress, Container, Grid, Typography } from '@mui/material';
import { Sync } from '@mui/icons-material';

// Core
import utils from '../../core/utils';
import { paramRepository, ParameterValues, ResourceTextApplication } from '../../core/resources';
import { dayFormat } from '../../core/constants';

// Common
import { ADefaultIconButton } from '../../common/buttons';
import AWarningIconButton from '../../common/buttons/AWarningIconButton';

// Interfaces
import { IAnimalAnnouncement } from '../../interfaces/IAnnouncement';
import { IFarmDetails } from '../../interfaces/IBusinessEntities';
import {
    IFillingCapacityStatistic,
    IFilteredAnimalList,
    ILatestBovineRegisterDataSyncRequest,
    INasevaResult,
    INasevaSpecialSituation,
} from '../../interfaces/IAnimal';

// Store
// API
import AnimalsApi from '../../api/animalsApi';

// Feature
import { ParamFilter } from './AnimalList';
import ACarousel, {
    ACarouselAdditionalBoxInfo,
    ACarouselProps,
    ACourouselItemList,
} from './ACarousel';
import { carouselConfig } from './ACarouselConfig';

export interface AnimalListFilterProps {
    additionalInfo?: ACarouselAdditionalBoxInfo[];
    allAnimalsData: IFilteredAnimalList[];
    allAnnouncementsData: IAnimalAnnouncement[];
    farmData: IFarmDetails | null;
    onFilterChange: (filter: ParamFilter) => void;
    isLoading: boolean;
    onIsLoadingChange: (isLoading: boolean) => void;
    lastSync: ILatestBovineRegisterDataSyncRequest | null;
    nasevaVisits: INasevaResult[] | null;
    fillingStatistic: IFillingCapacityStatistic | null;
    refreshData: () => void;
    animalAnnouncementCount: number;
    slaughterAnnouncementCount: number;
    intermediationAnnouncementCount: number;
    bovinePurchaseAnnouncementCount: number;
    slaughterAnnouncementAnimalCount: number;
    intermediationAnnouncementAnimalCount: number;
    bovinePurchaseAnnouncementAnimalCount: number;
    nasevaSpecialSituations: INasevaSpecialSituation[] | null;
}

export default function AnimalListFilter(props: AnimalListFilterProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine', 'AnelmaGeneral']);
    const { enqueueSnackbar } = useSnackbar();

    const [carouselBoxes, setCarouselBoxes] = useState<ACourouselItemList[] | null>(null);
    const [carouselBoxParams, setCarouselBoxParams] = useState<ParameterValues | null>(null);
    const [carouselBoxChoicesParams, setCarouselBoxChoicesParams] =
        useState<ParameterValues | null>(null);
    const [carouselProps, setCarouselProps] = useState<ACarouselProps | null>(null);
    const [syncText, setSyncText] = useState<string>('');
    const [latestNasevaVisit, setLatestNasevaVisit] = useState<JSX.Element[]>([]);
    const [refreshAnimalListFilterProps, setRefreshAnimalListFilterProps] =
        useState<boolean>(false);
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [nasevaSpecialSituations, setNasevaSpecialSituations] = useState<JSX.Element[]>([]);

    useEffect(() => {
        setCarouselBoxParams(paramRepository.resource('AnelmaBovine', 'CarouselBox'));
        setCarouselBoxChoicesParams(paramRepository.resource('AnelmaBovine', 'BoxChoices'));
    }, []);

    useEffect(() => {
        if (!carouselBoxes || carouselBoxes.length === 0) refreshCarouselBoxes();
    }, [carouselBoxParams, carouselBoxChoicesParams]);

    useEffect(() => {
        setSyncText(getLastSyncText());
    }, [props.lastSync, props.farmData]);

    useEffect(() => {
        if (props.nasevaVisits)
            setLatestNasevaVisit(getLatestAnnualVisitsText());
        else
            setLatestNasevaVisit([]);
    }, [props.nasevaVisits]);

    useEffect(() => {
        if (props.nasevaSpecialSituations) 
            setNasevaSpecialSituations(
                constructNasevaSpecialSituations(props.nasevaSpecialSituations)
            );
        else
            setNasevaSpecialSituations([]);
    }, [props.nasevaSpecialSituations]);

    const refreshCarouselBoxes = () => {
        if (carouselBoxParams && carouselBoxChoicesParams) {
            const boxes = carouselConfig.getCarouselBoxes(
                props.farmData,
                props.allAnimalsData,
                props.allAnnouncementsData
            );

            for (let mainBox of boxes) {
                const mainBoxParam = carouselBoxParams.find((pp) => Number(pp.code) === mainBox.id);
                const defaultBox = mainBox.dropdownItems.find((x) => x.isDefaultSelection);

                if (defaultBox) {
                    defaultBox.text = mainBoxParam?.text || '';

                    // All animals
                    if (mainBox.id === 7 && props.fillingStatistic) {
                        mainBox.additionalText = `${t('AnelmaBovine:1153')} ${
                            props.fillingStatistic.Capacity
                        }\n ${t('AnelmaBovine:1154')} ${props.fillingStatistic.FillingPercentage}%`;
                    } else {
                        mainBox.additionalText = '';
                    }
                }

                if (props.additionalInfo && props.additionalInfo.length > 0) {
                    const additionalInfo = props.additionalInfo.find((i) => i.boxId === mainBox.id);

                    if (additionalInfo) {
                        mainBox.additionalInfo.push(additionalInfo);
                    }
                }

                for (let subBox of mainBox.dropdownItems.filter((x) => !x.isDefaultSelection)) {
                    const subBoxParam = carouselBoxChoicesParams.find(
                        (pp) => Number(pp.code) === subBox.code
                    );
                    subBox.text = subBoxParam?.text || '';
                }
            }

            setCarouselBoxes(boxes);
        }
    };

    useEffect(() => {
        if (!carouselBoxes || carouselBoxes.length === 0) refreshCarouselBoxes();
        else {
            setRefreshAnimalListFilterProps(true);
        }
    }, [carouselBoxes]);

    useEffect(() => {
        if (refreshAnimalListFilterProps) {
            createAnimalListFilterProps();

            setRefreshAnimalListFilterProps(false);
        }
    }, [refreshAnimalListFilterProps, props.allAnimalsData]);

    useEffect(() => {
        if (carouselBoxes) setCarouselBoxes(null);
    }, [
        props.allAnimalsData,
        props.animalAnnouncementCount,
        props.slaughterAnnouncementCount,
        props.intermediationAnnouncementCount,
        props.bovinePurchaseAnnouncementCount,
        props.slaughterAnnouncementAnimalCount,
        props.intermediationAnnouncementAnimalCount,
        props.bovinePurchaseAnnouncementAnimalCount,
    ]);

    const createAnimalListFilterProps = () => {
        setCarouselProps({
            carouselItems: carouselBoxes && carouselBoxes?.length > 0 ? carouselBoxes : [],
            onItemSelected: (id: number, selection: number) => {
                onCarouselItemSelected(id, selection);
            },
            isLoading: props.isLoading,
            farmData: props.farmData,
            allAnimalData: props.allAnimalsData,
            slaughterAnnouncementCount: props.slaughterAnnouncementCount,
            intermediationAnnouncementCount: props.intermediationAnnouncementCount,
            bovinePurchaseAnnouncementCount: props.bovinePurchaseAnnouncementCount,
            slaughterAnnouncementAnimalCount: props.slaughterAnnouncementAnimalCount,
            intermediationAnnouncementAnimalCount: props.intermediationAnnouncementAnimalCount,
            bovinePurchaseAnnouncementAnimalCount: props.bovinePurchaseAnnouncementAnimalCount,
        });
    };

    const onCarouselItemSelected = (id: number, selection: number) => {
        if (carouselBoxes === null) return;

        const item = carouselBoxes.find((f) => f.id === id);

        const subSelection = item?.dropdownItems.find((f) => f.code === selection);

        if (item && subSelection) {
            props.onFilterChange({
                paramCode: item.id,
                subParam: subSelection.code,
            } as ParamFilter);
        }
    };

    const syncAnimals = async () => {
        if (props.farmData?.Id && !isSyncing) {
            setIsSyncing(true);
            props.onIsLoadingChange(true);

            const finished = () => {
                setIsSyncing(false);
                props.onIsLoadingChange(false);
            };
            const ok = () => {
                enqueueSnackbar(t('AnelmaGeneral:1025'), {
                    variant: 'success',
                });
                finished();
            };
            const fail = () => {
                enqueueSnackbar(t('AnelmaGeneral:1020'), {
                    variant: 'error',
                });
                finished();
            };

            let syncStateChecks = 0;
            const maxChecks = 60;
            const lastSync = (await checkAnimalSyncDate()) || new Date();
            const createSyncCheck = () =>
                window.setTimeout(async () => {
                    syncStateChecks++;
                    if (syncStateChecks > maxChecks) {
                        fail();
                        return;
                    }
                    const newSync = await checkAnimalSyncDate();
                    if (newSync && newSync > lastSync) {
                        ok();
                        return;
                    } else if (props.farmData?.Id) {
                        createSyncCheck();
                        return;
                    }
                    fail();
                }, 10000);

            AnimalsApi.syncAnimals(props.farmData.Id)
                .then((response) => {
                    if (response?.Entity?.Success) {
                        ok();
                    } else {
                        throw new Error('no successful response');
                    }
                    finished();
                })
                .catch((err) => {
                    if (err === 'timeout') {
                        createSyncCheck();
                        return;
                    }
                    fail();
                })
                .finally(() => {
                    props.refreshData();
                });
        }
    };

    const checkAnimalSyncDate = (): Promise<Date | null> => {
        if (props.farmData?.Id)
            return AnimalsApi.getLatestBovineRegisterDataSyncRequest(props.farmData.Id, true)
                .then((response) => {
                    const result =
                        (response?.Entity as ILatestBovineRegisterDataSyncRequest) || null;
                    return result?.RequestDate || null;
                })
                .catch(() => {
                    return null;
                });
        return Promise.resolve(null);
    };

    const getLastSyncText = () => {
        return props.lastSync?.RequestDate && !utils.isGuidEmpty(props.lastSync.FarmGUID)
            ? t('AnelmaBovine:1000').replace(
                  '{datetime}',
                  moment(props.lastSync.RequestDate).format('DD.MM.YYYY HH:mm')
              )
            : t('AnelmaBovine:1068');
    };

    const constructAnnualVisitTxt = (data: INasevaResult) => {
        return (
            <Grid key={`${data.FarmGUID}${utils.generateUniqueId()}`} item>
                <Typography align='left' variant='body1' style={{ paddingBottom: 4 }}>
                    {t('AnelmaBovine:1077')
                        .replace('{holdingnumber}', data.HoldingSiteId)
                        .replace('{date}', moment(data.LatestVisitDate).format('DD.MM.YYYY'))}
                </Typography>
            </Grid>
        );
    };

    const getLatestAnnualVisitsText = () => {
        if (!props.nasevaVisits || props.nasevaVisits?.length === 0)
            return [
                <Typography key={utils.generateUniqueId()} variant='body1'>
                    {t('AnelmaBovine:1078')}
                </Typography>,
            ];

        const visits = props.nasevaVisits.sort(
            (a, b) => new Date(b.LatestVisitDate).valueOf() - new Date(a.LatestVisitDate).valueOf()
        );

        let latestVisitsForHoldingSites: INasevaResult[] = [];

        for (let visit of visits) {
            const visitAdded = latestVisitsForHoldingSites.find(
                (v) => v.HoldingSiteId === visit.HoldingSiteId
            );

            if (!visitAdded) {
                const latestVisit = visits
                    .filter((v) => v.HoldingSiteId === visit.HoldingSiteId)
                    .sort(
                        (a, b) =>
                            new Date(b.LatestVisitDate).valueOf() -
                            new Date(a.LatestVisitDate).valueOf()
                    );

                if (latestVisit?.length > 0) latestVisitsForHoldingSites.push(latestVisit[0]);
            }
        }

        return (
            latestVisitsForHoldingSites.map((_) => {
                return moment(_.LatestVisitDate) > moment().subtract(13, 'months') ? (
                    constructAnnualVisitTxt(_)
                ) : (
                    <>
                        {constructAnnualVisitTxt(_)}
                        {<AWarningIconButton onClick={() => false} />}
                    </>
                );
            }) ?? ''
        );
    };

    const constructNasevaSpecialSituations = (data: INasevaSpecialSituation[]) => {
        const validSituations = data
            .filter((_) => _.CompletionDate === undefined || _.CompletionDate === null)
            .sort(
                (a, b) => new Date(b.DetectionDate).valueOf() - new Date(a.DetectionDate).valueOf()
            );

        return validSituations.map((s, i) => {
            return (
                <Grid key={`${i}-${s.DetectionDate}`} item>
                    <Typography variant='body1' style={{ paddingBottom: 4 }}>
                        {`${s.Content} ${moment(s.DetectionDate).format(dayFormat)}`}
                    </Typography>
                </Grid>
            );
        });
    };

    return (
        <Container id={'anelma-animals-animal-list-filter-container'}>
            <Grid container style={{ width: '100%', display: 'flex' }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ padding: '6px' }}>
                    {latestNasevaVisit}
                </Grid>
                {isSyncing && <CircularProgress size='24px' />}
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography variant='body1' align='right'>
                        <ADefaultIconButton
                            icon={<Sync />}
                            tooltip={t('AnelmaBovine:1069').toString()}
                            onClick={() => syncAnimals()}
                            disabled={props.isLoading ? true : false}
                        />
                        {isSyncing ? t('AnelmaBovine:1134') : syncText}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: '6px' }}>
                    {nasevaSpecialSituations}
                </Grid>
            </Grid>
            {carouselBoxes !== null && carouselProps !== null && (
                <Grid container>
                    <ACarousel {...carouselProps} />
                </Grid>
            )}
        </Container>
    );
}
