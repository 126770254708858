import { IFeedback } from '../../../interfaces/communication/feedback/IFeedback';
import { IMessageListMessage } from '../../general/components/MessageList';

export const mapFeedbackMessagesToMessageList = (feedback: IFeedback): IMessageListMessage[] => {
    return feedback.Messages.map((msg) => ({
        Created: msg.Created ?? '',
        SenderId: msg.MessageSenderId,
        Body: msg.Body,
    }));
};
