import ApiBase from '../ApiBase';

// Interfaces
import {
    IConversationResult,
    INewMessageResult,
    INewConversation,
    INewMessage,
    INewMessageResponse,
    INewConversationResponse,
    IBooleanResult,
    IModifyMessage,
    IUserMessages,
    IConversation,
} from '../../interfaces/ICommunication';
import { GUIDType } from '../../interfaces/types';

// API
import { messagingApi } from '../apiEndpoints';

class CommunicationApi extends ApiBase {
    getUserConversationList(userId: GUIDType) {
        return this.getList<IConversationResult>(`${messagingApi}/Conversation/${userId}`);
    }

    getAdminConversationList(userId: GUIDType) {
        return this.getList<IConversationResult>(
            `${messagingApi}/Conversation/AdminConversations/${userId}`
        );
    }

    getMessagesList(conversationId: GUIDType) {
        return this.getList<INewMessageResult>(
            `${messagingApi}/Conversation/Messages/${conversationId}`
        );
    }

    getMessageStatus(userId: GUIDType, messageId: GUIDType) {
        return this.get<IBooleanResult>(
            `${messagingApi}/Conversation/MessageStatus/${userId}/${messageId}`
        );
    }

    getMessageStatuses(payload: IUserMessages) {
        return this.postList<IBooleanResult>(
            `${messagingApi}/Conversation/MessageStatuses`,
            payload
        );
    }

    setMessageRead(payload: IModifyMessage) {
        return this.patch(`${messagingApi}/Conversation/SetMessageRead`, payload);
    }

    createConversation(payload: INewConversation, farmId?: string) {
        const formData = new FormData();
        formData.append('Title', payload.Title);
        formData.append('Topic', payload.Topic);
        formData.append(`SenderId`, payload.SenderId);
        formData.append(`Body`, payload.Body);
        payload.RecipientList.forEach((recipient) => formData.append(`RecipientList[]`, recipient));
        payload.Attachments?.forEach((attachment, index) => {
            formData.append(
                `attachments[` + index + `].formfile`,
                attachment.File,
                attachment.File.name
            );
            formData.append(`attachments[` + index + `].filename`, attachment.File.name);
            formData.append(`attachments[` + index + `].filetype`, `Image`);
            formData.append(`attachments[` + index + `].displayname`, attachment.File.name);
        });
        formData.append('FarmId', farmId ?? '');
        return this.post<INewConversationResponse>(`${messagingApi}/Conversation/Create`, formData);
    }

    createMessage(payload: INewMessage, farmId?: string) {
        const formData = new FormData();
        formData.append(`ConversationId`, payload.ConversationId);
        formData.append(`SenderId`, payload.SenderId);
        formData.append(`Body`, payload.Body);
        payload.RecipientList.forEach((recipient) => formData.append(`RecipientList[]`, recipient));
        payload.Attachments?.forEach((attachment, index) => {
            formData.append(
                `attachments[` + index + `].formfile`,
                attachment.File,
                attachment.File.name
            );
            formData.append(`attachments[` + index + `].filename`, attachment.File.name);
            formData.append(`attachments[` + index + `].filetype`, `Image`);
            formData.append(`attachments[` + index + `].displayname`, attachment.File.name);
        });
        formData.append('FarmId', farmId ?? '');
        return this.post<INewMessageResponse>(
            `${messagingApi}/Conversation/CreateMessage`,
            formData
        );
    }

    retrieveConversations(payload: string[]) {
        return this.postList<IConversation>(`${messagingApi}/Conversation/Conversations`, payload);
    }
}

export default new CommunicationApi();
