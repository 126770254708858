// Interfaces
import { IContactPerson, IPersonDetailsExtended } from '../../../interfaces/IBusinessEntities';
import { IParticipant } from '../../../interfaces/communication/feedback/IFeedback';
import { FeedbackParticipantRoleEnum, ReadTypeEnum } from '../../../interfaces/enums';
import { IPersonLoginSummary } from '../../../interfaces/utility/IPersonLoginSummary';

/**
 * Map contact persons to IParticipant array. Please, provide preliminaryRole parameter when needed.
 * @param persons
 * @param preliminaryRole defaults to FeedbackParticipantRoleEnum.Participant
 * @param preliminaryReadType defaults to ReadTypeEnum.NotRead
 * @returns IParticipant[]
 */
export const mapContactPersonsAsParticipants = (
    persons: IContactPerson[],
    preliminaryRole: FeedbackParticipantRoleEnum = FeedbackParticipantRoleEnum.Participant,
    preliminaryReadType: ReadTypeEnum = ReadTypeEnum.NotRead
): IParticipant[] => {
    return persons.map((person) => {
        return {
            Id: person.PersonId,
            Name: person.Name,
            ParticipantRole: preliminaryRole,
            ReadType: preliminaryReadType,
        };
    });
};

export const mapSnellmanGroupUsersAsParticipants = (
    persons: IPersonLoginSummary[]
): IParticipant[] => {
    return persons.map((person) => {
        return {
            Id: person.Id,
            Name: person.Name,
            ParticipantRole: FeedbackParticipantRoleEnum.Participant,
            ReadType: ReadTypeEnum.NotRead,
        };
    });
};

export const mapPersonDetailsToContactPerson = (person: IPersonDetailsExtended): IContactPerson => {
    return {
        Name: `${person.FirstName} ${person.LastName}`,
        PersonId: person.Id as string,
        Address: '',
        EmailAddress: '',
        Hash: '',
        Id: 0,
        IsDefault: false,
        Logins: [],
        PhoneNumber: '',
        Roles: [],
        Type: 0,
    };
};

export const findUniqueParticipants = (
    participants1: IParticipant[],
    participants2: IParticipant[]
): IParticipant[] => {
    const combinedParticipants = [...participants1, ...participants2];

    const uniqueValues = combinedParticipants.filter((item, idx, array) => {
        return array.findIndex((i) => i.Id === item.Id) === idx;
    });

    return uniqueValues;
};
