// Libraries
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import moment from 'moment';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';
import utils from '../../core/utils';

// Common
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../../common/dialog';
import {
    ADateInput,
    ATextInput,
    AInputContainer,
    AAutocomplete,
    AAutocompleteValue,
} from '../../common/inputs';

// Store
import { useAppSelector } from '../../store/hooks';

// Interfaces
import { IBasicDocumentInfo, IDocumentAddDialogData } from '../IDocuments';
import { IApiListResult, IApiResult } from '../../interfaces/IGeneral';

// Documents
import config, { DocumentDialogConfigName } from './documentAddDialogConfigs';
import { GUIDType } from '../../interfaces/types';
import AttachmentDropzone from '../../common/AttachmentDropzone';

export interface DocumentAddDialogProps {
    afterSave?: (data: IBasicDocumentInfo) => void;
    config: DocumentDialogConfigName;
    data: IDocumentAddDialogData;
    initialType: number | null;
    onClose: () => any;
    onSave: (
        data: IDocumentAddDialogData
    ) => Promise<IApiResult<unknown> | IApiListResult<unknown> | null>;
    types?: DocumentAddDialogTypes;
    validator: IFormValidator;
    farmId?: GUIDType;
    saveForPreview?: boolean;
}

export interface DocumentAddDialogTypes {
    label: string;
    parameter: string;
}

export default function DocumentAddDialog(props: DocumentAddDialogProps) {
    const userData = useAppSelector((state) => state.userData.data);
    const dialogConfig = config(props.config);

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaDocument']);
    const { enqueueSnackbar } = useSnackbar();

    const [validFrom, setvalidFrom] = useState<moment.Moment | null>(null);
    const [validDue, setvalidDue] = useState<moment.Moment | null>(null);
    const [file, setFile] = useState(props.data.file);
    const [displayName, setdisplayName] = useState(props.data.displayName);
    const [type, setType] = useState<AAutocompleteValue>(props.initialType);

    const onSave = () => {
        const data: IDocumentAddDialogData = {
            validFrom: utils.date.localMomentToUtcString(validFrom || moment()),
            validDue: utils.date.localMomentToUtcString(validDue),
            file: file,
            displayName: displayName || file[0].name,
            owner: userData?.Id || '',
            type: props.initialType || 0,
            farmId: props.farmId,
        };

        if (props.types) {
            if (type === null) data.type = 0;
            else {
                const int = parseInt(`${type}`, 10);
                if (Number.isInteger(int)) data.type = int;
            }
        }

        if (!validate(data)) return;

        const onFail = () => {
            if (!props.saveForPreview)
                enqueueSnackbar(t('AnelmaDocument:1022').replace('{0}', data.displayName), {
                    variant: 'error',
                });
        };

        props.onSave(data).then(
            (response: any) => {
                if (!response) {
                    onFail();
                    return;
                }
                if (!props.saveForPreview)
                    enqueueSnackbar(t('AnelmaDocument:1021').replace('{0}', data.displayName), {
                        variant: 'success',
                    });

                if (props.afterSave) props.afterSave(response.Entity);

                props.onClose();
            },
            () => onFail()
        );
    };

    useEffect(() => {}, [props]);

    const validate = (data: IDocumentAddDialogData) => {
        if (props.saveForPreview && props.saveForPreview === true) {
            return true;
        }
        if (props.validator.invalid || !data.owner) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return false;
        }

        if (data.file.length == 0) {
            enqueueSnackbar(t('AnelmaDocument:1019').replace('{0}', data.displayName), {
                variant: 'error',
            });
            return false;
        }

        if (data.file && data.file[0].size == 0) {
            enqueueSnackbar(t('AnelmaDocument:1020').replace('{0}', data.displayName), {
                variant: 'error',
            });
            return false;
        }

        return true;
    };

    return (
        <ADialog open onClose={() => props.onClose()}>
            <ADialogTitle>{t('AnelmaDocument:1010')}</ADialogTitle>

            <ADialogContent size='md'>
                {dialogConfig.displayName && (
                    <Grid container>
                        <Grid item sm={12}>
                            <ATextInput
                                label={t('AnelmaDocument:1015')}
                                id='display-name'
                                value={displayName}
                                onChange={(v) => setdisplayName(v)}
                                required
                                validator={props.validator}
                            />
                        </Grid>
                    </Grid>
                )}

                {props.types && (
                    <Grid container>
                        <Grid item xs={12}>
                            <AAutocomplete
                                label={props.types.label}
                                id='types'
                                onChange={(v) => setType(v)}
                                parameterName={props.types.parameter}
                                required
                                sortValues='alphabetical'
                                validator={props.validator}
                                value={type}
                            />
                        </Grid>
                    </Grid>
                )}

                {(dialogConfig.validFrom || dialogConfig.validDue) && (
                    <Grid container>
                        {dialogConfig.validFrom && (
                            <Grid item sm={6}>
                                <ADateInput
                                    id='valid-from'
                                    label='#Alkupäivä'
                                    onChange={(d) => setvalidFrom(moment(d))}
                                    value={validFrom?.toDate() || null}
                                    required
                                    validator={props.validator}
                                />
                            </Grid>
                        )}

                        {dialogConfig.validDue && (
                            <Grid item sm={6}>
                                <ADateInput
                                    id='valid-due'
                                    label={t('AnelmaDocument:1014')}
                                    onChange={(d) => {
                                        if (d) {
                                            setvalidDue(moment(d));
                                        } else {
                                            setvalidDue(d);
                                        }
                                    }}
                                    value={validDue?.toDate() || null}
                                    validator={props.validator}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}

                <AInputContainer>
                    <AttachmentDropzone
                        Text={t('AnelmaDocument:1016')}
                        acceptedFiles={dialogConfig.acceptedFiles}
                        filesLimit={dialogConfig.filesLimit}
                        maxFileSize={dialogConfig.maxFileSize}
                        onAttachmentsChanged={(fileToSave) => setFile(fileToSave as any)}
                        initialFiles={props.data.file}
                        Format={false}
                    ></AttachmentDropzone>
                </AInputContainer>
            </ADialogContent>

            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: props.onClose,
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            onClick: () => onSave(),
                            type: 'save',
                        },
                    ],
                }}
            />
        </ADialog>
    );
}
