// Interfaces
import { IFeedback } from '../../interfaces/communication/feedback/IFeedback';
import { IFeedbackMessageRequest } from '../../interfaces/communication/feedback/IFeedbackMessageRequest';
import {
    IFeedbackRequest,
    IPayloadFeedbackRequest,
    IReclamationFeedbackRequest,
} from '../../interfaces/communication/feedback/IFeedbackRequest';
import { IFeedbackSummary } from '../../interfaces/communication/feedback/IFeedbackSummary';
import { IUpdateFeedbackRequest } from '../../interfaces/communication/feedback/IUpdateFeedbackRequest';
import { IUpdateFeedbackReadTypeRequest } from '../../interfaces/communication/feedback/IUpdateFeedbackReadTypeRequest';

// API
import ApiBase from '../ApiBase';
import { feedbackApiUrl } from '../apiEndpoints';

class FeedbackApi extends ApiBase {
    getFeedbackList(companyId: string, displayTransports: boolean) {
        return this.getList<IFeedbackSummary>(
            `${feedbackApiUrl}/GetFeedbackList/${companyId}/${displayTransports}`
        );
    }

    /** Get all feedback items for users with AnelmaFeedbackAdmin:LIST access right. */
    getAllFeedback() {
        return this.getList<IFeedbackSummary>(`${feedbackApiUrl}/GetAllFeedback`);
    }

    getFeedback(id: number) {
        return this.get<IFeedback>(`${feedbackApiUrl}/GetFeedback/${id}`);
    }

    createFeedback(payload: IFeedbackRequest) {
        return this.post<IFeedback>(
            `${feedbackApiUrl}/CreateFeedback`,
            this.getBaseFormData(payload)
        );
    }

    /** Create a feedback message that is part of feedback item.
     *
     * @param payload IFeedbackMessageRequest
     * @returns
     */
    createFeedbackMessage(payload: IFeedbackMessageRequest) {
        const formData = new FormData();

        formData.append('FeedbackId', payload.FeedbackId.toString());
        formData.append('Body', payload.Body.toString() ?? '');
        formData.append('MessageSenderId', payload.MessageSenderId ?? '');

        payload.Attachments?.forEach((attachment, idx) => {
            formData.append(
                `Attachments[` + idx + `].FormFile`,
                attachment.File,
                attachment.File.name
            );
            formData.append(`Attachments[` + idx + `].FileName`, attachment.File.name);
            formData.append(`Attachments[` + idx + `].FileType`, `Image`);
            formData.append(`Attachments[` + idx + `].DisplayName`, attachment.File.name);
        });

        return this.post<IFeedback>(`${feedbackApiUrl}/CreateFeedbackMessage`, formData);
    }

    createReclamationFeedback(payload: IReclamationFeedbackRequest) {
        const formData = this.getBaseFormData(payload);

        formData.append('Reason', payload.Reason.toString());

        payload.BovineIdentifiers?.forEach((id, idx) => {
            formData.append(`BovineIdentifiers[` + idx + `].EUIdentifier`, id);
        });

        return this.post<IFeedback>(`${feedbackApiUrl}/CreateFeedback/Reclamation`, formData);
    }

    createPayloadFeedback(payload: IPayloadFeedbackRequest) {
        const formData = this.getBaseFormData(payload);

        formData.append('PayloadNumber', payload.PayloadNumber.toString());
        formData.append('Reason', payload.Reason.toString());

        payload.BovineIdentifiers?.forEach((id, idx) => {
            formData.append(`BovineIdentifiers[` + idx + `].EUIdentifier`, id);
        });

        return this.post<IFeedback>(`${feedbackApiUrl}/CreateFeedback/Payload`, formData);
    }

    updateFeedbackAdmin(payload: IUpdateFeedbackRequest) {
        const formData = new FormData();

        formData.append('FeedbackId', payload.FeedbackId.toString());
        formData.append('Topic', payload.Topic?.toString() ?? '');
        formData.append('Status', payload.Status?.toString() ?? '');
        formData.append('HandlerPersonId', payload.HandlerPersonId ?? '');

        payload.Attachments?.forEach((attachment, idx) => {
            formData.append(
                `Attachments[` + idx + `].FormFile`,
                attachment.File,
                attachment.File.name
            );
            formData.append(`Attachments[` + idx + `].FileName`, attachment.File.name);
            formData.append(`Attachments[` + idx + `].FileType`, `Image`);
            formData.append(`Attachments[` + idx + `].DisplayName`, attachment.File.name);
        });
        payload.Messages.forEach((_, idx) => {
            formData.append(
                `Messages[${idx}].FeedbackId`,
                _.FeedbackId?.toString() ?? Number(0).toString()
            );
            formData.append(`Messages[${idx}].Id`, _.Id ?? '');
            formData.append(`Messages[${idx}].MessageSenderId`, _.MessageSenderId ?? '');
            formData.append(`Messages[${idx}].Body`, _.Body);
            formData.append(`Messages[${idx}].Created`, _.Created ?? '');
            formData.append(`Messages[${idx}].Archived`, _.Archived.toString());
            formData.append(`Messages[${idx}].Deleted`, _.Deleted.toString());
        });
        formData.append('Reason', payload.Reason?.toString() ?? '');
        payload.BovineIdentifiers?.forEach((id, idx) => {
            formData.append(`BovineIdentifiers[` + idx + `].EUIdentifier`, id);
        });

        return this.patch<IFeedback>(`${feedbackApiUrl}/UpdateFeedbackAdmin`, formData);
    }

    updateFeedbackRead(payload: IUpdateFeedbackReadTypeRequest) {
        return this.patch<IFeedback>(`${feedbackApiUrl}/UpdateFeedbackRead`, payload);
    }

    /** Used to get feedback formdata that is shared by various endpoints that CREATE feedback. */
    protected getBaseFormData(payload: any): FormData {
        const formData = new FormData();

        formData.append('CommandType', payload.CommandType.toString());
        formData.append('SenderCompanyId', payload.SenderCompanyId);
        formData.append('Topic', payload.Topic.toString());
        formData.append('Status', payload.Status?.toString() ?? '');
        formData.append('HandlerPersonId', payload.HandlerPersonId ?? '');

        payload.Attachments?.forEach((attachment: any, idx: number) => {
            formData.append(
                `Attachments[` + idx + `].FormFile`,
                attachment.File,
                attachment.File.name
            );
            formData.append(`Attachments[` + idx + `].FileName`, attachment.File.name);
            formData.append(`Attachments[` + idx + `].FileType`, `Image`);
            formData.append(`Attachments[` + idx + `].DisplayName`, attachment.File.name);
        });
        payload.Messages.forEach((message: any, idx: number) => {
            formData.append(
                `Messages[${idx}].FeedbackId`,
                message.FeedbackId?.toString() ?? Number(0).toString()
            );
            formData.append(`Messages[${idx}].Id`, message.Id ?? '');
            formData.append(`Messages[${idx}].MessageSenderId`, message.MessageSenderId ?? '');
            formData.append(`Messages[${idx}].Body`, message.Body);
            formData.append(`Messages[${idx}].Created`, message.Created ?? '');
            formData.append(`Messages[${idx}].Archived`, message.Archived.toString());
            formData.append(`Messages[${idx}].Deleted`, message.Deleted.toString());
        });

        payload.Participants.forEach((participant: any, idx: number) => {
            formData.append(`Participants[${idx}].Id`, participant.Id);
            formData.append(
                `Participants[${idx}].ParticipantRole`,
                participant.ParticipantRole.toString()
            );
            formData.append(`Participants[${idx}].ReadType`, participant.ReadType.toString());
        });

        return formData;
    }
}

export default new FeedbackApi();
