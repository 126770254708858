// Libraries
import { useTranslation } from 'react-i18next';

// MUI
import InfoIcon from '@mui/icons-material/Info';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { AIconButtonBaseProps, ADefaultIconButton } from '../buttons';

export interface AInfoButtonProps extends AIconButtonBaseProps {}

export default function AInfoButtonProps(props: AInfoButtonProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral']);
    const { tooltip, ...buttonProps } = props;
    const tooltip2 = tooltip ? tooltip : t('AnelmaGeneral:1075');

    return (
        <ADefaultIconButton
            {...buttonProps}
            icon={<InfoIcon style={{ color: '#FF8A00' }} />}
            tooltip={tooltip2}
        />
    );
}
