// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// MUI
import { Container } from '@mui/material';

// Core
import {
    ParameterValues,
    paramRepository,
    ResourceTextApplication,
} from '../../../../core/resources';
import log from '../../../../core/log';
import auth from '../../../../core/authorization';

// Common
import { ViewTitle } from '../../../../common/typography';
import ViewLoader from '../../../../common/ViewLoader';
import SearchCalendar from '../../../../common/search/SearchCalendar';

// Interfaces
import {
    IAnimalAnnouncement,
    IUpdateAnnouncementResult,
} from '../../../../interfaces/IAnnouncement';
import { IFarmDetails } from '../../../../interfaces/IBusinessEntities';
import {
    AnimalFilterTypeEnum,
    AnnouncementTypeEnum,
    DialogModeEnum,
} from '../../../../interfaces/enums';
import { ITiltuApiResult } from '../../../../interfaces/IGeneral';
import { IProductionPlanSummary } from '../../../../interfaces/IProductionPlan';
import { GUIDType } from '../../../../interfaces/types';
import {
    IAnimalSummaryExtraInfo,
    IFarmAnimal,
    IFilteredAnimalList,
} from '../../../../interfaces/IAnimal';

// Store
import { useAppSelector } from '../../../../store/hooks';

// API
import companyApi from '../../../../api/companyApi';
import animalAnnouncementApi from '../../../../api/animalAnnouncementApi';
import productionPlanApi from '../../../../api/productionPlanApi';
import animalListApi from '../../../../api/animalListApi';

// Feature
import { AnimalAnnouncementsListViewStrings } from './strings/AnimalAnnouncementsListViewStrings';
import ControlButtons from './components/control/ControlButtons';
import AnimalAnnouncementListViewGrid from './components/grid/AnimalAnnouncementListViewGrid';

// Feature - Animal Announcements
import BovineSlaughterAnnouncement from '../bovine-slaughter-announcement/BovineSlaughterAnnouncement';
import BovineIntermediationAnnouncement from '../bovine-intermediation-announcement/BovineIntermediationAnnouncement';
import BovinePurchaseAnnouncement from '../bovine-purchase-announcement/BovinePurchaseAnnouncement';
import { mapAnnouncementResult } from '../bovine-slaughter-announcement/helpers/mapper';
import ManualAnnouncementDialog from '../../../manual-announcements/components/ManualAnnouncementDialog';
import { checkCanCreateAnimalAnnouncement } from '../../../shared/helpers/data';
import { isAnimalAnnouncementUpdateRestricted } from '../../helpers/data';
import { omitAnimalAnnouncementAnimalsFromSelectableAnimals } from '../../helpers/animal';

export default function AnimalAnnouncementsListView() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaLayout']);
    const context = useAppSelector((state) => state.context);
    const { enqueueSnackbar } = useSnackbar();
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

    const [viewLoaderTimer, setViewLoaderTimer] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [farmData, setFarmData] = useState<IFarmDetails | null>(null);
    const [animalAnnouncementData, setAnimalAnnouncementData] = useState<IAnimalAnnouncement[]>([]);
    const [userSelectedAnimalAnnouncementData, setUserSelectedAnimalAnnouncementData] = useState<
        IAnimalAnnouncement[]
    >([]);
    const [selectedAnimalAnnouncementType, setSelectedAnimalAnnouncementType] =
        useState<string>('');
    const [selectedAnimalAnnouncement, setSelectedAnimalAnnouncement] =
        useState<IAnimalAnnouncement | null>(null);
    const [animalAnnouncementDialogVisible, setAnimalAnnouncementDialogVisible] =
        useState<boolean>(false);
    const [fromDate, setFromDate] = useState<moment.Moment>(moment().add(-1, 'y'));
    const [toDate, setToDate] = useState<moment.Moment>(moment());
    const [productionPlanCompletedParameters, setProductionPlanCompletedParameters] =
        useState<ParameterValues | null>(null);
    const [planSummaries, setPlanSummaries] = useState<IProductionPlanSummary[] | null>(null);
    const [canCreateAnimalAnnouncement, setCanCreateAnimalAnnouncement] = useState<boolean | null>(
        null
    );
    const [farmAnimals, setFarmAnimals] = useState<IFilteredAnimalList[] | null>(null);

    useEffect(() => {
        setViewLoaderTimer(15);
        setProductionPlanCompletedParameters(
            paramRepository.resource('AnelmaBovine', 'ProductionPlanCompleted')
        );
    }, []);

    useEffect(() => {
        if (context?.data?.currentContext) {
            setIsLoading(true);
            companyApi
                .getCompanyByContext(context.data.currentContext?.context)
                .then((response) => {
                    if (response?.Entity) setFarmData(response.Entity as IFarmDetails);
                    else setFarmData(null);
                    setIsLoading(false);
                });
            setIsLoading(false);
        } else setFarmData(null);
    }, [context]);

    useEffect(() => {
        fetchAnimalAnnouncements();
        loadProductionPlans();
        fetchFarmAnimals();
    }, [farmData]);

    useEffect(() => {
        setUserSelectedAnimalAnnouncementData(
            animalAnnouncementData.filter((animalAnnouncement) => {
                return filterAnimalAnnouncementsByUserInput(animalAnnouncement);
            })
        );
    }, [selectedAnimalAnnouncementType]);

    useEffect(() => {
        setUserSelectedAnimalAnnouncementData(
            animalAnnouncementData.filter((animalAnnouncement) => {
                return filterAnimalAnnouncementsByUserInput(animalAnnouncement);
            })
        );
    }, [animalAnnouncementData, selectedAnimalAnnouncementType]);

    useEffect(() => {
        if (productionPlanCompletedParameters)
            setCanCreateAnimalAnnouncement(
                checkCanCreateAnimalAnnouncement(
                    auth,
                    planSummaries,
                    productionPlanCompletedParameters
                )
            );
    }, [planSummaries]);

    useEffect(() => {
        if (viewLoaderTimer === 0) setViewLoaderTimer(0);
        if (!viewLoaderTimer) return;
        const intervalId = setInterval(() => {
            setViewLoaderTimer(viewLoaderTimer - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [viewLoaderTimer]);

    const fetchFarmAnimals = () => {
        if (farmData?.Id) {
            animalListApi
                .getFilteredAnimals(farmData?.Id)
                .then((response) => {
                    if (response) setFarmAnimals(response.Items);
                })
                .catch(() => {
                    enqueueSnackbar(t('AnelmaGeneral:1020'), {
                        variant: 'error',
                    });
                });
        }
    };

    const loadProductionPlans = () => {
        if (farmData && farmData.Id) {
            productionPlanApi.getPlans(farmData.Id).then((response) => {
                if (!response) {
                    enqueueSnackbar(t('#Ongelma tuotantosuunnitelmien latauksessa'), {
                        variant: 'error',
                    });
                    return;
                }
                setPlanSummaries(response.Items);
            });
        }
    };

    const fetchAnimalAnnouncements = async () => {
        if (farmData !== null) {
            setIsLoading(true);
            await animalAnnouncementApi
                .getAnimalAnnouncementsFromAnelma(String(farmData.Id))
                .then((response) => {
                    if (!response) {
                        log(
                            'info',
                            `getAnimalAnnouncementsFromAnelma response returned no results for farm with id: ${farmData.Id}.`
                        );
                        setIsLoading(false);
                        return;
                    }
                    setAnimalAnnouncementData(response.Items);
                    setSelectedAnimalAnnouncementType('1');
                    setIsLoading(false);
                });
        }
    };

    const filterAnimalAnnouncementsByUserInput = (animalAnnouncement: IAnimalAnnouncement) => {
        if (
            (animalAnnouncement.Type === AnnouncementTypeEnum.Slaughter ||
                animalAnnouncement.Type === AnnouncementTypeEnum.Intermediation ||
                animalAnnouncement.Type === AnnouncementTypeEnum.ManualBovineSlaughter ||
                animalAnnouncement.Type === AnnouncementTypeEnum.ManualBovineIntermediation) &&
            Number(selectedAnimalAnnouncementType) === 1
        )
            return filterAnimalAnnouncementByUserRefinedDates(animalAnnouncement, fromDate, toDate);
        else if (
            animalAnnouncement.Type === AnnouncementTypeEnum.BovinePurchase &&
            Number(selectedAnimalAnnouncementType) === 2
        )
            return filterAnimalAnnouncementByUserRefinedDates(animalAnnouncement, fromDate, toDate);
    };

    const filterAnimalAnnouncementByUserRefinedDates = (
        animalAnnouncement: IAnimalAnnouncement,
        fromDate: moment.Moment,
        toDate: moment.Moment
    ) => {
        if (
            moment(`${animalAnnouncement.Created}Z`).format(dateFormat) >=
                fromDate.startOf('day').format(dateFormat) &&
            moment(`${animalAnnouncement.Created}Z`).format(dateFormat) <=
                toDate.endOf('day').format(dateFormat)
        ) {
            return animalAnnouncement;
        }
    };

    const sortAnimalAnnouncementsBySelectedDates = () => {
        setUserSelectedAnimalAnnouncementData(
            animalAnnouncementData.filter((animalAnnouncement) => {
                return filterAnimalAnnouncementsByUserInput(animalAnnouncement);
            })
        );
    };

    const removeAnimalAnnouncement = (announcementNumber: number) => {
        setAnimalAnnouncementData(
            animalAnnouncementData.filter((_) => _.Number !== announcementNumber)
        );
        setUserSelectedAnimalAnnouncementData(
            userSelectedAnimalAnnouncementData.filter((_) => _.Number !== announcementNumber)
        );
    };

    const removeAnimalAnnouncementById = (announcementId: GUIDType) => {
        setAnimalAnnouncementData(animalAnnouncementData.filter((_) => _.Id !== announcementId));
        setUserSelectedAnimalAnnouncementData(
            userSelectedAnimalAnnouncementData.filter((_) => _.Id !== announcementId)
        );
    };

    const handleAnimalAnnouncementDialogVisible = (data: IAnimalAnnouncement) => {
        setSelectedAnimalAnnouncement(data);
        setAnimalAnnouncementDialogVisible(true);
    };

    const handleAnimalAnnouncementDialogClosed = () => {
        setAnimalAnnouncementDialogVisible(false);
        setSelectedAnimalAnnouncement(null);
    };

    const sortAnimalAnnouncementsOnUpdate = (
        updatedAnimalAnnouncement: IAnimalAnnouncement,
        data: IAnimalAnnouncement[]
    ) => {
        return data.filter(
            (_) =>
                _.Number !==
                [...data].find((data) => {
                    return data.Number === updatedAnimalAnnouncement.Number;
                })?.Number
        );
    };

    const updateAnnouncementState = (updatedAnimalAnnouncement: IAnimalAnnouncement) => {
        const animalAnnouncementBeforeUpdate = animalAnnouncementData.filter(
            (_) =>
                _.Number ===
                [...animalAnnouncementData].find((data) => {
                    return data.Number === updatedAnimalAnnouncement.Number;
                })?.Number
        );
        // [AN-1279] Preserve some of the current field values. Made because of Created-value is not returned from API/Tiltu.
        const updatedObject = animalAnnouncementBeforeUpdate.map((_) => {
            if (_.Number === updatedAnimalAnnouncement.Number)
                return {
                    Id: _.Id, // Does not change
                    Number: _.Number, // Does not change
                    Type: updatedAnimalAnnouncement.Type,
                    Created: _.Created, // Cannot change
                    HoldingSiteId: updatedAnimalAnnouncement.HoldingSiteId,
                    Info: updatedAnimalAnnouncement.Info,
                    Status: updatedAnimalAnnouncement.Status,
                    Batches: updatedAnimalAnnouncement.Batches,
                };
        });
        const updatedAnimalAnnouncementData = sortAnimalAnnouncementsOnUpdate(
            updatedObject[0] as IAnimalAnnouncement,
            animalAnnouncementData
        );
        const updatedUserSelectedAnimalAnnouncementData = sortAnimalAnnouncementsOnUpdate(
            updatedObject[0] as IAnimalAnnouncement,
            userSelectedAnimalAnnouncementData
        );
        updatedAnimalAnnouncementData.push(updatedObject[0] as IAnimalAnnouncement);
        updatedUserSelectedAnimalAnnouncementData.push(updatedObject[0] as IAnimalAnnouncement);
        setAnimalAnnouncementData(updatedAnimalAnnouncementData);
        setUserSelectedAnimalAnnouncementData(updatedUserSelectedAnimalAnnouncementData);
    };

    const handleAnnouncementUpdate = (
        data: ITiltuApiResult<IUpdateAnnouncementResult>,
        type: AnnouncementTypeEnum,
        holdingSiteId: string
    ) => {
        updateAnnouncementState(mapAnnouncementResult(data.Entity, holdingSiteId, type));
    };

    const getFarmAnimalsForAddAnimalDialog = () => {
        return farmAnimals !== null
            ? (farmAnimals.find((_) => _.FilterType === AnimalFilterTypeEnum.All)
                  ?.Animals as IAnimalSummaryExtraInfo[])
            : null;
    };

    // Farm data can be null if the API doesn't work. Decided to apply timer to check against modifiable value (at the time of writing 15 seconds) when to show view loader.
    // If timer reaches 0 then render the actual component. I believe this is better than showing loading icon continually even if the API doesn't work.
    if (farmData === null && viewLoaderTimer && viewLoaderTimer > 0) return <ViewLoader />;

    const mapUpdatedSalesTypeToAnnouncementData = (response: IFarmAnimal[]) => {
        if (!userSelectedAnimalAnnouncementData) return;

        const updatedAnnouncementData = userSelectedAnimalAnnouncementData.map((announcement) => ({
            ...announcement,
            Batches: announcement.Batches.map((batch) => ({
                ...batch,
                Animals: batch.Animals.map((animal) => {
                    const changedAnimal = response.find(
                        (changed) => changed.AnimalGUID === animal.AnimalId
                    );
                    return changedAnimal
                        ? { ...animal, SalesType: changedAnimal.SalesType }
                        : animal;
                }),
            })),
        }));

        setUserSelectedAnimalAnnouncementData(updatedAnnouncementData);
    };

    return (
        <Container id={AnimalAnnouncementsListViewStrings.Container}>
            <ViewTitle>{t('AnelmaLayout:1078')}</ViewTitle>
            <ControlButtons
                handleSelectedAnimalAnnouncementTypeChange={(selectedAnimalAnnouncementType) =>
                    setSelectedAnimalAnnouncementType(selectedAnimalAnnouncementType)
                }
                selectedAnimalAnnouncementType={selectedAnimalAnnouncementType}
            />
            <SearchCalendar
                handleSort={() => sortAnimalAnnouncementsBySelectedDates()}
                handleFromDateChange={(fromDate) => setFromDate(fromDate)}
                handleToDateChange={(toDate) => setToDate(toDate)}
                fromDate={fromDate}
                toDate={toDate}
                className={AnimalAnnouncementsListViewStrings.Refiner}
                childrenText={t('AnelmaBovine:1033')}
                fromDateId={AnimalAnnouncementsListViewStrings.RefinerFromDate}
                toDateId={AnimalAnnouncementsListViewStrings.RefinerToDate}
            />
            <AnimalAnnouncementListViewGrid
                data={userSelectedAnimalAnnouncementData}
                loading={isLoading}
                handleAnimalAnnouncementDelete={(announcementNumber) => {
                    removeAnimalAnnouncement(announcementNumber);
                }}
                handleAnimalAnnouncementDeleteById={(announcementId) => {
                    removeAnimalAnnouncementById(announcementId);
                }}
                handleAnimalAnnouncementModification={(data) =>
                    handleAnimalAnnouncementDialogVisible(data)
                }
                canCreateAnimalAnnouncement={canCreateAnimalAnnouncement}
            />
            <>
                {animalAnnouncementDialogVisible &&
                selectedAnimalAnnouncement &&
                selectedAnimalAnnouncement.Type === AnnouncementTypeEnum.Slaughter ? (
                    <BovineSlaughterAnnouncement
                        data={selectedAnimalAnnouncement}
                        onClose={() => handleAnimalAnnouncementDialogClosed()}
                        mode={DialogModeEnum.Edit}
                        selectedAnimals={null}
                        animals={getFarmAnimalsForAddAnimalDialog()}
                        processAnnouncementSave={(data, type, holdingSiteId) =>
                            handleAnnouncementUpdate(data, type, holdingSiteId)
                        }
                        mapUpdatedSalesTypeToAllAnimalsData={(data) =>
                            mapUpdatedSalesTypeToAnnouncementData(data)
                        }
                        loading={false}
                        readOnly={isAnimalAnnouncementUpdateRestricted(selectedAnimalAnnouncement)}
                    />
                ) : animalAnnouncementDialogVisible &&
                  selectedAnimalAnnouncement &&
                  selectedAnimalAnnouncement.Type === AnnouncementTypeEnum.Intermediation ? (
                    <BovineIntermediationAnnouncement
                        data={selectedAnimalAnnouncement}
                        onClose={() => handleAnimalAnnouncementDialogClosed()}
                        mode={DialogModeEnum.Edit}
                        selectedAnimals={null}
                        animals={getFarmAnimalsForAddAnimalDialog()}
                        processAnnouncementSave={(data, type, holdingSiteId) =>
                            handleAnnouncementUpdate(data, type, holdingSiteId)
                        }
                        mapUpdatedSalesTypeToAllAnimalsData={(data) =>
                            mapUpdatedSalesTypeToAnnouncementData(data)
                        }
                        loading={false}
                        readOnly={isAnimalAnnouncementUpdateRestricted(selectedAnimalAnnouncement)}
                    />
                ) : animalAnnouncementDialogVisible &&
                  selectedAnimalAnnouncement &&
                  selectedAnimalAnnouncement.Type === AnnouncementTypeEnum.BovinePurchase ? (
                    <BovinePurchaseAnnouncement
                        data={selectedAnimalAnnouncement}
                        onClose={() => handleAnimalAnnouncementDialogClosed()}
                        mode={DialogModeEnum.Edit}
                        processAnnouncementSave={(data, type, holdingSiteId) =>
                            handleAnnouncementUpdate(data, type, holdingSiteId)
                        }
                    />
                ) : animalAnnouncementDialogVisible &&
                  selectedAnimalAnnouncement &&
                  selectedAnimalAnnouncement.Type === AnnouncementTypeEnum.ManualBovineSlaughter ? (
                    <ManualAnnouncementDialog
                        announcementData={selectedAnimalAnnouncement}
                        announcementType={AnnouncementTypeEnum.ManualBovineSlaughter}
                        onClose={() => handleAnimalAnnouncementDialogClosed()}
                        mode={DialogModeEnum.Edit}
                        processAnnouncementSave={(data, type, holdingSiteId) =>
                            handleAnnouncementUpdate(data, type, holdingSiteId)
                        }
                        mapUpdatedSalesTypeToAllAnimalsData={(data) => false}
                        animals={[]}
                        withChainInformation={false}
                        readOnly={isAnimalAnnouncementUpdateRestricted(selectedAnimalAnnouncement)}
                    />
                ) : animalAnnouncementDialogVisible &&
                  selectedAnimalAnnouncement &&
                  selectedAnimalAnnouncement.Type ===
                      AnnouncementTypeEnum.ManualBovineIntermediation ? (
                    <ManualAnnouncementDialog
                        announcementData={selectedAnimalAnnouncement}
                        announcementType={AnnouncementTypeEnum.ManualBovineIntermediation}
                        onClose={() => handleAnimalAnnouncementDialogClosed()}
                        mode={DialogModeEnum.Edit}
                        processAnnouncementSave={(data, type, holdingSiteId) =>
                            handleAnnouncementUpdate(data, type, holdingSiteId)
                        }
                        mapUpdatedSalesTypeToAllAnimalsData={(data) => false}
                        animals={[]}
                        withChainInformation={false}
                        readOnly={isAnimalAnnouncementUpdateRestricted(selectedAnimalAnnouncement)}
                    />
                ) : null}
            </>
        </Container>
    );
}
