// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { equals, isEmpty, isNil, propOr } from 'ramda';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication, paramRepository } from '../core/resources';
import { IFormValidator } from '../core/FormValidator';
import auth from '../core/authorization';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../common/dialog';
import {
    ADateInput,
    ANumberInput,
    ASwitch,
    ATextarea,
    ATimeInput,
    ADateValue,
} from '../common/inputs';
import { AAddButton } from '../common/buttons';

// Interfaces
import {
    IAnnouncementSearch,
    IBulletinItemCulture,
    IBulletinItemDetails,
} from '../interfaces/ICommunication';
import { AnelmaCultureType, EmptyGUID, GUIDType } from '../interfaces/types';
import { IContactRow, IContactPersonRow, IContactFarmRow } from '../interfaces/IBusinessEntities';

// API

// Feature
import { BulletinRequest, bulletinRequestBuilder } from './api/bulletinRequest';
import SearchMessageGroupDialog from '../shortMessageService/search/SearchMessageGroupDialog';
import Recipients from '../common/communicationGroup/Recipients';

export interface EditBulletinDialogProps {
    onSave: (message: BulletinRequest) => void;
    data: IBulletinItemDetails | null;
    onClose: () => void;
    title: string;
    validator: IFormValidator;
}

const getMessage = (
    bulletinItem: IBulletinItemDetails | undefined | null,
    culture: AnelmaCultureType
): null | string => {
    const value = bulletinItem?.Message.find((msg) => msg.Culture === culture)?.MessageText;

    return isNil(value) ? null : value;
};

function getDuration(data: IBulletinItemDetails | null): number | null {
    const value: number | null = propOr(null, 'Duration', data);

    if (isNil(value)) return null;
    return value / 1000;
}

export function BulletinForm({ onClose, onSave, data, title, validator }: EditBulletinDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaBulletin',
        'AnelmaGeneral',
        'AnelmaCommunication',
    ]);
    const { enqueueSnackbar } = useSnackbar();
    const messageFi = getMessage(data, 'fi-FI');
    const messageEn = getMessage(data, 'en-GB');
    const messageSw = getMessage(data, 'sv-SE');
    const [validFromDate, setValidFromDate] = useState<moment.Moment | null>(() => {
        const date = data?.ValidFromDate;
        return date ? moment(`${date}Z`) : moment().startOf('day');
    });
    const [validDueDate, setValidDueDate] = useState<moment.Moment | null>(() => {
        const date = data?.ValidDueDate;
        return date ? moment(`${date}Z`) : moment().endOf('day');
    });
    const [maxFromDate, setMaxFromDate] = useState<Date>();
    const [minDueDate, setMinDueDate] = useState<Date>();
    const [messageSwedish, setMessageSwedish] = useState<string>(messageSw || '');
    const [messageEnglish, setMessageEnglish] = useState<string>(messageEn || '');
    const [messageFinnish, setMessageFinnish] = useState<string>(messageFi || '');
    const [isPublic, setIsPublic] = useState<boolean>(() => propOr(false, 'IsPublic', data));
    const [isInternal, setIsInternal] = useState<boolean>(() => propOr(false, 'IsInternal', data));
    const [duration, setDuration] = useState<number | null>(getDuration(data));
    const [dirty, setDirty] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [searchMsgGroupsDialog, setSearchMsgGroupsDialog] = useState<boolean>(false);
    const [recipients, setRecipients] = useState<IContactPersonRow[]>([]);
    const [recipientsOpen, setRecipientsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        paramRepository.load(['AnelmaCompany', 'AnelmaGeneral']).then(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (data) {
            setEdit(true);
            setRecipients(data.ReceiverUsers);
        }
    }, []);

    useEffect(() => {
        const valid = validFromDate?.isBefore(validDueDate) || false;
        validator.setState('dates', valid, (valid) => {
            if (!valid) {
                enqueueSnackbar(t('AnelmaBulletin:1026'), {
                    variant: 'error',
                });
            }
        });
    }, [validator, validFromDate, validDueDate, enqueueSnackbar, t]);

    useEffect(() => {
        const valid = !!messageSwedish || !!messageEnglish || !!messageFinnish;
        validator.setState('messages', valid, (valid) => {
            if (!valid) {
                enqueueSnackbar(t('AnelmaBulletin:1027'), {
                    variant: 'error',
                });
            }
        });
    }, [validator, messageSwedish, messageEnglish, messageFinnish, enqueueSnackbar, t]);

    useEffect(() => {
        const valid = isPublic || isInternal;
        validator.setState('visibility', valid, (valid) => {
            if (!valid) {
                enqueueSnackbar(t('AnelmaBulletin:1028'), {
                    variant: 'error',
                });
            }
        });
    }, [validator, isPublic, isInternal, enqueueSnackbar, t]);

    function createPayload(): IBulletinItemDetails {
        return {
            Id: propOr('', 'Id', data),
            ValidFromDate: validFromDate?.format() || '',
            ValidDueDate: validDueDate?.format() || '',
            Message: getMessagesPayload(),
            IsPublic: isPublic,
            IsInternal: isInternal,
            Duration: isNil(duration) ? null : duration * 1000,
            ReceiverUsers: recipients,
        };
    }

    function getMessagesPayload(): IBulletinItemCulture[] {
        var res: IBulletinItemCulture[] = [];

        if (messageFinnish !== '') res.push({ MessageText: messageFinnish, Culture: 'fi-FI' });

        if (messageSwedish !== '') res.push({ MessageText: messageSwedish, Culture: 'sv-SE' });

        if (messageEnglish !== '') res.push({ MessageText: messageEnglish, Culture: 'en-GB' });

        return res;
    }

    function messageIsValid(): boolean {
        if (validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return false;
        }

        return true;
    }

    function updateFromDate(d: ADateValue, type: 'date' | 'time'): void {
        const newValue =
            d === null || !moment(d).isValid() ? null : updateDate(type, d, validFromDate);
        setValidFromDate(newValue);
        setMinDueDate(newValue?.toDate() || undefined);
    }

    function updateDueDate(d: ADateValue, type: 'date' | 'time'): void {
        const newValue =
            d === null || !moment(d).isValid() ? null : updateDate(type, d, validDueDate);
        setValidDueDate(newValue);
        setMaxFromDate(newValue?.toDate() || undefined);
    }

    function updateDate(type: 'date' | 'time', newDate: Date, oldDate: moment.Moment | null) {
        const newDate2 = moment(newDate);
        if (!oldDate) return newDate2;
        const oldDate2 = moment(oldDate);

        type === 'date'
            ? newDate2.hours(oldDate2.hours()).minutes(oldDate2.minutes())
            : newDate2.year(oldDate2.year()).month(oldDate2.month()).date(oldDate2.date());

        return newDate2;
    }

    const isDirty = useCallback((): boolean => {
        return !(
            equals(validFromDate, propOr('', 'ValidFromDate', data)) &&
            equals(validDueDate, propOr('', 'ValidDueDate', data)) &&
            (equals(messageEnglish, messageEn) || (isNil(messageEn) && isEmpty(messageEnglish))) &&
            (equals(messageSwedish, messageSw) || (isNil(messageSw) && isEmpty(messageSwedish))) &&
            (equals(messageFinnish, messageFi) || (isNil(messageFi) && isEmpty(messageFinnish))) &&
            equals(isPublic, propOr(false, 'IsPublic', data)) &&
            equals(isInternal, propOr(false, 'IsInternal', data)) &&
            equals(duration, propOr(null, 'Duration', data))
        );
    }, [
        validFromDate,
        validDueDate,
        messageEnglish,
        messageSwedish,
        messageFinnish,
        isPublic,
        isInternal,
        duration,
        data,
        messageEn,
        messageFi,
        messageSw,
    ]);

    useEffect(() => {
        setDirty(isDirty());
    }, [dirty, isDirty]);

    const resolveReceiverTxt = (recipDialogItems: IContactRow[]) => {
        return loading
            ? ''
            : t('AnelmaBulletin:1032', {
                  RecieverCount: recipDialogItems.length,
              });
    };

    const msgGroupBtnIsVisible = () => {
        return !isPublic && auth.canViewAnelmaCommunicationGroups;
    };

    const bulletinFormBtns: ADialogButtonsProp = {
        left:
            recipients.length > 0 && auth.canViewAnelmaCommunicationGroups && msgGroupBtnIsVisible()
                ? [
                      {
                          onClick: onClose,
                          type: 'cancel',
                      },
                      {
                          type: 'with-label',
                          label: t('AnelmaCommunication:1050'),
                          onClick: () => setRecipientsOpen(true),
                          leftSideText: resolveReceiverTxt(recipients),
                          disabled: loading,
                      },
                  ]
                : [
                      {
                          onClick: onClose,
                          type: 'cancel',
                      },
                  ],
        right: [
            {
                onClick: () => {
                    const message = createPayload();
                    if (!isDirty()) {
                        onClose();
                    } else if (messageIsValid()) {
                        message.ReceiverUsers = recipients;
                        onSave(bulletinRequestBuilder(message, data));

                        enqueueSnackbar(t('AnelmaGeneral:1018'), {
                            variant: 'success',
                        });

                        onClose();
                    }
                },
                type: 'save',
            },
        ],
    };

    const onSearchMessageGroupDialogSelect = (contacts: IContactRow[]) => {
        if (contacts.length > 0) {
            if (contacts[0].EntityType === 'Person') {
                const toAdd = contacts
                    .filter(
                        (c) =>
                            recipients.findIndex(
                                (r) =>
                                    r.EntityId === c.EntityId &&
                                    r.FarmId === (c as IContactPersonRow).FarmId
                            ) === -1
                    )
                    .map((c, i) => {
                        c.id = recipients.length + i;
                        return c as IContactPersonRow;
                    });

                setRecipients([...recipients, ...toAdd]);
            } else {
                let persons: IContactPersonRow[] = [];

                for (const contact of contacts) {
                    let company = contact as IContactFarmRow;

                    for (const person of company.ContactPersons) {
                        if (
                            recipients.findIndex(
                                (r) => r.EntityId === person.Id && r.FarmId === person.OwnerId
                            ) === -1
                        )
                            persons.push({
                                id: persons.length + recipients.length,
                                EntityId: person.Id,
                                EntityType: 'Person',
                                Name: person.Name,
                                PhoneNumber:
                                    person.PhoneNumbers?.find((n) => n.IsDefault)
                                        ?.NormalizedNumber ?? '',
                                PhoneNumberType: (
                                    person.PhoneNumbers?.find((n) => n.IsDefault)?.Type ?? ''
                                ).toString(),
                                ProducerNumber: company.ProducerNumber,
                                ProductionLines: company.ProductionLines,
                                CommunicationGroupId: company.CommunicationGroupId,
                                CommunicationGroupName: company.CommunicationGroupName,
                                FarmId: company.EntityId,
                                FarmName: company.Name,
                                Roles:
                                    company.ContactPersons.find((p) => p.Id === person.Id)?.Roles ??
                                    [],
                            });
                    }
                }

                setRecipients([...recipients, ...persons]);
            }
        }
    };

    return (
        <ADialog open>
            {isNil(title) ? '' : <ADialogTitle>{title}</ADialogTitle>}

            <ADialogContent size='md'>
                <Grid container sx={{ paddingTop: '10px' }}>
                    <Grid item sm={6} xs={12}>
                        <ADateInput
                            id='valid-from-date'
                            label={t('AnelmaBulletin:1006')}
                            maxDate={maxFromDate}
                            onChange={(d) => updateFromDate(d, 'date')}
                            required
                            validator={validator}
                            value={validFromDate?.toDate() || null}
                        />
                        <ATimeInput
                            id='valid-from-time'
                            label={''}
                            onChange={(d) => updateFromDate(d, 'time')}
                            required
                            validator={validator}
                            value={validFromDate?.toDate() || null}
                        />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <ADateInput
                            id='valid-due-date'
                            label={t('AnelmaBulletin:1007')}
                            minDate={minDueDate}
                            onChange={(d) => updateDueDate(d, 'date')}
                            required
                            validator={validator}
                            value={validDueDate?.toDate() || null}
                        />
                        <ATimeInput
                            id='valid-due-time'
                            label={''}
                            onChange={(d) => updateDueDate(d, 'time')}
                            required
                            validator={validator}
                            value={validDueDate?.toDate() || null}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item sm={12}>
                        <ATextarea
                            id='fi'
                            label={t('AnelmaBulletin:1015')}
                            value={messageFinnish}
                            onChange={(v) => setMessageFinnish(v)}
                            validator={validator}
                            lengthBoundaryName='AnelmaBulletin:MessageMaxLength'
                            maxLength={500}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <ATextarea
                            id='sv'
                            label={t('AnelmaBulletin:1016')}
                            value={messageSwedish}
                            onChange={(v) => setMessageSwedish(v)}
                            validator={validator}
                            lengthBoundaryName='AnelmaBulletin:MessageMaxLength'
                            maxLength={500}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <ATextarea
                            id='en'
                            label={t('AnelmaBulletin:1017')}
                            value={messageEnglish}
                            onChange={(v) => setMessageEnglish(v)}
                            validator={validator}
                            lengthBoundaryName='AnelmaBulletin:MessageMaxLength'
                            maxLength={500}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item sm={12}>
                        <ASwitch
                            checked={isPublic}
                            id='is-public'
                            label={t('AnelmaBulletin:1021')}
                            onChange={(v) => setIsPublic(v)}
                            validator={validator}
                            disabled={edit}
                        />
                    </Grid>

                    <Grid item sm={12}>
                        <ASwitch
                            checked={isInternal}
                            id='is-private'
                            label={t('AnelmaBulletin:1020')}
                            onChange={(v) => setIsInternal(v)}
                            validator={validator}
                            disabled={edit}
                        />
                    </Grid>
                </Grid>

                <Grid item sm={12}>
                    <ANumberInput
                        id='duration'
                        label={t('AnelmaBulletin:1019')}
                        min={3}
                        max={10}
                        mode='int'
                        onChange={(v) => {
                            setDuration(v);
                        }}
                        value={duration}
                        validator={validator}
                    />
                </Grid>
                {msgGroupBtnIsVisible() && (
                    <Grid item sm={12}>
                        <AAddButton
                            onClick={() => setSearchMsgGroupsDialog(!searchMsgGroupsDialog)}
                            type='action'
                            style={{ marginLeft: '40px' }}
                        >
                            {t('AnelmaBulletin:1031')}
                        </AAddButton>
                    </Grid>
                )}
            </ADialogContent>

            <ADialogActions buttons={bulletinFormBtns} />

            {searchMsgGroupsDialog && (
                <SearchMessageGroupDialog
                    validator={validator}
                    onSelect={(items) => onSearchMessageGroupDialogSelect(items)}
                    onClose={() => setSearchMsgGroupsDialog(!searchMsgGroupsDialog)}
                    includeContactPersons
                    includeFarmsWithoutContactPersons
                />
            )}

            {recipientsOpen && (
                <Recipients
                    onClose={() => setRecipientsOpen(false)}
                    items={recipients}
                    titleParameter={'AnelmaBulletin:1032'}
                    usePersonsConnectedToTheFarm
                />
            )}
        </ADialog>
    );
}
