// Libraries
// MUI
// Core
// Common
// Interfaces
import { AnimalTypeEnum } from '../../interfaces/enums';
import { IProductionLine } from '../../interfaces/IBusinessEntities';

// Store
// API
// Feature

export const constructTagPayload = (productionLine: IProductionLine[]): string[] => {
    for (const line of productionLine) {
        switch (line.Type) {
            case AnimalTypeEnum.Bovine:
                return addTagsForAnimalType(line.Type, 'FarmingTypeBovine', line.FarmingTypes);
            case AnimalTypeEnum.Pork:
                return addTagsForAnimalType(line.Type, 'FarmingTypePig', line.FarmingTypes);
        }
    }
    return [];
};

export const addTagsForAnimalType = (type: AnimalTypeEnum, prefix: string, farmingTypes: any[]) => {
    const tags: string[] = [];

    for (const farmingType of farmingTypes) {
        tags.push(`${prefix}:${String(farmingType).padStart(2, '0')}`);
    }

    return tags;
};
