// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';

// MUI
// Core
import { paramRepository, ParameterValues, ResourceTextApplication } from '../../core/resources';
import log from '../../core/log';
import defaultData from '../../core/defaultData';
import FormValidator from '../../core/FormValidator';

// Common
import { ADataGrid, gridColumns, AGridColumns } from '../../common/dataGrid';
import {
    AAddButton,
    ACheckboxIconButton,
    ADeleteIconButton,
    AEditIconButton,
    ASearchButton,
} from '../../common/buttons';
import { ADialogSubHeading } from '../../common/dialog';
import { AInputContainer } from '../../common/inputs';
import { TextsWithTooltip } from '../../common/typography';

// Interfaces
import {
    IAlias,
    IContactPerson,
    IContactPersonRole,
    IPersonDetails,
} from '../../interfaces/IBusinessEntities';
import { CompanyEntityTypeEnum } from '../../interfaces/enums';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import personApi from '../../api/personApi';
import accessManagementApi from '../../api/accessManagementApi';

// Feature - Access management
import { IAccessRole } from '../../accessRightsManagement/IAccessRightsManagement';

// Feature - Person
import PersonFormDialog from '../../persons/PersonFormDialog';

// Feature
import ContactPersonSearch from './ContactPersonSearch';
import AccessRolesDialog from './AccessRolesDialog';
import RolesDialog from './RolesDialog';
import { getUsername, mapFromDetails, mapFromSummary } from './contactPersons.utils';
import { isInReadOnlyModeAndTrafficContractorOrDriver } from '../helpers/userHelper';

export interface ContactPersonsProps {
    addContactPerson: (data: IContactPerson) => void;
    removeContactPerson: (data: IContactPerson) => void;
    setDefaultContactPerson: (data: IContactPerson) => void;
    setDialogIsLoading: (status: boolean) => void;
    updateAccessRoles: (changes: AccessRoleChanges[]) => void;
    updateContactPerson: (data: IContactPerson) => void;
    updateContactPersonRoles: (data: IContactPerson) => void;
    companyType: CompanyEntityTypeEnum;
    companyAlias?: IAlias[];
    contactPersons: IContactPerson[];
    accessRoles: AccessRoleChanges[];
    readonly?: boolean;
}

export interface AccessRoleChanges {
    disabled: boolean;
    newRoles: string[];
    oldRoles: string[];
    username: string;
}

export interface UsernameAccessRoles {
    contextScopedRoles: {
        contextName: string;
        contextType: string;
        roles: string[];
    }[];
    roles: string[];
    username: string;
}

export default function ContactPersons(props: ContactPersonsProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCompany',
        'AnelmaGeneral',
        'AnelmaAccessRights',
    ]);

    const userData = useAppSelector((state) => state.userData);

    const [addPersonVisible, setAddPersonVisible] = useState<boolean>(false);
    const [editPersonVisible, setEditPersonVisible] = useState<boolean>(false);
    const [personSearchVisible, setPersonSearchVisible] = useState<boolean>(false);
    const [editedAccessRoles, setEditedAccessRoles] = useState<string[] | null>(null);
    const [editedRoles, setEditedRoles] = useState<IContactPersonRole[] | null>(null);
    const [editedUsername, setEditedUsername] = useState<string | null>(null);
    const [companyRoles, setCompanyRoles] = useState<ParameterValues>([]);
    const [accessRoles, setAccessRoles] = useState<IAccessRole[]>([]);
    const [accessRoleChanges, setAccessRoleChanges] = useState<AccessRoleChanges[]>([]);
    const [editedContactPerson, setEditedContactPerson] = useState<IContactPerson | null>(null);
    const [editedPerson, setEditedPerson] = useState<IPersonDetails | null>(null);
    const [hasTwoFactorAuthentication, setHasTwoFactorAuthentication] = useState<boolean>(false);
    const [contactPersons, setContactPersons] = useState<IContactPerson[]>(props.contactPersons);

    useEffect(() => {
        accessManagementApi.getRoles(props.companyType).then((data) => {
            if (!data) return;
            setAccessRoles(data.Items);
        });

        let params: ParameterValues = [];
        if (props.companyType === CompanyEntityTypeEnum.Farm)
            params = paramRepository.resource('AnelmaGeneral', 'FarmTitles');
        else if (props.companyType === CompanyEntityTypeEnum.TrafficContractor)
            params = paramRepository.resource('AnelmaGeneral', 'LogisticsTitles');
        else if (props.companyType === CompanyEntityTypeEnum.Snellman)
            params = paramRepository.resource('AnelmaGeneral', 'SnellmanTitles');
        setCompanyRoles(params);
    }, []);

    useEffect(() => {
        setContactPersons(props.contactPersons);
    }, [props.contactPersons]);

    useEffect(() => {
        if (contactPersons.length > 0) {
            setAccessRoleChanges(props.accessRoles);
        }
    }, [contactPersons]);

    const updateUpdatedAccessRoles = (roles: string[]) => {
        const changes = [...accessRoleChanges];
        const index = changes.findIndex((i) => i.username === editedUsername);
        if (index === -1) {
            log('error', `User\'s access role change data was not found.`, {
                username: editedUsername || '',
            });
            return;
        }
        changes[index].newRoles = roles;
        setAccessRoleChanges(changes);
        props.updateAccessRoles([...changes]);
    };

    const getRoleNames = (contactPerson: IContactPerson) => {
        return contactPerson.Roles.map(
            (i) => companyRoles.find((p) => p.code === `${i.Type}`)?.text || `${i.Type}`
        );
    };

    const getRoleNamesColumn = (contactPerson: IContactPerson, secondaryColumns?: boolean) => {
        const texts = getRoleNames(contactPerson);
        return secondaryColumns ? (
            <TextsWithTooltip texts={texts} />
        ) : (
            <>
                <AEditIconButton
                        key={contactPerson.Id}
                        onClick={() => {
                            setEditedContactPerson(contactPerson);
                            setEditedRoles(contactPerson.Roles);
                        }}
                        disabled={props.readonly}
                    />
                <div style={{ display: 'inline-block' }}>
                        <TextsWithTooltip texts={texts} />
                </div>
            </>
        );
    };

    const getAccessRoleName = (roleName: string) => {
        const role = accessRoles.find((i) => i.name === roleName);
        if (!role) return roleName;
        return t(`AnelmaAccessRights:${role.textCode}`);
    };

    const getAccessRoleNameList = (contactPerson: IContactPerson) => {
        const username = getUsername(contactPerson);
        const updatedAccessRole = accessRoleChanges.find((i) => i.username === username);
        if (!updatedAccessRole) return [];
        const accessRoleNames = updatedAccessRole.newRoles.map((name) => getAccessRoleName(name));
        accessRoleNames.sort();
        return accessRoleNames;
    };

    const getAccessRoleNamesColumn = (contactPerson: IContactPerson) => {
        const username = getUsername(contactPerson);
        const updatedAccessRole = accessRoleChanges.find((i) => i.username === username);
        if (!updatedAccessRole) return '';
        const accessRoleNames = getAccessRoleNameList(contactPerson);
        return (
            <>
                <AEditIconButton
                    key={contactPerson.Id}
                    disabled={updatedAccessRole.disabled || props.readonly}
                    onClick={() => {
                        setEditedAccessRoles(updatedAccessRole.newRoles);
                        setEditedUsername(username);
                    }}
                />
                <div style={{ display: 'inline-block' }}>
                    <TextsWithTooltip texts={accessRoleNames} />
                </div>
            </>
        );
    };

    const onContactPersonRoleSave = (roles: IContactPersonRole[]) => {
        setEditedRoles(roles);

        if (editedContactPerson !== null) {
            let p: IContactPerson = { ...editedContactPerson };
            p.Roles = roles;
            props.updateContactPersonRoles(p);
        }
    };

    const addContactPerson = (person: IContactPerson) => {
        person.IsDefault = props.contactPersons.length === 0;
        props.addContactPerson(person);
        setAddPersonVisible(false);
    };

    const updateContactPerson = (person: IContactPerson) => {
        props.updateContactPerson(person);
        setEditPersonVisible(false);
    };

    const removeContactPerson = (person: IContactPerson) => {
        const newAccessRoleChanges = accessRoleChanges;
        const idx = newAccessRoleChanges.findIndex(
            (i) =>
                i.username ===
                person.Logins.find((personLogin) => {
                    return personLogin.Login;
                })?.Login
        );

        if (idx === -1) {
            log(
                'error',
                `Cannot delete user from company's contact persons. Couldn't match person's login information.`,
                {
                    username: editedUsername || '',
                }
            );
            return;
        }

        newAccessRoleChanges[idx].newRoles = [];

        setAccessRoleChanges(newAccessRoleChanges);
        props.updateAccessRoles([...newAccessRoleChanges]);
        props.removeContactPerson(person);
    };

    const openPersonEdit = (person: IContactPerson) => {
        personApi.getPersonByGuid(person.PersonId).then((response) => {
            if (!response) return;

            if (response.Entity.Logins.length > 0) {
                accessManagementApi
                    .getUser(response.Entity.Logins[0].Login)
                    .then((res) => {
                        if (res && res.Entity) {
                            setHasTwoFactorAuthentication(res.Entity.multifactorEnabled);
                        } else {
                            setHasTwoFactorAuthentication(false);
                        }
                    })
                    .finally(() => {
                        setEditedPerson(response.Entity);
                        setEditPersonVisible(true);
                    });
            } else {
                setEditedPerson(response.Entity);
                setEditPersonVisible(true);
            }
        });
    };

    const defaultColumns: AGridColumns = [
        {
            field: 'Roles',
            headerName: t('AnelmaCompany:1015'),
            renderCell: (params) => {
                const contactPerson = params.row as unknown as IContactPerson;
                return <>{getRoleNamesColumn(contactPerson)}</>;
            },
            valueGetter: (value, row) => {
                return getRoleNames(row as unknown as IContactPerson).join(', ');
            },
            width: 200,
        },
        gridColumns.personName(t('AnelmaGeneral:1014')),
        gridColumns.login(t('AnelmaCompany:1079')),
        gridColumns.phoneNumber(t('AnelmaGeneral:1015')),
        gridColumns.emailAddress(t('AnelmaGeneral:1013')),
        gridColumns.zipAndCity(t('AnelmaGeneral:1006')),
    ];

    /** Columns for traffic contractor/driver.
     * [AN-2400]
     */
    const secondaryColumns: AGridColumns = [
        {
            field: 'Roles',
            headerName: t('AnelmaCompany:1015'),
            renderCell: (params) => {
                const contactPerson = params.row as unknown as IContactPerson;
                return <>{getRoleNamesColumn(contactPerson, true)}</>;
            },
            valueGetter: (value, row) => {
                return getRoleNames(row as unknown as IContactPerson).join(', ');
            },
            width: 200,
        },
        gridColumns.personName(t('AnelmaGeneral:1014')),
        gridColumns.phoneNumber(t('AnelmaGeneral:1015')),
        gridColumns.emailAddress(t('AnelmaGeneral:1013')),
    ];

    if (
        props.companyType === CompanyEntityTypeEnum.Snellman ||
        props.companyType === CompanyEntityTypeEnum.Farm ||
        props.companyType === CompanyEntityTypeEnum.TrafficContractor
    ) {
        defaultColumns.push({
            field: 'AccessRights',
            headerName: t('AnelmaCompany:1045'),
            renderCell: (params) => {
                const contactPerson = params.row as unknown as IContactPerson;

                return <>{getAccessRoleNamesColumn(contactPerson)}</>;
            },
            valueGetter: (value, row) => {
                return getAccessRoleNameList(row as unknown as IContactPerson).join(', ');
            },
            width: 200,
        });
    }

    const getColumns = () => {
        if (isInReadOnlyModeAndTrafficContractorOrDriver(userData.data, props.readonly))
            return secondaryColumns;
        return defaultColumns;
    };

    return (
        <>
            <ADialogSubHeading>{t('AnelmaCompany:1039')}</ADialogSubHeading>

            <ADataGrid
                actions={
                    !props.readonly
                        ? [
                              (params) => {
                                  const row = params.row as unknown as IContactPerson;
                                  return (
                                      <ACheckboxIconButton
                                          key={`${params.id}-check`}
                                          checked={row.IsDefault}
                                          onClick={() => props.setDefaultContactPerson(row)}
                                          disabled={props.readonly}
                                      />
                                  );
                              },
                              (params) => {
                                  const row = params.row as unknown as IContactPerson;
                                  return (
                                      <AEditIconButton
                                          key={`${params.id}-edit`}
                                          onClick={() => openPersonEdit(row)}
                                          disabled={props.readonly}
                                      />
                                  );
                              },
                              (params) => {
                                  const row = params.row as unknown as IContactPerson;
                                  return (
                                      <ADeleteIconButton
                                          key={`${params.id}-delete`}
                                          onClick={() => removeContactPerson(row)}
                                          disabled={props.readonly}
                                      />
                                  );
                              },
                          ]
                        : []
                }
                columns={getColumns()}
                rows={
                    contactPersons.length > 0
                        ? contactPersons.map((_) => mergeRight(_, { id: _.PersonId }))
                        : []
                }
            />

            {isInReadOnlyModeAndTrafficContractorOrDriver(userData.data, props.readonly) ? (
                <></>
            ) : (
                <AInputContainer>
                    <div style={{ textAlign: 'right' }}>
                        <ASearchButton
                            onClick={() => setPersonSearchVisible(true)}
                            size='small'
                            disabled={props.readonly}
                        >
                            {t('AnelmaCompany:1078')}
                        </ASearchButton>
                        <AAddButton
                            onClick={() => setAddPersonVisible(true)}
                            size='small'
                            style={{ marginLeft: '10px' }}
                            disabled={props.readonly}
                        >
                            {t('AnelmaCompany:1077')}
                        </AAddButton>
                    </div>
                </AInputContainer>
            )}

            {personSearchVisible && (
                <ContactPersonSearch
                    onClose={() => setPersonSearchVisible(false)}
                    onSelect={(summary) => {
                        const contactPerson = mapFromSummary(summary);
                        addContactPerson(contactPerson);
                    }}
                />
            )}

            {addPersonVisible && (
                <PersonFormDialog
                    afterSave={(details) => {
                        const contactPerson = mapFromDetails(details);
                        addContactPerson(contactPerson);
                    }}
                    data={defaultData.personDetails()}
                    isInactive={false}
                    hasTwoFactorAuthentication={false}
                    mode='create'
                    onClose={() => setAddPersonVisible(false)}
                    validator={new FormValidator()}
                    fromCompanyModal={true}
                />
            )}

            {editPersonVisible && editedPerson && (
                <PersonFormDialog
                    afterSave={(details) => {
                        const contactPerson = mapFromDetails(details);
                        updateContactPerson(contactPerson);
                    }}
                    data={editedPerson}
                    isInactive={false}
                    hasTwoFactorAuthentication={hasTwoFactorAuthentication}
                    mode='modify'
                    onClose={() => setEditPersonVisible(false)}
                    validator={new FormValidator()}
                    fromCompanyModal={true}
                    readonly={props.readonly}
                />
            )}

            {editedAccessRoles && (
                <AccessRolesDialog
                    accessRoles={accessRoles}
                    onClose={() => setEditedAccessRoles(null)}
                    onUpdate={(roles) => updateUpdatedAccessRoles(roles)}
                    roles={editedAccessRoles}
                    readonly={props.readonly}
                />
            )}

            {editedRoles && (
                <RolesDialog
                    companyRoles={companyRoles}
                    contactPerson={editedContactPerson}
                    onClose={() => setEditedRoles(null)}
                    onSave={(roles: IContactPersonRole[]) => onContactPersonRoleSave(roles)}
                    roles={editedRoles}
                    readonly={props.readonly}
                />
            )}
        </>
    );
}
