import { IPersonDetailsExtended } from '../../interfaces/IBusinessEntities';

/** Checks if logged in user is traffic contractor or driver and if readonly true
 *
 *  @param userDetails IPersonDetailsExtended, basically userData reducer
 *  @param readonly boolean, optional
 *  @returns boolean
 */
export const isInReadOnlyModeAndTrafficContractorOrDriver = (
    userDetails: IPersonDetailsExtended,
    readonly?: boolean
) => {
    if (readonly && (userDetails.IsDriver || userDetails.IsTrafficContractor)) return true;

    return false;
};
