// Libraries
import { useEffect, useState } from 'react';

// Core
import { IFormValidator } from '../../core/FormValidator';

// Common
import { ATextInput, ATextInputProps } from '../../common/inputs';

// Interfaces
import { IAddress } from '../../interfaces/IBusinessEntities';

export interface AZipAndCityInputProps {
    error?: boolean;
    id: string;
    label?: React.ReactNode;
    onChange: (value: IAddress) => void;
    placeholder?: string;
    required?: boolean;
    validator: IFormValidator;
    value: IAddress;
    withoutContainer?: boolean;
    disabled?: boolean;
}

export default function AZipAndCityInput(props: AZipAndCityInputProps) {
    const { error, onChange, required, value, ...propsForTextInput } = props;

    const addressToValue = (address: IAddress) => {
        if (!address.Zip && !address.City) return '';
        if (address.Zip && !address.City)
            return address.Zip.length === 5 ? `${address.Zip} ` : address.Zip;
        if (!address.Zip && address.City) return address.City;
        return `${address.Zip} ${address.City}`;
    };

    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<string>(addressToValue(value));
    const [err, setErr] = useState<boolean>(!!error);

    useEffect(() => {
        setVal(addressToValue(value));
    }, [value]);

    useEffect(() => {
        const isValid = () => {
            if (error) return false;
            const v = parseZipAndCity(val);
            if (required && (!v.zip || !v.city)) return false;
            if (v.zip.length !== 0 && v.zip.length !== 5) return false;
            if (v.city.length !== 0 && v.city.length > 64) return false;
            return !!v.zip === !!v.city;
        };

        // NOTE: Validator state is set inside ATextInput based on error prop.
        setErr(!isValid());
    }, [error, required, touched, val]);

    const parseZipAndCity = (v: string) => {
        const tryZip = v.substr(0, 6);
        const zipFound = /^\d{5} $/.test(tryZip);
        const zip = zipFound ? tryZip.trimRight() : '';
        const city = zipFound ? v.substr(6) : v;
        return { zip, city };
    };

    const textInputProps = propsForTextInput as ATextInputProps;
    textInputProps.error = err;
    textInputProps.onChange = (v) => {
        setTouched(true);
        setVal(v);
        const newValue = { ...value };
        const zipAndZity = parseZipAndCity(v);
        newValue.Zip = zipAndZity.zip;
        newValue.City = zipAndZity.city;
        onChange(newValue);
    };
    textInputProps.value = val;
    textInputProps.disabled = props.disabled;

    // NOTE: Add top margin to fix visual bug when label text is not given. -MM
    if (!propsForTextInput.label) {
        textInputProps.style = {
            marginTop: '16px',
        };
    } else textInputProps.required = required;

    return <ATextInput {...textInputProps} />;
}
