// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNotNil, mergeRight } from 'ramda';
import { enqueueSnackbar } from 'notistack';

// MUI
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';

// Core
import { ParameterValues, ResourceTextApplication, paramRepository } from '../../core/resources';
import { dayFormat } from '../../core/constants';

// Common
import {
    ADialogButtonsProp,
    ADialog,
    ADialogTitle,
    ADialogContent,
    ADialogActions,
} from '../../common/dialog';
import { TextWithTooltip } from '../../common/typography';
import { ADataGrid, AGridColumns, gridColumns } from '../../common/dataGrid';

// Interfaces
import { IAnimalSummary } from '../../interfaces/IAnimal';

// Store
// API
import animalsApi from '../../api/animalsApi';
import animalListApi from '../../api/animalListApi';

// Feature - Animals
// Feature - Feedback
import { SelectorConstants } from './constants/SelectorConstants';
import AddEUIdentifiers from './AddEUIdentifiers';

interface IBovineDialogProps {
    onClose: () => void;
    onSave: (params: string[]) => void;
    farmId: string | null;
}

/** Dialog to display bovines in data grid. Also can be used to display deceased bovines and to add bovine/bovines by EU-identifiers.
 *  JIRA ticket: AN-2426
 */
export default function BovineDialog({ onClose, onSave, farmId }: IBovineDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCommunication',
        'AnelmaBovine',
    ]);

    const [loading, setLoading] = useState<boolean>(false);
    const [bovineSexParams, setBovineSexParams] = useState<ParameterValues>([]);
    const [bovines, setBovines] = useState<IAnimalSummary[]>([]);
    const [toggleTwelveMonthsSearch, setToggleTwelveMonthsSearch] = useState<boolean>(false);
    const [deceasedBovines, setDeceasedBovines] = useState<IAnimalSummary[]>([]);
    const [selectedEuIdentifiers, setSelectedEuIdentifiers] = useState<string[]>([]);
    const [addEUIdentifiersDialogOpen, setAddEUIdentifiersDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        paramRepository.load(['AnelmaBovine']).then(() => {
            setBovineSexParams(paramRepository.resource('AnelmaBovine', 'BovineSex'));
        });

        if (isNotNil(farmId)) getAnimals(farmId);
    }, []);

    useEffect(() => {
        if (toggleTwelveMonthsSearch) getDeceasedBovines();
    }, [toggleTwelveMonthsSearch]);

    useEffect(() => {
        if (isNotNil(deceasedBovines) && deceasedBovines.length > 0)
            appendDeceasedBovinesToBovines(deceasedBovines);
    }, [deceasedBovines]);

    const columns: AGridColumns = [
        gridColumns.euIdentifier(t('AnelmaBovine:1005')),
        gridColumns.dateTime('BirthDate', t('AnelmaBovine:1011'), dayFormat, 150),
        {
            field: 'Sex',
            headerName: t('AnelmaBovine:1048'),
            renderCell: (params) => {
                const value = String(params.api.getCellValue(params.id, params.field));
                return <TextWithTooltip text={isNotNil(value) ? value : ''} />;
            },
            valueGetter: (params, row) => {
                return bovineSexParams.find((param) => {
                    if (param.code === String(row.Sex)) return param.text;
                })?.text;
            },
            width: 80,
        },
    ];

    const getAnimals = (farmId: string) => {
        setLoading(true);

        animalListApi.getAnimals(farmId).then((_) => {
            if (!_) {
                enqueueSnackbar(t('AnelmaBovine:1179'), {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }

            setBovines(transformBovineEuIds(_.Items));
            setLoading(false);
        });
    };

    const getDeceasedBovines = () => {
        if (farmId !== null)
            animalsApi.getDeceasedAnimals(farmId).then((response) => {
                if (!response) {
                    enqueueSnackbar(t('AnelmaBovine:1179'), {
                        variant: 'error',
                    });
                    return;
                }
                displayEmptyBovineResultWarning(response.Items.length);
                setDeceasedBovines((prevItems) => {
                    const uniqueState = new Set(prevItems.map((item) => item));
                    const euIdsToAdd = response.Items.filter((item) => !uniqueState.has(item));
                    return [...prevItems, ...euIdsToAdd];
                });
            });
    };

    const displayEmptyBovineResultWarning = (count: number) => {
        if (count === 0)
            enqueueSnackbar(t('AnelmaGeneral:1180'), {
                variant: 'warning',
            });
    };

    const getDataGridRows = () => {
        return isNotNil(bovines) && bovines.length > 0
            ? bovines.map((_) => mergeRight(_, { id: _.Id }))
            : [];
    };

    const handleEuIdentifierSave = (euIds: string[]) => {
        setAddEUIdentifiersDialogOpen(false);

        const newEuIds = euIds.filter((obj) => !bovines?.some((o) => o.EuIdentifier === obj));

        setBovines((prev) => {
            if (prev === null) return mapEuIdsToBovines(euIds);
            else return [...prev, ...mapEuIdsToBovines(newEuIds)];
        });
    };

    const mapEuIdsToBovines = (euIds: string[]): IAnimalSummary[] => {
        return euIds.map((id) => ({
            AlternativeAnimalDetails: [],
            AnimalBreedType: 0,
            AnimalSexType: 0,
            BirthDate: new Date(''),
            BirthFarmId: '',
            Breed: 0,
            CrossBreed: '',
            DateOfPurchase: '',
            DateOfSale: new Date(''),
            Id: id,
            IsAlaCarteParent: '',
            EarNumber: '',
            EuIdentifier: id,
            MatureForSlaughterDate: null,
            Name: '',
            SalesType: 0,
            Sex: 0,
            TransmissionAgeDate: null,
            UsageType: '',
            VirtualWaitingRoomState: 0,
            FarmId: '',
            HoldingSiteId: '',
            Flags: 0,
        }));
    };

    /** Transform eu id to id field.
     * @param bovines IAnimalSummary
     * @returns 
     */
    const transformBovineEuIds = (bovines: IAnimalSummary[]) => {
        return (
            bovines?.map((bovine) => ({
                ...bovine,
                BirthDate: bovine.BirthDate,
                Id: bovine.EuIdentifier,
                EuIdentifier: bovine.EuIdentifier,
                SalesType: bovine.SalesType,
                Sex: bovine.Sex,
            })) || []
        );
    };

    const appendDeceasedBovinesToBovines = (deceased: IAnimalSummary[]) => {
        if (isNotNil(bovines) && bovines.length > 0)
            setBovines((previousState) => [...previousState, ...transformBovineEuIds(deceased)]);
    };

    const footerActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: onClose,
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () => setAddEUIdentifiersDialogOpen(true),
                type: 'with-label',
                label: t('AnelmaCommunication:1170'),
                disabled: loading,
                icon: <Add />,
            },
            {
                onClick: () => onSave(selectedEuIdentifiers),
                type: 'save',
                disabled: loading || selectedEuIdentifiers.length === 0,
            },
        ],
    };

    return (
        <ADialog open onClose={onClose}>
            <ADialogTitle>{t('AnelmaCommunication:1121')}</ADialogTitle>
            <ADialogContent size='md' isLoading={loading}>
                <Grid container>
                    <Grid
                        item
                        sm={12}
                        sx={{ paddingTop: '20px', paddingLeft: '15px', marginBottom: '20px' }}
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`${SelectorConstants.BovineDialog}-toggle-12-months-search-checkbox`}
                                        checked={toggleTwelveMonthsSearch}
                                        onClick={() =>
                                            setToggleTwelveMonthsSearch(!toggleTwelveMonthsSearch)
                                        }
                                        disabled={
                                            loading ||
                                            (toggleTwelveMonthsSearch && deceasedBovines.length > 0)
                                        }
                                    />
                                }
                                label={t('AnelmaCommunication:1122')}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <ADataGrid
                    columns={columns}
                    rows={getDataGridRows()}
                    onSelectionModelChange={(_) => setSelectedEuIdentifiers(_ as string[])}
                    enableFiltering
                    enableRowNumbers
                    enablePagination
                    loading={loading}
                />

                {addEUIdentifiersDialogOpen && (
                    <AddEUIdentifiers
                        onClose={() => setAddEUIdentifiersDialogOpen(false)}
                        onSave={(euIds) => handleEuIdentifierSave(euIds)}
                    />
                )}
            </ADialogContent>

            <ADialogActions buttons={footerActionButtons} />
        </ADialog>
    );
}
