import { ATextInput, ATextInputBaseProps } from '../inputs';

export interface AEUIdentifierInputProps extends ATextInputBaseProps<string> {}

export const euIdentifierRegExp = new RegExp(/^(\w{2})([\d]{12})-(\d{1})$/);

export default function AEUIdentifierInput(props: AEUIdentifierInputProps) {
    return (
        <ATextInput
            {...props}
            maxLength={16}
            minLength={16}
            regExp={euIdentifierRegExp} // Do we need country code validator (short iso code)?
        />
    );
}
