// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// MUI
import { Grid, Typography } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';
import { dayFormat } from '../../core/constants';
import defaultData from '../../core/defaultData';
import FormValidator from '../../core/FormValidator';
import auth from '../../core/authorization';

// Common
import { ADeleteIconButton, AEditIconButton } from '../../common/buttons';
import { ADialogSubHeading } from '../../common/dialog';
import { AInputContainer } from '../../common/inputs';
import { AAddButton } from '../../common/buttons';

// Interfaces
import { IPricing } from '../../interfaces/IBusinessEntities';
import { FormMode } from '../../interfaces/IForm';

// Feature - Pricing
import CompanyPricingDialog, { CompanyPricingDialogProps } from './CompanyPricingDialog';

export interface CompanyPricingProps {
    data: IPricing[];
    mode: FormMode;
    onChange: (data: IPricing[]) => void;
    readonly?: boolean;
}

export default function CompanyPricingForm(props: CompanyPricingProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany', 'AnelmaGeneral']);
    const [pricingModalVisible, setPricingModalVisible] = useState(false);
    const [pricingToEdit, setPricingToEdit] = useState<IPricing>(defaultData.pricing);
    const [mode, setMode] = useState<'add' | 'edit'>('add');

    const removePricing = (data: IPricing) => {
        let newData = [...props.data];
        newData = newData.filter((p) => p !== data);

        props.onChange(newData);
    };

    const addPricing = () => {
        setMode('add');
        setPricingToEdit(defaultData.pricing);
        setPricingModalVisible(true);
    };

    const editPricing = (data: IPricing) => {
        setMode('edit');
        setPricingToEdit(data);
        setPricingModalVisible(true);
    };

    const companyPricingDialogProps: CompanyPricingDialogProps = {
        pricing: pricingToEdit,
        onClose: () => setPricingModalVisible(false),
        onSave: (data: IPricing) => {
            setPricingModalVisible(false);
            const newData = [...props.data];

            if (mode === 'add') {
                newData.push(data);
                props.onChange([...newData]);
            } else if (mode === 'edit') {
                const index = newData.findIndex((p) => p === pricingToEdit);

                if (index > -1) {
                    newData[index] = data;
                    props.onChange(newData);
                }
            }
        },
        validator: new FormValidator(),
        readonly: props.readonly,
    };

    const canCreate = auth.canCreateCompanyPricing;
    const canModify =
        (props.mode === 'create' && canCreate) ||
        (props.mode === 'modify' && auth.canUpdateCompanyPricing);
    const canDelete =
        (props.mode === 'create' && canCreate) ||
        (props.mode === 'modify' && auth.canDeleteCompanyPricing);

    const companyPricingElements = props.data.map((p, index) => {
        let modifier: string[] = [];
        let creator: string[] = [];

        if (p.ModifiedBy !== null) {
            modifier.push(p.ModifiedBy);
        }
        if (p.ModificationDate !== null) {
            modifier.push(moment(p.ModificationDate).format(dayFormat));
        }

        if (p.CreatedBy !== null) {
            creator.push(p.CreatedBy);
        }
        if (p.CreationDate !== null) {
            creator.push(moment(p.CreationDate).format(dayFormat));
        }

        const canModifyThis = p.Id === null || canModify;
        const canDeleteThis = p.Id === null || canDelete;

        return (
            <div
                key={`company-pricing-form-element${index}`}
                style={{ marginBottom: '40px', marginLeft: '40px', marginRight: '40px' }}
            >
                <Grid container>
                    {(canModifyThis || canDeleteThis) && (
                        <Grid item sm={2}>
                            {canModifyThis && (
                                <AEditIconButton
                                    key={`${p.Id}-edit`}
                                    onClick={() => editPricing(p)}
                                    disabled={props.readonly}
                                />
                            )}
                            {canDeleteThis && (
                                <ADeleteIconButton
                                    key={`${p.Id}-delete`}
                                    onClick={() => removePricing(p)}
                                    disabled={props.readonly}
                                />
                            )}
                        </Grid>
                    )}

                    <Grid item sm={10} style={{ marginBottom: '10px' }}>
                        {p.Text}
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item sm={2}></Grid>
                    <Grid item sm={5}>
                        <Typography variant='h6' style={{ fontSize: '15px' }}>{`${t(
                            'AnelmaCompany:1086'
                        )}, ${t('AnelmaCompany:1087')}`}</Typography>
                        <Grid item>{modifier.length > 0 ? modifier.join(', ') : '-'}</Grid>
                    </Grid>
                    <Grid item sm={5}>
                        <Typography variant='h6' style={{ fontSize: '15px' }}>{`${t(
                            'AnelmaCompany:1084'
                        )}, ${t('AnelmaCompany:1085')}`}</Typography>
                        <Grid item>{creator.length > 0 ? creator.join(', ') : '-'}</Grid>
                    </Grid>
                </Grid>
            </div>
        );
    });

    return (
        <>
            <ADialogSubHeading>{t('AnelmaCompany:1043')}</ADialogSubHeading>

            <Grid container>
                {pricingModalVisible ? <CompanyPricingDialog {...companyPricingDialogProps} /> : ''}

                {companyPricingElements}

                {canCreate && (
                    <div style={{ textAlign: 'right', width: '100%' }}>
                        <AInputContainer>
                            <AAddButton onClick={addPricing} size='small' disabled={props.readonly}>
                                {t('AnelmaCompany:1102')}
                            </AAddButton>
                        </AInputContainer>
                    </div>
                )}
            </Grid>
        </>
    );
}
