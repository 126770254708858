// Libraries
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNotNil, mergeRight } from 'ramda';
import { enqueueSnackbar } from 'notistack';

// MUI
import { GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import { Grid, styled } from '@mui/material';

// Core
import auth from '../../core/authorization';
import { ParameterValues, ResourceTextApplication, paramRepository } from '../../core/resources';
import { dayFormat } from '../../core/constants';

// Common
import { ADataGrid, AGridColumns, gridColumns } from '../../common/dataGrid';
import { ASwitch } from '../../common/inputs';
import { TextWithTooltip } from '../../common/typography';
import AAttachmentIconButton from '../../common/buttons/AAttachmentIconButton';

// Interfaces
import {
    CommunicationReasonEnum,
    CommunicationStatusEnum,
    CommunicationSubjectEnum,
    FeedbackParticipantRoleEnum,
    ReadTypeEnum,
} from '../../interfaces/enums';
import {
    IParticipant,
    IFeedbackHandler,
    IFeedback,
} from '../../interfaces/communication/feedback/IFeedback';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import feedbackApi from '../../api/messaging/feedbackApi';

// Feature - Feedback
import { FeedbackViewContext } from '../context/feedback/IFeedbackViewContext';
import FeedbackDialog from './FeedbackDialog';
import { FeedbackDialogContext } from '../context/feedback/IFeedbackDialogContext';
import { SelectorConstants } from './constants/SelectorConstants';

const StyledFeedbackList = styled('div')(({ theme }) => ({
    paddingTop: '3em',
    paddingBottom: '3em',
}));

export interface IFeedbackListProps {
    toggleAllFeedbackView: (_: boolean) => void;
}

export default function FeedbackList({ toggleAllFeedbackView }: IFeedbackListProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCompany',
        'AnelmaCommunication',
    ]);
    const { feedbackSummaries, feedbackViewLoading, updateToSummaries } =
        useContext(FeedbackViewContext);
    const userData = useAppSelector((state) => state.userData.data);

    const [loading, setLoading] = useState<boolean>(false);
    const [allFeedbackSwitchValue, setAllFeedbackSwitchValue] = useState<boolean>(false);
    const [speciesParameters, setSpeciesParameters] = useState<ParameterValues>([]);
    const [communicationSubjectParameters, setCommunicationSubjectParameters] =
        useState<ParameterValues>([]);
    const [communicationStatusParameters, setCommunicationStatusParameters] =
        useState<ParameterValues>([]);
    const [communicationReasonParameters, setCommunicationReasonParameters] =
        useState<ParameterValues>([]);
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<IFeedback>();

    useEffect(() => {
        initializeParameters();
    }, []);

    const initializeColumns = (): AGridColumns => {
        const columns: AGridColumns = [
            gridColumns.dateTime('CreatedAt', t('AnelmaCommunication:1016'), dayFormat, 100),
            {
                field: 'Participants',
                headerName: t('AnelmaCommunication:1110'),
                renderCell: (params) => (
                    <TextWithTooltip
                        text={resolveParticipants(params.row.Participants)}
                        className={applyReadClassNameToGridColumns(params.row.Participants)}
                    />
                ),
                width: 140,
            },
            {
                field: 'SenderCompany',
                headerName: t('AnelmaCommunication:1047'),
                renderCell: (params) => (
                    <TextWithTooltip
                        text={params.row?.SenderCompany.Name ?? ''}
                        className={applyReadClassNameToGridColumns(params.row.Participants)}
                    />
                ),
                width: 200,
            },
            {
                field: 'Topic',
                headerName: t('AnelmaCommunication:1001'),
                renderCell: (params) => (
                    <TextWithTooltip
                        text={resolveTopic(params.row.Topic)}
                        className={applyReadClassNameToGridColumns(params.row.Participants)}
                    />
                ),
                width: 180,
            },
            {
                field: 'Reason',
                headerName: t('AnelmaCommunication:1112'),
                renderCell: (params) => (
                    <TextWithTooltip
                        text={resolveReason(params.row.Reason)}
                        className={applyReadClassNameToGridColumns(params.row.Participants)}
                    />
                ),
                width: 140,
            },
            {
                field: 'HandlerPerson',
                headerName: t('AnelmaCommunication:1111'),
                renderCell: (params) => (
                    <TextWithTooltip
                        text={resolveHandlerPerson(params.row.HandlerPerson)}
                        className={applyReadClassNameToGridColumns(params.row.Participants)}
                    />
                ),
                width: 140,
            },
            {
                field: 'Status',
                headerName: t('AnelmaCommunication:1038'),
                renderCell: (params) => (
                    <TextWithTooltip
                        text={resolveStatus(params.row.Status)}
                        className={applyReadClassNameToGridColumns(params.row.Participants)}
                    />
                ),
                width: 140,
            },
            {
                field: 'HasAttachments',
                headerName: t('AnelmaCommunication:1017'),
                renderCell: (params) => renderAttachments(params.value),
                valueGetter: (params, row) => (row.HasAttachments ? true : false),
                width: 100,
            },
        ];

        return columns;
    };

    const initializeParameters = () => {
        const paramPromise = paramRepository.load(['AnelmaCompany', 'AnelmaCommunication']);
        paramPromise.then(() => {
            setSpeciesParameters(paramRepository.resource('AnelmaCompany', 'Species'));
            setCommunicationSubjectParameters(
                paramRepository.resource('AnelmaCommunication', 'CommunicationSubject')
            );
            setCommunicationStatusParameters(
                paramRepository.resource('AnelmaCommunication', 'CommunicationStatus')
            );
            setCommunicationReasonParameters(
                paramRepository.resource('AnelmaCommunication', 'CommunicationReason')
            );
        });
    };

    const applyReadClassNameToGridColumns = (participants: IParticipant[]): string => {
        return isFeedbackRead(participants) ? 'communication-bold-text-with-tooltip' : '';
    };

    /** Sort of utility function to determine if feedback is read for the current (logged in) user. The data is in IParticipant array, because of ultrafast database 'structure' : ) */
    const isFeedbackRead = (participants: IParticipant[]) => {
        const read = participants.find((_) => _.Id === userData.Id)?.ReadType;

        return isNotNil(read) && read === ReadTypeEnum.NotRead ? true : false;
    };

    const resolveParticipants = (participants: IParticipant[]) => {
        return (
            participants.find(
                (_) => _.ParticipantRole === FeedbackParticipantRoleEnum.OriginalSender
            )?.Name ?? ''
        );
    };

    const resolveTopic = (topic: CommunicationSubjectEnum) => {
        return (
            communicationSubjectParameters.find((_) => Number(_.code) === (topic as number))
                ?.text ?? ''
        );
    };

    const resolveHandlerPerson = (handler?: IFeedbackHandler) => {
        return handler?.Name ?? '';
    };

    const resolveStatus = (status?: CommunicationStatusEnum) => {
        return (
            communicationStatusParameters.find((_) => Number(_.code) === (status as number))
                ?.text ?? ''
        );
    };

    const renderAttachments = (value: boolean) => {
        return value ? (
            <AAttachmentIconButton onClick={() => false} tooltip={t('AnelmaCommunication:1165')} />
        ) : (
            <></>
        );
    };

    const resolveReason = (reason?: CommunicationReasonEnum) => {
        return (
            communicationReasonParameters.find((_) => Number(_.code) === (reason as number))
                ?.text ?? ''
        );
    };

    const openFeedbackDialog = (_: GridRowParams) => {
        if (auth.canModifyFeedback) getFeedbackItem(_.id);
    };

    const getFeedbackItem = (id: GridRowId) => {
        setLoading(true);

        feedbackApi.getFeedback(id as number).then((_) => {
            if (_ === null) {
                enqueueSnackbar(t('AnelmaCommunication:1167'), {
                    variant: 'error',
                });
                setLoading(false);
                return;
            }

            setFeedback(_.Entity);
            setFeedbackDialogOpen(true);
            setLoading(false);

            updateFeedbackRead(_.Entity);
        });
    };

    const updateFeedbackRead = (feedbackItem: IFeedback) => {
        const participant = feedbackItem.Participants.find((_) => _.Id === userData.Id);

        if (!participant) {
            return;
        }

        feedbackApi
            .updateFeedbackRead({
                FeedbackId: feedbackItem.Id,
                ParticipantId: participant.Id,
                ReadType: ReadTypeEnum.Read,
            })
            .then((_) => {
                if (_ === null) {
                    enqueueSnackbar(t('AnelmaCommunication:1168'), {
                        variant: 'error',
                    });
                    return;
                }

                updateToSummaries(_.Entity);
            });
    };

    const onAllFeedbackSwitchChange = (value: boolean) => {
        setAllFeedbackSwitchValue(value);
        toggleAllFeedbackView(value);
    };

    return (
        <StyledFeedbackList data-robot-id='feedback-list'>
            {auth.canViewAllFeedback ? (
                <Grid container justifyContent='flex-end'>
                    <Grid item>
                        <ASwitch
                            checked={allFeedbackSwitchValue}
                            id={`${SelectorConstants.LoadAdminFeedback}`}
                            label={t('AnelmaCommunication:1085')}
                            onChange={(_: boolean) => onAllFeedbackSwitchChange(_)}
                            disabled={feedbackViewLoading}
                            withoutContainer
                        />
                    </Grid>
                </Grid>
            ) : null}

            <ADataGrid
                className={
                    auth.canModifyFeedback ? 'communication-hover-enable-cursor-pointer' : ''
                }
                columns={initializeColumns()}
                data-robot-id={`${SelectorConstants.FeedbackDataGrid}`}
                getRowClassName={(params) =>
                    applyReadClassNameToGridColumns(params.row.Participants)
                }
                enableFiltering
                enablePagination
                onRowClick={(_: GridRowParams) => openFeedbackDialog(_)}
                rows={feedbackSummaries.map((_) => mergeRight(_, { id: _.Id }))}
                loading={feedbackViewLoading || loading}
            />

            <FeedbackDialogContext.Provider
                value={{
                    feedback: feedback,
                }}
            >
                {feedbackDialogOpen && (
                    <FeedbackDialog
                        onClose={() => setFeedbackDialogOpen(false)}
                        formMode={'modify'}
                    />
                )}
            </FeedbackDialogContext.Provider>
        </StyledFeedbackList>
    );
}
