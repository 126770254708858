// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Interfaces
import { DocumentFileTypeEnum, IDocumentAddDialogData } from '../IDocuments';

// Core
import auth from '../../core/authorization';
import { paramRepository, ParameterValues } from '../../core/resources';

// Common
import { gridColumns } from '../../common/dataGrid';

// Feature
import DocumentView from './DocumentView';

// Api
import api from '../../api/documentApi';
import { publicApiUrl } from '../../api/apiEndpoints';

export default function InfoDocuments() {
    const { t } = useTranslation(['AnelmaLayout', 'AnelmaDocument']);

    const [loading, setLoading] = useState<boolean>(true);
    const [types, setTypes] = useState<ParameterValues>([]);

    useEffect(() => {
        paramRepository.load(['AnelmaDocument']).then(() => {
            const params = paramRepository.resource('AnelmaDocument', 'NewsArea');
            setTypes(params);
            setLoading(false);
        });
    }, []);

    const onSave = (data: IDocumentAddDialogData) => {
        return api.uploadDocument(data, DocumentFileTypeEnum.NewsDocument);
    };

    return (       
        <DocumentView
            additionalColumns={[
                gridColumns.dateTime('ValidFrom', t('AnelmaDocument:1026'), 'DD.MM.YYYY HH:mm'),
                gridColumns.withTooltip({
                    field: 'Type',
                    headerName: t('AnelmaDocument:1027'),
                    valueGetter: (value, row) => {
                        const param = types.find((type) => `${type.code}` === `${value}`);
                        return param?.text || '-';
                    },
                    width: 200,
                }),
            ]}
            copyLinkUrl={`${publicApiUrl}/news-file`}
            deleteConfirmation={t('AnelmaDocument:1028')}
            enableAddButton={auth.canCreateNewsFile}
            enableDeleteFile={auth.canDeleteNewsFile}
            fileType={DocumentFileTypeEnum.NewsDocument}
            onSave={onSave}
            preLoading={loading}
            title={t('AnelmaLayout:1054')}
            types={{
                label: t('AnelmaDocument:1029'),
                parameter: 'AnelmaDocument:NewsArea',
            }}
            documentDialogConfig={'infoDocuments'}
        />       
    );
}
