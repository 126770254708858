// Libraries
import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { isNil, isNotNil } from 'ramda';
import { enqueueSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import FormValidator from '../../core/FormValidator';
import auth from '../../core/authorization';

// Common
import { AAutocomplete } from '../../common/inputs';
import { AAddButton } from '../../common/buttons';

// Interfaces
import { ICompanyDetails, IPersonRelation } from '../../interfaces/IBusinessEntities';
import { AliasTypeEnum } from '../../interfaces/enums';
import { FormMode } from '../../interfaces/IForm';
import { ICompanyInfo } from '../../interfaces/IAnimal';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import companyApi from '../../api/companyApi';

// Feature - Feedback
import { FeedbackViewContext } from '../context/feedback/IFeedbackViewContext';
import { SelectorConstants } from './constants/SelectorConstants';
import SearchFarm from './SearchFarm';
import { FeedbackDialogContext } from '../context/feedback/IFeedbackDialogContext';

export interface ICompanySelectionProps {
    onSelectedCompanyChange: (id: string) => void;
    formMode: FormMode;
}

export default function CompanySelection({
    onSelectedCompanyChange,
    formMode,
}: ICompanySelectionProps) {
    const formValidator = new FormValidator();

    const userData = useAppSelector((state) => state.userData.data);

    const { farmDetails, payloadFarms } = useContext(FeedbackViewContext);
    const { feedback } = useContext(FeedbackDialogContext);

    const [companyRelations, setCompanyRelations] = useState<IPersonRelation[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<string>(
        formMode === 'create' ? '' : feedback?.SenderCompany.Id ?? ''
    );
    const [searchFarmOpen, setSearchFarmOpen] = useState<boolean>(false);

    useEffect(() => {
        getCompanyRelations();
    }, []);

    useEffect(() => {
        const timeOutId = setTimeout(() => onSelectedCompanyChange(selectedCompany), 500);
        return () => clearTimeout(timeOutId);
    }, [selectedCompany]);

    useEffect(() => {
        applySelectedCompanyToCompanyRelations();
    }, [companyRelations]);

    const getCompanyRelations = () => {
        if (isNotNil(payloadFarms) && payloadFarms.length > 0)
            setPayloadFarmsAsCompanyRelations(payloadFarms);
        else if (farmDetails) setCompanyRelations(userData.CompanyRelations);
    };

    const setPayloadFarmsAsCompanyRelations = (companies: ICompanyInfo[]) => {
        const relations: IPersonRelation[] = [];

        companies.forEach((company) => {
            const companyPersonRelation: IPersonRelation = {
                CompanyId: company.Guid,
                CompanyName: company.Name,
                CompanyProducerNumber: company.ProducerNumber,
                CompanyType: 0,
                Roles: [],
                AccessGroups: [],
            };

            relations.push(companyPersonRelation);
        });

        setCompanyRelations(relations);

        if (relations.length === 1) setSelectedCompany(relations[0].CompanyId);
    };

    const appendSelectedCompanyToCompanyRelations = (companyDetails: ICompanyDetails) => {
        const companyPersonRelation: IPersonRelation = {
            CompanyId: companyDetails.Id ?? '',
            CompanyName: companyDetails.Names.find((_) => _.IsDefault === true)?.Value ?? '',
            CompanyProducerNumber:
                companyDetails.Aliases.find((_) => _.Type === AliasTypeEnum.ProducerNumber)
                    ?.Value ?? '',
            CompanyType: companyDetails.CompanyType,
            Roles: [],
            AccessGroups: [],
        };

        setCompanyRelations((state) => [...state, companyPersonRelation]);
        setSelectedCompany(companyPersonRelation.CompanyId);
    };

    /** In a case where the end user doesn't belong to the feedback's company (=selectedCompany), get company details and apply the data to 'companyRelations' state.
     *  This is only because customer requirements are thoughtless in some cases.
     */
    const applySelectedCompanyToCompanyRelations = () => {
        if (formMode === 'modify' && selectedCompany.length > 0 && companyRelations.length > 0) {
            const companyInRelations = companyRelations.find(
                (_) => _.CompanyId === selectedCompany
            );

            if (isNil(companyInRelations)) getCompany(selectedCompany);
        }
    };

    const getCompany = (companyId: string) => {
        companyApi.getCompanyByGuid(companyId).then((response) => {
            if (!response) {
                enqueueSnackbar(t('AnelmaSolmuErrors:10009'), {
                    variant: 'error',
                });
                return;
            }

            const company = response.Entity;
            setCompanyRelations((previousState) => [
                ...previousState,
                {
                    CompanyId: company.Id ?? '',
                    CompanyName: company.Names.find((_) => _.IsDefault)?.Value ?? '',
                    CompanyProducerNumber:
                        company.Aliases.find((a) => a.Type === AliasTypeEnum.ProducerNumber)
                            ?.Value ?? '',
                    CompanyType: company.CompanyType,
                    Roles: [],
                    AccessGroups: [],
                },
            ]);
        });
    };

    return (
        <>
            {
                /** Company selection for producer user */
                !userData.IsSnellmanUser && companyRelations.length > 0 ? (
                    <Grid item sm={12}>
                        <AAutocomplete
                            id={`${SelectorConstants.FeedbackDialog}-company-autocomplete`}
                            label={t('AnelmaCommunication:1118')}
                            onChange={(_) => setSelectedCompany(_ !== null ? (_ as string) : '')}
                            value={selectedCompany}
                            items={companyRelations.map((_) => ({
                                text: `${_.CompanyName}, ${_.CompanyProducerNumber}`,
                                value: _.CompanyId,
                            }))}
                            validator={formValidator}
                            required
                            disabled={formMode === 'modify'}
                        />
                    </Grid>
                ) : (
                    <></>
                )
            }

            {
                /** Company selection for Snellman user */
                userData.IsSnellmanUser && companyRelations.length > 0 ? (
                    <Grid item sm={12}>
                        <AAutocomplete
                            id={`${SelectorConstants.FeedbackDialog}-company-autocomplete`}
                            label={t('AnelmaCommunication:1118')}
                            onChange={(_) => setSelectedCompany(_ !== null ? (_ as string) : '')}
                            value={selectedCompany}
                            items={companyRelations.map((_) => ({
                                text: `${_.CompanyName}, ${_.CompanyProducerNumber}`,
                                value: _.CompanyId,
                            }))}
                            validator={formValidator}
                            required
                            disabled={formMode === 'modify'}
                        />

                        {auth.canCreateFeedbackAdmin && (
                            <Grid item sm={12} sx={{ textAlign: 'right', paddingRight: '40px' }}>
                                <AAddButton
                                    data-robot-id={`${SelectorConstants.FeedbackDialog}-open-search-farm-dialog`}
                                    onClick={() => setSearchFarmOpen(true)}
                                    type='default'
                                    children={t('AnelmaCommunication:1166')}
                                    disabled={formMode === 'modify'}
                                />
                            </Grid>
                        )}

                        {searchFarmOpen && (
                            <SearchFarm
                                onClose={() => setSearchFarmOpen(false)}
                                handleSelectedCompany={(_: ICompanyDetails) =>
                                    appendSelectedCompanyToCompanyRelations(_)
                                }
                            />
                        )}
                    </Grid>
                ) : (
                    <></>
                )
            }
        </>
    );
}
