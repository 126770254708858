// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// MUI
import { Grid } from '@mui/material';

// Core
import { ParameterResource, ResourceTextApplication } from '../core/resources';
import { IFormValidator } from '../core/FormValidator';
import auth from '../core/authorization';

// Common
import {
    ABankAccountInput,
    ATextInput,
    AInputItem,
    ARadioButtons,
    AMultiselect,
    AMultiselectValue,
} from '../common/inputs';
import { ADialogSubHeading } from '../common/dialog';

// Interfaces
import {
    IFarmDetails,
    IPricing,
    IProductionLine,
    IHoldingSite,
    IAlias,
} from '../interfaces/IBusinessEntities';
import { AliasTypeEnum, AnimalTypeEnum } from '../interfaces/enums';
import { FormMode } from '../interfaces/IForm';

// Store
import { useAppSelector } from '../store/hooks';

// API
// Feature
import CompanyPricingForm, { CompanyPricingProps } from './pricing/CompanyPricingForm';
import CompanyHoldingSitesForm, {
    CompanyHoldingSitesProps,
} from './holdingSites/CompanyHoldingSitesForm';
import { isIbanLengthValid } from './helpers/bankAccountHelper';
import { isInReadOnlyModeAndTrafficContractorOrDriver } from './helpers/userHelper';

export interface FarmFormProps {
    data: IFarmDetails;
    mode: FormMode;
    onChange: (data: IFarmDetails) => void;
    validator: IFormValidator;
    inputValues: ParameterResource;
    readonly?: boolean;
}

export default function FarmForm(props: FarmFormProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCompany',
        'AnelmaGeneral',
        'AnelmaSolmuErrors',
    ]);

    const userData = useAppSelector((state) => state.userData);

    const [farmDetails, setFarmDetails] = useState<IFarmDetails>(props.data);

    useEffect(() => {
        setFarmDetails(props.data);
    }, [props.data]);

    useEffect(() => {
        const timeOutId = setTimeout(() => props.onChange(farmDetails), 500);
        return () => clearTimeout(timeOutId);
    }, [
        farmDetails.Aliases,
        farmDetails.CattleId,
        farmDetails.TeamAreaCode,
        farmDetails.FeedSuppliers,
        farmDetails.Dairy,
    ]);

    useEffect(() => {
        validateBankAccount(farmDetails.Aliases);
    }, [farmDetails.Aliases]);

    /** IBAN actually validated in the input ABankAccountInput. Just checking here if it has an actual value. */
    const validateBankAccount = (aliases: IAlias[]) => {
        props.validator.setState(
            'bank-account',
            isIbanLengthValid(
                aliases.find((_) => _.Type === AliasTypeEnum.BankAccount)?.Value ?? null
            )
        );
    };

    const handleOnChange = (value: string | number | string[], property: string) => {
        switch (property) {
            case 'bankAccount':
                const bankAlias = [...farmDetails.Aliases];
                const bankIdIdx = bankAlias.findIndex((_) => _.Type === AliasTypeEnum.BankAccount);
                bankIdIdx === -1
                    ? bankAlias.push({
                          Type: AliasTypeEnum.BankAccount,
                          Value: value as string,
                      })
                    : (bankAlias[bankIdIdx].Value = value as string);
                setFarmDetails((prev) => {
                    return {
                        ...prev,
                        Aliases: bankAlias,
                    };
                });
                break;
            case 'farmId':
                const farmIdAlias = [...farmDetails.Aliases];
                const farmIdIdx = farmIdAlias.findIndex((_) => _.Type === AliasTypeEnum.FarmId);
                farmIdIdx === -1
                    ? farmIdAlias.push({
                          Type: AliasTypeEnum.FarmId,
                          Value: value as string,
                      })
                    : (farmIdAlias[farmIdIdx].Value = value as string);
                setFarmDetails((prev) => {
                    return {
                        ...prev,
                        Aliases: farmIdAlias,
                    };
                });
                break;
            case 'cattleId':
                setFarmDetails((prev) => {
                    return {
                        ...prev,
                        CattleId: value as string,
                    };
                });
                break;
            case 'TeamAreaCode':
                setFarmDetails((prev) => {
                    return {
                        ...prev,
                        TeamAreaCode: value as number,
                    };
                });
                break;
            case 'feedSupplier':
                setFarmDetails((prev) => {
                    return {
                        ...prev,
                        FeedSuppliers: props.inputValues['feedSupplier']
                            .filter((v) => (value as string[]).includes(v.text))
                            .map((v) => v.code),
                    };
                });
                break;
            case 'dairy':
                setFarmDetails((prev) => {
                    return {
                        ...prev,
                        Dairy: props.inputValues['dairy']
                            .filter((v) => (value as string[]).includes(v.text))
                            .map((v) => v.code),
                    };
                });
                break;
        }
    };

    const companyPricingProps: CompanyPricingProps = {
        data: props.data.Pricing,
        mode: props.mode,
        onChange: (data: IPricing[]) => {
            const newData = { ...props.data };
            newData.Pricing = data;
            props.onChange(newData);
        },
        readonly: props.readonly,
    };

    const companyHoldingSitesProps: CompanyHoldingSitesProps = {
        productionLines: props.data.ProductionLines,
        onChange: (data: IHoldingSite[] | IProductionLine[], property: string) => {
            const newData = { ...props.data };

            if (property === 'holdingSites') {
                newData.HoldingSites = data as IHoldingSite[];
                props.onChange(newData);
            } else if (property === 'productionLines') {
                newData.ProductionLines = data as IProductionLine[];
                props.onChange(newData);
            }
        },
        holdingSites: props.data.HoldingSites,
        validator: props.validator,
        readonly: props.readonly,
    };

    const getValues = (type: 'feedSupplier' | 'dairy') => {
        switch (type) {
            case 'feedSupplier':
                return props.inputValues['feedSupplier']?.map((item) => item.text);
            case 'dairy':
                return props.inputValues['dairy']?.map((item) => item.text);
        }
    };

    const teamAreaCodeOptions: AInputItem<number>[] = [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
    ];

    const hasBovineLines = (): boolean => {
        const bovineLines = farmDetails.ProductionLines?.find(
            (p) => p.Type === AnimalTypeEnum.Bovine
        );
        return !!bovineLines?.FarmingTypes.length;
    };

    const canEditBankAccount =
        (props.mode === 'create' && auth.canCreateCompanyBankAccount) ||
        (props.mode === 'modify' && auth.canUpdateCompanyBankAccount);
    const canEditTeamArea =
        (props.mode === 'create' && auth.canCreateCompanyTeamAreaCode) ||
        (props.mode === 'modify' && auth.canUpdateCompanyTeamAreaCode);
    const canDeleteTeamArea =
        (props.mode === 'create' && auth.canCreateCompanyTeamAreaCode) ||
        (props.mode === 'modify' && auth.canDeleteCompanyTeamAreaCode);

    return (
        <>
            <ADialogSubHeading>{t('AnelmaCompany:1040')}</ADialogSubHeading>

            <Grid container>
                <Grid item sm={6}>
                    <ATextInput
                        id='farm-id'
                        label={t('AnelmaCompany:1021')}
                        maxLength={9}
                        minLength={9}
                        onChange={(v) => handleOnChange(v, 'farmId')}
                        regExp={/^\d{9}$/}
                        required
                        validator={props.validator}
                        value={
                            farmDetails.Aliases.find((a) => a.Type === AliasTypeEnum.FarmId)
                                ?.Value || ''
                        }
                        disabled={props.readonly}
                    />
                </Grid>
                <Grid item sm={6}>
                    <ATextInput
                        id='cattle-id'
                        label={t('AnelmaCompany:1022')}
                        maxLength={7}
                        minLength={7}
                        onChange={(v) => handleOnChange(v, 'cattleId')}
                        regExp={/^\d{7}$/}
                        required={hasBovineLines()}
                        validator={props.validator}
                        value={farmDetails.CattleId ?? ''}
                        disabled={props.readonly}
                    />
                </Grid>
            </Grid>

            <CompanyHoldingSitesForm {...companyHoldingSitesProps} />

            {isInReadOnlyModeAndTrafficContractorOrDriver(userData.data, props.readonly) ? (
                <></>
            ) : (
                <>
                    <ADialogSubHeading>{t('AnelmaCompany:1041')}</ADialogSubHeading>
                    <Grid container>
                        <Grid item sm={12}>
                            <AMultiselect
                                id={'radiobuttons-for-feedSupplier'}
                                validator={props.validator}
                                getOptionLabel={(opt) => opt as string}
                                onChange={(e: AMultiselectValue[]) =>
                                    handleOnChange(e as string[], 'feedSupplier')
                                }
                                label={t('AnelmaCompany:1031')}
                                items={getValues('feedSupplier')}
                                value={props.inputValues['feedSupplier']
                                    .filter((v) => farmDetails.FeedSuppliers.includes(v.code))
                                    .map((v) => v.text)}
                                disabled={props.readonly}
                            />
                        </Grid>

                        <Grid item sm={12}>
                            <AMultiselect
                                id={'radiobuttons-for-dairy'}
                                validator={props.validator}
                                getOptionLabel={(opt) => opt as string}
                                onChange={(e: AMultiselectValue[]) =>
                                    handleOnChange(e as string[], 'dairy')
                                }
                                label={t('AnelmaCompany:1032')}
                                items={getValues('dairy')}
                                value={props.inputValues['dairy']
                                    .filter((v) => farmDetails.Dairy.includes(v.code))
                                    .map((v) => v.text)}
                                disabled={props.readonly}
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            {isInReadOnlyModeAndTrafficContractorOrDriver(userData.data, props.readonly) ? (
                <></>
            ) : (
                auth.canViewCompanyBankAccount && (
                    <>
                        <ADialogSubHeading>{t('AnelmaCompany:1042')}</ADialogSubHeading>

                        <Grid container>
                            <Grid item sm={6}>
                                <ABankAccountInput
                                    disabled={!canEditBankAccount || props.readonly}
                                    id='bank-account'
                                    label={t('AnelmaCompany:1034')}
                                    onChange={(v) => handleOnChange(v, 'bankAccount')}
                                    required={canEditBankAccount}
                                    validator={props.validator}
                                    value={
                                        farmDetails.Aliases.find(
                                            (a) => a.Type === AliasTypeEnum.BankAccount
                                        )?.Value || ''
                                    }
                                />
                            </Grid>
                        </Grid>
                    </>
                )
            )}

            {isInReadOnlyModeAndTrafficContractorOrDriver(userData.data, props.readonly) ? (
                <></>
            ) : (
                auth.canViewCompanyPricing && <CompanyPricingForm {...companyPricingProps} />
            )}

            {isInReadOnlyModeAndTrafficContractorOrDriver(userData.data, props.readonly) ? (
                <></>
            ) : (
                auth.canViewCompanyTeamAreaCode && (
                    <>
                        <ADialogSubHeading>{t('AnelmaCompany:1035')}</ADialogSubHeading>

                        <Grid container>
                            <Grid item sm={6}>
                                <ARadioButtons
                                    disabled={!canEditTeamArea || props.readonly}
                                    id='team-area-code'
                                    items={teamAreaCodeOptions}
                                    onChange={(e: any) => handleOnChange(e, 'TeamAreaCode')}
                                    unselectable={canDeleteTeamArea}
                                    validator={props.validator}
                                    value={farmDetails.TeamAreaCode}
                                />
                            </Grid>
                        </Grid>
                    </>
                )
            )}
        </>
    );
}
