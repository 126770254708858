// Libraries
import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import './App.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Theme
import { anelmaTheme } from './muiTheme';
import './common/layout/fonts.scss';
import { ThemeProvider } from '@mui/material/styles';

// Store
import { useAppDispatch, useAppSelector } from './store/hooks';
import { loadConfigurationAsync } from './store/configurationSlice';
import { loadLoginStatusAsync } from './store/loginStatusSlice';
import { loadAccessList } from './store/authorizationSlice';
import { loadUserDataAsync } from './store/userDataSlice';

//Calendar
import eventMapper from './calendar/eventMapper';

// Core
import auth from './core/authorization';
import { SessionNotifier } from './core/SessionNotifier';
import ApiResults from './core/ApiResults';
import { ResourceTextApplication } from './core/resources';

// Common
import Header from './common/layout/Header';
import Footer from './common/layout/Footer';
import ViewLoader from './common/ViewLoader';
import ContextSelector from './common/contextSelector/ContextSelector';
import CookieNotification from './common/layout/CookieNotification';

// Interfaces
import { RoutePaths } from './interfaces/enums';
import { EditableContentType } from './editableContent/IEditableContent';

// Views
import Accounting from './economy/accounting/Accounting';
import AccessRightManagement from './accessRightsManagement/AccessRightsManagement';
import AnimalListMain from './animals/animal-list/AnimalListMain';
import AnimalPayloads from './animals/animal-payloads/AnimalPayloads';
import AnimalBreedings from './animalBreedings/AnimalBreedings';
import Bulletin from './bulletin/Bulletin';
import ButcheryReport from './reports/ButcheryReport';
import Calendar from './calendar/Calendar';
import Companies from './companies/Companies';
import ContentEditor from './editableContent/ContentEditor';
import ContentView from './editableContent/views/ContentView';
import FarmInfo from './infoViews/farmInfo/FarmInfo';
import FarmVisit from './farmVisit/FarmVisit';
import FeedingInfo from './info/FeedingInfo';
import FinancialReport from './reports/FinancialReport';
import GMFreeInfo from './info/GMFreeInfo';
import HealthReport from './reports/HealthReport';
import InfoDocuments from './documents/views/InfoDocuments';
import InstructionsAndCode from './info/InstructionsAndCode';
import Invoices from './economy/invoices/Invoices';
import LabInfo from './info/LabInfo';
import Logging from './logging/Logging';
import Messaging from './communication/messaging/Messaging';
import MPInfo from './info/MPInfo';
import MyInfo from './infoViews/myInfo/MyInfo';
import NewsDocuments from './documents/views/NewsDocuments';
import PageNotFound from './404';
import Persons from './persons/Persons';
import ProductionReport from './reports/ProductionReport';
import PublicContactPersons from './publicContactPersons/PublicContactPersons';
import PublicContentView from './editableContent/views/PublicContentView';
import SearchParameters from './searchParameters/SearchParameters';
import ShortMessageServiceView from './shortMessageService/ShortMessageService';
import SlaughterVariables from './slaughterVariables/SlaughterVariables';
import { ReportingApiUnintercepted } from './api/reportingApiUnintercepted';
import PriceList from './economy/pricelist/PriceList';
import AnimalAnnouncementsListView from './animals/announcements/components/list-view/AnimalAnnouncementsListView';
import ProductionPlanList from './animals/production-plan/ProductionPlanList';
import TaxYearSummary from './economy/taxYearSummary/TaxYearSummary';
import FarmDocuments from './documents/views/FarmDocuments';
import FarmVisitNotification from './farmVisit/notifications/FarmVisitNotification';
import InvoiceDetailViewNotification from './economy/invoices/notifications/InvoiceDetailViewNotification';
import ProductionPlanNotification from './animals/production-plan/notifications/ProductionPlanNotification';
import ConversationDialogNotification from './communication/messaging/notifications/ConversationDialogNotification';
import FeedbackView from './communication/feedback/FeedbackView';
import Forbidden from './core/Forbidden';

export default function App() {
    const authorization = useAppSelector((state) => state.authorization);
    const configuration = useAppSelector((state) => state.configuration);
    const context = useAppSelector((state) => state.context);
    const loginStatus = useAppSelector((state) => state.loginStatus);
    const userData = useAppSelector((state) => state.userData);
    const Dashboard = lazy(() => import('./dashboard/Dashboard'));
    const Events = lazy(() => import('./events/EventsMain'));
    const EventAttendees = lazy(() => import('./events/EventAttendees'));
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCalendar']);

    const dispatch = useAppDispatch();
    const loadConfigurationData = useCallback(() => dispatch(loadConfigurationAsync()), [dispatch]);
    const loadLoginStatusData = useCallback(() => dispatch(loadLoginStatusAsync()), [dispatch]);
    const loadUserData = useCallback(() => dispatch(loadUserDataAsync()), [dispatch]);
    const loadAccessListData = useCallback(
        (context?: string | null) => dispatch(loadAccessList(context)),
        [dispatch]
    );
    const currentLocation = useLocation();

    const reportingApiUnintercepted = new ReportingApiUnintercepted();

    const [appPreInitialized, setAppPreInitialized] = useState<boolean>(false);
    const [appFullyInitialized, setAppFullyInitialized] = useState<boolean>(false);
    const [displayContextSelector, setDisplayContextSelector] = useState<boolean>(false);

    // 1. Load all global states without mutual dependencies
    useEffect(() => {
        loadConfigurationData();
        loadLoginStatusData();
    }, [loadConfigurationData, loadLoginStatusData]);

    // 2.1 Set localization based on configuration
    useEffect(() => {
        if (configuration.status !== 'ready') return;

        const culture = configuration.data.culture.supportedCultures.find((i) => i.selected)?.value;
        if (culture === 'en-GB') moment.updateLocale('en-gb', {});
        else if (culture === 'sv-SE') moment.updateLocale('sv', {});
        else moment.updateLocale('fi', {});
        eventMapper.init({
            labels: {
                farmVisitTitle: t('AnelmaCalendar:1005'),
                anelmaRegistrationTitle: t('AnelmaCalendar:1006'),
                anelmaEventTitle: t('AnelmaCalendar:1007'),
            },
        });
    }, [configuration]);

    // 3. Listen when all global data is loaded
    useEffect(() => {
        if (configuration.status !== 'ready') return;
        if (loginStatus.status !== 'ready') return;

        setAppPreInitialized(true);

        if (!loginStatus.data.loggedIn) setAppFullyInitialized(true);
    }, [configuration, loginStatus]);

    // 4. Reload access list and user data on context change
    useEffect(() => {
        if (!appPreInitialized) return;
        if (!loginStatus.data.loggedIn) return;
        if (
            !context.data.currentContext?.context ||
            context.data.currentContext?.context.length === 0
        )
            return;

        loadAccessListData(context.data.currentContext?.context).then(() =>
            loadUserData().then(() => setAppFullyInitialized(true))
        );
    }, [context.data.currentContext?.context, appPreInitialized, loginStatus]);

    useEffect(() => {
        if (currentLocation.pathname.length > 0)
            setDisplayContextSelector(shouldDisplayContextSelector());
    }, [currentLocation]);

    const qlikSessionHandler = () => {
        if (checkIfEligibleForEndingQlikSession(window.location.href))
            reportingApiUnintercepted.logout();
    };

    const checkIfEligibleForEndingQlikSession = (location: string): boolean => {
        if (location.includes('/reporting') || location.includes('/tax-year-summary')) return false;
        return true;
    };

    const shouldDisplayContextSelector = (): boolean => {
        switch (true) {
            case currentLocation.pathname.includes(RoutePaths.GeneralContacts):
            case currentLocation.pathname.includes(RoutePaths.InfoInstructionsAndCode):
            case currentLocation.pathname.includes(RoutePaths.InfoMP):
            case currentLocation.pathname.includes(RoutePaths.InfoFeeding):
            case currentLocation.pathname.includes(RoutePaths.InfoLab):
            case currentLocation.pathname.includes(RoutePaths.InfoShop):
            case currentLocation.pathname.includes(RoutePaths.ManagementPersonSearch):
            case currentLocation.pathname.includes(RoutePaths.ManagementCompanySearch):
            case currentLocation.pathname.includes(RoutePaths.ManagementAccessRights):
            case currentLocation.pathname.includes(RoutePaths.ManagementLogs):
            case currentLocation.pathname.includes(RoutePaths.ManagementBulletin):
            case currentLocation.pathname.includes(RoutePaths.ManagementSearchParameters):
            case currentLocation.pathname.includes(RoutePaths.ManagementShortMessageService):
            case currentLocation.pathname.includes(RoutePaths.DocumentsInfo):
            case currentLocation.pathname.includes(RoutePaths.DocumentsNews):
            case currentLocation.pathname.includes(RoutePaths.GeneralFarmVisits):
            case currentLocation.pathname.includes(RoutePaths.FarmVisitDialogEdit):
            case currentLocation.pathname.includes(RoutePaths.ManagementContentEditor):
            case currentLocation.pathname.includes(RoutePaths.ManagementSlaughterVariables):
            case currentLocation.pathname.includes(RoutePaths.AnimalsPayloads):
            case currentLocation.pathname.includes(RoutePaths.GeneralMyInfo):
                return false;
            default:
                return true;
        }
    };

    return (
        <ThemeProvider theme={anelmaTheme}>
            <Header appFullyInitialized={appFullyInitialized} />

            <SessionNotifier />

            <ApiResults />

            <ContextSelector displayContextSelector={displayContextSelector} />

            <div className='an-body' data-robot-id={'app-body'}>
                {appFullyInitialized ? (
                    <Routes>
                        <Route
                            path={RoutePaths.Root}
                            element={
                                <Suspense fallback={<ViewLoader />}>
                                    <Dashboard />
                                </Suspense>
                            }
                        />

                        <Route
                            path={RoutePaths.ManagementAccessRights}
                            element={
                                auth.canViewAccessRightsManagement ? (
                                    <AccessRightManagement />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.AnimalsList}
                            element={auth.canViewBovineList ? <AnimalListMain /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.AnimalsProductionPlans}
                            element={
                                auth.canViewProductionPlanList ? (
                                    <ProductionPlanList />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.ProductionPlanDialogRoot}
                            element={
                                auth.canViewProductionPlanList ? (
                                    <ProductionPlanNotification />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.AnimalAnnouncements}
                            element={
                                auth.canViewAnelmaAnimalAnnouncementsList ? (
                                    <AnimalAnnouncementsListView />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.AnimalsPayloads}
                            element={
                                auth.canViewAnimalPayloads ? <AnimalPayloads /> : <PageNotFound />
                            }
                        />

                        <Route
                            path={RoutePaths.AnimalBreeding}
                            element={
                                auth.canViewAnimalBreedings ? <AnimalBreedings /> : <PageNotFound />
                            }
                        />

                        <Route
                            path={RoutePaths.EconomyAccountings}
                            element={
                                auth.canViewEconomyAccounting ? <Accounting /> : <PageNotFound />
                            }
                        />

                        <Route
                            path={RoutePaths.EconomyInvoices}
                            element={auth.canViewEconomyInvoice ? <Invoices /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.EconomyPriceList}
                            element={
                                auth.canViewEconomyPriceList ? <PriceList /> : <PageNotFound />
                            }
                        />

                        <Route
                            path={RoutePaths.EconomyTaxYearSummary}
                            element={
                                auth.canViewEconomyTaxYearSummary ? (
                                    <TaxYearSummary endQlikSession={qlikSessionHandler} />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.InvoiceDetailView}
                            element={
                                auth.canViewEconomyInvoice ? (
                                    <InvoiceDetailViewNotification />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.ManagementBulletin}
                            element={auth.canViewBulletinItems ? <Bulletin /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.ReportingButchery}
                            element={
                                auth.canViewButcheryReport ? (
                                    <ButcheryReport endQlikSession={qlikSessionHandler} />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.GeneralCalendar}
                            element={auth.canViewCalendar ? <Calendar /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.ManagementCompanySearch}
                            element={auth.canViewCompaniesAdmin ? <Companies /> : <PageNotFound />}
                        />

                        {/* NOTE: Can be viewed on public site */}
                        <Route
                            path={RoutePaths.GeneralContacts}
                            element={<PublicContactPersons />}
                        />

                        <Route
                            path={RoutePaths.ManagementContentEditor}
                            element={
                                auth.canViewPublicContentManagement ? (
                                    <ContentEditor />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        {/* NOTE: Can be viewed on public site */}

                        <Route
                            path={RoutePaths.GeneralDashboard}
                            element={
                                <Suspense fallback={<ViewLoader />}>
                                    <Dashboard />
                                </Suspense>
                            }
                        />

                        <Route
                            path={RoutePaths.ManagementEvents}
                            element={
                                auth.canViewEventManagement ? (
                                    <Suspense fallback={<ViewLoader />}>
                                        <Events />
                                    </Suspense>
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.ManagementEventAttendees}
                            element={
                                auth.canViewEventManagement ? (
                                    <Suspense fallback={<ViewLoader />}>
                                        <EventAttendees />
                                    </Suspense>
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.GeneralFarmInfo}
                            element={auth.canViewFarmInfo ? <FarmInfo /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.FarmVisitDialogEdit}
                            element={
                                auth.canViewFarmInfo ? <FarmVisitNotification /> : <PageNotFound />
                            }
                        ></Route>

                        <Route
                            path={RoutePaths.GeneralFarmVisits}
                            element={
                                auth.canViewAllFarmVisits && auth.canViewFarmInfo ? (
                                    <FarmVisit />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />
                        
                        <Route
                            path={RoutePaths.GeneralFeedback}
                            element={auth.canViewFeedback ? <FeedbackView /> : <Forbidden />}
                        />

                        <Route
                            path={RoutePaths.InfoFeeding}
                            element={auth.canViewFeedingInfo ? <FeedingInfo /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.ReportingFinancial}
                            element={
                                auth.canViewFinancialReport ? (
                                    <FinancialReport endQlikSession={qlikSessionHandler} />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.InfoShop}
                            element={auth.canViewGMFreeInfo ? <GMFreeInfo /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.ReportingHealth}
                            element={
                                auth.canViewHealthReport ? (
                                    <HealthReport endQlikSession={qlikSessionHandler} />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.InfoInstructionsAndCode}
                            element={
                                auth.canViewInstructionsAndCode ? (
                                    <InstructionsAndCode />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.InfoLab}
                            element={auth.canViewLabInfo ? <LabInfo /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.ManagementLogs}
                            element={auth.canViewLogs ? <Logging /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.GeneralMessaging}
                            element={auth.canViewMessages ? <Messaging /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.ConversationDialogEdit}
                            element={
                                auth.canViewMessages ? (
                                    <ConversationDialogNotification />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.ReportingProduction}
                            element={
                                auth.canViewProductionReport ? (
                                    <ProductionReport endQlikSession={qlikSessionHandler} />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.PublicMPBovine}
                            element={
                                loginStatus.data.loggedIn ? (
                                    <ContentView type={EditableContentType.MPBovine} />
                                ) : (
                                    <PublicContentView type={EditableContentType.PublicMPBovine} />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.InfoMP}
                            element={auth.canViewMPInfo ? <MPInfo /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.PublicMPPork}
                            element={
                                loginStatus.data.loggedIn ? (
                                    <ContentView type={EditableContentType.MPPork} />
                                ) : (
                                    <PublicContentView type={EditableContentType.PublicMPPork} />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.GeneralMyInfo}
                            element={auth.canViewMyInfo ? <MyInfo /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.ManagementPersonSearch}
                            element={auth.canViewPersons ? <Persons /> : <PageNotFound />}
                        />

                        <Route
                            path={RoutePaths.ManagementSearchParameters}
                            element={
                                auth.canViewCommunicationGroups ? (
                                    <SearchParameters />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.ManagementShortMessageService}
                            element={
                                auth.canViewSms ? <ShortMessageServiceView /> : <PageNotFound />
                            }
                        />

                        <Route
                            path={RoutePaths.PrivacyStatement}
                            element={
                                loginStatus.data.loggedIn ? (
                                    <ContentView type={EditableContentType.PrivacyStatement} />
                                ) : (
                                    <PublicContentView
                                        type={EditableContentType.PrivacyStatement}
                                    />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.DocumentsInfo}
                            element={
                                auth.canViewInfoDocuments ? <InfoDocuments /> : <PageNotFound />
                            }
                        />

                        <Route
                            path={RoutePaths.DocumentsNews}
                            element={
                                auth.canViewNewsDocuments ? <NewsDocuments /> : <PageNotFound />
                            }
                        />

                        <Route
                            path={RoutePaths.ManagementSlaughterVariables}
                            element={
                                auth.canViewVariablesAdmin ? (
                                    <SlaughterVariables />
                                ) : (
                                    <PageNotFound />
                                )
                            }
                        />

                        <Route
                            path={RoutePaths.FarmDocuments}
                            element={
                                auth.canViewFarmDocuments ? <FarmDocuments /> : <PageNotFound />
                            }
                        />

                        <Route path='*' element={<PageNotFound />} />
                    </Routes>
                ) : (
                    <ViewLoader />
                )}
            </div>

            <Footer />

            <CookieNotification />
        </ThemeProvider>
    );
}
