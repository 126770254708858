// Librries
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// MUI
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Feature
import { IMainMenuItem, IMainMenuLink, MainMenuItemType } from './IMainMenu';

export interface MainMenuItemProps {
    closeSubMenu: () => void;
    item: MainMenuItemType;
}

export default function MainMenuItem(props: MainMenuItemProps) {
    const { t } = useTranslation('AnelmaLayout');

    const hasSubItems = !!(props.item as IMainMenuItem).itemGroups?.length;
    const link = props.item as IMainMenuLink;

    if (link.href) {
        if (link.externalLink) {
            return (
                <a
                    href={link.href}
                    rel='noreferrer'
                    target='_blank'
                    data-robot-id={'main-menu-external-link-' + link.textCode}
                >
                    {t(`AnelmaLayout:${link.textCode}`)}
                    {hasSubItems && <ExpandMoreIcon />}
                </a>
            );
        }

         else {
            return (
                <Link
                    to={link.href}
                    onClick={() => props.closeSubMenu()}
                    data-robot-id={'main-menu-link-' + link.textCode}
                >
                    {t(`AnelmaLayout:${link.textCode}`)}
                    {hasSubItems && <ExpandMoreIcon />}
                </Link>
            );
        }
    }

    return (
        <>
            <span data-robot-id={'main-menu-item-type-' + props.item.textCode}>
                {t(`AnelmaLayout:${props.item.textCode}`)}
                {hasSubItems && <ExpandMoreIcon />}
            </span>
        </>
    );
}
