// Libraries
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { ADialog, ADialogActions, ADialogContent, ADialogTitle } from '../../common/dialog';
import { AInputContainer } from '../../common/inputs';

export interface InactivationConfirmDialogProps {
    contentStr: string;
    inactivate: boolean;
    title: string;
    onConfirm: (confirm: boolean) => void;
    readonly?: boolean;
}

export function InactivationConfirmDialog(props: InactivationConfirmDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaPerson']);

    return (
        <ADialog open onClose={() => props.onConfirm(false)}>
            <ADialogTitle>{props.title}</ADialogTitle>
            <ADialogContent size='sm'>
                <div style={{ paddingTop: '40px' }}></div>
                <AInputContainer>
                    <>{props.contentStr}</>
                </AInputContainer>
            </ADialogContent>
            <ADialogActions
                buttons={{
                    left: [
                        {
                            onClick: () => props.onConfirm(false),
                            type: 'cancel',
                        },
                    ],
                    right: [
                        {
                            enabledWhileLoading: false,
                            label: props.inactivate
                                ? t('AnelmaPerson:1010')
                                : t('AnelmaPerson:1011'),
                            onClick: () => props.onConfirm(true),
                            type: 'save',
                            disabled: props.readonly,
                        },
                    ],
                }}
            />
        </ADialog>
    );
}
