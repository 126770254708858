// Libraries
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNotNil } from 'ramda';

// MUI
import { Grid } from '@mui/material';

// Core
import { ParameterValues, ResourceTextApplication, paramRepository } from '../../core/resources';
import FormValidator from '../../core/FormValidator';
import auth from '../../core/authorization';

// Common
import { AAutocomplete, AMultiselect } from '../../common/inputs';
import { AAddButton } from '../../common/buttons';

// Interfaces
import { CommunicationReasonEnum, CommunicationSubjectEnum } from '../../interfaces/enums';

// Store
import { useAppSelector } from '../../store/hooks';

// API
// Feature - Feedback
import { FeedbackViewContext } from '../context/feedback/IFeedbackViewContext';
import { ReclamationFeedbackContext } from '../context/feedback/IReclamationFeedbackContext';
import { SelectorConstants } from './constants/SelectorConstants';
import BovineDialog from './BovineDialog';

interface IReclamationFeedbackProps {
    onSave: (euIds: string[]) => void;
}

export default function ReclamationFeedback({ onSave }: IReclamationFeedbackProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCommunication',
        'AnelmaLayout',
        'AnelmaSolmuErrors',
    ]);
    const formValidator = new FormValidator();

    const userData = useAppSelector((state) => state.userData.data);

    const { farmDetails } = useContext(FeedbackViewContext);
    const {
        loading,
        formMode,
        selectedReason,
        bovineOptions,
        euIdentifiers,
        setSelectedReason,
        setEuIdentifiers,
        selectedCompany,
        selectedSubject,
    } = useContext(ReclamationFeedbackContext);

    const [bovineDialogOpen, setBovineDialogOpen] = useState<boolean>(false);
    const [reclamationReasonParameters, setReclamationReasonParameters] = useState<ParameterValues>(
        []
    );
    const [payloadDriverReasonParameters, setPayloadDriverReasonParameters] =
        useState<ParameterValues>([]);
    const [payloadProducerReasonParameters, setPayloadProducerReasonParameters] =
        useState<ParameterValues>([]);

    useEffect(() => {
        initializeParameters();
    }, []);

    /** Initialize parameters and from CommunicationReason parameter set. Filter parameters when code is smaller than 11. Check out the suunnitteludokumentti for more information. */
    const initializeParameters = () => {
        paramRepository.load(['AnelmaCommunication']).then(() => {
            setReclamationReasonParameters(
                paramRepository
                    .resource('AnelmaCommunication', 'CommunicationReason')
                    .filter(
                        (_) => Number(_.code) <= CommunicationReasonEnum.TeenagePregnancy.valueOf()
                    )
            );
            setPayloadDriverReasonParameters(
                paramRepository
                    .resource('AnelmaCommunication', 'CommunicationReason')
                    .filter(
                        (_) =>
                            Number(_.code) >= CommunicationReasonEnum.UnattendedYard.valueOf() &&
                            Number(_.code) < CommunicationReasonEnum.AnimalHandling.valueOf()
                    )
            );
            setPayloadProducerReasonParameters(
                paramRepository
                    .resource('AnelmaCommunication', 'CommunicationReason')
                    .filter(
                        (_) => Number(_.code) >= CommunicationReasonEnum.AnimalHandling.valueOf()
                    )
            );
        });
    };

    const handleSave = (euIds: string[]) => {
        setBovineDialogOpen(false);
        onSave(euIds);
    };

    const getFarmId = () => {
        if (isNotNil(selectedCompany) && selectedCompany.length > 0) return selectedCompany;
        return isNotNil(farmDetails?.Id) ? farmDetails.Id : null;
    };

    /** When selected subject is certain get AAutocomplete JSX with certain parameter set as items.
     *  Check suunnitteludokumentti for more information, though it may not be valid at the time of when you're reading this...
     * @returns
     */
    const renderReasonAutoCompleteJsx = () => {
        if (isReclamationFeedback()) return getReasonAutocompleteJsx(reclamationReasonParameters);
        else if (isTransportProducerFeedback())
            return getReasonAutocompleteJsx(payloadProducerReasonParameters);
        else if (isTransportOperatorFeedback())
            return getReasonAutocompleteJsx(payloadDriverReasonParameters);
        return <></>;
    };

    const getReasonAutocompleteJsx = (params: ParameterValues) => {
        return (
            <Grid item sm={12}>
                <AAutocomplete
                    id={`${SelectorConstants.FeedbackDialog}-reason-autocomplete`}
                    label={t('AnelmaCommunication:1112')}
                    onChange={(_) => setSelectedReason(_ as string)}
                    value={selectedReason}
                    items={params.map((_) => ({
                        text: _.text,
                        value: _.code,
                    }))}
                    validator={formValidator}
                    required={formMode !== 'modify'}
                    disabled={formMode === 'modify' && !userData.IsSnellmanUser}
                />
            </Grid>
        );
    };

    const renderBovineSelectJsx = () => {
        if (isReclamationFeedback() || isTransportOperatorFeedback()) return getBovineSelectJsx();
        return <></>;
    };

    const getBovineSelectJsx = () => {
        return (
            <>
                <Grid item sm={12}>
                    <AMultiselect
                        id={`${SelectorConstants.FeedbackDialog}-bovines-multiselect`}
                        label={t('AnelmaCommunication:1113')}
                        value={euIdentifiers}
                        getOptionLabel={(_) => _}
                        onChange={(_) => setEuIdentifiers(_ as string[])}
                        items={bovineOptions}
                        validator={formValidator}
                        required={formMode !== 'modify' && isReclamationFeedback()}
                        disabled={formMode === 'modify' && !userData.IsSnellmanUser}
                    />
                </Grid>

                {auth.canViewBovineList && (
                    <Grid container justifyContent={'flex-end'} sx={{ paddingRight: '40px' }}>
                        <AAddButton
                            data-robot-id={`${SelectorConstants.FeedbackDialog}-open-add-bovine-dialog`}
                            children={t('AnelmaCommunication:1121')}
                            onClick={() => setBovineDialogOpen(true)}
                            type='default'
                            disabled={
                                loading || (!userData.IsSnellmanUser && formMode === 'modify')
                            }
                        />
                    </Grid>
                )}
            </>
        );
    };

    const isReclamationFeedback = () => {
        if (selectedSubject === CommunicationSubjectEnum.CompensationRequest.valueOf()) return true;
        return false;
    };

    const isTransportProducerFeedback = () => {
        if (selectedSubject === CommunicationSubjectEnum.TransportProducer.valueOf()) return true;
        return false;
    };

    const isTransportOperatorFeedback = () => {
        if (selectedSubject === CommunicationSubjectEnum.TransportOperator.valueOf()) return true;
        return false;
    };

    return (
        <>
            {renderReasonAutoCompleteJsx()}
            {renderBovineSelectJsx()}

            {bovineDialogOpen && (
                <BovineDialog
                    onClose={() => setBovineDialogOpen(false)}
                    onSave={(ids) => handleSave(ids)}
                    farmId={getFarmId()}
                />
            )}
        </>
    );
}
