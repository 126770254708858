import {
    IAddress,
    IBusinessCode,
    IBusinessForm,
    ICar,
    ICarCapacity,
    ICompanyDetails,
    IFarmDetails,
    ILogin,
    IPersonDetails,
    IPhoneNumber,
    IPricing,
    IProductionLine,
    ITrafficContractorDetails,
    IHoldingSite,
    IInactivationDetails,
    IPersonSummary,
} from '../interfaces/IBusinessEntities';

import {
    AddressTypeEnum,
    AnimalTypeEnum,
    BusinessCodeTypeEnum,
    CarCapacityTypeEnum,
    CompanyEntityTypeEnum,
    CompanyNameTypeEnum,
    PhoneNumberTypeEnum,
} from '../interfaces/enums';
import { IDocumentAddDialogData } from '../documents/IDocuments';
import { Configuration } from '../store/configurationSlice';
import { IPredefinedSearch } from '../interfaces/ISearchParameters';
import moment from 'moment';
import { IMessages } from '../shortMessageService/ISms';
import { IFarmVisit } from '../interfaces/IFarmVisit';
import { IEvent, IRegistrationForm } from '../interfaces/IEvent';
import { IBovineVariables } from '../interfaces/IBovineVariables';
import { ICreateBovinePurchaseAnnouncement } from '../interfaces/IAnnouncement';
import { IFarmSupplier } from '../interfaces/IProductionPlan';
import { newRandomGuid } from '../animals/production-plan/helpers/FarmSupplierHelper';
import { EmptyGUID } from '../interfaces/types';

class DefaultData {
    address(type: AddressTypeEnum, isDefault: boolean): IAddress {
        return {
            City: '',
            CountryShortCode: 'FI',
            IsDefault: isDefault,
            NormalizedAddress: '',
            Street: '',
            Type: type,
            Zip: '',
        };
    }

    animalAnnoucementBoviePurchase(): ICreateBovinePurchaseAnnouncement {
        return {
            ProducerNumber: 0,
            AnnouncementInfo: '',
            FarmId: '',
            BovineInfos: [],
        };
    }

    private businessCode(): IBusinessCode {
        return {
            Type: BusinessCodeTypeEnum.Company,
            Value: '',
        };
    }

    private businessForm(): IBusinessForm {
        return {
            Type: null,
        };
    }

    car(): ICar {
        return {
            Address: this.address(AddressTypeEnum.Street, true),
            Capacities: this.carCapacities(),
            Id: null,
            Licence: '',
            Number: null,
            PhoneNumber: {
                IsDefault: true,
                NormalizedNumber: '',
                Type: PhoneNumberTypeEnum.CompanySMS,
            },
            CompanyId: EmptyGUID,
        };
    }

    private carCapacities(): ICarCapacity[] {
        return [
            this.carCapacity(CarCapacityTypeEnum.Bovines, null),
            this.carCapacity(CarCapacityTypeEnum.Calves, null),
            this.carCapacity(CarCapacityTypeEnum.Pigs, null),
            this.carCapacity(CarCapacityTypeEnum.Piglets, null),
        ];
    }

    carCapacity(type: CarCapacityTypeEnum, capacity: number | null): ICarCapacity {
        return {
            Capacity: capacity,
            Type: type,
        };
    }

    companyDetails(companyType: CompanyEntityTypeEnum): ICompanyDetails {
        return {
            Addresses: [this.address(AddressTypeEnum.Street, true)],
            Aliases: [],
            BusinessCode: this.businessCode(),
            BusinessForm: this.businessForm(),
            CompanyType: companyType,
            ContactPersons: [],
            County: '',
            EffectiveTo: null,
            EmailAddresses: [
                {
                    IsDefault: true,
                    Value: '',
                },
            ],
            Id: null,
            InactivationDetails: null,
            LanguageShortCode: 'FI',
            MunicipalityCode: null,
            Names: [
                {
                    IsDefault: true,
                    Type: CompanyNameTypeEnum.Primary,
                    Value: '',
                },
            ],
            PhoneNumbers: [this.phoneNumber(PhoneNumberTypeEnum.CompanyOffice, true)],
            Inactivated: false,
        };
    }

    documentDetails(): IDocumentAddDialogData {
        return {
            validFrom: '',
            validDue: '',
            file: [],
            displayName: '',
            owner: '',
            type: 0,
        };
    }

    eventDetails(): IEvent {
        return {
            Id: '',
            RegistrationValidFrom: '',
            RegistrationValidDue: '',
            EventValidFrom: '',
            EventValidDue: '',
            RegistrationRequired: true,
            PublicEvent: false,
            OnlineEvent: false,
            Location: '',
            OnlineLink: '',
            HeaderFI: '',
            HeaderEN: '',
            HeaderSE: '',
            BodyTextFI: '',
            BodyTextEN: '',
            BodyTextSE: '',
            ContentId: '',
            Registrants: [],
            Tags: [],
        };
    }

    farmVisit(): IFarmVisit {
        return {
            CreationDate: '',
            Creator: '',
            FromTime: '',
            FarmGUID: '',
            Flags: 0,
            Id: 0,
            ModificationDate: '',
            Modificator: '',
            Notes: '',
            ContactId: 0,
            ToTime: '',
            ProducerNumber: '',
            CompanyName: '',
            Subject: 0,
        };
    }

    farmDetails(): IFarmDetails {
        return {
            Addresses: [this.address(AddressTypeEnum.Street, true)],
            Aliases: [],
            BusinessCode: this.businessCode(),
            BusinessForm: this.businessForm(),
            CompanyType: CompanyEntityTypeEnum.Farm,
            ContactPersons: [],
            County: '',
            EffectiveTo: null,
            EmailAddresses: [
                {
                    IsDefault: true,
                    Value: '',
                },
            ],
            HoldingSites: [],
            Id: null,
            Inactivated: false,
            InactivationDetails: null,
            LanguageShortCode: 'FI',
            CattleId: '',
            MunicipalityCode: null,
            Names: [
                {
                    IsDefault: true,
                    Type: CompanyNameTypeEnum.Primary,
                    Value: '',
                },
            ],
            PhoneNumbers: [this.phoneNumber(PhoneNumberTypeEnum.CompanyOffice, true)],
            Pricing: [],
            ProductionLines: this.productionLines(),
            TeamAreaCode: null,
            FeedSuppliers: [],
            Dairy: [],
        };
    }

    private login(): ILogin {
        return {
            Login: '',
        };
    }

    personDetails(): IPersonDetails {
        return {
            Addresses: [this.address(AddressTypeEnum.Postal, true)],
            Aliases: [],
            EffectiveTo: null,
            EmailAddresses: [
                {
                    IsDefault: true,
                    Value: '',
                },
            ],
            FirstName: '',
            Id: null,
            LanguageShortCode: 'FI',
            LastName: '',
            Logins: [this.login()],
            PhoneNumbers: [this.phoneNumber(PhoneNumberTypeEnum.PersonHome, true)],
        };
    }

    personSummary(): IPersonSummary {
        return {
            Address: '',
            Aliases: [],
            EmailAddress: '',
            Id: '',
            Logins: [],
            Name: '',
            PhoneNumber: '',
        };
    }

    phoneNumber(type: PhoneNumberTypeEnum, isDefault: boolean): IPhoneNumber {
        return {
            IsDefault: isDefault,
            NormalizedNumber: '',
            Type: type,
        };
    }

    pricing(): IPricing {
        return {
            CreatedBy: null,
            CreationDate: null,
            Id: null,
            ModificationDate: null,
            ModifiedBy: null,
            Text: '',
        };
    }

    private productionLine(type: AnimalTypeEnum): IProductionLine {
        return {
            FarmingTypes: [],
            Type: type,
        };
    }

    private productionLines(): IProductionLine[] {
        return [
            this.productionLine(AnimalTypeEnum.Bovine),
            this.productionLine(AnimalTypeEnum.Pork),
        ];
    }

    registrationForm(eventId: string, userId: string | null): IRegistrationForm {
        return {
            Id: newRandomGuid(),
            EventId: eventId,
            UserId: userId || null,
            FirstName: '',
            LastName: '',
            PhoneNumber: '',
            Email: '',
            Diet: '',
            AdditionalInformation: '',
            RegistrationTime: '',
            Flags: -1,
            FarmId: '',
            CompanyName: '',
        };
    }

    trafficContractorDetails(): ITrafficContractorDetails {
        return {
            Addresses: [this.address(AddressTypeEnum.Street, true)],
            Aliases: [],
            BusinessCode: this.businessCode(),
            BusinessForm: this.businessForm(),
            Cars: [],
            CompanyType: CompanyEntityTypeEnum.TrafficContractor,
            ContactPersons: [],
            County: '',
            EffectiveTo: null,
            EmailAddresses: [
                {
                    IsDefault: true,
                    Value: '',
                },
            ],
            Id: null,
            InactivationDetails: null,
            LanguageShortCode: 'FI',
            MunicipalityCode: null,
            Names: [
                {
                    IsDefault: true,
                    Type: CompanyNameTypeEnum.Primary,
                    Value: '',
                },
            ],
            PhoneNumbers: [this.phoneNumber(PhoneNumberTypeEnum.CompanySMS, true)],
            Inactivated: false,
        };
    }

    activation(): IInactivationDetails {
        return {
            InactivationTypeID: null,
            InactivationReason: null,
            Info: '',
        };
    }

    holdingSite(): IHoldingSite {
        return {
            Address: this.address(AddressTypeEnum.HoldingSite, true),
            EntryCode: '',
            HoldingSiteId: '',
            Type: null,
        };
    }

    configurations(): Configuration {
        return {
            culture: {
                changeCultureUrl: '',
                supportedCultures: [
                    {
                        selected: false,
                        text: '',
                        value: 'fi',
                    },
                ],
            },
            emailMessages: {
                supportRequest: {
                    from: '',
                    to: '',
                },
            },
            sessionNotification: {
                countDownStartInMinutes: null,
            },
            qlik: {
                qlikCloudBaseUrl: null,
                webIntegrationId: null,
            },
        };
    }

    sendDirectMessages(): IMessages {
        return {
            ActualDeliveryDate: '',
            BillingState: '',
            Content: '',
            Count: 0,
            CreationDate: '',
            Creator: '',
            DeliveryState: '',
            Flags: 0,
            Guid: '',
            MessageType: '',
            ModificationDate: '',
            Modificator: '',
            Receivers: [],
            RequestedDeliveryDate: '',
            Sender: '',
            ReceiverName: '',
            ReceiverFarmName: '',
            ReceiverFarmProducerId: '',
            ReceiverFarmType: 0,
            ReceiverRole: [],
            Receiver: [],
        };
    }

    predefinedSearch(): IPredefinedSearch {
        return {
            GUID: '',
            Name: '',
            Modificator: '',
            ModificationDate: moment().utc().toDate(),
            Parameters: [],
        };
    }

    sellerFarm(): IFarmSupplier {
        return {
            Id: newRandomGuid(),
            ValidId: '',
            canEdit: true,
            GMFreedomValidated: false,
            YearlyAmount: 0,
            Flags: 0,
            SellingFarmName: '',
            SellingFarmTiltu: '',
            FarmId: '',
            SnellmanQuestion: false,
        };
    }
}

export default new DefaultData();
