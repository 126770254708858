// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { isNil } from 'ramda';

// MUI
import { Grid } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../../../core/resources';
import { IFormValidator } from '../../../core/FormValidator';

// Common
import { ADropdown, ADropdownItemProps, ATextInput } from '../../../common/inputs';
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogContentProps,
    ADialogTitle,
} from '../../../common/dialog';
import { ASearchIconButton } from '../../../common/buttons';

// Interfaces
import { GUIDType } from '../../../interfaces/types';
import IContactPersonRecipient from '../interfaces/IContactPersonRecipient';
import { ICompanyContactPersons } from '../../../interfaces/IBusinessEntities';

// API
import companyApi from '../../../api/companyApi';

// Communication feature
import SearchRecipientsResult from './SearchRecipientsResult';
import { capitalizeFirstLetter, resolveContactPersonRole } from '../helpers/helperFunctions';
import ConfirmAddRecipientDialog from './ConfirmAddRecipientDialog';

export interface ISearchResult {
    CompanyId: string;
    Id: string;
    CompanyName: string;
    Name: string;
    Title: string;
}

export interface ISearchRecipientsDialogProps {
    handleSaveRecipientsFromDialog: (params: string[], producerCompanyId: string) => void;
    onClose: Function;
    snellmanContactPersons: IContactPersonRecipient[];
    validator: IFormValidator;
    relatedFarmId?: string;
}

export default function SearchRecipientsDialog(props: ISearchRecipientsDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCommunication']);

    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedRecipients, setSelectedRecipients] = useState<GUIDType[]>([]);
    const [selectedRecipientType, setSelectedRecipientType] = useState<string>('');
    const [searchResults, setSearchResults] = useState<ISearchResult[] | IContactPersonRecipient[]>(
        []
    );
    const [companySearchDetails, setCompanySearchDetails] = useState<ICompanyContactPersons[]>([]);
    const [recipientSearchTypes, setRecipientSearchTypes] = useState<ParameterValues>([]);
    const [recipientTypeProps, setRecipientTypeProps] = useState<ADropdownItemProps[]>([]);
    const [farmCompanyTitles, setFarmCompanyTitles] = useState<ParameterValues>([]);
    const [logisticsCompanyTitles, setLogisticsCompanyTitles] = useState<ParameterValues>([]);
    const [snellmanCompanyTitles, setSnellmanCompanyTitles] = useState<ParameterValues>([]);
    const [selectedProducerCompanyId, setSelectedProducerCompanyId] = useState<string>(
        props.relatedFarmId ?? ''
    );

    useEffect(() => {
        paramRepository.load(['AnelmaCommunication', 'AnelmaGeneral']).then(() => {
            setRecipientSearchTypes(
                paramRepository.resource('AnelmaCommunication', 'RecipientSearchTypes')
            );
            setFarmCompanyTitles(paramRepository.resource('AnelmaGeneral', 'FarmTitles'));
            setLogisticsCompanyTitles(paramRepository.resource('AnelmaGeneral', 'LogisticsTitles'));
            setSnellmanCompanyTitles(paramRepository.resource('AnelmaGeneral', 'SnellmanTitles'));
        });
    }, []);

    useEffect(() => {
        if (recipientSearchTypes.length > 0) {
            const constructedRecipientTypes = recipientSearchTypes.map((type) => {
                return {
                    value: type.code,
                    text: type.text,
                };
            });

            setRecipientTypeProps(constructedRecipientTypes);
            setSelectedRecipientType('0');
        }
    }, [recipientSearchTypes]);

    useEffect(() => {
        setSearchTerm('');
        setSearchResults([]);
    }, [selectedRecipientType]);

    useEffect(() => {
        if (companySearchDetails.length > 0) {
            const actualSearchResult: ISearchResult[] = [];

            const companyDetailsArray = Array.from(companySearchDetails.values());

            companyDetailsArray.forEach((companyDetail) => {
                companyDetail.ContactPersons.forEach((contactPerson) => {
                    actualSearchResult.push({
                        CompanyId: String(contactPerson.OwnerId),
                        Id: String(contactPerson.Id),
                        CompanyName: companyDetail.CompanyName,
                        Name:
                            contactPerson.Name !== undefined && contactPerson.Name.length > 0
                                ? contactPerson.Name
                                : 'Nimetön henkilö',
                        Title:
                            contactPerson.Roles.length > 0
                                ? resolveContactPersonRole(
                                      companyDetail.CompanyType,
                                      contactPerson.Roles[0].Type,
                                      farmCompanyTitles,
                                      logisticsCompanyTitles,
                                      snellmanCompanyTitles
                                  )
                                : '-',
                    });
                });
            });

            setSearchResults(actualSearchResult);
        }

        setIsLoading(false);
    }, [companySearchDetails]);

    // #region General function expressions
    const search = () => {
        if (!searchTerm) return;

        setIsLoading(true);
        setSearchResults([]);

        // If the search fails due to the issue in the REST requests it may be, that even though the loading is set to false, the asynchronous state change does not actually have time to change the state.
        // And it doesn't help, if the whole chain is converted to asynchronous function chain.
        switch (selectedRecipientType) {
            case '0':
                searchFromCompanies(searchTerm);
                break;
            case '1':
                setSearchResults(searchFromSnellmanPersons(searchTerm));
                setIsLoading(false);
                break;
        }
    };

    const searchFromSnellmanPersons = (searchTerm: string) => {
        return props.snellmanContactPersons.filter((person) => {
            // there is a bug in Anelma's yhteyshenkilöt ~/contacts where the person.Name 'sometimes' becomes null or empty
            if (isContactPersonNameNullOrEmpty(person)) {
                return '';
            }
            return person.Name.toLowerCase().includes(searchTerm.toLowerCase());
        });
    };
    // #endregion

    // #region API function expressions
    const searchFromCompanies = (searchTerm: string) => {
        companyApi
            .searchCompaniesContactPersonsByCompanyName(searchTerm)
            .then((response) => {
                if (!response) return;

                setCompanySearchDetails(response.Items);
            })
            .catch((error) => {
                handleSnackbarMessage(`${t('AnelmaGeneral:1020')}, ${error}`, 'error');
                setIsLoading(false);
            });
    };
    // #endregion

    // #region Handlers
    const handleOnSelectedRecipientChange = (selectionModel: GridRowSelectionModel) => {
        if (selectionModel.length === 0) {
            setSelectedRecipients([]);
            setSelectedProducerCompanyId('');
            return;
        }

        const personIds = selectionModel as string[];

        /** To prevent messing with previous notifications as notifications need farm id.
         *  And the farm/company that is part of the conversation is set once the conversation is sent for the whole conversation on CreateConversationDialog.
         *  NOTE! Seems messy but this has been changing a few times so we're just trying to do what customer is hoping the feature is about. */
        if ((props.relatedFarmId ?? '').length === 0) {
            // AN-2287, pretty stupid imo but that's as requested
            const companyId = searchResults.find((s) =>
                personIds.some((v) => s.Id === v)
            )?.CompanyId;

            if (companyId) setSelectedProducerCompanyId(companyId);
        }

        setSelectedRecipients(
            selectionModel.map((value) => {
                return value as GUIDType;
            })
        );
    };
    // #endregion

    const isContactPersonNameNullOrEmpty = (person: IContactPersonRecipient) => {
        if (person.Name === '' || person.Name === null) return true;
        else return false;
    };

    const handleSnackbarMessage = (
        message: string,
        variant: 'success' | 'error' | 'warning' | 'info'
    ) => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };

    const footerActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () => setShowConfirmDialog(true),
                disabled: selectedRecipients.length === 0 ? true : false,
                type: 'save',
            },
        ],
    };

    const dialogContentProps: ADialogContentProps = {
        size: 'md',
        isLoading: isLoading,
    };

    return (
        <>
            <ADialog open onClose={() => props.onClose()}>
                <ADialogTitle>{t('AnelmaCommunication:1068')}</ADialogTitle>
                <ADialogContent {...dialogContentProps}>
                    <Grid container direction='row' alignItems='stretch'>
                        <Grid item sm={2}>
                            <ADropdown
                                id='communication-recipient-type'
                                items={recipientTypeProps}
                                onChange={(value) => {
                                    setSelectedRecipientType(value as string);
                                }}
                                style={{ minWidth: '100px' }}
                                validator={props.validator}
                                value={selectedRecipientType}
                            />
                        </Grid>
                        <Grid item sm={8}>
                            <ATextInput
                                id='search-recipients-input'
                                label={
                                    selectedRecipientType === '0'
                                        ? t('AnelmaCommunication:1075')
                                        : t('AnelmaCommunication:1082')
                                }
                                onChange={(v) => setSearchTerm(v)}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') search();
                                }}
                                validator={props.validator}
                                value={searchTerm}
                            />
                        </Grid>

                        <Grid item sm={2}>
                            <ASearchIconButton
                                style={{ marginTop: '12px' }}
                                onClick={() => search()}
                                type='action'
                                tooltip={capitalizeFirstLetter(t('AnelmaCommunication:1058'))}
                            ></ASearchIconButton>
                        </Grid>
                    </Grid>

                    <SearchRecipientsResult
                        rowData={searchResults}
                        handleOnSelectedRecipientChange={handleOnSelectedRecipientChange}
                    ></SearchRecipientsResult>
                </ADialogContent>

                <ADialogActions buttons={footerActionButtons} />
            </ADialog>

            <ConfirmAddRecipientDialog
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirmation={() =>
                    props.handleSaveRecipientsFromDialog(
                        selectedRecipients,
                        selectedProducerCompanyId
                    )
                }
                confirmation={t('AnelmaCommunication:1081')}
            />
        </>
    );
}
