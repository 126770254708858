// Libraries
import { createContext } from 'react';

// MUI
// Core
// Common
// Interfaces
import { IFeedback } from '../../../interfaces/communication/feedback/IFeedback';

// Store
// API
// Feature - Feedback

interface IFeedbackDialogContext {
    feedback?: IFeedback;
}

export const FeedbackDialogContext = createContext<IFeedbackDialogContext>({});
