import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import { ParameterValues, ResourceTextApplication, paramRepository } from '../../../core/resources';
import FormValidator from '../../../core/FormValidator';
import auth from '../../../core/authorization';

// Common
import {
    ADialog,
    ADialogContent,
    ADialogTitle,
    ADialogActions,
    ADialogButtonsProp,
} from '../../../common/dialog';
import { AInputContainer, ATextarea, AInputItem, ATextInput, ALabel } from '../../../common/inputs';
import { AAddPersonIconButton, ASendButton } from '../../../common/buttons';
import ViewLoader from '../../../common/ViewLoader';
import AttachmentDropzone from '../../../common/AttachmentDropzone';
import { Context } from '../../../common/contextSelector/ContextSelector';

// Interfaces
import { GUIDType } from '../../../interfaces/types';
import {
    IConversationResult,
    IModifyMessage,
    INewMessage,
    INewMessageResponse,
    INewMessageResult,
} from '../../../interfaces/ICommunication';
import { IFarmDetails } from '../../../interfaces/IBusinessEntities';

// Store
import { useAppSelector } from '../../../store/hooks';

// API
import api from '../../../api/messaging/conversationApi';
import personApi from '../../../api/personApi';
import companyApi from '../../../api/companyApi';

// Feature - Communication, messaging
import {
    composeProducerRecipientOptions,
    propContactPersonsAreDefined,
    propSnellmanContactPersonsDefined,
} from '../../general/helpers/helperFunctions';
import { dialogContentProps } from '../../general/types/generalTypeDefinitions';
import Recipient from '../../general/components/Recipient';
import SearchRecipientsDialog from '../../general/components/SearchRecipientsDialog';
import IContactPersonRecipient from '../../general/interfaces/IContactPersonRecipient';
import AddUserToConversationDialog from '../../general/components/AddUserToConversationDialog';
import MessageList, { IMessageListMessage } from '../../general/components/MessageList';
import config from '../../../documents/addDialog/documentAddDialogConfigs';

export interface IConversationDialogProps {
    onClose: () => void;
    onUpdate?: () => void;
    personCompanyContactPersons: IContactPersonRecipient[];
    snellmanContactPersons: IContactPersonRecipient[];
    updateMessageStatus?: (id: string) => void;
    conversation: IConversationResult;
}

export interface IMappedRecipient {
    recipientId: string;
    recipientName: string;
}

export default function ConversationDialog(props: IConversationDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
        'AnelmaLayout',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const dialogConfig = config('multi');

    const validator = new FormValidator();
    const userData = useAppSelector((state) => state.userData.data);
    const context = useAppSelector((state) => state.context);

    const isInitialRender = useRef(true);
    const bodyId = 'body';

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [topicParam, setTopicParam] = useState<ParameterValues>([]);
    const [welcomeMessage, setWelcomeMessage] = useState<INewMessage[]>([]);
    const [showAddUserDialog, setShowAddUserDialog] = useState<boolean>(false);
    const [searchRecipientsDialogVisible, setSearchRecipientsDialogVisible] =
        useState<boolean>(false);
    const [producerRecipientOptions, setProducerRecipientOptions] = useState<AInputItem<string>[]>(
        []
    );
    const [recipientMap, setRecipientMap] = useState<IMappedRecipient[]>([]);
    const [body, setBody] = useState<string>('');
    const [recipients, setRecipients] = useState<Set<string>>(new Set());
    const [messages, setMessages] = useState<INewMessageResult[]>([]);
    const [senderId, setSenderId] = useState<string>(userData?.Id || '');
    const [addedRecipients, setAddedRecipients] = useState<string[]>([]);
    const [attachments, setAttachments] = useState<File[]>([]);
    const [attachmentsFormat, setAttachmentsFormat] = useState<boolean>(false);
    const [updateRequested, setUpdateRequested] = useState<boolean>(false);
    const [farmDetails, setFarmDetails] = useState<IFarmDetails>();
    const [relatedFarmId, setRelatedFarmId] = useState<string>(
        props.conversation.RelatedFarmId ?? ''
    );

    // #region Effects
    useEffect(() => {
        if (
            propContactPersonsAreDefined(
                props.snellmanContactPersons,
                props.personCompanyContactPersons
            )
        ) {
            const composedProducerRecipientOptions = [
                ...composeProducerRecipientOptions(props.snellmanContactPersons),
            ];
            composedProducerRecipientOptions.push(
                ...composeProducerRecipientOptions(props.personCompanyContactPersons)
            );
            setProducerRecipientOptions(
                composedProducerRecipientOptions.filter(
                    (val, idx, self) => self.findIndex((newVal) => newVal.Id === val.Id) === idx
                )
            );
        } else if (propSnellmanContactPersonsDefined(props.snellmanContactPersons))
            setProducerRecipientOptions([
                ...composeProducerRecipientOptions(props.snellmanContactPersons),
            ]);

        paramRepository.load(['AnelmaCommunication']).then(() => {
            setTopicParam(paramRepository.resource('AnelmaCommunication', 'CommunicationTopic'));
        });
    }, []);

    useEffect(() => {
        if (messages.length > 0) setMessageAsRead();
    }, [messages]);

    useEffect(() => {
        setRecipients(new Set(props.conversation.ParticipantList));

        if (props.conversation.ConversationId)
            fetchInitialMessageListAsync(props.conversation.ConversationId);
    }, [props.conversation.ParticipantList]);

    useEffect(() => {
        if (userData.Id) setSenderId(userData.Id);
    }, [userData]);

    useEffect(() => {
        switch (isInitialRender.current) {
            case true:
                isInitialRender.current = false;
                break;
            case false:
                composeRecipientForWelcomeMessage(addedRecipients);
                break;
        }
    }, [addedRecipients]);

    useEffect(() => {
        composeWelcomeMessage();
    }, [recipientMap]);

    useEffect(() => {
        if (welcomeMessage.length !== 0 || welcomeMessage !== undefined)
            handleCreateWelcomeMessages(welcomeMessage);
    }, [welcomeMessage]);

    useEffect(() => {
        if (context.data.currentContext) initializeFarmDetails(context.data.currentContext);
        else setFarmDetails(undefined);
    }, [context]);
    // #endregion

    // #region General
    const initializeFarmDetails = (context: Context) => {
        setIsLoading(true);
        const companyByContext = companyApi
            .getCompanyByContext(context.context)
            .then((response) => {
                setFarmDetails(response?.Entity as IFarmDetails);
            });
        Promise.all([companyByContext]).then(() => setIsLoading(false));
    };

    const clearBodyState = () => {
        setBody(' ');
    };

    const clearAttachmentState = () => {
        setAttachments([]);
        setAttachmentsFormat((a) => !a);
    };

    const composeMessage = (): INewMessage => {
        return {
            ConversationId: props.conversation.ConversationId as string,
            Body: body.trim(),
            SenderId: senderId,
            RecipientList: Array.from(recipients.values()),
            Attachments: attachments?.map((attachment) => {
                return { File: attachment };
            }),
        };
    };

    const mapReplyMessageResponseToState = (response: INewMessageResponse) => {
        const value: INewMessageResult = {
            SenderId: response.SenderId,
            MessageId: response.MessageId,
            ConversationId: response.ConversationId,
            Body: response.Body,
            CreatedDateTime: response.CreatedDateTime,
            ParticipantList: response.RecipientList.map((value) => {
                return value;
            }),
            Attachments: response.Attachments ? response.Attachments : [],
        };
        setUpdateRequested(true);
        setMessages((previousMessages) => [...previousMessages, value]);
        setWelcomeMessage([]);
    };

    const mapParameterCodeAsText = (parameterCode: number | string): string => {
        const parameterText = topicParam.find((parameters) => {
            if (String(parameterCode) === String(parameters.code)) return parameters.text;
        })?.text;

        if (parameterText !== undefined) return parameterText;
        else return `${parameterCode}`;
    };

    // #endregion

    // #region API function expressions
    const setMessageAsRead = () => {
        const payload: IModifyMessage = {
            MessageId: messages[0].MessageId,
            UserId: senderId,
        };

        if (payload) {
            api.setMessageRead(payload).then(() => {
                if (updateRequested) {
                    if (props.onUpdate) props.onUpdate();
                    setUpdateRequested(false);
                }
            });
        }
    };

    const fetchInitialMessageListAsync = async (conversationId: GUIDType) => {
        setIsLoading(true);

        await api.getMessagesList(conversationId).then((response) => {
            if (!response) {
                setIsLoading(false);
                enqueueSnackbar(t('AnelmaGeneral:1169'), {
                    variant: 'error',
                });
                return;
            }
            setMessages(response.Items);
            setIsLoading(false);

            if (props.updateMessageStatus) {
                props.updateMessageStatus(conversationId);
            }
        });
    };

    const handleCreateMessage = () => {
        setIsLoading(true);
        if (validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            setIsLoading(false);
            return;
        }

        api.createMessage(composeMessage(), relatedFarmId).then((response) => {
            if (!response) {
                handleSnackbarMessage(`${t('AnelmaCommunication:1108')}`, 'error');
                setIsLoading(false);
                return;
            }

            clearBodyState();
            clearAttachmentState();
            handleSnackbarMessage(t('AnelmaCommunication:1107'), 'success');
            mapReplyMessageResponseToState(response.Entity);
            setIsLoading(false);
        });
    };

    const handleCreateWelcomeMessages = async (messages: INewMessage[]) => {
        setIsLoading(true);
        if (messages.length > 0) {
            const promises = messages.map((message, idx) => {
                // This is the ensure that concurrency does not cause any issues in the UI or in the API
                setTimeout(() => createMessage(message), idx * 200);
            });

            const createMessage = (message: INewMessage) => {
                api.createMessage(message, relatedFarmId).then((response) => {
                    if (!response) {
                        handleSnackbarMessage(`${t('AnelmaCommunication:1108')}`, 'error');
                        setIsLoading(false);
                        return;
                    }
                    mapReplyMessageResponseToState(response.Entity);
                });
            };

            await Promise.all(promises).then((promise) => {
                handleSnackbarMessage(t('AnelmaCommunication:1103'), 'success');
            });

            setRecipientMap([]);
        }
        setIsLoading(false);
    };

    const composeWelcomeMessage = async () => {
        const messages: INewMessage[] = [];

        setIsLoading(true);

        if (recipientMap.length > 0) {
            for await (const recipient of recipientMap) {
                const message: INewMessage = {
                    ConversationId: props.conversation.ConversationId as string,
                    Body: t('AnelmaCommunication:1074')
                        .replace(
                            '{user}',
                            userData ? `${userData.FirstName} ${userData.LastName}` : ''
                        )
                        .replace('{addeduser}', recipient.recipientName),
                    SenderId: senderId,
                    RecipientList: Array.from(recipients.values()),
                };

                messages.push(message);
            }
            setWelcomeMessage(messages);
        }

        setIsLoading(false);
    };

    const composeRecipientForWelcomeMessage = async (newRecipients: string[]) => {
        const resolvedRecipientData: any[] = [];

        setIsLoading(true);

        const promises = newRecipients.map((recipient) => personApi.getPersonByGUID(recipient));

        await Promise.all(promises).then((promise) => {
            const result = promise.map((data) => {
                if (!data) {
                    return;
                }

                const mappedRecipient: IMappedRecipient = {
                    recipientId: data.Entity.Id as string,
                    recipientName: `${data.Entity.FirstName} ${data.Entity.LastName}`,
                };

                return mappedRecipient;
            });

            if (result !== undefined) resolvedRecipientData.push(...result);
        });

        setRecipientMap(resolvedRecipientData);
        setIsLoading(false);
    };
    // #endregion

    // #region Handlers
    const handleSaveRecipientsFromDialog = (params: string[], producerCompanyId: string) => {
        setSearchRecipientsDialogVisible(false);
        params.map((value) => setRecipients((previousState) => new Set(previousState).add(value)));
        setAddedRecipients(params);
        setRelatedFarmId(producerCompanyId);
    };

    const handleSnackbarMessage = (
        message: string,
        variant: 'success' | 'error' | 'warning' | 'info'
    ) => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };

    const mapMessagesToMessageList = (messages: INewMessageResult[]): IMessageListMessage[] => {
        return messages.map((msg) => ({
            Created: msg.CreatedDateTime,
            SenderId: msg.SenderId,
            Body: msg.Body,
            Attachments: msg.Attachments,
        }));
    };
    // #endregion

    const defaultFooterActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
                disabled: isLoading ? true : false,
            },
        ],
        right: [],
    };

    return (
        <>
            <ADialog open={true} onClose={() => props.onClose()}>
                <ADialogTitle>{props.conversation.Title}</ADialogTitle>

                <ADialogContent {...dialogContentProps} isLoading={isLoading}>
                    <Grid container direction='row' alignItems='stretch'>
                        <Grid item sm={12}>
                            <ATextInput
                                id='topic'
                                label={t('AnelmaCommunication:1001')}
                                onChange={() => {}}
                                validator={validator}
                                disabled
                                value={mapParameterCodeAsText(props.conversation.Topic)}
                            />
                        </Grid>
                    </Grid>

                    <AInputContainer>
                        <Grid container direction='row'>
                            <Grid item sm={10}>
                                <>
                                    {
                                        new Set(
                                            Array.from(recipients).map((key: string) => {
                                                return (
                                                    <Recipient
                                                        key={key}
                                                        styleClassName={'add'}
                                                        personGuid={key}
                                                    />
                                                );
                                            })
                                        )
                                    }
                                </>
                            </Grid>

                            <Grid item sm={2} style={{ textAlign: 'right' }}>
                                <AAddPersonIconButton
                                    onClick={() =>
                                        auth.canAddAnyEntityToConversation
                                            ? setSearchRecipientsDialogVisible(true)
                                            : setShowAddUserDialog(true)
                                    }
                                    type='default'
                                    children={t('AnelmaCommunication:1068')}
                                ></AAddPersonIconButton>
                            </Grid>
                        </Grid>
                    </AInputContainer>

                    <Grid container direction='row'>
                        <Grid item sm={12} style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                            <ALabel>{t('AnelmaCommunication:1115')}</ALabel>

                            <AttachmentDropzone
                                Format={attachmentsFormat}
                                filesLimit={dialogConfig.filesLimit}
                                maxFileSize={dialogConfig.maxFileSize}
                                Text={t('AnelmaCommunication:1116')}
                                onAttachmentsChanged={(files) => {
                                    setAttachments(files);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction='row'>
                        <Grid item sm={12}>
                            <ATextarea
                                id={bodyId}
                                label={t('AnelmaCommunication:1069')}
                                onChange={(v) => setBody(v)}
                                value={body}
                                lengthBoundaryName='AnelmaCommunication:MessageBodyLength'
                                required
                                validator={validator}
                            ></ATextarea>
                        </Grid>
                    </Grid>

                    <Grid container direction='row'>
                        <Grid item sm={12} style={{ textAlign: 'right', paddingRight: '40px' }}>
                            <ASendButton
                                disabled={!auth.canCreateMessage}
                                onClick={() => handleCreateMessage()}
                                type='action'
                            >
                                {t('AnelmaCommunication:1010')}
                            </ASendButton>
                        </Grid>
                    </Grid>

                    <Grid container>
                        {messages !== undefined ? (
                            <MessageList
                                messages={mapMessagesToMessageList(messages)}
                                loggedInUserId={userData?.Id}
                            />
                        ) : (
                            <ViewLoader />
                        )}
                    </Grid>

                    {searchRecipientsDialogVisible && (
                        <SearchRecipientsDialog
                            onClose={() => setSearchRecipientsDialogVisible(false)}
                            snellmanContactPersons={props.snellmanContactPersons}
                            handleSaveRecipientsFromDialog={handleSaveRecipientsFromDialog}
                            validator={validator}
                            relatedFarmId={props.conversation.RelatedFarmId ?? ''}
                        ></SearchRecipientsDialog>
                    )}
                </ADialogContent>

                <ADialogActions buttons={defaultFooterActionButtons} />
            </ADialog>

            <AddUserToConversationDialog
                open={showAddUserDialog}
                onClose={() => setShowAddUserDialog(false)}
                onUpdate={(users) => {
                    handleSaveRecipientsFromDialog(users, '');
                }}
                producerRecipientOptions={producerRecipientOptions.filter(
                    (u) => !Array.from(recipients.values()).some((a: string) => u.value === a)
                )}
            />
        </>
    );
}
