// Libraries
import { useRef, useEffect, useState, startTransition } from 'react';
import { Calendar, SlotInfo, View } from 'react-big-calendar';
import { SizeMeProps, withSize } from 'react-sizeme';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { clone } from 'ramda';
import { useSnackbar } from 'notistack';

// MUI
import { Container, Grid } from '@mui/material';

// Core
import FormValidator from '../core/FormValidator';
import { ResourceTextApplication } from '../core/resources';
import auth from '../core/authorization';
import defaultData from '../core/defaultData';
import utils from '../core/utils';

// Event
import { IEvent, IEventFilters, IRegistrationForm } from '../interfaces/IEvent';
import EventRegistrationDialog from '../events/EventRegistrationDialog';

// Common
import { ViewTitle } from '../common/typography';
import { AAddButton } from '../common/buttons';
import { ViewActionsContainer } from '../common/viewContainers';
import ContentLoader from '../common/ContentLoader';

// Feature - Calendar
import calendarLocalization from './calendarLocalization';
import CalendarEventDialog, { CalendarEventDialogMode } from './CalendarEventDialog';
import calendarEventStorage, { CalendarRange } from './CalendarEventStorage';
import DateHeader from './components/DateHeader';
import './calendar.scss';
import CustomEvent from './components/CustomEvent';
import CalendarFilter from './CalendarFilter';
import { getEventStyle, getScrollToTimeValue } from './calendarHelper';

// Feature - AnimalPayload
import { AnimalPayloadsBatchForm } from '../animals/animal-payloads/AnimalPayloadsBatchForm';

// Feature - Event
import { constructTagPayload } from '../events/helpers/payloadHelper';

// API
import eventsApi from '../api/eventsApi';
import companyApi from '../api/companyApi';
import AnimalPayloadsApi from '../api/animalPayloadsApi';

// Store
import { useAppSelector } from '../store/hooks';

// Interface
import { IFarmVisit } from '../interfaces/IFarmVisit';
import CreateFarmVisitDialog from '../farmVisit/CreateFarmVisitDialog';
import { IFarmDetails, IProductionLine } from '../interfaces/IBusinessEntities';
import { DialogModeEnum, EventDialogMode } from '../interfaces/enums';
import { CalendarEventTypes } from '../interfaces/enums';
import { CalendarEvent, ExtendedCalendarEvent } from '../interfaces/calendar/CalendarEvent';
import { IAnimalPayloadCalendarEvent } from '../interfaces/calendar/AnimalPayloadCalendarEvent';
import { IAnimalPayloadItem } from '../interfaces/IAnimal';

interface ExtendedCalendarProps extends SizeMeProps {}

export default withSize()(function CalendarView(props: ExtendedCalendarProps) {
    const context = useAppSelector((state) => state.context);
    const { enqueueSnackbar } = useSnackbar();

    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaLayout']);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [containerHeight, setContainerHeight] = useState<number>(500);
    const [eventData, setEventData] = useState<
        CalendarEvent | IEvent | IFarmVisit | IAnimalPayloadCalendarEvent
    >();
    const [dialogMode, setDialogMode] = useState<CalendarEventDialogMode>('create');
    const [events, setEvents] = useState<ExtendedCalendarEvent[]>([]);
    const [filteredEvents, setFilteredEvents] = useState<ExtendedCalendarEvent[]>([]);
    const [eventFilters, setEventFilters] = useState<IEventFilters>();
    const [viewRange, setViewRange] = useState<CalendarRange>(() => {
        const startOfMonth = moment().startOf('month');
        const endOfMonth = moment().endOf('month');
        const startOfView = startOfMonth.subtract(startOfMonth.isoWeekday() - 1, 'days');
        const endOfView = endOfMonth.add(7 - endOfMonth.isoWeekday(), 'days');

        return {
            companyId: '',
            end: endOfView,
            producerNumber: '',
            start: startOfView,
        };
    });
    const [registerDialogVisible, setRegisterDialogVisible] = useState<boolean>(false);
    const [farmVisitDialog, setFarmVisitDialog] = useState<boolean>(false);
    const [calendarDialogVisible, setCalendarDialogVisible] = useState<boolean>(false);
    const [isLoadingAnimalPayload, setIsLoadingAnimalPayload] = useState<boolean>(false);
    const [animalPayloadDialogVisible, setAnimalPayloadDialogVisible] = useState<boolean>(false);
    const [animalPayload, setAnimalPayload] = useState<IAnimalPayloadItem>();
    const [farmEntityId, setFarmEntityId] = useState<string>('');
    const [selectedView, setSelectedView] = useState<View>('agenda');
    const [farmProductionLines, setFarmProductionLines] = useState<IProductionLine[]>([]);

    useEffect(() => {
        const con = context.data.currentContext?.context;

        if (con) {
            const prodId = utils.context.parseFarmContext(con)?.producerId || '';

            setIsLoading(true);

            companyApi.getCompanyByContext(con).then((response) => {
                if (!response) {
                    setViewRange({
                        ...viewRange,
                        companyId: '',
                        producerNumber: prodId,
                    });
                } else {
                    setViewRange({
                        ...viewRange,
                        companyId: response.Entity.Id || '',
                        producerNumber: prodId,
                    });
                    setFarmEntityId(response.Entity.Id || '');
                    setFarmProductionLines((response?.Entity as IFarmDetails).ProductionLines);
                }

                setIsLoading(false);
            });
        } else {
            setViewRange({
                ...viewRange,
                companyId: '',
                producerNumber: '',
            });
        }
    }, [context]);

    const loadEventsOnRangeChange = (
        range: Date[] | { start: string | Date; end: string | Date }
    ) => {
        const dates = Array.isArray(range)
            ? range.map((r) => moment(r))
            : [moment(range.start), moment(range.end)];

        if (!dates.length) return;

        let end: moment.Moment = dates[0].clone().endOf('day');
        let start: moment.Moment = dates[0].clone().startOf('day');

        for (var i in dates) {
            const date = dates[i];
            if (date.isAfter(end)) end = date.clone().endOf('day');
            if (date.isBefore(start)) start = date.clone().startOf('day');
        }

        setViewRange({ ...viewRange, end, start });
    };

    useEffect(() => {
        const fitDatesToCurrentViewToGoAroundBigCalendarBug = (
            rawEventData: ExtendedCalendarEvent[]
        ): ExtendedCalendarEvent[] => {
            const fixedEventData: ExtendedCalendarEvent[] = [];

            rawEventData.forEach((event) => {
                const eventStart = moment(event.start);
                const eventEnd = moment(event.end);

                if (eventStart.isAfter(viewRange.end)) return;
                if (eventEnd.isBefore(viewRange.start)) return;

                const fixedEvent = clone(event);

                if (eventStart.isBefore(viewRange.start))
                    fixedEvent.start = viewRange.start.toDate();
                if (eventEnd.isAfter(viewRange.end)) fixedEvent.end = viewRange.end.toDate();

                fixedEventData.push(fixedEvent);
            });

            return fixedEventData;
        };

        const loadEvents = (range: CalendarRange) => {
            setIsLoading(true);
            calendarEventStorage
                .load(farmEntityId, range, true, constructTagPayload(farmProductionLines))
                .then((data) => {
                    setEvents(fitDatesToCurrentViewToGoAroundBigCalendarBug(data));
                    setIsLoading(false);
                });
        };
        if (farmEntityId) loadEvents(viewRange);
    }, [viewRange, farmEntityId]);

    useEffect(() => {
        const minHeight = 500;
        const maxHeight = window.innerHeight - 200;
        const inProportionToContainer = Math.floor((props.size?.width || 0) * 0.7) || minHeight;
        setContainerHeight(Math.max(Math.min(inProportionToContainer, maxHeight), minHeight));
    }, [props.size]);

    useEffect(() => {
        handleFilteredEventsChange();
    }, [events, eventFilters]);

    const handleFilteredEventsChange = () => {
        setFilteredEvents([
            ...events.filter((event) => {
                if (!eventFilters) return true;
                if (!eventFilters.eventTypes.find((et) => Number(et) === event.type)) return false;
                if (eventFilters.tags.length > 0) {
                    for (let filterTag of eventFilters.tags)
                        if (event.tags.find((eventTag) => eventTag === filterTag)) return true;
                    return false;
                }
                return true;
            }),
        ]);
    };

    const removeEventFromCalendar = (basicEvent: CalendarEvent) => {
        calendarEventStorage.remove(basicEvent);
        calendarEventStorage
            .load(farmEntityId, viewRange, true, constructTagPayload(farmProductionLines))
            .then((e) => setEvents(e));
    };

    const updadeEventToCalendar = (basicEvent: CalendarEvent) => {
        calendarEventStorage.update(basicEvent);
        calendarEventStorage
            .load(farmEntityId, viewRange, true, constructTagPayload(farmProductionLines))
            .then((e) => setEvents(e));
        handleFilteredEventsChange();
    };

    const saveRegistrationToEvent = (registration: IRegistrationForm[], mode: EventDialogMode) => {
        setIsLoading(true);
        if (mode === EventDialogMode.Save) {
            eventsApi.registerToEvent(registration).then((_) => {
                if (_ !== null) {
                    enqueueSnackbar(t('AnelmaEvents:1043'), {
                        variant: 'success',
                    });
                    setRegisterDialogVisible(false);
                } else {
                    enqueueSnackbar(t('AnelmaEvents:1056'), {
                        variant: 'error',
                    });
                }
                setIsLoading(false);
            });
        } else if (mode === EventDialogMode.Edit) {
            eventsApi.editRegistration(registration).then((_) => {
                if (_ !== null) {
                    enqueueSnackbar(t('AnelmaEvents:1044'), {
                        variant: 'success',
                    });
                    setRegisterDialogVisible(false);
                } else {
                    enqueueSnackbar(t('AnelmaEvents:1056'), {
                        variant: 'error',
                    });
                }
                setIsLoading(false);
            });
        } else if (mode === EventDialogMode.Delete) {
            eventsApi.editRegistration(registration).then((_) => {
                if (_ !== null) {
                    enqueueSnackbar(t('AnelmaEvents:1054'), {
                        variant: 'success',
                    });
                } else {
                    enqueueSnackbar(t('AnelmaEvents:1055'), {
                        variant: 'error',
                    });
                }
                setRegisterDialogVisible(false);
                setIsLoading(false);
            });
        }
    };

    const handleOnSelectSlot = (slot: SlotInfo) => {
        if (selectedView === 'month') handleOpenCalendarDialog(slot.start, slot.end);
        else if (selectedView === 'week' || selectedView === 'day' || selectedView === 'work_week')
            handleOpenCalendarDialogFromDayOrWeekView(slot.slots as string[]);
    };

    const handleOpenCalendarDialog = (start?: string | Date, end?: string | Date) => {
        let fromDate = '';
        let dueDate = '';

        if (start && end) {
            fromDate = moment(start).clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
            dueDate = moment(end)
                .clone()
                .subtract(1, 'day')
                .endOf('day')
                .format('YYYY-MM-DDTHH:mm:ss');
        }

        openCreateDialog(fromDate, dueDate, 'create', true);
    };

    const handleOpenCalendarDialogFromDayOrWeekView = (slots: string[]) => {
        openCreateDialog(slots[0], slots.at(-1) as string, 'create', false);
    };

    const openCreateDialog = (
        from: string,
        due: string,
        mode: CalendarEventDialogMode,
        allDay: boolean
    ) => {
        setEventData({
            AllDay: allDay,
            Content: '',
            Deleted: false,
            DueDate: due,
            FromDate: from,
            Id: '',
            Title: '',
            Tags: [],
            Type: CalendarEventTypes.OwnBookings,
        });
        setDialogMode(mode);
        setCalendarDialogVisible(true);
    };

    const getAnimalPayloadByNumber = (event: IAnimalPayloadCalendarEvent) => {
        setIsLoadingAnimalPayload(true);
        AnimalPayloadsApi.getAnimalPayloadByPayloadNumber(
            event.PayloadNumber,
            auth.canViewAdminContextSelector
        ).then((response) => {
            if (response?.Entity) {
                setAnimalPayload(response.Entity);
                setIsLoadingAnimalPayload(false);
                setAnimalPayloadDialogVisible(true);
            } else {
                enqueueSnackbar(t('AnelmaGeneral:1020'), {
                    variant: 'error',
                });
                setIsLoadingAnimalPayload(false);
            }
        });
    };

    const onSelectEvent = (event: ExtendedCalendarEvent) => {
        // startTransition... slows down dialog opening.
        // otherwise calendar freezes
        // because some states update at the same time (as I see it)
        // search React 18 for ref.
        startTransition(() => {
            switch (event.type) {
                case CalendarEventTypes.OwnBookings:
                    setEventData(event.fullData as CalendarEvent);
                    setDialogMode('modify');
                    setCalendarDialogVisible(true);
                    break;
                case CalendarEventTypes.Event:
                case CalendarEventTypes.EventRegistration:
                    setEventData(event.fullData as IEvent);
                    setRegisterDialogVisible(true);
                    break;
                case CalendarEventTypes.FarmVisit:
                    setEventData(event.fullData as IFarmVisit);
                    setFarmVisitDialog(!farmVisitDialog);
                    break;
                case CalendarEventTypes.PickupSlaughterAnimal:
                case CalendarEventTypes.PickupTransMissionAnimal:
                    setEventData(event.fullData as IAnimalPayloadCalendarEvent);
                    getAnimalPayloadByNumber(event.fullData as IAnimalPayloadCalendarEvent);
                    break;
            }
        });
    };

    const customComponents: any = {
        dateHeader: DateHeader,
        event: CustomEvent,
    };

    return (
        <Container ref={containerRef} data-robot-id={'app-body-calendar'}>
            <ViewTitle>{t('AnelmaLayout:1017')}</ViewTitle>

            <ViewActionsContainer>
                <Grid container>
                    <Grid xs={6} item>
                        {isLoading && <ContentLoader text={t('AnelmaGeneral:1141')} />}
                        {isLoadingAnimalPayload && <ContentLoader text={t('AnelmaGeneral:1177')} />}
                    </Grid>

                    <Grid xs={6} item style={{ textAlign: 'right' }}>
                        {auth.canCreateCalendarEvent && (
                            <AAddButton onClick={() => handleOpenCalendarDialog()} type='action'>
                                {t('AnelmaGeneral:1135')}
                            </AAddButton>
                        )}
                    </Grid>
                </Grid>
            </ViewActionsContainer>
            <CalendarFilter onChange={setEventFilters} />
            <Calendar
                {...calendarLocalization(t)}
                components={customComponents}
                events={filteredEvents}
                eventPropGetter={getEventStyle}
                formats={{
                    dayHeaderFormat: 'ddd DD.MM.YYYY (ww)',
                    dayRangeHeaderFormat: (range) => {
                        const start = moment(range.start);
                        const end = moment(range.end);
                        const startMonth = start.format('MMMM');
                        const endMonth = end.format('MMMM');
                        return startMonth === endMonth
                            ? `${startMonth} (${start.week()})`
                            : `${startMonth} – ${endMonth} (${start.week()})`;
                    },
                }}
                onRangeChange={(range) => loadEventsOnRangeChange(range)}
                onSelectEvent={
                    auth.canUpdateCalendarEvent
                        ? (event: ExtendedCalendarEvent) => onSelectEvent(event)
                        : undefined
                }
                onSelectSlot={
                    auth.canCreateCalendarEvent ? (slot) => handleOnSelectSlot(slot) : undefined
                }
                selectable
                style={{ height: `${containerHeight}px` }}
                scrollToTime={getScrollToTimeValue()}
                onView={(_) => setSelectedView(_)}
                view={selectedView}
            />

            {eventData && calendarDialogVisible && (
                <CalendarEventDialog
                    afterRemove={(e) => removeEventFromCalendar(e)}
                    afterSave={(e) => updadeEventToCalendar(e)}
                    event={eventData as CalendarEvent}
                    mode={dialogMode}
                    onClose={() => {
                        setEventData(undefined);
                        setCalendarDialogVisible(false);
                    }}
                    validator={new FormValidator()}
                    farmEntityId={farmEntityId}
                />
            )}
            {eventData && registerDialogVisible && (
                <EventRegistrationDialog
                    admin={false}
                    data={eventData as IEvent}
                    user={defaultData.personSummary()}
                    onSave={saveRegistrationToEvent}
                    onClose={() => {
                        setEventData(undefined);
                        setRegisterDialogVisible(false);
                    }}
                    validator={new FormValidator()}
                    mode={EventDialogMode.Save}
                    loading={isLoading}
                />
            )}

            {eventData && farmVisitDialog && (
                <CreateFarmVisitDialog
                    mode={DialogModeEnum.Edit}
                    farmDetails={eventData as unknown as IFarmDetails}
                    data={eventData as unknown as IFarmVisit}
                    onClose={() => {
                        setEventData(undefined);
                        setFarmVisitDialog(false);
                    }}
                    handleMapFarmVisit={() => false}
                />
            )}

            {animalPayload && animalPayloadDialogVisible && (
                <AnimalPayloadsBatchForm
                    payloadData={animalPayload}
                    onClose={() => setAnimalPayloadDialogVisible(false)}
                    isLoading={isLoadingAnimalPayload}
                />
            )}
        </Container>
    );
});
