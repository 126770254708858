// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

// Core
import { paramRepository, ParameterValue, ResourceTextApplication } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../common/dialog';
import { ADataGrid, AGridColumns, gridColumns } from '../../common/dataGrid';
import { TextsWithTooltip, TextWithTooltip } from '../../common/typography';

// Interfaces
import { IPredefinedSearchFarmResult } from '../../interfaces/ISearchParameters';
import {
    IContactFarmRow,
    IContactPersonRow,
    IContactRow,
} from '../../interfaces/IBusinessEntities';
import { GetProductionLinesFromIntList } from '../../searchParameters/Helpers/Parsers';

// Store
// API
// Feature

export interface CompaniesGroupDialogProps {
    data: IPredefinedSearchFarmResult[];
    onClose: () => void;
    onSelect: (item: IContactRow[]) => void;
    validator: IFormValidator;
    groupName: string;
    searchGuid: string;
    usePersonsConnectedToTheFarm: boolean;
    showPhoneNumbers?: boolean;
}

export default function CompaniesGroupDialog(props: CompaniesGroupDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCompany',
        'AnelmaCommunication',
        'AnelmaGeneral',
        'AnelmaLayout',
    ]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [receiverInfos, setReceiverInfos] = useState<IContactRow[]>([]);

    const [roleParams, setRoleParams] = useState<ParameterValue[]>([]);
    const [phoneNumberTypeParams, setPhoneNumberTypeParams] = useState<ParameterValue[]>([]);
    const [farmingTypeBovineParams, setFarmingTypeBovineParams] = useState<ParameterValue[]>([]);
    const [farmingTypePigParams, setFarmingTypePigParams] = useState<ParameterValue[]>([]);

    useEffect(() => {
        paramRepository.load(['AnelmaCompany', 'AnelmaGeneral']).then(() => {
            setFarmingTypeBovineParams(
                paramRepository.resource('AnelmaCompany', 'FarmingTypeBovine')
            );
            setFarmingTypePigParams(paramRepository.resource('AnelmaCompany', 'FarmingTypePig'));

            setRoleParams(paramRepository.resource('AnelmaGeneral', 'FarmTitles'));
            setPhoneNumberTypeParams(
                paramRepository.resource('AnelmaGeneral', 'PhoneNumberTypePerson')
            );

            setIsLoading(false);
        });
    }, []);

    const personColumns: AGridColumns = [];
    const farmColumns: AGridColumns = [];

    if (props.usePersonsConnectedToTheFarm) {
        personColumns.push(gridColumns.companyName(t('AnelmaCommunication:1070'), 'FarmName'));
        personColumns.push(gridColumns.personName(t('AnelmaCommunication:1071'), 'Name'));
        personColumns.push({
            field: 'Roles',
            headerName: t('AnelmaCommunication:1072'),
            renderCell: (params) => {
                const contactPerson = params.row as unknown as IContactPersonRow;
                return <>{getRoleNamesColumn(contactPerson)}</>;
            },
            width: 200,
        });

        if (props.showPhoneNumbers) {
            personColumns.push(
                gridColumns.companyName(t('AnelmaCommunication:1157'), 'PhoneNumber')
            );
            personColumns.push({
                field: 'PhoneNumberType',
                headerName: t('AnelmaCommunication:1151'),
                renderCell: (params) => <TextWithTooltip text={params.value as string} />,
                width: 250,
            });
        }
    } else {
        farmColumns.push(
            gridColumns.producerNumber(t('AnelmaCommunication:1078'), 'ProducerNumber', 150)
        );
        farmColumns.push(gridColumns.companyName(t('AnelmaCommunication:1070'), 'Name'));
        farmColumns.push(gridColumns.companyName(t('AnelmaCommunication:1157'), 'PhoneNumber'));
        /* Not needed yet
        farmColumns.push(
            {
                field: 'PhoneNumberType',
                headerName: t('AnelmaCommunication:1096'),
                renderCell: (params) => <TextWithTooltip text={params.value as string} />,
                width: 250
            });
        */
        farmColumns.push({
            field: 'FarmingTypes',
            headerName: t('AnelmaCommunication:1048'),

            renderCell: (params) => {
                return <TextsWithTooltip texts={params.value as string[]} />;
            },
            valueGetter: (params, row) =>
                GetProductionLinesFromIntList(
                    row.ProductionLines,
                    farmingTypeBovineParams,
                    farmingTypePigParams
                ),
            width: 200,
        });
    }

    const personRows = props.data.reduce((res: IContactPersonRow[], company) => {
        if (props.showPhoneNumbers) {
            var companyContracts = company.ContactPersons.reduce(
                (contacts: IContactPersonRow[], p) => {
                    p.PhoneNumbers?.forEach((phoneNumber) => {
                        contacts.push({
                            id: res.length + contacts.length,
                            EntityId: p.Id,
                            EntityType: 'Person',
                            Name: p.Name,
                            PhoneNumber: phoneNumber.NormalizedNumber,
                            PhoneNumberType:
                                phoneNumberTypeParams.find(
                                    (x) => x.code == phoneNumber.Type.toString()
                                )?.text ?? '',
                            CommunicationGroupId: props.searchGuid,
                            CommunicationGroupName: props.groupName,
                            FarmId: company.FarmGuid,
                            FarmName: company.Name,
                            ProductionLines: company.ProductionLines,
                            ProducerNumber: Number(company.ProducerId) || 0,
                            Roles: p.Roles,
                        });
                    });

                    return contacts;
                },
                []
            );
        } else {
            var companyContracts = company.ContactPersons.reduce(
                (contacts: IContactPersonRow[], p) => {
                    contacts.push({
                        id: res.length + contacts.length,
                        EntityId: p.Id,
                        EntityType: 'Person',
                        Name: p.Name,
                        PhoneNumber: (
                            p.PhoneNumbers?.find((n) => n.IsDefault)?.NormalizedNumber ?? ''
                        ).toString(),
                        PhoneNumberType:
                            phoneNumberTypeParams.find(
                                (x) =>
                                    x.code ==
                                    (
                                        p.PhoneNumbers?.find((n) => n.IsDefault)?.Type ?? ''
                                    ).toString()
                            )?.text ?? '',
                        CommunicationGroupId: props.searchGuid,
                        CommunicationGroupName: props.groupName,
                        FarmId: company.FarmGuid,
                        FarmName: company.Name,
                        ProductionLines: company.ProductionLines,
                        ProducerNumber: Number(company.ProducerId) || 0,
                        Roles: p.Roles,
                    });

                    return contacts;
                },
                []
            );
        }

        return res.concat(companyContracts);
    }, []);

    const farmRows = props.data.reduce((res: IContactFarmRow[], company) => {
        res.push({
            id: res.length,
            EntityId: company.FarmGuid,
            EntityType: 'Company',
            Name: company.Name,
            PhoneNumber: company.CompanyPhoneNumber || '',
            CommunicationGroupId: props.searchGuid,
            CommunicationGroupName: props.groupName,
            ProducerNumber: Number(company.ProducerId) || 0,
            ProductionLines: company.ProductionLines,
            ContactPersons: company.ContactPersons,
        });

        return res;
    }, []);

    const getRoleNames = (contactPerson: IContactPersonRow) => {
        return contactPerson.Roles?.map((i) => {
            return roleParams.find((role) => role.code === `${i.Type}`)?.text || `${i.Type}`;
        });
    };

    const getRoleNamesColumn = (contactPerson: IContactPersonRow) => {
        const texts = getRoleNames(contactPerson);
        return (
            <>
                <TextsWithTooltip texts={texts as string[]} />
            </>
        );
    };

    const onRowSelectHandler = (selectedItems: IContactRow[]) => {
        setReceiverInfos(selectedItems);
    };

    const footerButtons: ADialogButtonsProp = {
        left: [
            {
                type: 'cancel',
                onClick: () => props.onClose(),
            },
        ],
        right: [
            {
                type: 'ok',
                onClick: () => {
                    props.onSelect(receiverInfos);
                    props.onClose();
                },
            },
        ],
    };

    return (
        <ADialog open={true} onClose={() => props.onClose()}>
            <ADialogTitle>{props.groupName}</ADialogTitle>
            <ADialogContent
                isLoading={isLoading}
                size={props.usePersonsConnectedToTheFarm ? 'xl' : 'lg'}
            >
                {!isLoading ? (
                    <ADataGrid
                        columns={props.usePersonsConnectedToTheFarm ? personColumns : farmColumns}
                        rows={props.usePersonsConnectedToTheFarm ? personRows : farmRows}
                        onSelectionModelChange={(item: GridRowSelectionModel) => {
                            const selectedID = new Set(item);
                            const selectedRowData: IContactRow[] =
                                props.usePersonsConnectedToTheFarm
                                    ? personRows.filter((row) => selectedID.has(row.id))
                                    : farmRows.filter((row) => selectedID.has(row.id));
                            onRowSelectHandler(selectedRowData);
                        }}
                        enableFiltering
                    />
                ) : undefined}
            </ADialogContent>
            <ADialogActions buttons={footerButtons} isLoading={isLoading} />
        </ADialog>
    );
}
