// Libraries
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isNotNil, mergeRight } from 'ramda';
import { enqueueSnackbar } from 'notistack';

// MUI
import Grid from '@mui/material/Grid';
import { GridRowParams } from '@mui/x-data-grid-pro';

// Core
import { ResourceTextApplication, ParameterValues, paramRepository } from '../../../core/resources';
import FormValidator from '../../../core/FormValidator';
import auth from '../../../core/authorization';

// Common
import { ADataGrid, gridColumns, AGridColumns } from '../../../common/dataGrid';
import ASwitch from '../../../common/inputs/ASwitch';
import { TextsWithTooltip, TextWithTooltip } from '../../../common/typography';
import AAttachmentIconButton from '../../../common/buttons/AAttachmentIconButton';

// Store
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { loadPersonNamesAsync } from '../../../store/personNameSlice';

// Interfaces
import { IConversationResult } from '../../../interfaces/ICommunication';
import { ICompanyDetails } from '../../../interfaces/IBusinessEntities';
import { AliasTypeEnum } from '../../../interfaces/enums';

// API
import companyApi from '../../../api/companyApi';

// Communication - feature
import ConversationDialog, { IConversationDialogProps } from './ConversationDialog';
import { capitalizeFirstLetter } from '../../general/helpers/helperFunctions';
import IContactPersonRecipient from '../../general/interfaces/IContactPersonRecipient';
import '../../general/styles/communicationStyles.scss';

export interface IConversationTableProps {
    adminView: boolean;
    conversations: IConversationResult[];
    loading: boolean;
    personCompanyContactPersons: IContactPersonRecipient[];
    setAdminView: (admin: boolean) => void;
    snellmanContactPersons: IContactPersonRecipient[];
    onUpdate?: () => void;
    updateStatusForMessage: string;
    preSelectedConversationId: string | null;
}

export default function ConversationTable(props: IConversationTableProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
    ]);
    const userData = useAppSelector((state) => state.userData);
    const personNames = useAppSelector((state) => state.personNames.data);
    const dispatch = useAppDispatch();
    const loadNames = useCallback(
        (ids: string[]) => dispatch(loadPersonNamesAsync(ids)),
        [dispatch]
    );

    const [communicationTopicParameterValues, setCommunicationTopicParameterValues] =
        useState<ParameterValues>([]);
    const [conversationDialogData, setConversationDialogData] =
        useState<IConversationDialogProps>();
    const [conversations, setConversations] = useState<IConversationResult[]>(props.conversations);
    const [loading, setLoading] = useState<boolean>(false);
    const [preSelectedConversationId, setPreSelectedConversationId] = useState<string | null>(
        props.preSelectedConversationId
    );
    const [companies, setCompanies] = useState<ICompanyDetails[]>([]);

    useEffect(() => {
        setConversations(props.conversations);
    }, [props.conversations]);

    useEffect(() => {
        if (conversations.length > 0) {
            setLoading(true);

            const personIds: string[] = [];
            conversations.forEach((i) => personIds.push(...i.ParticipantList));

            loadNames(personIds).then(() => {
                setLoading(false);
            });

            const ids = conversations.map((_) => _.RelatedFarmId).filter((v) => v) as string[];

            if (ids.length > 0) fetchCompanies(ids);
        }
        const paramPromise = paramRepository.load(['AnelmaCommunication']);
        paramPromise.then(() => {
            setCommunicationTopicParameterValues(
                paramRepository.resource('AnelmaCommunication', 'CommunicationTopic')
            );
        });
    }, [conversations]);

    useEffect(() => {
        if (props.updateStatusForMessage.length > 0)
            updateMessageStatus(props.updateStatusForMessage);
    }, [props.updateStatusForMessage]);

    useEffect(() => {
        const conversation = conversations.find(
            (_) => _.ConversationId === preSelectedConversationId
        );
        if (preSelectedConversationId && conversation) handleOpenConversationDialog(conversation);
    }, [preSelectedConversationId, conversations]);

    const columns: AGridColumns = [
        {
            field: 'ParticipantList',
            headerName: t('AnelmaCommunication:1056'),
            renderCell: (params) => {
                return (
                    <TextsWithTooltip
                        texts={params.value as string[]}
                        className={params.row.WasRead ? '' : 'communication-bold-text-with-tooltip'}
                    />
                );
            },
            valueGetter: (params, row) => {
                return row.ParticipantList.map(
                    (participant: string) =>
                        personNames.find((i) => i.Owner === participant)?.Data || '-'
                );
            },
            width: 200,
        },
        {
            field: 'CompanyName',
            headerName: t('AnelmaCommunication:1047'),
            renderCell: (params) => {
                return (
                    <TextWithTooltip
                        text={params.value as string}
                        className={params.row.WasRead ? '' : 'communication-bold-text-with-tooltip'}
                    />
                );
            },
            valueGetter: (params, row) => resolveCompanyName(row.RelatedFarmId),
            width: 200,
        },
        {
            field: 'ProducerNumber',
            headerName: t('AnelmaCommunication:1078'),
            renderCell: (params) => {
                return (
                    <TextWithTooltip
                        text={params.value as string}
                        className={params.row.WasRead ? '' : 'communication-bold-text-with-tooltip'}
                    />
                );
            },
            valueGetter: (params, row) => resolveProducerNumber(row.RelatedFarmId),
            width: 120,
        },
        {
            field: 'Title',
            headerName: t('AnelmaCommunication:1008'),
            renderCell: (params) => {
                return (
                    <TextWithTooltip
                        text={params.value as string}
                        className={params.row.WasRead ? '' : 'communication-bold-text-with-tooltip'}
                    />
                );
            },
            width: 250,
        },
        {
            field: 'Topic',
            headerName: t('AnelmaCommunication:1001'),
            renderCell: (params) => {
                return (
                    <TextWithTooltip
                        text={resolveTopicFromParameter(params.value as string)}
                        className={params.row.WasRead ? '' : 'communication-bold-text-with-tooltip'}
                    />
                );
            },
            width: 150,
        },
        gridColumns.dateTime('CreatedDateTime', t('AnelmaCommunication:1016'), 'DD.MM.YYYY HH:mm'),
        {
            field: 'Attachments',
            headerName: t('AnelmaCommunication:1017'),
            renderCell: (params) =>
                resolveAttachments(params.value) === true ? (
                    <AAttachmentIconButton
                        onClick={() => false}
                        tooltip={t('AnelmaCommunication:1163')}
                    />
                ) : (
                    <></>
                ),
        },
    ];

    const resolveAttachments = (value?: string) => {
        return isNotNil(value) && value.length > 0 ? true : false;
    };

    const resolveTopicFromParameter = (value: string) => {
        return (
            communicationTopicParameterValues.find((parameterValue) => {
                if (parameterValue.code === value?.toString()) return parameterValue.text;
            })?.text ?? ''
        );
    };

    const resolveCompanyName = (relatedFarmId: string): string => {
        if (userData.data.CompanyRelations.length > 1)
            return (
                companies
                    .find((company) => {
                        if (company.Id === relatedFarmId) return company;
                    })
                    ?.Names.find((n) => n.IsDefault)?.Value ?? ''
            );

        return userData.data.CompanyRelations.find((c) => c.CompanyName)?.CompanyName ?? '';
    };

    const resolveProducerNumber = (relatedFarmId: string): string => {
        if (userData.data.CompanyRelations.length > 1)
            return (
                companies
                    .find((company) => {
                        if (company.Id === relatedFarmId) return company;
                    })
                    ?.Aliases.find((a) => a.Type === AliasTypeEnum.ProducerNumber)?.Value ?? ''
            );

        return (
            userData.data.CompanyRelations.find((c) => c.CompanyProducerNumber)
                ?.CompanyProducerNumber ?? ''
        );
    };

    const fetchCompanies = (ids: string[]) => {
        companyApi.getCompaniesByIdList(ids).then((_) => {
            if (!_) {
                enqueueSnackbar(t('AnelmaSolmuErrors:10009'), {
                    variant: 'error',
                });
                return;
            }
            setCompanies(_.Items.filter((_) => !companies.includes(_)));
        });
    };

    const updateMessageStatus = (id: string) => {
        var item = conversations.find((c) => c.ConversationId === id);

        if (item) {
            item.WasRead = true;
            setConversations((previous) => [
                ...previous.filter((c) => c.ConversationId !== id),
                item as IConversationResult,
            ]);
        }
    };

    const handleOpenConversationDialog = (conversation: IConversationResult) => {
        setConversationDialogData({
            onClose: () => setConversationDialogData(undefined),
            onUpdate: () => {
                setPreSelectedConversationId(null);
                if (props.onUpdate) props.onUpdate();
            },
            personCompanyContactPersons: props.personCompanyContactPersons,
            snellmanContactPersons: props.snellmanContactPersons,
            updateMessageStatus: updateMessageStatus,
            conversation: conversation,
        });
    };

    return (
        <div style={{ paddingTop: '3em', paddingBottom: '3em' }}>
            {auth.canViewAllMessages ? (
                <Grid container justifyContent='flex-end'>
                    <Grid item>
                        <ASwitch
                            checked={props.adminView}
                            id='load-admin-conversations'
                            label={capitalizeFirstLetter(t('AnelmaCommunication:1054'))}
                            onChange={() => props.setAdminView(!props.adminView)}
                            validator={new FormValidator()}
                            withoutContainer
                        />
                    </Grid>
                </Grid>
            ) : null}

            <ADataGrid
                className={'communication-hover-enable-cursor-pointer'}
                columns={columns}
                getRowClassName={(params) =>
                    params.row.WasRead ? '' : 'communication-bold-text-with-tooltip'
                }
                enableFiltering
                loading={props.loading || loading}
                onRowClick={(_: GridRowParams) =>
                    handleOpenConversationDialog(_.row as IConversationResult)
                }
                rows={conversations
                    .map((c) => mergeRight(c, { id: c.ConversationId }))
                    .sort(
                        (a, b) =>
                            new Date(b.CreatedDateTime).valueOf() -
                            new Date(a.CreatedDateTime).valueOf()
                    )}
                enablePagination
            />

            {conversationDialogData && <ConversationDialog {...conversationDialogData} />}
        </div>
    );
}
