// Libraries
import { useState } from 'react';
import { isNotNil } from 'ramda';

// MUI
// Core
import auth from '../core/authorization';
import FormValidator from '../core/FormValidator';

// Common
import { ADataGridActions } from '../common/dataGrid';
import { AEditIconButton } from '../common/buttons';

// Interfaces
import { IPersonDetails, IPersonSummary } from '../interfaces/IBusinessEntities';

// Store
// API
import accessManagementApi from '../api/accessManagementApi';
import personApi from '../api/personApi';

// Feature - Person
import PersonFormDialog from './PersonFormDialog';
import PersonSearchResult from './search/PersonSearchResult';

export interface PersonsProps {
    data: IPersonSummary[];
    isInactive: boolean;
    isLoading: boolean;
    showMoreInfo: boolean;
}

export function PersonsView(props: PersonsProps) {
    const [showPersonDialog, setShowPersonDialog] = useState<boolean>(false);
    const [personToEditData, setPersonToEditData] = useState<IPersonDetails | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(props.isLoading);
    const [hasTwoFactorAuthentication, setHasTwoFactorAuthentication] = useState<boolean>(false);

    const closePersonDialog = () => {
        setShowPersonDialog(false);
        setPersonToEditData(null);
    };

    // This was extracted from useEffect that was imho pointless to have. Time has passed since the creation of the side effect.
    const fetchPersonDetails = (personId: string) => {
        setIsLoading(true);
        personApi.getPersonByGuid(personId).then((response) => {
            if (!response) return;

            if (response.Entity.Logins.length > 0) {
                accessManagementApi
                    .getUser(response.Entity.Logins[0].Login)
                    .then((res) => {
                        if (res && res.Entity) {
                            setHasTwoFactorAuthentication(res.Entity.multifactorEnabled);
                        } else {
                            setHasTwoFactorAuthentication(false);
                        }
                    })
                    .finally(() => {
                        setPersonToEditData(response.Entity);
                        setIsLoading(false);
                        setShowPersonDialog(true);
                    });
            } else {
                setPersonToEditData(response.Entity);
                setIsLoading(false);
                setShowPersonDialog(true);
            }
        });
    };

    const actionButton: ADataGridActions | undefined = auth.canUpdatePerson
        ? [
              (params) => (
                  <AEditIconButton
                      onClick={() =>
                          fetchPersonDetails((params.row as unknown as IPersonSummary).Id)
                      }
                  />
              ),
          ]
        : undefined;

    const renderPersonForm = () => {
        if (showPersonDialog && isNotNil(personToEditData))
            return (
                <PersonFormDialog
                    data={personToEditData}
                    isInactive={props.isInactive}
                    hasTwoFactorAuthentication={hasTwoFactorAuthentication}
                    mode={'modify'}
                    onClose={closePersonDialog}
                    validator={new FormValidator()}
                    fromCompanyModal={false}
                />
            );

        return <></>;
    };

    return (
        <>
            <PersonSearchResult
                actionColumn={actionButton}
                data={props.data}
                enableFiltering
                isLoading={isLoading}
                showMoreInfo={props.showMoreInfo}
            />

            {renderPersonForm()}
        </>
    );
}
