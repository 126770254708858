import { IBulletinItemCulture, IBulletinItemDetails } from '../../interfaces/ICommunication';
import { FieldUpdate, IUpdatePayload, NamedField } from '../../interfaces/IGeneral';
import { isNil, keys, reduce, mergeRight, equals, propOr, append } from 'ramda';
import moment from 'moment';
import { IContactPersonRow } from '../../interfaces/IBusinessEntities';

export interface BulletinRequest {
    Entity: IBulletinItemDetails;
}

export interface BulletinUpdateRequest
    extends BulletinRequest,
        IUpdatePayload<IBulletinItemDetails> {}

/**
 * Goes through keys of the new entity and if values of a key differ with original value FieldUpdate object is appended
 * to a accumulating array.
 * @param newEntity
 * @param originalEntity
 */
function updates(
    newEntity: IBulletinItemDetails,
    originalEntity: IBulletinItemDetails
): FieldUpdate[] {
    const fields = keys(newEntity);

    return reduce(
        (a: FieldUpdate[], key: string) => {
            // gets current and original values for a certain key
            const value = propOr(undefined, key, newEntity);
            const origValue = propOr(undefined, key, originalEntity);

            if (value === undefined || origValue === undefined || equals(value, origValue))
                return a;

            if (key === 'Message') {
                let messages: IBulletinItemCulture[] = value as IBulletinItemCulture[];
                let origMessages: IBulletinItemCulture[] = origValue as IBulletinItemCulture[];

                messages.forEach((m) => {
                    if (origMessages.findIndex((om) => om.Culture === m.Culture) !== -1) {
                        let om = origMessages.find((om) => om.Culture === m.Culture);

                        if (om && om.MessageText !== m.MessageText && m.MessageText !== '')
                            a = append(
                                {
                                    Field: key,
                                    NewData: m,
                                    OldData: om,
                                },
                                a
                            );
                    }
                });

                return a;
            } else if (key === 'ValidFromDate' || key === 'ValidDueDate') {
                if (equals(origValue, moment(value as string).format('DD.MM.YYYY HH:mm'))) return a;
                else
                    return append(
                        {
                            Field: key,
                            NewData: moment(value as string)
                                .utc()
                                .format(),
                            OldData: origValue,
                        },
                        a
                    );
            } else if (key === 'ReceiverUsers') {
                return a;
            } else if (equals(value, origValue)) return a;

            return append(
                {
                    Field: key,
                    NewData: value,
                    OldData: origValue,
                },
                a
            );
        },
        [],
        fields
    );
}

function additions(
    newEntity: IBulletinItemDetails,
    originalEntity: IBulletinItemDetails
): NamedField[] {
    const fields = keys(newEntity);

    return reduce(
        (a: NamedField[], key: string) => {
            const value = propOr(undefined, key, newEntity);
            const origValue = propOr(undefined, key, originalEntity);

            if (value === undefined || origValue === undefined || equals(value, origValue))
                return a;

            if (key === 'Message') {
                let messages: IBulletinItemCulture[] = value as IBulletinItemCulture[];
                let origMessages: IBulletinItemCulture[] = origValue as IBulletinItemCulture[];

                messages.forEach((m) => {
                    if (
                        origMessages.findIndex((om) => om.Culture === m.Culture) === -1 &&
                        m.MessageText !== ''
                    )
                        a = append(
                            {
                                Field: key,
                                Data: m,
                            },
                            a
                        );
                });

                return a;
            } else if (key === 'ReceiverUsers') {
                let receivers: IContactPersonRow[] = value as IContactPersonRow[];
                let origReceivers: IContactPersonRow[] = origValue as IContactPersonRow[];

                receivers.forEach((r) => {
                    if (
                        origReceivers.findIndex(
                            (or) => or.EntityId === r.EntityId && or.FarmId === r.FarmId
                        ) === -1
                    )
                        a = append(
                            {
                                Field: key,
                                Data: r,
                            },
                            a
                        );
                });

                return a;
            } else if (
                (origValue === null) === (value === null) ||
                origValue !== null ||
                key === 'Duration'
            )
                return a;

            return append(
                {
                    Field: key,
                    Data: value,
                },
                a
            );
        },
        [],
        fields
    );
}

function deletions(
    newEntity: IBulletinItemDetails,
    originalEntity: IBulletinItemDetails
): NamedField[] {
    const fields = keys(newEntity);

    return reduce(
        (a: NamedField[], key: string) => {
            const value = propOr(undefined, key, newEntity);
            const origValue = propOr(undefined, key, originalEntity);

            if (value === undefined || origValue === undefined || equals(value, origValue))
                return a;

            if (key === 'Message') {
                let messages: IBulletinItemCulture[] = value as IBulletinItemCulture[];
                let origMessages: IBulletinItemCulture[] = origValue as IBulletinItemCulture[];

                origMessages.forEach((om) => {
                    if (messages.findIndex((m) => m.Culture === om.Culture) === -1)
                        a = append(
                            {
                                Field: key,
                                Data: om,
                            },
                            a
                        );
                    else if (messages.find((m) => m.Culture === om.Culture)?.MessageText === '')
                        a = append(
                            {
                                Field: key,
                                Data: om,
                            },
                            a
                        );
                });

                return a;
            } else if (
                (origValue === null) === (value === null) ||
                origValue === null ||
                key === 'Duration'
            )
                return a;

            return append(
                {
                    Field: key,
                    Data: value,
                },
                a
            );
        },
        [],
        fields
    );
}

export function bulletinRequestBuilder(
    newEntity: IBulletinItemDetails,
    originalEntity: IBulletinItemDetails | null
): BulletinRequest {
    let request = { Entity: newEntity };
    if (isNil(originalEntity)) return request;

    return mergeRight(request, {
        Additions: additions(newEntity, originalEntity),
        Deletions: deletions(newEntity, originalEntity),
        Updates: updates(newEntity, originalEntity),
    });
}
