import { Grid } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ASwitch } from '../../../common/inputs';
import { dayFormat } from '../../../core/constants';
import { IFormValidator } from '../../../core/FormValidator';
import { ResourceTextApplication } from '../../../core/resources';
import { FarmingTypeEnum } from '../../../interfaces/enums';
import {
    IPlannedAnimal,
    IProductionPlan,
    IProductionPlanAccess,
} from '../../../interfaces/IProductionPlan';
import EditProductionAnimalDataDialog from '../data-grid/EditProductionAnimalDataDialog';
import ProductionPlanAnimalsDataGrid from '../data-grid/ProductionPlanAnimalDataGrid';
import {
    BuildAnimalCapacities,
    BuildIntermediationAnimalSales,
    BuildIntermediationAnimalRequirements,
    BuildSlaughterAnimals,
    IProductionPlanDataGrid,
} from '../data-grid/ProductionPlanDataGrids';
import SectionTitle from '../data-grid/SectionTitle';
import AnimalCountMapper from '../helpers/data-grid-mappers/AnimalCounts';
import IntermedSalesMapper from '../helpers/data-grid-mappers/IntermediationSales';
import SlaughterSalesMapper from '../helpers/data-grid-mappers/SlaughterSales';
import { styled } from '@mui/material';

interface IProps {
    data: IProductionPlan;
    access: IProductionPlanAccess;
    readonly?: boolean;
    validator: IFormValidator;
    handleNoSlaughterBovineSoldChange: (_: boolean) => void;
    handleChangeNoTransmissionBovineSoldChange: (_: boolean) => void;
    capacityError: boolean;
}

interface IEditDialogProps {
    title: string;
    data: IProductionPlanDataGrid;
    onSave: (newData: IProductionPlanDataGrid) => void;
}

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    paddingTop: '20px',
}));

export default function AnimalDataGrids({
    data,
    access,
    readonly,
    validator,
    handleNoSlaughterBovineSoldChange,
    handleChangeNoTransmissionBovineSoldChange,
    capacityError,
}: IProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaLayout',
        'AnelmaCattleProductionPlan',
    ]);

    const [showTransmissionSales] = useState<boolean>(
        access.HasDairyAnimals ||
            access.HasBreedingAnimals ||
            !!access.ProductionDirection.find((x) => x === FarmingTypeEnum.Transmission) ||
            !!access.ProductionDirection.find((x) => x === FarmingTypeEnum.Dairy)
    );

    const [showTransmissionReq] = useState<boolean>(
        !!access.ProductionDirection.find((x) => x === FarmingTypeEnum.Transmission) ||
            !!access.ProductionDirection.find((x) => x === FarmingTypeEnum.Upbringing)
    );

    const [showSlaughter] = useState<boolean>(
        !!access.ProductionDirection.find((x) => x === FarmingTypeEnum.Dairy) ||
            !!access.ProductionDirection.find(
                (x) =>
                    x === FarmingTypeEnum.Upbringing ||
                    !!access.ProductionDirection.find((x) => x === FarmingTypeEnum.Calving)
            )
    );

    const [showRegisterDate, setShowRegisterDate] = useState<boolean>(false);

    const [showTransmissionSalesDataGrid, setShowTransmissionSalesDataGrid] = useState<boolean>(
        !data.NoTransmissionBovineSold
    );

    const [showSlaughterSoldDataGrid, setShowSlaughterSoldDataGrid] = useState<boolean>(
        !data.NoSlaughterBovineSold
    );

    const [animalCapacities, setAnimalCapacities] = useState<IProductionPlanDataGrid>();
    const [intermedSales, setIntermedSales] = useState<IProductionPlanDataGrid>();
    const [intermedReq, setIntermedReq] = useState<IProductionPlanDataGrid>();
    const [slaughter, setSlaughter] = useState<IProductionPlanDataGrid>();

    const [editData, setEditData] = useState<IEditDialogProps>();

    const getAnimalCapacities = (all?: boolean) =>
        BuildAnimalCapacities(access, data.AnimalCapacities, all);

    const getIntermediationAnimalSales = (all?: boolean) =>
        BuildIntermediationAnimalSales(
            access,
            data.PreviousTransmissionAnimalsSold,
            data.PlannedTransmissionAnimalsSold,
            all
        );

    const getIntermediationAnimalRequirements = (all?: boolean) =>
        BuildIntermediationAnimalRequirements(
            access,
            data.PreviousTransmissionAnimalsNeeded,
            data.PlannedTransmissionAnimalsNeeded,
            all
        );

    const getSlaughterAnimals = (all?: boolean) =>
        BuildSlaughterAnimals(
            access,
            data.PreviousSlaughterBovineSold,
            data.PlannedSlaughterBovineSold,
            all
        );

    useEffect(() => {
        setAnimalCapacities(getAnimalCapacities());
        setIntermedSales(getIntermediationAnimalSales());
        setIntermedReq(getIntermediationAnimalRequirements());
        setSlaughter(getSlaughterAnimals());
    }, [
        data.AnimalCapacities,
        data.PreviousTransmissionAnimalsSold,
        data.PlannedTransmissionAnimalsSold,
        data.PreviousTransmissionAnimalsNeeded,
        data.PlannedTransmissionAnimalsNeeded,
        data.PreviousSlaughterBovineSold,
        data.PlannedSlaughterBovineSold,
    ]);

    useEffect(() => {
        setShowRegisterDate(!data.AnimalCapacities.find((x) => x.Animals > 0 && x.IsPlanned));
    }, [animalCapacities]);

    const onAnimalCapacitiesEdit = () => {
        setEditData({
            title: t('AnelmaLayout:1069'),
            data: getAnimalCapacities(true),
            onSave: (newData: IProductionPlanDataGrid) => {
                newData.rows.forEach((row) => {
                    AnimalCountMapper.ToArray(data.AnimalCapacities, row);
                });
                setAnimalCapacities(getAnimalCapacities());
            },
        });
    };

    const onIntermedSalesEdit = () => {
        setEditData({
            title: t('AnelmaLayout:1066'),
            data: getIntermediationAnimalSales(true),
            onSave: (newData: IProductionPlanDataGrid) => {
                newData.rows.forEach((row) => {
                    IntermedSalesMapper.ToPlan(data, row.collection, row);
                });
                setIntermedSales(getIntermediationAnimalSales());
                handleChangeNoTransmissionBovineSoldChange(false);
                setShowTransmissionSalesDataGrid(true);
            },
        });
    };

    const onIntermedReqEdit = () => {
        setEditData({
            title: t('AnelmaLayout:1067'),
            data: getIntermediationAnimalRequirements(true),
            onSave: (newData: IProductionPlanDataGrid) => {
                newData.rows.forEach((row) => {
                    IntermedSalesMapper.ToPlan(data, row.collection, row);
                });
                setIntermedReq(getIntermediationAnimalRequirements());
            },
        });
    };

    const onSlaughterSalesEdit = () => {
        setEditData({
            title: t('AnelmaLayout:1068'),
            data: getSlaughterAnimals(),
            onSave: (newData: IProductionPlanDataGrid) => {
                newData.rows.forEach((row) => {
                    SlaughterSalesMapper.ToPlan(data, row.collection, row);
                });
                setSlaughter(getSlaughterAnimals());
                handleNoSlaughterBovineSoldChange(false);
                setShowSlaughterSoldDataGrid(true);
            },
        });
    };

    const setPlannedTransmissionSalesValuesToZero = () => {
        let zeroPlannedValues = { ...intermedSales };
        if (zeroPlannedValues.rows && zeroPlannedValues.columns) {
            zeroPlannedValues.rows[1].ColostrumCalfBulls = 0;
            zeroPlannedValues.rows[1].ColostrumCalfCows = 0;
            zeroPlannedValues.rows[1].IntermediationBulls = 0;
            zeroPlannedValues.rows[1].IntermediationCows = 0;
            zeroPlannedValues.rows[1].BeefCalfBulls = 0;
            zeroPlannedValues.rows[1].BeefCalfCows = 0;
            zeroPlannedValues.rows[1].VkBreederCalfBulls = 0;
            zeroPlannedValues.rows[1].VkBreederCalfCows = 0;
            setIntermedSales(zeroPlannedValues as IProductionPlanDataGrid);
        }
        for (let i = 0; i < data.PlannedTransmissionAnimalsSold.length; i++) {
            data.PlannedTransmissionAnimalsSold[i].Amount = 0;
        }
    };

    const setPlannedSlaughterSoldValuesToZero = () => {
        let zeroPlannedValues = { ...slaughter };
        if (zeroPlannedValues.rows && zeroPlannedValues.columns) {
            zeroPlannedValues.rows[1].Cows = 0;
            zeroPlannedValues.rows[1].Bulls = 0;
            zeroPlannedValues.rows[1].Heifers = 0;
            zeroPlannedValues.rows[1].BeefCalfBulls = 0;
            zeroPlannedValues.rows[1].BeefCalfCows = 0;
            zeroPlannedValues.rows[1].ColostrumCalfBulls = 0;
            zeroPlannedValues.rows[1].ColostrumCalfCows = 0;
            zeroPlannedValues.rows[1].IntermediationBulls = 0;
            zeroPlannedValues.rows[1].IntermediationCows = 0;
            zeroPlannedValues.rows[1].VkBreederCalfBulls = 0;
            zeroPlannedValues.rows[1].VkBreederCalfCows = 0;
            setSlaughter(zeroPlannedValues as IProductionPlanDataGrid);
        }
        for (let i = 0; i < data.PlannedSlaughterBovineSold.length; i++) {
            data.PlannedSlaughterBovineSold[i].Amount = 0;
        }
    };

    const disableSwitch = (plannedAnimals: IPlannedAnimal[]) => {
        return plannedAnimals.find((_) => _.Amount !== 0 && _.Amount !== null) ? true : false;
    };

    return (
        <>
            <Grid container direction='row' sx={{ paddingLeft: '40px' }}>
                <StyledGridContainer
                    container
                    style={{ border: capacityError ? 'solid red' : 'none' }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SectionTitle
                            title={t('AnelmaLayout:1069')}
                            readonly={readonly}
                            onEdit={onAnimalCapacitiesEdit}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {showRegisterDate && data.BovineRegisterDate && (
                            <span>
                                {`${t('AnelmaCattleProductionPlan:1063')} ${moment(
                                    data.BovineRegisterDate
                                ).format(dayFormat)}`}
                            </span>
                        )}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ProductionPlanAnimalsDataGrid data={animalCapacities} />
                        </Grid>
                    </Grid>
                </StyledGridContainer>

                {showTransmissionSales && (
                    <StyledGridContainer container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SectionTitle
                                title={t('AnelmaLayout:1066')}
                                readonly={showTransmissionSalesDataGrid ? readonly : !readonly}
                                onEdit={onIntermedSalesEdit}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            {showTransmissionSalesDataGrid && (
                                <ProductionPlanAnimalsDataGrid data={intermedSales} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ASwitch
                                id={'no-transmission-bovine-sold'}
                                label={t('AnelmaCattleProductionPlan:1064')}
                                checked={data.NoTransmissionBovineSold}
                                disabled={disableSwitch(data.PlannedTransmissionAnimalsSold)}
                                validator={validator}
                                onChange={(checked) => {
                                    data.NoTransmissionBovineSold = checked;
                                    setShowTransmissionSalesDataGrid(!checked);
                                    if (checked) setPlannedTransmissionSalesValuesToZero();
                                }}
                            />
                        </Grid>
                    </StyledGridContainer>
                )}

                {showTransmissionReq && (
                    <StyledGridContainer container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SectionTitle
                                title={t('AnelmaLayout:1067')}
                                readonly={readonly}
                                onEdit={onIntermedReqEdit}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ProductionPlanAnimalsDataGrid data={intermedReq} />
                        </Grid>
                    </StyledGridContainer>
                )}

                {showSlaughter && (
                    <StyledGridContainer container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SectionTitle
                                title={t('AnelmaLayout:1068')}
                                readonly={showSlaughterSoldDataGrid ? readonly : !readonly}
                                onEdit={onSlaughterSalesEdit}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            {showSlaughterSoldDataGrid && (
                                <ProductionPlanAnimalsDataGrid data={slaughter} />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ASwitch
                                id={'no-slaughter-bovine-sold'}
                                label={t('AnelmaCattleProductionPlan:1065')}
                                checked={data.NoSlaughterBovineSold}
                                disabled={disableSwitch(data.PlannedSlaughterBovineSold)}
                                validator={validator}
                                onChange={(checked) => {
                                    data.NoSlaughterBovineSold = checked;
                                    setShowSlaughterSoldDataGrid(!checked);
                                    if (checked) setPlannedSlaughterSoldValuesToZero();
                                }}
                            />
                        </Grid>
                    </StyledGridContainer>
                )}
            </Grid>
            {editData && !readonly && (
                <EditProductionAnimalDataDialog
                    title={editData.title}
                    data={editData.data}
                    onSave={editData.onSave}
                    onClose={() => setEditData(undefined)}
                />
            )}
        </>
    );
}
