// Libraries
import moment from 'moment';

// MUI
// Core
// Common
// Interfaces
import { IEvent } from '../interfaces/IEvent';
import { IFarmVisit } from '../interfaces/IFarmVisit';
import { CalendarEventTypes } from '../interfaces/enums';
import { ExtendedCalendarEvent, CalendarEvent } from '../interfaces/calendar/CalendarEvent';
import { IAnimalPayloadCalendarEvent } from '../interfaces/calendar/AnimalPayloadCalendarEvent';

// Store
// API
import api from '../api/calendarApi';
import eventsApi from '../api/eventsApi';
import animalPayloadsApi from '../api/animalPayloadsApi';
import farmVisitApi from '../api/farmVisitApi';

// Feature - Event
import eventMapper from './eventMapper';

interface LoadedIntervals {
    data: ExtendedCalendarEvent[];
    end: moment.Moment;
    start: moment.Moment;
}

export interface CalendarRange {
    companyId: string;
    end: moment.Moment;
    producerNumber: string;
    start: moment.Moment;
}

interface IntervalToLoad extends CalendarRange {
    endOfChunk: moment.Moment;
    startOfChunk: moment.Moment;
}

class CalendarEventStorage {
    private readonly apiFormat = 'YYYY-MM-DDTHH:mm:ss';

    private readonly loadedIntervals: LoadedIntervals[] = [];

    load(
        farmId: string,
        range: CalendarRange,
        loadPayloadEventsFromTiltu: boolean,
        tags: string[]
    ): Promise<ExtendedCalendarEvent[]> {
        return new Promise<ExtendedCalendarEvent[]>((resolve) => {
            const intervalToLoad = this.getIntervalInMonths(range);
            const loadedInterval = this.getLoadedInterval(range);

            const loadStart = intervalToLoad.startOfChunk.format(this.apiFormat);
            const loadEnd = intervalToLoad.endOfChunk.format(this.apiFormat);
            const promises: Promise<any>[] = [];

            if (!loadedInterval) {
                this.loadedIntervals.push({
                    data: [],
                    end: intervalToLoad.endOfChunk,
                    start: intervalToLoad.startOfChunk,
                });

                promises.push(
                    api.getCalendarEvents(farmId, loadStart, loadEnd).then((response) => {
                        if (!response) return;
                        const loaded = this.getLoadedInterval(range);
                        loaded?.data.push(
                            ...response.Items.map(eventMapper.calendarOwnBookingToEvent)
                        );
                    })
                );

                promises.push(
                    this.loadAnelmaEvents(loadStart, loadEnd, tags).then((response) => {
                        if (!response) return;
                        const loaded = this.getLoadedInterval(range);

                        for (let i = 0; i < response.length; i++) {
                            if (
                                response[i].RegistrationValidFrom >= loadStart &&
                                response[i].RegistrationValidDue <= loadEnd
                            ) {
                                loaded?.data.push(
                                    eventMapper.anelmaEventToEventRegistration(response[i])
                                );
                            }
                            if (
                                response[i].EventValidFrom >= loadStart &&
                                response[i].EventValidDue <= loadEnd
                            ) {
                                loaded?.data.push(eventMapper.anelmaEventToEvent(response[i]));
                            }
                        }
                    })
                );
            }

            promises.push(
                this.loadFarmVisits(range.companyId).then((response) => {
                    const loaded = this.getLoadedInterval(range);

                    if (loaded) {
                        const updatedData = loaded.data.filter(
                            (i) => i.type !== CalendarEventTypes.FarmVisit
                        );

                        if (response)
                            updatedData.push(
                                ...response.map((i) => eventMapper.farmVisitToEvent(i))
                            );

                        loaded.data = updatedData;
                    }
                })
            );

            if (loadPayloadEventsFromTiltu) {
                promises.push(
                    this.loadAnimalPayloadsCalendar(loadStart, loadEnd, range.producerNumber).then(
                        (response) => {
                            const loaded = this.getLoadedInterval(range);

                            if (loaded) {
                                const updatedData = loaded.data.filter(
                                    (i) =>
                                        i.type !== CalendarEventTypes.PickupTransMissionAnimal &&
                                        i.type !== CalendarEventTypes.PickupSlaughterAnimal
                                );

                                if (response)
                                    updatedData.push(
                                        ...response.map(eventMapper.animalPayloadToEvent)
                                    );

                                loaded.data = updatedData;
                            }
                        }
                    )
                );
            }

            Promise.all(promises).then(() => {
                const loadedInterval = this.getLoadedInterval(range);
                if (loadedInterval) {
                    resolve(loadedInterval.data);
                } else {
                    resolve([]);
                }
            });
        });
    }

    private getLoadedInterval(range: CalendarRange) {
        const intervalToLoad = this.getIntervalInMonths(range);
        const loadedInterval = this.loadedIntervals.find(
            (interval) =>
                interval.start.diff(intervalToLoad.startOfChunk) <= 0 &&
                interval.end.diff(intervalToLoad.endOfChunk) >= 0
        );

        return loadedInterval;
    }

    private loadAnelmaEvents(start: string, end: string, tags: string[]): Promise<IEvent[]> {
        return new Promise<IEvent[]>((resolve) => {
            eventsApi
                .getEventsByDate({
                    EventValidFrom: start,
                    EventValidDue: end,
                    Tags: tags,
                })
                .then((response) => {
                    resolve(response?.Items || []);
                });
        });
    }

    private loadAnimalPayloadsCalendar(
        start: string,
        end: string,
        producerNumber: string | null
    ): Promise<IAnimalPayloadCalendarEvent[]> {
        if (!producerNumber)
            return new Promise<IAnimalPayloadCalendarEvent[]>((resolve) => resolve([]));

        const from = moment(start).format('YYYY-MM-DD').toString();
        const to = moment(end).format('YYYY-MM-DD').toString();

        return new Promise<IAnimalPayloadCalendarEvent[]>((resolve) => {
            animalPayloadsApi
                .getAnimalPayloadCalendar(producerNumber, from, to)
                .then((response) => {
                    resolve(response?.Items || []);
                });
        });
    }

    private loadFarmVisits(farmId: string | null): Promise<IFarmVisit[]> {
        if (!farmId) return new Promise<IFarmVisit[]>((resolve) => resolve([]));
        return new Promise<IFarmVisit[]>((resolve) => {
            farmVisitApi.getFarmVisitsByFarmId(farmId).then((response) => {
                resolve(response?.Items || []);
            });
        });
    }

    remove(basicEvent: CalendarEvent): void {
        this.loadedIntervals.forEach((interval) => {
            const index = interval.data.findIndex((i) => i.fullData.Id === basicEvent.Id);
            if (index === -1) return;
            interval.data.splice(index, 1);
        });
    }

    update(basicEvent: CalendarEvent): void {
        const event = eventMapper.calendarOwnBookingToEvent(basicEvent);
        const eventStart = moment(event.start);
        const eventEnd = moment(event.end);

        this.loadedIntervals.forEach((interval) => {
            if (interval.start.isAfter(eventEnd)) return;
            if (interval.end.isBefore(eventStart)) return;
            const index = interval.data.findIndex((i) => i.fullData.Id === event.fullData.Id);
            index === -1 ? interval.data.push(event) : (interval.data[index] = event);
        });
    }

    private getIntervalInMonths(range: CalendarRange): IntervalToLoad {
        const startOfMonth = range.start.clone().startOf('month');
        const endOfMonth = range.end.clone().endOf('month');
        const startOfChunk = startOfMonth.subtract(startOfMonth.isoWeekday() - 1, 'days');
        const endOfChunk = endOfMonth.add(7 - endOfMonth.isoWeekday(), 'days');

        return {
            ...range,
            endOfChunk: endOfChunk,
            startOfChunk: startOfChunk,
        };
    }
}

export default new CalendarEventStorage();
