export class SelectorConstants {
    static readonly CreateFeedbackBtn = 'feedback-create-btn';
    static readonly FeedbackDialog = 'feedback-dialog';
    static readonly FeedbackDataGrid = 'feedback-data-grid';
    static readonly FeedbackViewContainer = 'feedback-view-container';
    static readonly LoadAdminFeedback = 'load-admin-feedback';
    static readonly SearchFarmDataGrid = 'search-farm-data-grid';
    static readonly BovineDialog = 'bovine-dialog';
    static readonly AddBovineDialog = 'add-bovine-dialog';
}
