export const getRoleName = (): string => {
    switch (window.location.host) {
        case 'localhost:44388':
            return 'Suunnittelijat en (Mtech)';
        case 'snellman-anelma3-dev.mtech.fi':
            return 'Snellman Anelma - Snellman';
        case 'snellman-anelma3-production-demo.mtech.fi':
        case 'anelma3.snellman.fi':
            return 'Anelma 3 - Administrators';
        default:
            return 'SNE Administrator';
    }
};
