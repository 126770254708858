// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Interfaces
import { IPhoneNumber, ICar } from '../../interfaces/IBusinessEntities';
import { CarCapacityTypeEnum } from '../../interfaces/enums';

// Core
import defaultData from '../../core/defaultData';
import { ResourceTextApplication } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import { ANumberInput, APhoneNumberInput, ATextInput, AZipAndCityInput } from '../../common/inputs';
import {
    ADialog,
    ADialogActions,
    ADialogContent,
    ADialogTitle,
    ADialogButtonsProp,
    ADialogSubHeading,
} from '../../common/dialog';

export interface CompanyCarsFormProps {
    data: ICar;
    onClose: Function;
    mode: 'create' | 'edit';
    onEdit: (data: ICar, i: number) => any;
    onAdd: (data: ICar) => any;
    index: number;
    validator: IFormValidator;
    readonly?: boolean;
}

export default function CompanyCarsForm(props: CompanyCarsFormProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCompany',
        'AnelmaAccessRights',
    ]);
    const { enqueueSnackbar } = useSnackbar();
    const { data, validator } = props;
    const nameTitles = [
        {
            text: t('AnelmaCompany:1071'),
            type: CarCapacityTypeEnum.Bovines,
            boundary: 'AnelmaCompany:VehicleCapacityBovine',
        },
        {
            text: t('AnelmaCompany:1072'),
            type: CarCapacityTypeEnum.Calves,
            boundary: 'AnelmaCompany:VehicleCapacityCalves',
        },
        {
            text: t('AnelmaCompany:1073'),
            type: CarCapacityTypeEnum.Pigs,
            boundary: 'AnelmaCompany:VehicleCapacityFatteningPigs',
        },
        {
            text: t('AnelmaCompany:1074'),
            type: CarCapacityTypeEnum.Piglets,
            boundary: 'AnelmaCompany:VehicleCapacityPiglets',
        },
    ];

    const capacities = nameTitles.map((n) => {
        const found = data.Capacities.find((c) => c.Type === n.type);
        return found || defaultData.carCapacity(n.type, null);
    });

    const [carCapacities, setCarCapacities] = useState(capacities);

    const [carNumber, setCarNumber] = useState<number | null>(data.Number);
    const [carLicence, setCarLicence] = useState<string>(data.Licence);
    const [carStreeAddress, setCarStreertAddress] = useState<string>(data.Address.Street);
    const [zip, setZip] = useState<string>(data.Address.Zip);
    const [city, setCity] = useState<string>(data.Address.City);
    const [carPhoneNumber, setCarPhoneNumber] = useState<IPhoneNumber>(data.PhoneNumber);

    const EditAddVehicle = () => {
        const address = { ...data.Address };
        address.Street = carStreeAddress;
        address.Zip = zip;
        address.City = city;

        const newdata: ICar = {
            ...data,
            Number: carNumber,
            Licence: carLicence,
            Capacities: carCapacities,
            PhoneNumber: carPhoneNumber,
            Address: address,
        };

        if (props.mode === 'create') {
            props.onAdd(newdata);
        } else if (props.mode === 'edit') {
            props.onEdit(newdata, props.index);
        }

        props.onClose();
    };

    const footerButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () => {
                    if (validator.invalid) {
                        enqueueSnackbar(t('AnelmaGeneral:1030'), {
                            variant: 'error',
                        });
                        return;
                    }
                    EditAddVehicle();
                },
                type: 'ok',
                disabled: props.readonly,
            },
        ],
    };

    return (
        <>
            <ADialog open={true} onClose={() => props.onClose()}>
                <ADialogTitle>{t('AnelmaCompany:1002')}</ADialogTitle>

                <ADialogContent size='md'>
                    <Grid container>
                        <Grid item sm={6}>
                            <ADialogSubHeading>{t('AnelmaCompany:1101')}</ADialogSubHeading>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item sm={6}>
                            <ANumberInput
                                lengthBoundaryName='AnelmaCompany:VehicleNumber'
                                id='car-number'
                                label={t('AnelmaCompany:1057')}
                                onChange={(e) => setCarNumber(e)}
                                mode='int'
                                required
                                value={carNumber}
                                validator={validator}
                                disabled={props.readonly}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <ATextInput
                                lengthBoundaryName='AnelmaCompany:VehicleRegistrationNumber'
                                id='licence'
                                label={t('AnelmaCompany:1058')}
                                value={carLicence}
                                onChange={(v) => setCarLicence(v)}
                                required
                                validator={validator}
                                disabled={props.readonly}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <ATextInput
                                id='address-1'
                                label={t('AnelmaCompany:1070')}
                                maxLength={8000}
                                minLength={1}
                                onChange={(v) => setCarStreertAddress(v)}
                                required
                                validator={validator}
                                value={carStreeAddress}
                                disabled={props.readonly}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <AZipAndCityInput
                                id='address-2'
                                label={t('AnelmaCompany:1095')}
                                value={data.Address}
                                onChange={(v) => {
                                    setZip(v.Zip);
                                    setCity(v.City);
                                }}
                                required
                                validator={validator}
                                disabled={props.readonly}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <APhoneNumberInput
                                id='phone-number'
                                label={t('AnelmaCompany:1013')}
                                onChange={(v) => setCarPhoneNumber(v)}
                                required
                                value={carPhoneNumber}
                                validator={validator}
                                disabled={props.readonly}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item sm={6}>
                            <ADialogSubHeading>{t('AnelmaCompany:1060')}</ADialogSubHeading>
                        </Grid>
                    </Grid>

                    <Grid container>
                        {carCapacities.map((n, i) => (
                            <Grid item sm={6} key={i}>
                                <ANumberInput
                                    boundaryName={
                                        nameTitles.find((t) => t.type === n.Type)?.boundary ||
                                        undefined
                                    }
                                    id={`capacity-${n.Type}`}
                                    label={nameTitles.find((t) => t.type === n.Type)?.text || ''}
                                    mode='int'
                                    onChange={(v) => {
                                        carCapacities[i].Capacity = v;
                                        setCarCapacities([...carCapacities]);
                                    }}
                                    validator={validator}
                                    value={n.Capacity}
                                    disabled={props.readonly}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </ADialogContent>

                <ADialogActions buttons={footerButtons} />
            </ADialog>
        </>
    );
}
