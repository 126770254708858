// Core
import { groupUrl } from '../api/apiEndpoints';
import { IApiListResult, IApiResult } from '../interfaces/IGeneral';
import { IAnimalGroup, IMainGroup } from '../interfaces/IGroup';
import { GUIDType } from '../interfaces/types';
import axios from 'axios';

import ApiBase from './ApiBase';
import { IAnimalDeletionModel } from '../animals/Groups/components/GroupDialog';

class GroupsApi extends ApiBase {
    getGroups(farmGuid: GUIDType) {
        return axios.get<IApiListResult<IMainGroup>>(`${groupUrl}/${farmGuid}`).then((d) => d.data);
    }
    saveGroups(payload: any) {
        return this.post<IApiResult<IMainGroup>>(`${groupUrl}/add-group`, payload);
    }

    updateGroups(payload: any) {
        return this.put<IApiResult<IMainGroup>>(`${groupUrl}/modify-animal-group`, payload);
    }

    deleteGroup(groupId: GUIDType) {
        return this.delete<void>(`${groupUrl}/remove-group/${groupId}`);
    }

    deleteAnimalsFromGroup(selectedAnimals: IAnimalDeletionModel[]) {
        var payload = JSON.stringify({ idPairs: selectedAnimals });
        return this.post<void>(`${groupUrl}/remove-animals-from-group`, JSON.parse(payload));
    }

    setAnimalsInGroup(selectedAnimals: string[], subGroupId: string) {
        return this.post<IAnimalGroup>(
            `${groupUrl}/set-animals-groups/${subGroupId}`,
            selectedAnimals
        );
    }
}

export default new GroupsApi();
