// Libraries
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// Core
import { ResourceTextApplication } from '../../../core/resources';

// Interfaces
import { IConversationResult } from '../../../interfaces/ICommunication';
import { GUIDType } from '../../../interfaces/types';

// Store
import { useAppSelector } from '../../../store/hooks';

// API
import api from '../../../api/messaging/conversationApi';

// Messaging
import ConversationTable from './ConversationTable';
import IContactPersonRecipient from '../../general/interfaces/IContactPersonRecipient';

export interface IInboxProps {
    personCompanyContactPersons: IContactPersonRecipient[];
    snellmanContactPersons: IContactPersonRecipient[];
    conversationResult: IConversationResult | null;
    updateStatusForMessage: string;
    preSelectedConversationId: string | null;
}

export default function Inbox(props: IInboxProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCommunication']);
    const { enqueueSnackbar } = useSnackbar();

    const userData = useAppSelector((state) => state.userData.data);

    const [adminView, setAdminView] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [conversationsLoaded, setConversationsLoaded] = useState<boolean>(false);
    const [conversations, setConversations] = useState<IConversationResult[]>([]);

    useEffect(() => {
        Refresh();
    }, [adminView]);

    useEffect(() => {
        onConversationResultChange();
    }, [props.conversationResult]);

    useEffect(() => {
        if (conversations) setConversationsLoaded(true);
    }, [conversations]);

    const onConversationResultChange = () => {
        const result = props.conversationResult;

        if (
            result !== null &&
            result.ConversationId &&
            !conversations.find((_) => _.ConversationId === result.ConversationId)
        )
            setConversations((previousState) => [...previousState, result]);
    };

    const handleSnackbarMessage = (
        message: string,
        variant: 'success' | 'error' | 'warning' | 'info'
    ) => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };

    return (
        <>
            {conversationsLoaded && (
                <ConversationTable
                    adminView={adminView}
                    onUpdate={() => Refresh()}
                    conversations={conversations}
                    loading={isLoading}
                    personCompanyContactPersons={props.personCompanyContactPersons}
                    setAdminView={(av) => setAdminView(av)}
                    snellmanContactPersons={props.snellmanContactPersons}
                    updateStatusForMessage={props.updateStatusForMessage}
                    preSelectedConversationId={props.preSelectedConversationId}
                />
            )}
        </>
    );

    function Refresh() {
        setIsLoading(true);

        const promise = adminView
            ? api.getAdminConversationList(userData?.Id as GUIDType)
            : api.getUserConversationList(userData?.Id as GUIDType);

        promise.then((response) => {
            if (!response) {
                handleSnackbarMessage(t('AnelmaCommunication:1105'), 'error');
                setIsLoading(false);
                return;
            }

            setConversations(response.Items);
            setIsLoading(false);
        });
    }
}
