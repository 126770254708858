// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { clone } from 'ramda';

// MUI
import { Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

// Core
import auth from '../core/authorization';
import { ResourceTextApplication } from '../core/resources';
import { IFormValidator } from '../core/FormValidator';
import utils from '../core/utils';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogContentProps,
    ADialogTitle,
} from '../common/dialog';
import {
    AAddressesInputList,
    AEmailAddressInput,
    AInputContainer,
    ALanguageInput,
    ALoginNameInput,
    APhoneNumbersInputList,
    ARadioButtonGroupInputValue,
    ASwitch,
    ATextInput,
} from '../common/inputs';
import { ADefaultButton } from '../common/buttons';

// Interfaces
import { GUIDType, LanguageShortCodeType } from '../interfaces/types';
import {
    ILogin,
    IPersonDetails,
    IEmailAddress,
    IPhoneNumber,
    IAddress,
} from '../interfaces/IBusinessEntities';
import { PhoneNumberTypeEnum } from '../interfaces/enums';

// Feature
import {
    InactivationInfoForm,
    InactivationInfoFormProps,
} from './inactivation/InactivationInfoForm';
import personApi, { sendPasswordResetLinkEmail } from '../api/personApi';
import {
    InactivationConfirmDialog,
    InactivationConfirmDialogProps,
} from './inactivation/InactivationConfirmDialog';
import PersonsAccessRoles from './PersonsAccessRoles';
import updateDataBuilder from './updateDataBuilder';
import accessManagementApi from '../api/accessManagementApi';

export interface EditPersonFormProps {
    afterSave?: (person: IPersonDetails) => void;
    data: IPersonDetails;
    isInactive: boolean | null;
    hasTwoFactorAuthentication: boolean
    mode: 'create' | 'modify';
    onClose: Function;
    validator: IFormValidator;
    fromCompanyModal: boolean;
    readonly?: boolean;
}

export default function PersonFormDialog(props: EditPersonFormProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaPerson']);
    const { enqueueSnackbar } = useSnackbar();
    const { data, validator } = props;

    const [originalPerson, setOriginalPerson] = useState<IPersonDetails>(clone(data));
    const [addresses, setAddresses] = useState<IAddress[]>(data.Addresses);
    const [firstName, setFirstName] = useState<string>(data.FirstName);
    const [lastName, setLastName] = useState<string>(data.LastName);
    const [logins, setLogins] = useState<ILogin[]>(data.Logins);
    const [languageShortCode, setLanguageShortCode] = useState<LanguageShortCodeType>(
        data.LanguageShortCode
    );
    const [emailAddresses, setEmailAddresses] = useState<IEmailAddress[]>(data.EmailAddresses);
    const [phoneNumbers, setPhoneNumbers] = useState<IPhoneNumber[]>(data.PhoneNumbers);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [withLoginDetails, setWithLoginDetails] = useState<boolean>(false);

    useEffect(() => {
        if (props.mode === 'modify') {
            utils.addUniqueIdForArrayItems(data.Addresses);
            utils.addUniqueIdForArrayItems(data.EmailAddresses);
            utils.addUniqueIdForArrayItems(data.PhoneNumbers);
        }

        setOriginalPerson(clone(data));
    }, [data]);

    const [inactivationModalIsVisible, setInactivationModalIsVisible] = useState<boolean>(false);
    const [inactivationConfirmed, setInactivationConfirmed] = useState<boolean>(false);

    const showMessage = (message: string, variant: 'success' | 'error' | 'warning' | 'info') => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };

    const languageOnChange = (value: ARadioButtonGroupInputValue) => {
        setLanguageShortCode(value as LanguageShortCodeType);
    };

    const handleTextEmailChange = (v: string) => {
        let newEmail: IEmailAddress = {
            IsDefault: emailAddresses[0].IsDefault,
            Value: v,
        };

        let newArr = [...emailAddresses];
        newArr[0] = newEmail;
        setEmailAddresses(newArr);
    };

    const handleLoginOnChange = (v: string) => {
        let newLogin: ILogin = {
            Login: v,
        };

        let newArr = [...logins];
        newArr[0] = newLogin;
        setLogins(newArr);
    };

    const createPersonSaveObject = (): IPersonDetails => {
        return {
            Addresses: addresses,
            Aliases: data.Aliases,
            EffectiveTo: data.EffectiveTo,
            EmailAddresses: emailAddresses,
            FirstName: firstName,
            Id: data.Id,
            LanguageShortCode: languageShortCode,
            LastName: lastName,
            Logins: logins,
            PhoneNumbers: phoneNumbers,
        };
    };

    const createPerson = () => {
        if (validator.invalid) {
            return;
        }

        setIsLoading(true);

        personApi
            .createNewPerson(createPersonSaveObject())
            .then((response) => {
                if (!response) {
                    showMessage(t('AnelmaGeneral:1020'), 'error');
                    return;
                }

                showMessage(t('AnelmaGeneral:1018'), 'success');

                if (
                    withLoginDetails &&
                    response.Entity &&
                    response.Entity.Logins &&
                    response.Entity.Logins[0]?.Login &&
                    response.Entity.LanguageShortCode
                ) {
                    setTimeout(() => {
                        sendPasswordResetLinkEmail(
                            response.Entity.Logins[0].Login,
                            response.Entity.LanguageShortCode
                        );
                    }, 5000);
                }

                if (props.afterSave) props.afterSave(response.Entity);

                props.onClose();
            })
            .catch((err) => {
                showMessage(t('AnelmaGeneral:1020'), 'error');
                setIsLoading(false);
            })
            .finally(() => setIsLoading(false));
    };

    const editPerson = () => {
        if (validator.invalid) {
            return;
        }

        const updateData = updateDataBuilder(originalPerson, createPersonSaveObject());

        if (
            updateData.Additions.length === 0 &&
            updateData.Deletions.length === 0 &&
            updateData.Updates.length === 0
        ) {
            showMessage(t('AnelmaGeneral:1080'), 'info');
            return;
        }

        setIsLoading(true);
        personApi.updatePerson(updateData).then((response) => {
            if (!response) {
                setIsLoading(false);
                return;
            }

            showMessage(t('AnelmaGeneral:1025'), 'success');
            setIsLoading(false);
            if (props.afterSave) props.afterSave(response.Entity);
            props.onClose();
        });
    };

    const inactivationModalConfirm = (confirm: boolean) => {
        if (confirm) {
            setInactivationConfirmed(true);
        }
        setInactivationModalIsVisible(false);
    };

    useEffect(() => {
        if (inactivationConfirmed) {
            setIsLoading(true);

            if (!props.isInactive) {
                personApi.inactivatePerson(data.Id as GUIDType).then((response) => {
                    if (response?.Results.Errors.length) {
                        // enqueueSnackbar is set at Snellman.Anelma3.UI\reactapp\src\core\ApiResults.tsx
                        setIsLoading(false);
                        return;
                    }

                    showMessage(t('AnelmaPerson:1033'), 'success');
                    setIsLoading(false);
                    props.onClose();
                });
            } else if (props.isInactive) {
                personApi.activatePerson(data.Id as GUIDType).then((response) => {
                    if (!response) {
                        setIsLoading(false);
                        return;
                    }

                    showMessage(t('AnelmaPerson:1035'), 'success');
                    setIsLoading(false);
                    props.onClose();
                });
            }

            setInactivationConfirmed(false);
        }
    }, [inactivationConfirmed]);

    const activateOrInactivatePersonClick = () => {
        if (!inactivationModalIsVisible && !inactivationConfirmed) {
            setInactivationModalIsVisible(true);
            return;
        }
    };

    const resetPasswordEmail = () => {
        if (logins && logins[0]?.Login) {
            sendPasswordResetLinkEmail(logins[0].Login, languageShortCode);
        }
    };

    const footerButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () => {
                    if (props.validator.invalid) {
                        enqueueSnackbar(t('AnelmaGeneral:1030'), {
                            variant: 'error',
                        });
                        return;
                    }
                    props.mode === 'create' ? createPerson() : editPerson();
                },
                type: 'save',
                disabled: props.readonly,
            },
        ],
    };

    if (props.mode === 'modify' && auth.canInactivatePerson && !props.fromCompanyModal) {
        footerButtons.left.push({
            onClick: activateOrInactivatePersonClick,
            label: (props.isInactive as boolean) ? t('AnelmaPerson:1011') : t('AnelmaPerson:1010'),
            type: 'default',
            disabled: props.readonly,
        });
    }

    const inactivationFormProps: InactivationInfoFormProps = {
        data: data,
    };

    const dialogContentProps: ADialogContentProps = {
        size: 'md',
        isLoading: isLoading,
    };

    const inactivationConfirmDialogProps: InactivationConfirmDialogProps = {
        contentStr: !props.isInactive ? t('AnelmaPerson:1031') : t('AnelmaPerson:1032'),
        inactivate: !props.isInactive,
        title: !props.isInactive ? t('AnelmaPerson:1010') : t('AnelmaPerson:1011'),
        onConfirm: (confirm: boolean) => {
            inactivationModalConfirm(confirm);
        },
        readonly: props.readonly,
    };

    const removeTwoFactorAuthenticator = () => {
        if (data.Logins && data.Logins.length > 0) {
            let username = data.Logins[0].Login
            accessManagementApi.removeTwoFactorAuthenticatorAndPassword(username)
                .then((resp) => {
                    if (resp)
                        enqueueSnackbar(t('AnelmaPerson:1054'), {
                            variant: 'success',
                        })
                    else
                        enqueueSnackbar(t('AnelmaGeneral:1026'), {
                            variant: 'error',
                        });
                })
                .catch(() => {
                    enqueueSnackbar(t('AnelmaGeneral:1026'), {
                        variant: 'error',
                    });
                });

        }
    }

    return (
        <>
            <ADialog open={true}>
                <ADialogTitle>
                    {props.mode === 'create' ? t('AnelmaPerson:1015') : t('AnelmaPerson:1016')}
                </ADialogTitle>
                <ADialogContent {...dialogContentProps}>
                    {props.isInactive ? (
                        <InactivationInfoForm {...inactivationFormProps} />
                    ) : undefined}
                    <Grid container>
                        <Grid item sm={6}>
                            <ATextInput
                                id='first-name'
                                label={t('AnelmaPerson:1000')}
                                maxLength={64}
                                minLength={2}
                                onChange={(v) => setFirstName(v)}
                                required
                                validator={validator}
                                value={firstName}
                                disabled={props.readonly}
                            />
                        </Grid>

                        <Grid item sm={6}>
                            <ATextInput
                                id='last-name'
                                label={t('AnelmaPerson:1001')}
                                maxLength={64}
                                minLength={2}
                                onChange={(v) => setLastName(v)}
                                required
                                validator={validator}
                                value={lastName}
                                disabled={props.readonly}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item sm={12}>
                            <AAddressesInputList
                                addressTypeParam='AnelmaGeneral:AddressTypePerson'
                                data={addresses}
                                id='address-list'
                                onChange={(v) => setAddresses(v)}
                                required
                                validator={validator}
                                disabled={props.readonly}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item sm={6}>
                            <APhoneNumbersInputList
                                defaultPhoneNumberType={PhoneNumberTypeEnum.PersonHome}
                                data={phoneNumbers}
                                id='phone-number-list'
                                onChange={(v) => setPhoneNumbers(v)}
                                phoneNumberTypeParam='AnelmaGeneral:PhoneNumberTypePerson'
                                required
                                validator={validator}
                                disabled={props.readonly}
                            />
                        </Grid>

                        <Grid item sm={6}>
                            <AEmailAddressInput
                                id='email-address'
                                label={t('AnelmaPerson:1008')}
                                onChange={(v) => handleTextEmailChange(v)}
                                required
                                validator={validator}
                                value={emailAddresses[0].Value}
                                disabled={props.readonly}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                            <ALoginNameInput
                                disabled={!!data.Id || props.readonly}
                                id='login'
                                label={t('AnelmaPerson:1004')}
                                onChange={(v) => handleLoginOnChange(v)}
                                required
                                validator={validator}
                                value={logins[0]?.Login || ''}
                            />
                            {props.mode === 'create' ? (
                                <ASwitch
                                    id={'test'}
                                    label={t('AnelmaPerson:1026')}
                                    onChange={(v) => setWithLoginDetails(v as boolean)}
                                    validator={validator}
                                />
                            ) : (
                                ''
                            )}
                        </Grid>

                        <Grid item xs={6}>
                            <ALanguageInput
                                id='lang'
                                label={t('AnelmaPerson:1009')}
                                onChange={(value) => languageOnChange(value)}
                                validator={validator}
                                value={languageShortCode}
                                disabled={props.readonly}
                            />
                        </Grid>
                    </Grid>

                    {props.mode === 'modify' && logins[0]?.Login !== '' ? (
                        <>
                            <Grid container>
                                <Grid item xs={12}>
                                    <AInputContainer>
                                        <ADefaultButton
                                            icon={<SendIcon />}
                                            onClick={resetPasswordEmail}
                                            size='small'
                                            disabled={props.readonly}
                                        >
                                            {t('AnelmaPerson:1026')}
                                        </ADefaultButton>
                                    </AInputContainer>

                                    {auth.canRemoveTwoFactorAuthentication &&
                                        ( <AInputContainer>
                                            <ADefaultButton
                                                onClick={() => removeTwoFactorAuthenticator()}
                                                size='small'
                                                disabled={props.readonly || !props.hasTwoFactorAuthentication}
                                            >
                                                {t('AnelmaPerson:1053')}
                                            </ADefaultButton>
                                        </AInputContainer> )
                                    }
                                </Grid>
                            </Grid>

                            <PersonsAccessRoles login={logins && logins[0]?.Login} />
                        </>
                    ) : (
                        ''
                    )}
                </ADialogContent>

                <ADialogActions buttons={footerButtons} />
            </ADialog>

            {inactivationModalIsVisible ? (
                <InactivationConfirmDialog {...inactivationConfirmDialogProps} />
            ) : (
                ''
            )}
        </>
    );
}
