// Libraries
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';

// MUI
import { Grid } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

// Core
import FormValidator from '../../core/FormValidator';
import { ResourceTextApplication, ParameterValues, paramRepository } from '../../core/resources';

// Common
import { ASearchIconButton } from '../../common/buttons';
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../common/dialog';
import { ADropdown, ATextInput } from '../../common/inputs';
import { AGridColumns, ADataGrid } from '../../common/dataGrid';
import { TextWithTooltip } from '../../common/typography';

// Interfaces
import { ICompanyDetails, ICompanyName, IContactPerson } from '../../interfaces/IBusinessEntities';

// Store
// API
import companyApi from '../../api/companyApi';

// Feature - Feedback
import { SelectorConstants } from './constants/SelectorConstants';
import { resolveContactPersonRole } from '../general/helpers/helperFunctions';

export interface ISearchFarmProps {
    onClose: () => void;
    handleSelectedCompany: (_: ICompanyDetails) => void;
}

/** Rather specific farm search for feedback feature. Please, don't 'generalize' the component as it currently is.
 *
 */
export default function SearchFarm({ onClose, handleSelectedCompany }: ISearchFarmProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
        'AnelmaCompany',
    ]);

    const { enqueueSnackbar } = useSnackbar();

    const formValidator = new FormValidator();

    const kEventEnter = 'Enter';

    const [loading, setLoading] = useState<boolean>();
    const [selectedRecipientType, setSelectedRecipientType] = useState<string>('');
    const [recipientSearchTypes, setRecipientSearchTypes] = useState<ParameterValues>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [farmCompanyTitles, setFarmCompanyTitles] = useState<ParameterValues>([]);
    const [logisticsCompanyTitles, setLogisticsCompanyTitles] = useState<ParameterValues>([]);
    const [snellmanCompanyTitles, setSnellmanCompanyTitles] = useState<ParameterValues>([]);

    const [companySearchResults, setCompanySearchResults] = useState<ICompanyDetails[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<ICompanyDetails | null>(null);
    const [rowSelection, setRowSelection] = useState<GridRowSelectionModel>([]);

    useEffect(() => {
        paramRepository.load(['AnelmaCommunication', 'AnelmaGeneral']).then(() => {
            setRecipientSearchTypes(
                paramRepository
                    .resource('AnelmaCommunication', 'RecipientSearchTypes')
                    .filter((_) => Number(_.code) !== 1) // Omit Snellman text, used elsewhere
            );
            setFarmCompanyTitles(paramRepository.resource('AnelmaGeneral', 'FarmTitles'));
            setLogisticsCompanyTitles(paramRepository.resource('AnelmaGeneral', 'LogisticsTitles'));
            setSnellmanCompanyTitles(paramRepository.resource('AnelmaGeneral', 'SnellmanTitles'));
        });
    }, []);

    useEffect(() => {
        if (recipientSearchTypes.length > 0) setSelectedRecipientType('0');
    }, [recipientSearchTypes]);

    useEffect(() => {
        handleCompanySelection(rowSelection);
    }, [rowSelection]);

    const search = () => {
        if (!searchTerm) return;

        setLoading(true);

        companyApi.getCompanyFullDetailsByName(searchTerm).then((response) => {
            if (!response) {
                enqueueSnackbar(t('AnelmaCompany:1106'), {
                    variant: 'info',
                });
                setLoading(false);
                return;
            }

            setCompanySearchResults(response.Items);
            setLoading(false);
        });
    };

    const getrowData = () => {
        if (companySearchResults.length === 0) []; // For the data grid to work without any items, return empty array

        return companySearchResults.map((_) => mergeRight(_, { id: _.Id }));
    };

    const initializeColumns = () => {
        const columns: AGridColumns = [
            {
                field: 'Names',
                headerName: t('AnelmaCommunication:1070'),
                width: 250,
                renderCell: (params) => (
                    <TextWithTooltip text={resolveCompanyName(params.row.Names)} />
                ),
            },
            {
                field: 'ContactPersons',
                headerName: t('AnelmaCompany:1118'),
                width: 200,
                renderCell: (params) => (
                    <TextWithTooltip
                        text={resolveDefaultContactPersons(params.row.ContactPersons)}
                    />
                ),
            },
            {
                // Show default contact persons, no point in appending every person to the field in my opinion as long lists would not be really great even when displayed within tooltip
                field: 'ContactPersonTitle',
                headerName: t('AnelmaCompany:1015'),
                width: 100,
                renderCell: (params) => (
                    <TextWithTooltip text={resolveDefaultContactPersonTitle(params.row)} />
                ),
            },
        ];

        return columns;
    };

    const resolveCompanyName = (names: ICompanyName[]) => {
        if (names.length === 0) return '';

        return names.find((_) => _.IsDefault === true)?.Value ?? '';
    };

    const resolveDefaultContactPersons = (contactPersons: IContactPerson[]) => {
        if (contactPersons.length === 0) return '';

        return contactPersons.find((_) => _.IsDefault === true)?.Name ?? '';
    };

    const resolveDefaultContactPersonTitle = (companyDetails: ICompanyDetails) => {
        if (companyDetails.ContactPersons.length === 0) return '';

        const defaultContactPerson = companyDetails.ContactPersons.find(
            (_) => _.IsDefault === true
        );

        if (defaultContactPerson && defaultContactPerson?.Roles.length > 0)
            return resolveContactPersonRole(
                companyDetails.CompanyType,
                defaultContactPerson.Roles[0].Type,
                farmCompanyTitles,
                logisticsCompanyTitles,
                snellmanCompanyTitles
            );

        return '';
    };

    const handleCompanySelection = (selection: GridRowSelectionModel) => {
        selection.length > 0
            ? setSelectedCompany(
                  companySearchResults.find((_) => _.Id === selection[0].toString()) ?? null
              )
            : setSelectedCompany(null);
    };

    const footerActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: onClose,
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () => {
                    if (selectedCompany) {
                        handleSelectedCompany(selectedCompany);
                        onClose();
                    }
                },
                type: 'save',
                disabled: loading || selectedCompany === null,
            },
        ],
    };

    return (
        <>
            <ADialog open onClose={onClose}>
                <ADialogTitle>{t('AnelmaCommunication:1166')}</ADialogTitle>
                <ADialogContent size='md'>
                    <Grid container direction='row' alignItems='stretch'>
                        <Grid item sm={2}>
                            <ADropdown
                                id={`${SelectorConstants.FeedbackDialog}-search-farm-type-selection`}
                                label={t('AnelmaCompany:1000')}
                                items={recipientSearchTypes.map((_) => {
                                    return {
                                        value: _.code,
                                        text: _.text,
                                    };
                                })}
                                onChange={(_) => setSelectedRecipientType(String(_))}
                                style={{ minWidth: '100px' }}
                                validator={formValidator}
                                value={selectedRecipientType}
                            />
                        </Grid>
                        <Grid item sm={8}>
                            <ATextInput
                                id={`${SelectorConstants.FeedbackDialog}-search-farm-search-input-field`}
                                label={t('AnelmaCommunication:1075')}
                                onChange={(_) => setSearchTerm(_)}
                                onKeyUp={(e) => {
                                    if (e.key === kEventEnter) search();
                                }}
                                validator={formValidator}
                                value={searchTerm}
                            />
                        </Grid>

                        <Grid item sm={2}>
                            <ASearchIconButton
                                data-robot-id={`${SelectorConstants.FeedbackDialog}-search-farm-btn`}
                                style={{ marginTop: '12px' }}
                                onClick={() => search()}
                                type='action'
                                tooltip={t('AnelmaCommunication:1166')}
                            ></ASearchIconButton>
                        </Grid>
                    </Grid>

                    <ADataGrid
                        columns={initializeColumns()}
                        data-robot-id={SelectorConstants.SearchFarmDataGrid}
                        enableRowNumbers
                        rows={getrowData()}
                        onSelectionModelChange={(_: GridRowSelectionModel) => {
                            _.length > 1
                                ? setRowSelection(_.filter((s) => !new Set(rowSelection).has(s)))
                                : setRowSelection(_);
                        }}
                        enablePagination
                        loading={loading}
                    />
                </ADialogContent>

                <ADialogActions buttons={footerActionButtons} />
            </ADialog>
        </>
    );
}
