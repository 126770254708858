// Libraries
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// MUI
import { ListItem, ListItemIcon, ListItemText, Link as ExternalLink } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import PersonIcon from '@mui/icons-material/Person';
import HouseIcon from '@mui/icons-material/House';
import KeyIcon from '@mui/icons-material/VpnKey';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import Feedback from '@mui/icons-material/Feedback';

// Core
import auth from '../../../../core/authorization';
import { ResourceTextApplication } from '../../../../core/resources';
import { baseUrl } from '../../../../core/constants';

// Store
import { useAppSelector } from '../../../../store/hooks';

// Feature
import './userMenuLinks.scss';
import { RoutePaths } from '../../../../interfaces/enums';
import { ReportingApiUnintercepted } from '../../../../api/reportingApiUnintercepted';
import { Folder, FolderOpen } from '@mui/icons-material';

export interface UserMenuLinksProps {
    closeUserMenu: () => void;
}

export default function UserMenuLinks(props: UserMenuLinksProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaCommunication',
        'AnelmaGeneral',
        'AnelmaLayout',
    ]);
    const qlikUninterceptor = new ReportingApiUnintercepted();

    const loginStatus = useAppSelector((state) => state.loginStatus.data);

    const handleQlikLogout = () => {
        qlikUninterceptor.logout();
    };

    return (
        <div className='an-user-menu-links' data-robot-id='user-menu'>
            {auth.canViewBasicFeatures && (
                <ListItem data-robot-id='user-menu-list-item'>
                    <ListItemIcon data-robot-id='user-menu-list-item-icon'>
                        <PersonIcon data-robot-id='user-menu-list-item-person' />
                    </ListItemIcon>

                    <ListItemText data-robot-id={'user-menu-list-item-text'}>
                        <Link
                            to={RoutePaths.GeneralMyInfo}
                            onClick={() => props.closeUserMenu()}
                            data-robot-id={'user-menu-list-item-link'}
                        >
                            {t('AnelmaGeneral:1121')}
                        </Link>
                    </ListItemText>
                </ListItem>
            )}

            {auth.canViewFarmInfo && (
                <ListItem data-robot-id={'farminfo-menu-list-item'}>
                    <ListItemIcon data-robot-id={'farminfo-menu-list-item-icon'}>
                        <HouseIcon data-robot-id={'farminfo-menu-list-item-house'} />
                    </ListItemIcon>

                    <ListItemText data-robot-id={'farminfo-menu-list-item-text'}>
                        <Link
                            to={RoutePaths.GeneralFarmInfo}
                            onClick={() => props.closeUserMenu()}
                            data-robot-id={'farminfo-menu-list-item-link'}
                        >
                            {t('AnelmaLayout:1026')}
                        </Link>
                    </ListItemText>
                </ListItem>
            )}

            {auth.canViewFarmDocuments && (
                <ListItem data-robot-id='farm-documents-menu-list-item'>
                    <ListItemIcon data-robot-id='farm-documents-menu-list-item-icon'>
                        <Folder data-robot-id='farm-documents-menu-list-item-house' />
                    </ListItemIcon>

                    <ListItemText data-robot-id='farm-documents-menu-list-item-text'>
                        <Link
                            to={RoutePaths.FarmDocuments}
                            onClick={() => props.closeUserMenu()}
                            data-robot-id='farm-documents-menu-list-item-link'
                        >
                            {t('AnelmaLayout:1077')}
                        </Link>
                    </ListItemText>
                </ListItem>
            )}

            {auth.canViewMessages && (
                <ListItem data-robot-id={'message-menu-list-item'}>
                    <ListItemIcon data-robot-id={'message-menu-list-item-icon'}>
                        <MessageIcon data-robot-id={'message-menu-list-item-message'} />
                    </ListItemIcon>

                    <ListItemText data-robot-id={'message-menu-list-item-text'}>
                        <Link
                            data-robot-id={'message-menu-list-item-link'}
                            to={RoutePaths.GeneralMessaging}
                            onClick={() => props.closeUserMenu()}
                        >
                            {t('AnelmaCommunication:1049')}
                        </Link>
                    </ListItemText>
                </ListItem>
            )}

            {auth.canViewFeedback && (
                <ListItem data-robot-id={'feedback-menu-list-item'}>
                    <ListItemIcon data-robot-id={'feedback-menu-list-item-icon'}>
                        <Feedback data-robot-id={'feedback-menu-list-item-feedback'} />
                    </ListItemIcon>

                    <ListItemText data-robot-id={'feedback-menu-list-item-text'}>
                        <Link
                            to={RoutePaths.GeneralFeedback}
                            onClick={() => props.closeUserMenu()}
                            data-robot-id={'feedback-menu-list-item-link'}
                        >
                            {t('AnelmaLayout:1013')}
                        </Link>
                    </ListItemText>
                </ListItem>
            )}

            <ListItem data-robot-id={'changepassword-menu-list-item'}>
                <ListItemIcon data-robot-id={'changepassword-menu-list-item-icon'}>
                    <KeyIcon data-robot-id={'changepassword-menu-list-key'} />
                </ListItemIcon>

                <ListItemText data-robot-id={'changepassword-menu-list-item-text'}>
                    <ExternalLink
                        data-robot-id={'changepassword-menu-list-item-link'}
                        href={`${loginStatus.changePasswordUrl}?returnUrl=${encodeURIComponent(
                            window.location.href
                        )}`}
                    >
                        {t('AnelmaGeneral:1119')}
                    </ExternalLink>
                </ListItemText>
            </ListItem>

            <ListItem data-robot-id={'logout-menu-list-item'}>
                <ListItemIcon data-robot-id={'logout-menu-list-item-icon'}>
                    <LogoutIcon data-robot-id={'logout-menu-list-item-logout'} />
                </ListItemIcon>

                <ListItemText data-robot-id={'logout-menu-list-item-text'}>
                    <ExternalLink
                        href={`${baseUrl}/logout`}
                        onClick={() => handleQlikLogout()}
                        data-robot-id={'logout-menu-list-item-link'}
                    >
                        {t('AnelmaGeneral:1120')}
                    </ExternalLink>
                </ListItemText>
            </ListItem>
        </div>
    );
}
