function internalBaseUrl(): string {
    const location = window.location;
    const baseUrl = `${location.protocol}//${location.host}`;
    if (location.host === 'localhost')
        return `${baseUrl}/${window.location.pathname.split('/')[1] || ''}`;
    if (location.host === 'localhost:3000') {
        return process.env.REACT_APP_API_URI as string;
    }
    return baseUrl;
}

const baseUrl = internalBaseUrl();

const dayFormat = 'DD.MM.YYYY';
const yearMonthDateFormat = 'YYYY-MM-DD';
const yearMonthDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export { baseUrl, dayFormat, yearMonthDateFormat, yearMonthDateTimeFormat };
