// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

// MUI
import { Container, Grid } from '@mui/material';

// Core
import { paramRepository, ResourceTextApplication } from '../../core/resources';
import utils from '../../core/utils';

// Interfaces
import {
    IProductionPlan,
    IProductionPlanUserAccess,
    IProductionPlanSummary,
    IFarmSupplier,
    IProductionPlanNotificationLocationData,
} from '../../interfaces/IProductionPlan';
import { IFarmDetails } from '../../interfaces/IBusinessEntities';

// Store
import { useAppSelector } from '../../store/hooks';

// API
import productionPlanApi from '../../api/productionPlanApi';
import companyApi from '../../api/companyApi';

// Common
import { ViewTitle } from '../../common/typography';
import ViewLoader from '../../common/ViewLoader';
import ADefaultButton from '../../common/buttons/ADefaultButton';
import { Context } from '../../common/contextSelector/ContextSelector';

// Feature
import ProductionPlan from './ProductionPlan';
import ProductionPlanSummaryDataGrid from './data-grid/ProductionPlanSummaryDataGrid';
import { canCreate, canUpdate, isAdminUser } from './helpers/ProductionPlanAccessRights';
import '../shared/styles/sharedStyles.scss';

enum IProductionPlanBooleanFieldEnum {
    BuysCalfsDirectlyFromOtherFarm = 'BuysCalfsDirectlyFromOtherFarm',
    NoSlaughterBovineSold = 'NoSlaughterBovineSold',
    NoTransmissionBovineSold = 'NoTransmissionBovineSold',
}

export default function ProductionPlanList() {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaBovine',
        'AnelmaLayout',
        'AnelmaCattleProductionPlan',
        'AnelmaCompany',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const context = useAppSelector((state) => state.context);
    const location = useLocation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedPlan, setSelectedPlan] = useState<IProductionPlan | undefined | null>(null);
    const [planIsReadOnly, setPlanIsReadOnly] = useState<boolean>(false);
    const [farmDetails, setFarmDetails] = useState<IFarmDetails>();
    const [planSummaries, setPlanSummaries] = useState<IProductionPlanSummary[] | null>(null);
    const [userAccess, setUserAccess] = useState<IProductionPlanUserAccess | undefined | null>(
        null
    );
    const [canCreatePlan, setCanCreatePlan] = useState<boolean>(false);
    const [productionPlanStart, setProductionPlanStart] = useState<Date>();
    const [productionPlanTargetYear, setProductionPlanTargetYear] = useState<number | null>(null);
    const [locationData, setLocationData] = useState<IProductionPlanNotificationLocationData>(
        initializeLocationData()
    );
    const [isNew, setIsNew] = useState<boolean>(false);

    useEffect(() => {
        paramRepository.load(['AnelmaBovine']).then(() => {
            const ppStart = paramRepository.resource('AnelmaBovine', 'ProductionPlanStart');
            if (ppStart && ppStart.length > 0) {
                setProductionPlanStart(new Date(ppStart[ppStart.length - 1].code));
            }
        });

        setUserAccess({
            IsAdmin: isAdminUser(),
            CanUpdate: canUpdate(),
            CanCreate: canCreate(),
        });
    }, []);

    useEffect(() => {
        if (context.data.currentContext) {
            initializeFarmDetails(context.data.currentContext);
        } else setFarmDetails(undefined);
    }, [context]);

    useEffect(() => {
        if (!userAccess?.IsAdmin) loadPlans();
    }, [farmDetails]);

    useEffect(() => {
        if (userAccess?.IsAdmin) loadPlans();
    }, [userAccess]);

    useEffect(() => {
        setCanCreatePlan(checkCanCreatePlan());
    }, [planSummaries, userAccess, farmDetails]);

    useEffect(() => {
        if (canCreatePlan && locationData.DisplayProductionPlanFormInstantly) createNewPlan();
    }, [canCreatePlan]);

    /** Cannot create plan when:
     *  - logged in user is producer user
     *  - selected farm is inactivated
     *  - there are already production plan for the selected farm for the target year
     *
     *  Additional information from JIRA ticket: http://jira.mtech.fi/browse/AN-1771
     */
    const checkCanCreatePlan = () => {
        if (!userAccess) return false;
        if ((userAccess.IsAdmin || userAccess.CanCreate) && planSummaries && farmDetails) {
            if (utils.isFarmInactivated(farmDetails)) return false;

            const now = new Date().getTime();
            if (productionPlanStart) {
                if (
                    now < productionPlanStart.getTime() ||
                    moment().subtract(1, 'year').format('YYYY') ===
                        moment(productionPlanStart).format('YYYY')
                ) {
                    const targetYear = moment().year();
                    setProductionPlanTargetYear(targetYear);
                    if (
                        planSummaries.some(
                            (_) =>
                                (farmDetails.Id as string) === _.FarmId &&
                                _.TargetYear === targetYear
                        )
                    )
                        return false;
                } else if (now >= productionPlanStart.getTime()) {
                    const targetYear = moment().year() + 1;
                    setProductionPlanTargetYear(targetYear);
                    if (
                        planSummaries.some(
                            (_) =>
                                (farmDetails.Id as string) === _.FarmId &&
                                _.TargetYear === targetYear
                        )
                    )
                        return false;
                }
            }
            return true;
        }
        return false;
    };

    const initializeFarmDetails = (context: Context) => {
        setIsLoading(true);
        const companyByContext = companyApi
            .getCompanyByContext(context.context)
            .then((response) => {
                setFarmDetails(response?.Entity as IFarmDetails);
            });
        Promise.all([companyByContext]).then(() => {
            setIsLoading(false);
        });
    };

    const createNewPlan = () => {
        if (!canCreatePlan) return;
        if (farmDetails && farmDetails.Id && productionPlanTargetYear !== null) {
            if (farmDetails.ProductionLines.length === 0) {
                enqueueSnackbar(t('AnelmaCompany:1122'), {
                    variant: 'error',
                });
                return;
            }

            setIsLoading(true);
            productionPlanApi
                .getTemplate(farmDetails.Id, productionPlanTargetYear)
                .then((response) => {
                    setIsLoading(false);
                    if (!response) {
                        enqueueSnackbar(t('AnelmaCattleProductionPlan:1085'), {
                            variant: 'error',
                        });
                        return;
                    }
                    if (selectedPlan && productionPlanStart && productionPlanTargetYear) {
                        response.Entity.TargetYear = productionPlanTargetYear;
                    }

                    response.Entity.NewSalmonellaSampleOrdered = null;
                    response.Entity.SoftSleepingPad = null;

                    setPlanIsReadOnly(false);
                    setIsNew(true);
                    setSelectedPlan(response.Entity);
                });
        }
    };

    const openPlan = (item: IProductionPlanSummary, readonly: boolean) => {
        setIsLoading(true);
        productionPlanApi.getPlan(item.Id).then((response) => {
            setIsLoading(false);
            if (!response) {
                enqueueSnackbar(t('AnelmaCattleProductionPlan:1085'), {
                    variant: 'error',
                });
                return;
            }
            setPlanIsReadOnly(readonly);
            setIsNew(false);
            setSelectedPlan(response.Entity);
        });
    };

    const deletePlan = (item: IProductionPlanSummary) => {
        if (!planSummaries) return;
        setIsLoading(true);
        productionPlanApi.deletePlan(item.Id).then((response) => {
            setIsLoading(false);
            if (!response) {
                enqueueSnackbar(t('AnelmaCattleProductionPlan:1086'), {
                    variant: 'error',
                });
                return;
            }
            setPlanSummaries([...planSummaries.filter((x) => x.Id !== item.Id)]);
        });
    };

    const loadPlans = () => {
        setIsLoading(true);
        if (userAccess?.IsAdmin)
            productionPlanApi.getAllPlans().then((response) => {
                if (!response) {
                    enqueueSnackbar(t('AnelmaCattleProductionPlan:1085'), {
                        variant: 'error',
                    });
                    return;
                }
                setPlanSummaries(response.Items);
                setIsLoading(false);
            });
        else if (farmDetails && farmDetails.Id)
            productionPlanApi.getPlans(farmDetails.Id).then((response) => {
                if (!response) {
                    enqueueSnackbar(t('AnelmaCattleProductionPlan:1085'), {
                        variant: 'error',
                    });
                    return;
                }
                setPlanSummaries(response.Items);
                setIsLoading(false);
            });
    };

    const handleSellerFarmsChange = (farms: IFarmSupplier[]) => {
        if (selectedPlan) {
            const current = selectedPlan;
            current.SellingFarms = farms;
            setSelectedPlan(current);
        }
    };

    const handleChangeProductionEndYear = (_: number) => {
        if (selectedPlan) {
            const current = selectedPlan;
            current.ProductionEndYear = _;
            setSelectedPlan(current);
        }
    };

    const handleBooleanValueChange = (_: boolean, field: IProductionPlanBooleanFieldEnum) => {
        if (selectedPlan) {
            const current = selectedPlan;
            switch (field) {
                case IProductionPlanBooleanFieldEnum.BuysCalfsDirectlyFromOtherFarm:
                    current.BuysCalfsDirectlyFromOtherFarm = _;
                    setSelectedPlan(current);
                    break;
                case IProductionPlanBooleanFieldEnum.NoSlaughterBovineSold:
                    current.NoSlaughterBovineSold = _;
                    setSelectedPlan(current);
                    break;
                case IProductionPlanBooleanFieldEnum.NoTransmissionBovineSold:
                    current.NoTransmissionBovineSold = _;
                    setSelectedPlan(current);
                    break;
                default:
                    throw new Error(`Unhandled IProductionPlanBooleanFieldEnum case ${field}`);
            }
        }
    };

    function initializeLocationData() {
        return location.state !== null
            ? location.state.options
            : { DisplayProductionPlanFormInstantly: false };
    }

    if (!userAccess || !farmDetails) return <ViewLoader />;
    return (
        <Container id='anelma-animals-production-plan-list-container'>
            <ViewTitle>{t('AnelmaLayout:1075')}</ViewTitle>
            {userAccess.CanCreate && (
                <Grid container direction='row'>
                    <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
                    <Grid
                        item
                        className='animal-announcement-button-content-position'
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                    >
                        <ADefaultButton
                            onClick={createNewPlan}
                            children={t('AnelmaCattleProductionPlan:1052')}
                            disabled={!canCreatePlan || isLoading}
                        ></ADefaultButton>
                    </Grid>
                </Grid>
            )}
            {(planSummaries && (
                <ProductionPlanSummaryDataGrid
                    data={planSummaries}
                    access={userAccess}
                    isLoading={isLoading}
                    onPlanOpen={openPlan}
                    onPlanDelete={deletePlan}
                />
            )) || <ViewLoader />}
            {selectedPlan && (
                <ProductionPlan
                    data={selectedPlan}
                    access={userAccess}
                    farmDetails={!userAccess.IsAdmin ? farmDetails : null}
                    readonly={planIsReadOnly}
                    onClose={(reload) => {
                        setSelectedPlan(null);
                        if (reload) loadPlans();
                        if (locationData.DisplayProductionPlanFormInstantly)
                            setLocationData({ DisplayProductionPlanFormInstantly: false });
                    }}
                    handleSellerFarmsChange={(_: IFarmSupplier[]) => handleSellerFarmsChange(_)}
                    handleBuyCalfsChange={(_: boolean) =>
                        handleBooleanValueChange(
                            _,
                            IProductionPlanBooleanFieldEnum.BuysCalfsDirectlyFromOtherFarm
                        )
                    }
                    changeProductionEndYear={(_: number) => handleChangeProductionEndYear(_)}
                    handleNoSlaughterBovineSoldChange={(_: boolean) =>
                        handleBooleanValueChange(
                            _,
                            IProductionPlanBooleanFieldEnum.NoSlaughterBovineSold
                        )
                    }
                    handleChangeNoTransmissionBovineSoldChange={(_: boolean) =>
                        handleBooleanValueChange(
                            _,
                            IProductionPlanBooleanFieldEnum.NoTransmissionBovineSold
                        )
                    }
                    isNew={isNew}
                />
            )}
        </Container>
    );
}
