// Libraries
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { ListItem, ListItemIcon, Grid, List } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { ALabel } from '../../common/inputs';
import { TextWithTooltip } from '../../common/typography';
import { ADownloadIconButton } from '../../common/buttons';

// Interfaces
// Store
// API
import documentApi from '../../api/documentApi';

// Feature - Documents
import { IBasicDocumentInfo } from '../../documents/IDocuments';

// Feature - Feedback
import { FeedbackDialogContext } from '../context/feedback/IFeedbackDialogContext';

export default function FeedbackAttachments() {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCommunication']);

    const { feedback } = useContext(FeedbackDialogContext);

    const getAttachmentName = (attachment: IBasicDocumentInfo) => {
        if (attachment.DisplayName !== null || attachment.DisplayName !== '')
            return attachment.FileName;

        return attachment.DisplayName;
    };

    const generateAttachmentList = (attachments: IBasicDocumentInfo[]) => {
        return attachments.map((_: IBasicDocumentInfo) => (
            <ListItem key={_.Id}>
                <ListItemIcon>
                    <ADownloadIconButton
                        onClick={() => documentApi.downloadFile(_.FileType, _.Id)}
                        tooltip='download'
                    />
                </ListItemIcon>
                <TextWithTooltip text={getAttachmentName(_)} />
            </ListItem>
        ));
    };

    return feedback && feedback.Attachments.length > 0 ? (
        <>
            <Grid item sm={12} style={{ marginInline: 40 }}>
                <ALabel>{t('AnelmaCommunication:1115')}</ALabel>
                <List dense>{generateAttachmentList(feedback.Attachments)}</List>
            </Grid>
        </>
    ) : (
        <></>
    );
}
