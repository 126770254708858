import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { IAnnouncementImport } from '../../../../interfaces/IAnnouncement';
import { AnnouncementTypeEnum, DialogModeEnum } from '../../../../interfaces/enums';
import {
    IAnimalSummary,
    IAnimalSummaryExtraInfo,
    IFarmAnimal,
} from '../../../../interfaces/IAnimal';
import { IFarmDetails } from '../../../../interfaces/IBusinessEntities';
import { ITiltuApiResult } from '../../../../interfaces/IGeneral';
import { ResourceTextApplication } from '../../../../core/resources';

import BovineSlaughterAnnouncement from '../bovine-slaughter-announcement/BovineSlaughterAnnouncement';
import BovineIntermediationAnnouncement from '../bovine-intermediation-announcement/BovineIntermediationAnnouncement';

export interface IAnnouncementImportProps {
    data: IAnnouncementImport;
    farmData: IFarmDetails | null;
    animalData: IAnimalSummaryExtraInfo[];

    onClose: () => void;
    processAnnouncementSave: (
        data: ITiltuApiResult<any>,
        type: AnnouncementTypeEnum,
        holdingSiteId: string
    ) => void;
    mapUpdatedSalesTypeToAllAnimalsData: (data: IFarmAnimal[]) => void;
}

export default function AnnouncementImport(props: IAnnouncementImportProps) {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine']);

    const [selectedAnimals, setSelectedAnimals] = useState<IAnimalSummary[]>([]);

    useEffect(() => {
        if (!props.data) return;
        if (!props.farmData) return;
        if (!props.animalData) return;
        if (props.farmData.Aliases.find((x) => x.Type === 3)?.Value !== props.data.FarmId) return;

        const animals = [];
        let animalIsMissing = false;

        for (let euIdentifier of props.data.EUIdentifiers) {
            const animal = props.animalData.find((x) => x.EuIdentifier == euIdentifier);

            if (animal) {
                animals.push(animal as IAnimalSummary);
            } else {
                animalIsMissing = true;
            }
        }

        if (animalIsMissing)
            enqueueSnackbar(t('AnelmaBovine:1161'), {
                variant: 'error',
            });

        if (animals.length > 0) {
            setSelectedAnimals(animals);
        }
    }, [props.data, props.farmData, props.animalData]);

    const onClose = () => {
        window.location.href = '/animals/list';
        props.onClose();
    };

    const processAnnouncementSave = (
        data: ITiltuApiResult<any>,
        type: AnnouncementTypeEnum,
        holdingSiteId: string
    ) => {
        props.processAnnouncementSave(data, type, holdingSiteId);
        if (type !== AnnouncementTypeEnum.SlaughterWithChainInformation) onClose();
    };

    switch (props.data?.AnimalAnnouncementType) {
        default:
            return <></>;
        case AnnouncementTypeEnum.Slaughter:
            return (
                <BovineSlaughterAnnouncement
                    data={null}
                    onClose={onClose}
                    mode={DialogModeEnum.Create}
                    selectedAnimals={selectedAnimals.length == 0 ? null : selectedAnimals}
                    selectedHoldingSites={props.data.HoldingPlaces}
                    animals={props.animalData.length == 0 ? null : props.animalData}
                    processAnnouncementSave={processAnnouncementSave}
                    mapUpdatedSalesTypeToAllAnimalsData={props.mapUpdatedSalesTypeToAllAnimalsData}
                    loading={false}
                    dataImportedViaMmfi={true}
                    readOnly={false}
                />
            );
        case AnnouncementTypeEnum.Intermediation:
            return (
                <BovineIntermediationAnnouncement
                    data={null}
                    onClose={onClose}
                    mode={DialogModeEnum.Create}
                    selectedAnimals={selectedAnimals.length == 0 ? null : selectedAnimals}
                    selectedHoldingSites={props.data.HoldingPlaces}
                    animals={props.animalData.length == 0 ? null : props.animalData}
                    processAnnouncementSave={processAnnouncementSave}
                    mapUpdatedSalesTypeToAllAnimalsData={props.mapUpdatedSalesTypeToAllAnimalsData}
                    loading={false}
                    dataImportedViaMmfi={true}
                    readOnly={false}
                />
            );
    }
}
