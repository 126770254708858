// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../../../../core/resources';
import FormValidator from '../../../../core/FormValidator';

// Common
import {
    ADialog,
    ADialogTitle,
    ADialogContent,
    ADialogActions,
    ADialogButtonsProp,
} from '../../../../common/dialog';

// Interfaces
import { DialogModeEnum } from '../../../../interfaces/enums';
import { IAnimalAnnouncementBatch, ICollectWeek } from '../../../../interfaces/IAnnouncement';

// Store
// API
// Feature
import SecondaryDialogDataGrid from '../../../shared/components/data-grid/SecondaryDialogDataGrid';
import { SecondaryDialogTypeEnum } from '../../../shared/types/enum';
import { IModifyCollectWeekDialogProps } from '../../../shared/types/propTypes';
import { ALabel, ADropdown, ATextInput } from '../../../../common/inputs';
import { Strings } from '../../../shared/constants/strings';
import {
    generateCollectWeekSequence,
    initializeCollectWeekStart,
    mapCollectWeeksForDropdown,
} from '../../helpers/date';
import {
    collectWeekStartNotValid,
    collectWeekStartHasValue,
    collectWeekEndHasValue,
    collectWeekEndNotValid,
    collectWeekEditAllowed,
} from '../../helpers/collectWeek';
import '../../styles/announcementStyles.scss';

export default function ModifyCollectWeekDialog(props: IModifyCollectWeekDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine']);
    const validator = new FormValidator();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>();
    const [isCollectWeekEditAllowed, setIsCollectWeekEditAllowed] = useState<boolean>(false);
    const [collectWeekStart, setCollectWeekStart] = useState<ICollectWeek>({ Year: -1, Week: -1 });
    const [collectWeekEnd, setCollectWeekEnd] = useState<ICollectWeek>({ Year: -1, Week: -1 });
    const [collectWeeks, setCollectWeeks] = useState<ICollectWeek[]>([]);
    const [info, setInfo] = useState<string>('');
    const [batches, setBatches] = useState<IAnimalAnnouncementBatch[] | undefined>();

    const footerActionButtons: ADialogButtonsProp = {
        left: [{ onClick: () => props.onClose(true), type: 'cancel' }],
        right: [
            {
                onClick: () => modifyCollectWeek(),
                type: 'ok',
                disabled:
                    collectWeekStartHasValue(collectWeekStart) &&
                    collectWeekEndHasValue(collectWeekEnd)
                        ? false
                        : true,
            },
        ],
    };

    useEffect(() => {
        initializeCollectWeeks(initializeCollectWeekStart(props.announcementType).Week);

        // If all selected batches have the same week start/end/info, pre-select them.
        if (props && props.batches && props.batches.length > 0) {
            const selectedBatches = mapAnimalsFromBatches(props.batches);

            // Check that none of the selected animals disallow collection week editing.
            const canEditCollectWeek = !!!selectedBatches.find(
                (batch) => !collectWeekEditAllowed(batch.Animals[0], batch.BatchStatus)
            );

            setIsCollectWeekEditAllowed(canEditCollectWeek);
            setBatches(selectedBatches);

            if (canEditCollectWeek && selectedBatches.length > 0) {
                if (selectedBatches.length === 1) {
                    setCollectWeekStart(selectedBatches[0].CollectWeekStart);
                    setCollectWeekEnd(selectedBatches[0].CollectWeekEnd);
                    if (selectedBatches[0].BatchInfo !== '-') setInfo(selectedBatches[0].BatchInfo);
                }
            }
        }
    }, [props.batches]);

    useEffect(() => {
        if (collectWeekStartNotValid(collectWeekStart, collectWeekEnd)) {
            if (
                collectWeekStartHasValue(collectWeekStart) &&
                collectWeekEndHasValue(collectWeekEnd)
            ) {
                enqueueSnackbar(t('AnelmaAnimalAnnouncement:1006'), {
                    variant: 'error',
                });
                setCollectWeekStart({ Year: -1, Week: -1 });
            }
        }
    }, [collectWeekStart]);

    useEffect(() => {
        if (collectWeekEndNotValid(collectWeekEnd, collectWeekStart)) {
            if (collectWeekEndHasValue(collectWeekEnd)) {
                enqueueSnackbar(t('AnelmaAnimalAnnouncement:1007'), {
                    variant: 'error',
                });
                setCollectWeekEnd({ Year: -1, Week: -1 });
            }
        }
    }, [collectWeekEnd]);

    // Generate collect week sequences for the current and next year
    const initializeCollectWeeks = (from: number) => {
        setCollectWeeks(
            generateCollectWeekSequence(
                from,
                moment().isoWeeksInYear() - from,
                moment().isoWeekYear()
            )
        );
        if (moment().add(12, 'months').year() === moment().add(1, 'y').year()) {
            setCollectWeeks((previousState) => [
                ...previousState,
                ...generateCollectWeekSequence(
                    1,
                    moment().add(12, 'months').isoWeek(),
                    moment().isoWeekYear() + 1
                ),
            ]);
        }
    };

    const mapAnimalsFromBatches = (
        batches: IAnimalAnnouncementBatch[]
    ): IAnimalAnnouncementBatch[] => {
        if (!batches || batches.length === 0 || !props.animals || props.animals.length === 0)
            return [];

        return [...batches].filter((batch) =>
            props.animals.map((a) => a.AnimalId)?.includes(batch.Animals[0]?.AnimalId)
        );
    };

    const modifyCollectWeek = () => {
        setIsLoading(true);
        if (validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            setIsLoading(false);
            return;
        }
        if (batches) {
            mapChangedBatchData([...batches]);
            props.onClose();
        }
        setIsLoading(false);
    };

    const mapChangedBatchData = (batches: IAnimalAnnouncementBatch[]) => {
        for (const batch of batches) {
            if (info !== '' && info != '-') batch.BatchInfo = info;
            if (isCollectWeekEditAllowed) {
                batch.CollectWeekStart = collectWeekStart;
                batch.CollectWeekEnd = collectWeekEnd;
            }
        }
        return batches;
    };

    return (
        <>
            <ADialog
                open
                onClose={() => props.onClose(true)}
                disableEnforceFocus
                data-robot-id='modifycollectweek'
            >
                <ADialogTitle>{t('AnelmaBovine:1147')}</ADialogTitle>

                <ADialogContent size={'lg'} isLoading={isLoading}>
                    <Grid container direction='row'>
                        <Grid container >
                            <ALabel className='anelma-animal-announcement-general-label'>
                                {t('AnelmaBovine:1043')}
                            </ALabel>
                        </Grid>
                        {isCollectWeekEditAllowed && (
                            <>
                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    <ADropdown
                                        id={Strings.AnnouncementCollectWeekStartNumber}
                                        onChange={(v) =>
                                            setCollectWeekStart(
                                                JSON.parse(v as string) as ICollectWeek
                                            )
                                        }
                                        value={JSON.stringify(
                                            collectWeekStart.Week === -1 &&
                                                collectWeekStart.Year === -1
                                                ? { Year: '', Week: '' }
                                                : collectWeekStart
                                        )}
                                        items={mapCollectWeeksForDropdown(collectWeeks)}
                                        validator={validator}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    <ADropdown
                                        id={Strings.AnnouncementCollectWeekEndNumber}
                                        onChange={(v) =>
                                            setCollectWeekEnd(
                                                JSON.parse(v as string) as ICollectWeek
                                            )
                                        }
                                        value={JSON.stringify(
                                            collectWeekEnd.Week === -1 && collectWeekEnd.Year === -1
                                                ? { Year: '', Week: '' }
                                                : collectWeekEnd
                                        )}
                                        items={mapCollectWeeksForDropdown(collectWeeks)}
                                        validator={validator}
                                        required
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={8} sm={8} md={8} lg={8}>
                            <ATextInput
                                id={Strings.AnnouncementAdditionalInformation}
                                label={t('AnelmaBovine:1180')}
                                onChange={(v) => setInfo(v)}
                                value={info}
                                validator={validator}
                                minLength={4}
                                maxLength={500}
                            />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <SecondaryDialogDataGrid
                            fromDialog={SecondaryDialogTypeEnum.ModifyCollectWeek}
                            mode={DialogModeEnum.Edit}
                            handleRowSelectionChange={() => {}}
                            handleRowDeleteClick={() => {}}
                            data={null}
                            animals={undefined}
                            batches={batches}
                        />
                    </Grid>
                </ADialogContent>

                <ADialogActions buttons={footerActionButtons} />
            </ADialog>
        </>
    );
}
