// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { isNotNil } from 'ramda';

// MUI
import { Grid } from '@mui/material';

// Core
import { ParameterValues, ResourceTextApplication } from '../../../../core/resources';
import FormValidator from '../../../../core/FormValidator';
import auth from '../../../../core/authorization';

// Common
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../../../../common/dialog';
import { ADropdown } from '../../../../common/inputs';
import { ACloseIconButton } from '../../../../common/buttons';

// Interfaces
import { DialogModeEnum } from '../../../../interfaces/enums';
import { IAnimalSalesTypeUpdate, IFarmAnimal } from '../../../../interfaces/IAnimal';

// Store
// API
import animalsApi from '../../../../api/animalsApi';

// Feature
import { ISalesTypeDialogProps } from '../../../shared/types/propTypes';
import SecondaryDialogDataGrid from '../../../shared/components/data-grid/SecondaryDialogDataGrid';
import { SecondaryDialogTypeEnum } from '../../../shared/types/enum';
import { mapParameters } from '../../../shared/helpers/data';
import {
    getSalesTypeParameters,
    ISalesTypeParams,
    salesTypeParamsForFarmAndAnnouncementType,
} from '../../helpers/salesType';
import '../../../shared/styles/sharedStyles.scss';

export default function ModifySalesTypeDialog(props: ISalesTypeDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaBovine']);
    const { enqueueSnackbar } = useSnackbar();

    const validator = new FormValidator();

    const [salesTypeParameterCode, setSalesTypeParameterCode] = useState<number>(-1);
    const [salesTypeParams, setSalesTypeParams] = useState<ParameterValues>([]);
    const [combinedSalesTypeParams, setCombinedSalesTypeParams] = useState<ParameterValues>([]);

    const [allSalesTypeParams, setAllSalesTypeParams] = useState<ISalesTypeParams | null>(null);

    useEffect(() => {
        getSalesTypeParameters().then((params) => {
            setAllSalesTypeParams(params);
        });
    }, []);

    useEffect(() => {
        if (props.farmDetails !== undefined && allSalesTypeParams)
            setCombinedSalesTypeParams(
                salesTypeParamsForFarmAndAnnouncementType(
                    props.farmDetails,
                    allSalesTypeParams,
                    props.announcementType
                )
            );
    }, [allSalesTypeParams]);

    useEffect(() => {
        if (combinedSalesTypeParams.length > 0) {
            setSalesTypeParams(
                combinedSalesTypeParams.filter(
                    (value, idx, self) =>
                        idx ===
                        self.findIndex((t) => t.code === value.code && t.text === value.text)
                )
            );
        }
    }, [combinedSalesTypeParams]);

    const isSalesTypeValid = (): boolean => {
        return isNotNil(salesTypeParameterCode) && salesTypeParameterCode !== -1;
    };

    const updateSalesType = () => {
        const salestypePayload = composeUpdateAnimalSalesTypePayload();
        let result: IFarmAnimal[] = [];

        if (props.isFromAnimalList) {
            animalsApi
                .updateAnimalSalesType(salestypePayload)
                .then((response) => {
                    if (response && response.Items.length > 0) {
                        enqueueSnackbar(t('AnelmaGeneral:1018'), {
                            variant: 'success',
                        });

                        result = response.Items;
                    } else {
                        enqueueSnackbar(t('AnelmaGeneral:1019'), {
                            variant: 'error',
                        });
                    }
                    // if it seems to be repeated, it's async
                    props.onClose();
                    props.handleSalesTypeUpdate(result);
                })
                .catch((err) => {
                    enqueueSnackbar(t('AnelmaGeneral:1019'), {
                        variant: 'error',
                    });
                    props.onClose();
                });
        } else {
            props.onClose();
            props.handleSalesTypeUpdate(mapPayloadToResponse(salestypePayload));
        }
    };

    const composeUpdateAnimalSalesTypePayload = (): IAnimalSalesTypeUpdate => {
        return {
            AnimalId: props.animals.map((a) => a.AnimalId),
            AnnouncementId: props.id,
            FarmId: props.farmId,
            SalesType: Number(salesTypeParameterCode),
        };
    };

    const mapPayloadToResponse = (payload: IAnimalSalesTypeUpdate): IFarmAnimal[] => {
        let ret: IFarmAnimal[] = [];

        for (const nextId of payload.AnimalId) {
            ret.push({
                AnimalGUID: nextId,
                AnimalType: 1,
                ExternalIdentifier: '', // unknown here
                FarmId: payload.FarmId,
                SalesType: payload.SalesType,
                ValidFrom: new Date(),
                ValidTo: null,
            });
        }

        return ret;
    };

    const footerActionButtons: ADialogButtonsProp = {
        left: [],
        right: [
            {
                onClick: () => updateSalesType(),
                type: !props.isFromAnimalList ? 'ok' : 'save',
                disabled:
                    isSalesTypeValid() && props.animals.length > 0
                        ? auth.canUpdateAnimalSalesType
                            ? false
                            : true
                        : true,
            },
        ],
    };

    return (
        <>
            <ADialog open onClose={props.onClose} disableEnforceFocus>
                <ADialogTitle>
                    {t('AnelmaBovine:1066')}
                    <ACloseIconButton
                        data-robot-id='close-icon-button'
                        className='animal-announcement-close-button-float-right'
                        onClick={props.onClose}
                    ></ACloseIconButton>
                </ADialogTitle>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <ADropdown
                        data-robot-id='salestype-dropdown'
                        label={t('AnelmaBovine:1019')}
                        id='sales-type-dropdown'
                        items={mapParameters(salesTypeParams)}
                        onChange={(_) => setSalesTypeParameterCode(_ as number)}
                        validator={validator}
                        value={salesTypeParameterCode === -1 ? '' : salesTypeParameterCode}
                        required
                    ></ADropdown>
                </Grid>

                <Grid container sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                    <ADialogContent size={'md'}>
                        <SecondaryDialogDataGrid
                            data-robot-id='data-grid-animals'
                            fromDialog={SecondaryDialogTypeEnum.SalesType}
                            mode={DialogModeEnum.Edit}
                            handleRowSelectionChange={() => {}}
                            handleRowDeleteClick={() => {}}
                            data={null}
                            animals={props.animals}
                        />
                    </ADialogContent>
                </Grid>

                <ADialogActions buttons={footerActionButtons} />
            </ADialog>
        </>
    );
}
