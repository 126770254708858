// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { Container } from '@mui/material';

// Core
import { ParameterResource, paramRepository, ResourceTextApplication } from '../core/resources';

// Common
import ViewLoader from '../common/ViewLoader';
import { ViewTitle } from '../common/typography';

// Interfaces
import { FormMode } from '../interfaces/IForm';
import { ICompanyRow } from '../interfaces/IBusinessEntities';
import { CompanyEntityTypeEnum } from '../interfaces/enums';

// Store
// API
// Feature
import CompanySearchBar from './search/CompanySearchBar';
import { CompanyView } from './CompanyView';
import CompanyInfoDialog, { CompanyInfoDialogProps } from './CompanyInfoDialog';

export default function Companies() {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaPerson',
        'AnelmaCompany',
        'AnelmaLayout',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const [initialized, setInitialized] = useState<boolean | null>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [infoDialogVisible, setInfoDialogVisible] = useState<boolean>(false);
    const [companyList, setCompanyList] = useState<ICompanyRow[]>([]);
    const [companyInfoProps, setCompanyInfoProps] = useState<CompanyInfoDialogProps>();
    const [farmResources, setFarmResources] = useState<ParameterResource>({});

    useEffect(() => {
        initializeAnelmaResources();
    }, []);

    const initializeAnelmaResources = () => {
        const promises = [paramRepository.load(['AnelmaCompany', 'AnelmaGeneral'])];
        Promise.all(promises).then(
            () => {
                let params: ParameterResource = {};
                params['feedSupplier'] = paramRepository.resource('AnelmaCompany', 'FeedSupplier');
                params['dairy'] = paramRepository.resource('AnelmaCompany', 'Dairy');

                setFarmResources(params);
                setInitialized(true);
            },
            () => {
                setInitialized(null);
                enqueueSnackbar(t('AnelmaGeneral:1020'), {
                    variant: 'error',
                });
            }
        );
    };

    const openCompanyInfoDialog = (
        mode: FormMode,
        companyType: CompanyEntityTypeEnum,
        companyId?: string
    ) => {
        setCompanyInfoProps({
            mode: mode,
            onClose: () => setInfoDialogVisible(false),
            companyId: companyId ?? '',
            companyType: companyType,
            farmResources: farmResources,
            readonly: false,
        });
        setInfoDialogVisible(true);
    };

    return (
        <Container data-robot-id={'app-body-management-companies'}>
            <ViewTitle>{t('AnelmaLayout:1003')}</ViewTitle>

            <br />

            {initialized ? (
                <>
                    <CompanySearchBar
                        afterSearch={(v) => {
                            setCompanyList(v);
                            setIsLoading(false);
                        }}
                        beforeSearch={() => setIsLoading(true)}
                        onShowInactiveChange={() => {}}
                        openAddDialog={(_: CompanyEntityTypeEnum) =>
                            openCompanyInfoDialog('create', _)
                        }
                    />

                    <CompanyView
                        data={companyList}
                        isLoading={isLoading}
                        openEdit={(companyId: string, companyType: CompanyEntityTypeEnum) =>
                            openCompanyInfoDialog('modify', companyType, companyId)
                        }
                    />

                    {infoDialogVisible && companyInfoProps && (
                        <CompanyInfoDialog {...companyInfoProps} />
                    )}
                </>
            ) : (
                <ViewLoader />
            )}
        </Container>
    );
}
