// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Button, Grid, Typography } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { styled } from '@mui/material';
import { GridProps } from '@mui/system';

// Core
import FormValidator from '../core/FormValidator';
import { ParameterValues, paramRepository, ResourceTextApplication } from '../core/resources';

// Common
import { ACloseIconButton } from '../common/buttons';
import { AMultiselect, ASwitch } from '../common/inputs';

// Interfaces
import { IEventFilters } from '../interfaces/IEvent';

// Store
// API
// Feature

const FilterWrapper = styled(Grid)<GridProps>(({ theme }) => ({
    height: 707,
    width: 300,
    float: 'right',
    marginLeft: 5,
    border: '1px solid #DDD',
}));

interface IProps {
    onChange: (filters: IEventFilters) => void;
}
export default function CalendarFilter(props: IProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaGeneral', 'AnelmaCalendar']);

    const [validator] = useState<FormValidator>(new FormValidator());
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>([]);
    const [selectedFarmingTypes, setSelectedFarmingTypes] = useState<string[]>([]);
    const [filterCount, setFilterCount] = useState<number>(0);

    const [eventTypeOptions, setEventTypeOptions] = useState<ParameterValues>([]);
    const [farmingTypeOptions, setFarmingTypeOptions] = useState<ParameterValues>([]);

    const changed = () => {
        const filters = {
            eventTypes: selectedEventTypes,
            tags: [...selectedFarmingTypes],
        };
        setFilterCount(
            eventTypeOptions.length - selectedEventTypes.length + selectedFarmingTypes.length
        );
        props.onChange(filters);
    };

    const boxTitle = (title: string) => {
        return (
            <Typography variant='h5' color='primary'>
                {title}
            </Typography>
        );
    };

    useEffect(() => {
        paramRepository.load(['AnelmaCalendar', 'AnelmaCompany']).then(() => {
            const calendarEventTypes = paramRepository.resource(
                'AnelmaCalendar',
                'CalendarEventTypes'
            );
            const farmingTypeBovines = paramRepository.resource(
                'AnelmaCompany',
                'FarmingTypeBovine'
            );
            const farmingTypePigs = paramRepository.resource('AnelmaCompany', 'FarmingTypePig');

            setEventTypeOptions(calendarEventTypes);
            setFarmingTypeOptions([
                ...farmingTypeBovines.map((x) => {
                    return { code: `FarmingTypeBovine:${x.code}`, text: x.text };
                }),
                ...farmingTypePigs.map((x) => {
                    return { code: `FarmingTypePig:${x.code}`, text: x.text };
                }),
            ]);

            setSelectedEventTypes(calendarEventTypes.map((x) => x.code));
        });
    }, []);

    useEffect(() => {
        changed();
    }, [selectedEventTypes, selectedFarmingTypes]);

    return (
        <>
            <Grid container direction='row' style={{ display: 'flow-root' }}>
                <Button
                    color='primary'
                    style={{ float: 'right' }}
                    onClick={() => setShowFilters(!showFilters)}
                >
                    <FilterList />
                    {t('AnelmaGeneral:1081')}
                    {filterCount === 0 ? '' : `(${filterCount})`}
                </Button>
            </Grid>
            {showFilters && (
                <FilterWrapper container direction='column'>
                    <div style={{ display: 'flex', borderBottom: '1px solid #DDD' }}>
                        <FilterList color='primary' fontSize='large' />
                        <span style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                            {t('AnelmaGeneral:1081')}
                        </span>
                        <ACloseIconButton
                            style={{ marginLeft: 'auto' }}
                            onClick={() => setShowFilters(false)}
                        />
                    </div>
                    <div style={{ borderBottom: '1px solid #DDD' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {boxTitle(t('AnelmaCalendar:1010'))}
                            {eventTypeOptions.map((et) => (
                                <ASwitch
                                    key={et.text}
                                    id={`event-type-${et.code}`}
                                    label={et.text}
                                    checked={!!selectedEventTypes.find((x) => x === et.code)}
                                    onChange={(checked) => {
                                        if (checked)
                                            setSelectedEventTypes([...selectedEventTypes, et.code]);
                                        else
                                            setSelectedEventTypes([
                                                ...selectedEventTypes.filter((x) => x !== et.code),
                                            ]);
                                    }}
                                    validator={validator}
                                    withoutContainer
                                />
                            ))}
                        </div>
                    </div>
                    <div style={{ borderBottom: '1px solid #DDD' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {boxTitle(t('AnelmaCalendar:1011'))}
                            <AMultiselect
                                label={''}
                                id='farming-types'
                                value={selectedFarmingTypes}
                                keyProp={'code'}
                                getOptionLabel={(tag) => tag.text}
                                onChange={(v) => setSelectedFarmingTypes(v.map(String))}
                                items={farmingTypeOptions}
                                validator={validator}
                                withoutContainer
                            />
                        </div>
                    </div>
                </FilterWrapper>
            )}
        </>
    );
}
