// Libraries
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI
import { Grid } from '@mui/material';

// Core
import { paramRepository, ParameterValues } from '../core/resources';
import { ResourceTextApplication } from '../core/resources';
import { IFormValidator } from '../core/FormValidator';

// Common
import { AInputContainer, ARadioButtons, ATextarea, ASwitch } from '../common/inputs';
import {
    ADialogSubHeading,
    ADialog,
    ADialogActions,
    ADialogContent,
    ADialogTitle,
    ADialogButtonsProp,
} from '../common/dialog';

// Interfaces
import { GUIDType } from '../interfaces/types';
import { CompanyEntityTypeEnum } from '../interfaces/enums';
import { ICompanyDetails } from '../interfaces/IBusinessEntities';

// Feature
import companyApi, { CompanyInactivationPayload } from '../api/companyApi';

export interface InActivationFormDialogProps {
    afterSave: () => void;
    data: ICompanyDetails;
    onClose: () => void;
    validator: IFormValidator;
    readonly?: boolean;
}

export default function InActivationForm(props: InActivationFormDialogProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany', 'AnelmaGeneral']);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reason, setReason] = useState<string | null>(null);
    const [additionalReason, setAdditionalReason] = useState<string[]>([]);
    const [additionalReasonParam, setAdditionalReasonParam] = useState<ParameterValues>([]);
    const [infoText, setInfoText] = useState<string>('');

    useEffect(() => {
        paramRepository.load(['AnelmaCompany']).then(() => {
            setAdditionalReasonParam(
                paramRepository.resource('AnelmaCompany', 'InactiveAddReasons')
            );
        });
        validateAdditionalReasons(additionalReason);
    }, []);

    const validateAdditionalReasons = (reasons: string[]) => {
        if (props.data.Inactivated) return;
        if (props.data.CompanyType !== CompanyEntityTypeEnum.Farm) return;
        props.validator.setState('additional-reasons', reasons.length > 0, (valid) => {
            if (!valid) showMessage(t('AnelmaCompany:1121'), 'error');
        });
    };

    const showMessage = (message: string, variant: 'success' | 'error') => {
        enqueueSnackbar(message, {
            variant: variant,
        });
    };

    const activate = () => {
        companyApi.activateCompany(props.data.Id as GUIDType).then(
            (data) => {
                if (data) {
                    showMessage(t('AnelmaGeneral:1018'), 'success');
                } else {
                    showMessage(t('AnelmaGeneral:1019'), 'error');
                }
                props.afterSave();
                props.onClose();
            },
            () => {
                setIsLoading(false);
                showMessage(t('AnelmaGeneral:1019'), 'error');
            }
        );
    };

    const inactivate = (data?: CompanyInactivationPayload) => {
        companyApi.inactivateCompany(props.data.Id as GUIDType, data).then(
            (data) => {
                if (data) {
                    showMessage(t('AnelmaGeneral:1018'), 'success');
                } else {
                    showMessage(t('AnelmaGeneral:1019'), 'error');
                }
                props.afterSave();
                props.onClose();
            },
            () => {
                setIsLoading(false);
                showMessage(t('AnelmaGeneral:1019'), 'error');
            }
        );
    };

    const onSave = () => {
        if (props.validator.invalid) {
            enqueueSnackbar(t('AnelmaGeneral:1030'), {
                variant: 'error',
            });
            return;
        }

        setIsLoading(true);

        if (props.data.Inactivated) activate();
        else {
            if (props.data.CompanyType == CompanyEntityTypeEnum.Farm) {
                const data: CompanyInactivationPayload = {
                    reason: {
                        inactivationTypeID: reason ? parseInt(reason, 10) : null,
                        inactivationReason: additionalReason.map((r) => parseInt(r, 10)),
                        info: infoText,
                    },
                };
                inactivate(data);
            } else inactivate();
        }
    };

    const handleAddMoreReason = (v: string, checked: boolean) => {
        const newReasonList = [...additionalReason];
        if (checked) newReasonList.push(v);
        else {
            const index = newReasonList.findIndex((i) => i === v);
            if (index !== -1) {
                newReasonList.splice(index, 1);
            }
        }
        setAdditionalReason(newReasonList);
        validateAdditionalReasons(newReasonList);
    };

    const footerButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                label: t('AnelmaCompany:1036'),
                type: 'save',
                enabledWhileLoading: false,
                onClick: () => onSave(),
                disabled: props.readonly,
            },
        ],
    };

    const activationFooterButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: () => props.onClose(),
                type: 'cancel',
            },
        ],
        right: [
            {
                label: t('AnelmaCompany:1037'),
                type: 'save',
                enabledWhileLoading: false,
                onClick: () => onSave(),
                disabled: props.readonly,
            },
        ],
    };

    // Inactivate traffic contractor
    if (
        props.data.CompanyType === CompanyEntityTypeEnum.TrafficContractor &&
        !props.data.Inactivated
    ) {
        return (
            <>
                <ADialog open={true} onClose={() => props.onClose()}>
                    <ADialogTitle>{t('AnelmaCompany:1036')}</ADialogTitle>
                    <ADialogContent size='md' isLoading={isLoading}>
                        <div style={{ paddingTop: '40px' }}></div>
                        <AInputContainer>{t('AnelmaCompany:1117')}</AInputContainer>
                    </ADialogContent>
                    <ADialogActions buttons={footerButtons} isLoading={isLoading} />
                </ADialog>
            </>
        );
    }

    // Activate company
    if (props.data.Inactivated) {
        return (
            <>
                <ADialog open={true} onClose={() => props.onClose()}>
                    <ADialogTitle>{t('AnelmaCompany:1037')}</ADialogTitle>
                    <ADialogContent size='md' isLoading={isLoading}>
                        <div style={{ paddingTop: '40px' }}></div>
                        <AInputContainer>{t('AnelmaCompany:1120')}</AInputContainer>
                    </ADialogContent>
                    <ADialogActions buttons={activationFooterButtons} isLoading={isLoading} />
                </ADialog>
            </>
        );
    }

    // Inactivate company
    return (
        <>
            <ADialog open={true} onClose={() => props.onClose()}>
                <ADialogTitle>{t('AnelmaCompany:1046')}</ADialogTitle>

                <ADialogContent size='md' isLoading={isLoading}>
                    <ADialogSubHeading>{t('AnelmaCompany:1119')}</ADialogSubHeading>
                    <Grid container>
                        <Grid item sm={12}>
                            <ARadioButtons
                                id='inactivation-reason'
                                onChange={(v) => setReason(v as string)}
                                parameterName={'AnelmaCompany:InactiveReason'}
                                required
                                validator={props.validator}
                                value={reason}
                                disabled={props.readonly}
                            ></ARadioButtons>
                        </Grid>
                    </Grid>

                    <ADialogSubHeading>{t('AnelmaCompany:1048')}</ADialogSubHeading>
                    <Grid container>
                        <Grid item sm={12}>
                            {additionalReasonParam.map((item, index) => (
                                <ASwitch
                                    id={`reason-${index}`}
                                    label={item.text}
                                    checked={additionalReason.includes(item.code)}
                                    onChange={(v) => {
                                        handleAddMoreReason(item.code, v);
                                    }}
                                    validator={props.validator}
                                    disabled={props.readonly}
                                ></ASwitch>
                            ))}
                        </Grid>
                    </Grid>

                    <ADialogSubHeading>{t('AnelmaCompany:1049')}</ADialogSubHeading>
                    <Grid container>
                        <Grid item sm={12}>
                            <ATextarea
                                id='info'
                                label=''
                                maxLength={300}
                                onChange={(v) => setInfoText(v)}
                                validator={props.validator}
                                value={infoText}
                                disabled={props.readonly}
                            />
                        </Grid>
                    </Grid>
                </ADialogContent>

                <ADialogActions buttons={footerButtons} isLoading={isLoading} />
            </ADialog>
        </>
    );
}
