// Libraries
// MUI
import { Typography } from '@mui/material';
import { Container } from '@mui/system';

// Core
// Common
// Interfaces
// Store
// API
// Feature

export default function Forbidden() {
    return (
        <Container data-robot-id={'app-body-page-forbidden'}>
            <Typography variant='h2'>
                403 - Käyttöoikeutesi eivät riittäneet toimenpiteen suorittamiseen
            </Typography>
        </Container>
    );
}
