// Libraries
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';

// MUI
import { Grid } from '@mui/material';

//Core
import { ResourceTextApplication } from '../../core/resources';

// Common
import { ADeleteIconButton, AEditIconButton } from '../../common/buttons';
import { ADataGrid, gridColumns, AGridColumns } from '../../common/dataGrid';

// Interfaces
import { IHoldingSite } from '../../interfaces/IBusinessEntities';
import { AnimalTypeEnum } from '../../interfaces/enums';

export interface CompanyHoldingSitesGridProps {
    holdingSites: IHoldingSite[];
    onDelete: (data: IHoldingSite) => void;
    onSetHoldingSite: (data: IHoldingSite) => void;
    specieType: 'bovine' | 'pork';
    readonly?: boolean;
}

export default function CompanyHoldingSiteGrid(props: CompanyHoldingSitesGridProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany', 'AnelmaGeneral']);

    const columns: AGridColumns = [
        gridColumns.euIdentifier(t('AnelmaCompany:1025'), 'HoldingSiteId'),
        gridColumns.address(t('AnelmaCompany:1026')),
    ];

    if (props.specieType === 'pork') {
        columns.push(
            gridColumns.withTooltip({
                field: 'EntryCode',
                headerName: t('AnelmaCompany:1028'),
                type: 'string',
                width: 160,
            })
        );
    }

    const rows = props.holdingSites
        ? props.holdingSites.map((i, index) => mergeRight(i, { id: index }))
        : [];

    return (
        <Grid container>
            <Grid item sm={12}>
                <ADataGrid
                    actions={[
                        (params) => {
                            let row = params.row as unknown as IHoldingSite;
                            row.Type =
                                props.specieType === 'bovine'
                                    ? AnimalTypeEnum.Bovine
                                    : AnimalTypeEnum.Pork;

                            return (
                                <AEditIconButton
                                    key={params.id}
                                    onClick={() => props.onSetHoldingSite(row)}
                                    disabled={props.readonly}
                                />
                            );
                        },
                        (params) => {
                            let row = params.row as unknown as IHoldingSite;
                            row.Type =
                                props.specieType === 'bovine'
                                    ? AnimalTypeEnum.Bovine
                                    : AnimalTypeEnum.Pork;

                            return (
                                <ADeleteIconButton
                                    key={`${row.HoldingSiteId}-delete`}
                                    onClick={() => props.onDelete(row)}
                                    disabled={props.readonly}
                                />
                            );
                        },
                    ]}
                    columns={columns}
                    rows={rows}
                />
            </Grid>
        </Grid>
    );
}
