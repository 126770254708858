// Libraries
import { isNotNil } from 'ramda';
import axios from 'axios';

// MUI
// Core
// Common
// Interfaces
import { IApiResult } from '../interfaces/IGeneral';
import { GUIDType } from '../interfaces/types';
import { IEvent, IEventTags, IRegistrationForm } from '../interfaces/IEvent';

// Store
// API
import ApiBase from './ApiBase';
import { eventsUrl, publicApiUrl } from '../api/apiEndpoints';

// Feature

export interface IEventsByDateRequest {
    EventValidFrom: string;
    EventValidDue: string;
    Tags: string[];
}

class EventsApi extends ApiBase {
    createEvent(payload: IEvent) {
        return this.post<IEvent>(`${eventsUrl}StoreEvent`, payload);
    }

    updateEvent(payload: IEvent) {
        return this.put<IEvent>(`${eventsUrl}UpdateEvent`, payload);
    }

    getEvents(payload: IEventTags) {
        return this.postList<IEvent>(`${eventsUrl}GetEvents`, payload);
    }

    getEventsByDate(payload: IEventsByDateRequest) {
        return this.postList<IEvent>(`${eventsUrl}GetEventsByDate`, payload);
    }

    registerToEvent(data: IRegistrationForm[], loggedIn?: boolean) {
        let url: string = '';

        if (isNotNil(loggedIn)) url = loggedIn ? eventsUrl : `${publicApiUrl}/`;
        else url = eventsUrl;

        return this.postList<IRegistrationForm>(
            `${url}StoreRegistration`,
            JSON.parse(JSON.stringify({ items: data }))
        );
    }

    editRegistration(data: IRegistrationForm[]) {
        var payload = JSON.stringify({ items: data });
        return this.postList<IRegistrationForm>(
            `${eventsUrl}EditRegistration`,
            JSON.parse(payload)
        );
    }

    checkRegistration(userId: GUIDType | null, eventId: GUIDType) {
        return this.getList<IRegistrationForm>(
            `${eventsUrl}CheckRegistration/${userId}/${eventId}`
        );
    }

    deleteEvent(id: GUIDType, contentId: GUIDType) {
        return axios
            .delete<IApiResult<IEvent>>(`${eventsUrl}DeleteEvent`, {
                data: { id: id, contentId: contentId },
            })
            .then((d) => d.data);
    }
}

export default new EventsApi();
