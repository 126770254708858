import { DialogTitle, Typography, styled } from '@mui/material';

export interface ADialogTitleProps {
    children: React.ReactNode;
}

const Wrapper = styled(DialogTitle)(({ theme }) => ({
    root: {
        borderBottom: '2px solid #0a4c31',
        padding: '5px 15px',
    },
}));

/** Renders a MUI Typography component with a variant h3 wrapped in a MUI DialogTitle component that is forced to render as a div.
 *  By doing so the validateDOMNesting in React doesn't give a warning that h3 cannot appear as a descendant of h2.
 *
 * @param props
 * @returns
 */
export default function ADialogTitle(props: ADialogTitleProps) {
    return (
        <Wrapper {...({ component: 'div' } as any)}>
            <Typography variant='h3'>{props.children}</Typography>
        </Wrapper>
    );
}
