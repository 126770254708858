import { IFarmSupplier } from '../../../interfaces/IProductionPlan';

export const flagSellerFarmsDeleted = (farms: IFarmSupplier[]) => {
    let result: IFarmSupplier[] = [];
    for (const farm of farms) {
        result.push({
            Id: farm.Id,
            ValidId: farm.ValidId,
            canEdit: farm.canEdit,
            FarmId: farm.FarmId,
            GMFreedomValidated: farm.GMFreedomValidated,
            YearlyAmount: farm.YearlyAmount,
            Flags: 1,
            SellingFarmName: farm.SellingFarmName,
            SellingFarmTiltu: farm.SellingFarmTiltu,
            SnellmanQuestion: farm.SnellmanQuestion,
        });
    }
    return result;
};

export const flagOrRemoveSellerFarm = (farms: IFarmSupplier[], initialSellerFarms: IFarmSupplier[], id: string) => {
    let result: IFarmSupplier[] = [];

    for (const farm of farms) {
        if (farm.Id === id) {

            if (initialSellerFarms.find(x => x.Id === id)) {
                result.push({
                    Id: farm.Id,
                    ValidId: farm.ValidId,
                    canEdit: farm.canEdit,
                    FarmId: farm.FarmId,
                    GMFreedomValidated: farm.GMFreedomValidated,
                    YearlyAmount: farm.YearlyAmount,
                    Flags: 1,
                    SellingFarmName: farm.SellingFarmName,
                    SellingFarmTiltu: farm.SellingFarmTiltu,
                    SnellmanQuestion: farm.SnellmanQuestion,
                });
            }
        }
        else {
            result.push(farm);
        }
    }

    return result;
};

export const RemoveEmptyFarms = (farms: IFarmSupplier[]) => {
    return farms.filter(_ => _.SellingFarmName !== '' && _.Id !== '');
};

export const ChangeCanEdit = (farms: IFarmSupplier[]) => {
    let result: IFarmSupplier[] = [];
    for (const farm of farms) {
        result.push({
            Id: farm.Id,
            ValidId: farm.ValidId,
            canEdit: false,
            FarmId: farm.FarmId,
            GMFreedomValidated: farm.GMFreedomValidated,
            YearlyAmount: farm.YearlyAmount,
            Flags: farm.Flags,
            SellingFarmName: farm.SellingFarmName,
            SellingFarmTiltu: farm.SellingFarmTiltu,
            SnellmanQuestion: farm.SnellmanQuestion,
        });
    }
    return result;
};

export const MapAndRemoveDuplicates = (suppliers: IFarmSupplier[], initialSellerFarms: IFarmSupplier[]): IFarmSupplier[] => {

    let result: IFarmSupplier[] = [];

    for (const farm of suppliers) {

        if (result.find((x) => x.SellingFarmName === farm.SellingFarmName && x.SellingFarmTiltu === farm.SellingFarmTiltu && x.Flags === 0)) {
            continue;
        }

        var duplicate = initialSellerFarms.find((x) => x.SellingFarmName === farm.SellingFarmName && x.SellingFarmTiltu === farm.SellingFarmTiltu);

        if (duplicate === undefined || duplicate === null) {
            if (farm.Flags === 0) {
                result.push({
                    Id: farm.Id,
                    ValidId: farm.ValidId,
                    canEdit: false,
                    FarmId: farm.FarmId,
                    GMFreedomValidated: farm.GMFreedomValidated,
                    YearlyAmount: farm.YearlyAmount,
                    Flags: farm.Flags,
                    SellingFarmName: farm.SellingFarmName,
                    SellingFarmTiltu: farm.SellingFarmTiltu,
                    SnellmanQuestion: farm.SnellmanQuestion,
                });
            }
        }
        else {
            var index = result.findIndex((x) => x.SellingFarmName === farm.SellingFarmName && x.SellingFarmTiltu === farm.SellingFarmTiltu);

            if (index === -1) {
                result.push({
                    Id: duplicate.Id,
                    ValidId: duplicate.ValidId,
                    canEdit: false,
                    FarmId: duplicate.FarmId,
                    GMFreedomValidated: farm.GMFreedomValidated,
                    YearlyAmount: farm.YearlyAmount,
                    Flags: farm.Flags,
                    SellingFarmName: duplicate.SellingFarmName,
                    SellingFarmTiltu: farm.SellingFarmTiltu,
                    SnellmanQuestion: farm.SnellmanQuestion,
                });
            }
            else {
                result[index].Id = duplicate.Id;
                result[index].ValidId = duplicate.ValidId;
                result[index].canEdit = false;
                result[index].FarmId = duplicate.FarmId;
                result[index].GMFreedomValidated = farm.GMFreedomValidated;
                result[index].YearlyAmount = farm.YearlyAmount;
                result[index].Flags = farm.Flags;
                result[index].SellingFarmName = duplicate.SellingFarmName;
                result[index].SellingFarmTiltu = duplicate.SellingFarmTiltu;
                result[index].SnellmanQuestion = duplicate.SnellmanQuestion;
            }
        }
    }
    return result;
};

export const RemoveDuplicates = (suppliers: IFarmSupplier[]): IFarmSupplier[] => {

    let result: IFarmSupplier[] = [];

    for (const farm of suppliers) {
        if (!result.find((x) => x.SellingFarmName === farm.SellingFarmName && x.SellingFarmTiltu === farm.SellingFarmTiltu && x.Id === farm.Id)) {
            result.push({
                Id: farm.Id,
                ValidId: farm.ValidId,
                canEdit: false,
                FarmId: farm.FarmId,
                GMFreedomValidated: farm.GMFreedomValidated,
                YearlyAmount: farm.YearlyAmount,
                Flags: farm.Flags,
                SellingFarmName: farm.SellingFarmName,
                SellingFarmTiltu: farm.SellingFarmTiltu,
                SnellmanQuestion: farm.SnellmanQuestion,
            });
        }
    }

    return result;
}

export const newRandomGuid = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
