// Libraries
import { useState, useEffect } from 'react';
import { clone } from 'ramda';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid } from '@mui/material';

// Core
import utils from '../../core/utils';
import auth from '../../core/authorization';

// Common
import { ADataGrid, gridColumns, gridUtils } from '../../common/dataGrid';
import { AAddButton, ACloneIconButton, ACheckboxIconButton } from '../../common/buttons';

// Feature
import { IEditableContent, EditableContentType } from '../IEditableContent';
import contentUtils from './contentUtils';

export interface VersionListProps {
    addVersion: (version: IEditableContent) => void;
    contents: IEditableContent[];
    loading: boolean;
    onSelect: (version: IEditableContent | null) => void;
    type: EditableContentType;
}

export default function VersionList(props: VersionListProps) {
    const { t } = useTranslation(['AnelmaContent']);

    const [selectedVersion, setSelectedVersion] = useState<string>('');

    useEffect(() => {
        const version = props.contents.find((i) => i._uiId === selectedVersion);
        props.onSelect(version || null);
    }, [props.contents, selectedVersion]);

    const addEmpty = () => {
        const newVersion: IEditableContent = {
            _uiId: utils.generateUniqueId(),
            Comment: '',
            Translations: [],
            Type: props.type,
        };
        add(newVersion);
    };

    const cloneVersion = (from: IEditableContent) => {
        const newVersion: IEditableContent = {
            _uiId: utils.generateUniqueId(),
            Comment: '',
            Due: from.Due,
            From: from.From,
            Translations: clone(from.Translations),
            Type: props.type,
        };
        add(newVersion);
    };

    const add = (version: IEditableContent) => {
        setSelectedVersion(version._uiId);
        props.addVersion(version);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <ADataGrid
                    actions={[
                        (params) => {
                            const row = params.row as IEditableContent;
                            const checked = row._uiId === selectedVersion;
                            return (
                                <ACheckboxIconButton
                                    checked={checked}
                                    checkedTooltip={t('AnelmaContent:1012') as string}
                                    key='checkbox'
                                    onClick={() => setSelectedVersion(row._uiId)}
                                    uncheckedTooltip={t('AnelmaContent:1013') as string}
                                />
                            );
                        },
                        (params) => {
                            const row = params.row as IEditableContent;
                            return row.Created ? (
                                <ACloneIconButton key='clone' onClick={() => cloneVersion(row)} />
                            ) : undefined;
                        },
                    ]}
                    columns={[
                        gridColumns.withTooltip({
                            field: 'Status',
                            headerName: t('AnelmaContent:1014'),
                            valueGetter: (params, row) => {
                                const content = row as IEditableContent;
                                return contentUtils.contentValidity(t, content, props.contents);
                            },
                            width: 150,
                        }),
                        gridColumns.withTooltip({
                            field: 'Comment',
                            headerName: t('AnelmaContent:1015'),
                            width: 250,
                        }),
                        gridColumns.dateTime('From', t('AnelmaContent:1016'), 'DD.MM.YYYY HH:mm'),
                        gridColumns.dateTime('Due', t('AnelmaContent:1017'), 'DD.MM.YYYY HH:mm'),
                        gridColumns.dateTime(
                            'Created',
                            t('AnelmaContent:1018'),
                            'DD.MM.YYYY HH:mm'
                        ),
                        gridColumns.dateTime(
                            'Modified',
                            t('AnelmaContent:1019'),
                            'DD.MM.YYYY HH:mm'
                        ),
                    ]}
                    enableRowNumbers
                    loading={props.loading}
                    rows={gridUtils.addGridId(props.contents)}
                />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '2em' }}>
                {auth.canCreateContentManagement && (
                    <AAddButton onClick={() => addEmpty()}>{t('AnelmaContent:1020')}</AAddButton>
                )}
            </Grid>
        </Grid>
    );
}
