// Libraries
import { useState } from 'react';

// MUI
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Core
import auth from '../core/authorization';

// Common
import { ADeleteIconButton, AEditIconButton } from '../common/buttons';
import UserAvatar from '../common/UserAvatar';

// Feature
import { PublicContactPerson, ParamState } from './interfaces';
import ContactPersonLabels from './ContactPersonLabels';
import { reverseName } from './helpers';
import './ContactPersonCard.scss';

interface ContactPersonCardProps {
    culture?: string;
    data: PublicContactPerson;
    onRemove: () => void;
    openEdit: () => void;
    responsibilities: ParamState[];
}

export default function ContactPersonCard(props: ContactPersonCardProps) {
    const [openMenuItem, setOpenMenuItem] = useState<Element | null>(null);

    const aboutMe = () => {
        if (props.culture === 'sv-SE') return props.data.AboutMeSE;
        if (props.culture === 'en-GB') return props.data.AboutMeEN;
        return props.data.AboutMeFI;
    };

    const getJobTitleBySelectedLanguage = () => {
        if (props.culture === 'sv-SE') return props.data.JobTitleSE;
        if (props.culture === 'en-GB') return props.data.JobTitleEN;
        return props.data.JobTitleFI;
    };

    return (
        <Card className={'an-card'}>
            <ContactPersonLabels
                data={props.data}
                responsibilities={props.responsibilities}
                type='card'
                data-robot-id='snellman-contact-person-labels'
            />

            {auth.canUpdateContactPerson && (
                <CardHeader
                    className={'an-card-menu'}
                    action={
                        <div style={{ position: 'relative' }}>
                            <IconButton
                                aria-label='settings'
                                data-robot-id='snellman-contact-person-settings'
                                onClick={(event) => {
                                    setOpenMenuItem(event.currentTarget);
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>

                            <Menu
                                open={!!openMenuItem}
                                anchorEl={openMenuItem}
                                onClose={() => setOpenMenuItem(null)}
                            >
                                <MenuItem>
                                    <AEditIconButton
                                        key={`${props.data.GUID}-edit`}
                                        data-robot-id='snellman-contact-person-edit'
                                        onClick={() => props.openEdit()}
                                    />
                                </MenuItem>

                                <MenuItem>
                                    <ADeleteIconButton
                                        key={`${props.data.GUID}-delete`}
                                        data-robot-id='snellman-contact-person-delete'
                                        onClick={() => props.onRemove()}
                                    />
                                </MenuItem>
                            </Menu>
                        </div>
                    }
                />
            )}

            <UserAvatar
                data-robit-id='snellman-contact-person-user-avatar'
                personId={props.data.PersonGUID}
            />

            <CardContent data-robot-id='an-card-content' sx={{ textAlign: 'center' }}>
                <Typography variant='h5'>{reverseName(props.data.Name || '')}</Typography>

                <Typography variant='h6' sx={{ marginBottom: '0.5em' }}>
                    {getJobTitleBySelectedLanguage()}
                </Typography>

                <Typography variant='h6' color={'textPrimary'} sx={{ padding: '0.125em' }}>
                    {props.data.PhoneNumber}
                </Typography>

                <Typography variant='h6' color={'textPrimary'} sx={{ padding: '0.125em' }}>
                    {props.data.EmailAddress}
                </Typography>

                <Typography variant='body1' sx={{ color: '#888', padding: '0.25em' }}>
                    {aboutMe()}
                </Typography>
            </CardContent>
        </Card>
    );
}
