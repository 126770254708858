// MUI
import { Typography } from '@mui/material';

// Common
import { AInputContainer } from '../inputs';

export interface AColorizedDialogSubHeaderProps {
    children: React.ReactNode;
    color?: string;
}

export default function AColorizedDialogSubHeader(props: AColorizedDialogSubHeaderProps) {
    return (
        <AInputContainer>
            <Typography
                variant='h5'
                style={{ fontSize: '1.2rem', marginTop: '20px', color: props.color }}
            >
                {props.children}
            </Typography>
        </AInputContainer>
    );
}
