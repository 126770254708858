// Libraries
import { useEffect, useState } from 'react';

// MUI
import { ButtonGroup } from '@mui/material';

// Core
import { paramRepository, ResourceTextApplication, ParameterSorting } from '../../core/resources';
import { IFormValidator } from '../../core/FormValidator';

// Common
import { AButtonBaseProps, AActionButton, ADefaultButton } from '../../common/buttons';
import { AInputContainer, ALabel, AInputItem } from '../../common/inputs';

// Feature
import './buttonGroupInput.scss';

export type ARadioButtonGroupInputValue = boolean | number | string | null;

interface ARadioButtonsBaseProps {
    disabled?: boolean;
    error?: boolean;
    fullWidth?: boolean;
    id: string;
    label?: string;
    onChange: (value: ARadioButtonGroupInputValue) => any;
    required?: boolean;
    unselectable?: boolean;
    validator: IFormValidator;
    value: ARadioButtonGroupInputValue;
    withoutContainer?: boolean;
}

interface ARadioButtonsWithItemsProps extends ARadioButtonsBaseProps {
    items: AInputItem<ARadioButtonGroupInputValue>[];
}

interface ARadioButtonsWithParamProps extends ARadioButtonsBaseProps {
    parameterName: string;
    sortValues?: ParameterSorting;
}

export type ARadioButtonsProps = ARadioButtonsWithItemsProps | ARadioButtonsWithParamProps;

export default function ARadioButtons(props: ARadioButtonsProps) {
    const { error, id, parameterName, required, sortValues, validator, value } =
        props as ARadioButtonsWithParamProps;

    const [items, setItems] = useState<AInputItem<ARadioButtonGroupInputValue>[]>(
        (props as ARadioButtonsWithItemsProps).items || []
    );
    const [touched, setTouched] = useState<boolean>(false);
    const [val, setVal] = useState<ARadioButtonGroupInputValue>(value);
    const [err, setErr] = useState<boolean>(false);

    const optionIsSelected = (option: AInputItem<ARadioButtonGroupInputValue>) =>
        val === option.value;

    const onClick = (clickedValue: ARadioButtonGroupInputValue) => {
        const newValue = props.unselectable && clickedValue === val ? null : clickedValue;
        setTouched(true);
        setVal(newValue);
        props.onChange(newValue);
    };

    useEffect(() => {
        if (!parameterName) return;
        const [groupName, paramName] = parameterName.split(':', 2);
        const group = groupName as ResourceTextApplication;
        paramRepository.load([group]).then(() => {
            const params = paramRepository.resource(group, paramName, sortValues);
            setItems(params.map((p) => ({ text: p.text, value: p.code })));
        });
    }, [parameterName]);

    useEffect(() => {
        setVal(value);
    }, [value]);

    useEffect(() => {
        const isValid = () => {
            if (error) return false;
            if (required && val === null) return false;
            return true;
        };

        const isValidAfterTouch = () => {
            if (touched) return isValid();
            return true;
        };

        validator.setState(id, isValid(), () => setTouched(true));
        setErr(!isValidAfterTouch());
    }, [error, id, required, touched, val, validator]);

    useEffect(() => {
        setItems((props as ARadioButtonsWithItemsProps).items || []);
    }, [(props as ARadioButtonsWithItemsProps).items]);

    const label = (text: string | JSX.Element, empty: boolean) => (
        <ALabel
            error={err || error}
            required={!empty && props.required}
            style={{ fontSize: '12px' }}
        >
            {text}
        </ALabel>
    );

    const group = (
        <>
            {props.label ? label(props.label, false) : label(<span>&nbsp;</span>, true)}
            <ButtonGroup
                className={err ? 'an-button-group-error' : ''}
                color='primary'
                fullWidth={props.fullWidth}
                sx={{ marginTop: '5px', borderStyle: 'none' }}
            >
                {items.map((item) => {
                    const buttonProps: AButtonBaseProps = {
                        children: item.text,
                        disabled: props.disabled,
                        onClick: () => onClick(item.value),
                        size: 'small',
                    };
                    return optionIsSelected(item) ? (
                        <AActionButton key={`${item.value}`} {...buttonProps} />
                    ) : (
                        <ADefaultButton key={`${item.value}`} {...buttonProps} />
                    );
                })}
            </ButtonGroup>
        </>
    );

    return props.withoutContainer ? group : <AInputContainer>{group}</AInputContainer>;
}
