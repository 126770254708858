// Libraries
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mergeRight } from 'ramda';
import { useSnackbar } from 'notistack';

// MUI
import { Grid, Container } from '@mui/material';
import { HourglassEmptyTwoTone, PictureAsPdf } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

// Core
import { dayFormat } from '../../../../../../core/constants';
import {
    ParameterValues,
    paramRepository,
    ResourceTextApplication,
} from '../../../../../../core/resources';
import auth from '../../../../../../core/authorization';

// Common
import {
    ADataGrid,
    ADataGridActions,
    AGridColumns,
    gridColumns,
} from '../../../../../../common/dataGrid';
import {
    ADefaultIconButton,
    ADeleteIconButton,
    AEditIconButton,
    ASearchIconButton,
} from '../../../../../../common/buttons';
import { TextsWithTooltip, TextWithTooltip } from '../../../../../../common/typography';
import AAttachmentIconButton from '../../../../../../common/buttons/AAttachmentIconButton';
import AWarningIconButton from '../../../../../../common/buttons/AWarningIconButton';
import ADoneIconButton from '../../../../../../common/buttons/ADoneIconButton';
import APriorityHighIconButton from '../../../../../../common/buttons/APriorityHighIconButton';

// Interfaces
import { AnnouncementTypeEnum } from '../../../../../../interfaces/enums';
import {
    IAnimalAnnouncement,
    IAnimalAnnouncementAnimal,
    IAnimalAnnouncementBatch,
} from '../../../../../../interfaces/IAnnouncement';
import { GUIDType } from '../../../../../../interfaces/types';

// Store
import { useAppSelector } from '../../../../../../store/hooks';

// API
import animalAnnouncementApi from '../../../../../../api/animalAnnouncementApi';
import chainInformationApi from '../../../../../../api/chainInformationApi';
import printingApi from '../../../../../../api/printingApi';

// Feature - Animal Announcement
import ConfirmDialog from '../../../../../shared/components/confirm/ConfirmDialog';
import { Strings } from '../../../../../shared/constants/strings';
import {
    calculateAmountOfAnimals,
    calculateOrderedAnimalCount,
    collectWeekComparator,
    getAnnouncementAnimals,
    getUniqueCollectWeeks,
    handleAnimalAnnouncementDeletionRestrictions,
    isAnimalAnnouncementUpdateRestricted,
    hasAnnouncementResponseErrors,
} from '../../../../helpers/data';
import { AnimalAnnouncementsListViewStrings } from '../../strings/AnimalAnnouncementsListViewStrings';
import { isAnimalsAndSalesType } from '../../../../../shared/helpers/data';

// Feature - Chain Information
import { IChainInformationData } from '../../../../../chain-information/IChainInformationDialog';
import ChainInformationDialog from '../../../../../chain-information/components/ChainInformationDialog';

// Feature - Persons
import { fetchPersonRelations } from '../../../../../../persons/fetchPersonRelations';

export interface IAnimalAnnouncementListViewGridProps {
    data: IAnimalAnnouncement[];
    loading: boolean;
    handleAnimalAnnouncementDelete: (announcementNumber: number) => void;
    handleAnimalAnnouncementDeleteById: (announcementId: GUIDType) => void;
    handleAnimalAnnouncementModification: (item: IAnimalAnnouncement) => void;
    canCreateAnimalAnnouncement: boolean | null;
}

export default function AnimalAnnouncementListViewGrid(
    props: IAnimalAnnouncementListViewGridProps
) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaBovine',
        'AnelmaGeneral',
        'AnelmaTiltu',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const userData = useAppSelector((state) => state.userData.data);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState<string>('');
    const [collectWeekDayParams, setCollectWeekDayParams] = useState<ParameterValues>([]);
    const [bovineSalesTypeParams, setBovineSalesTypeParams] = useState<ParameterValues>([]);
    const [selectedRow, setSelectedRow] = useState<IAnimalAnnouncement>();
    const [chainInformations, setChainInformations] = useState<IChainInformationData[]>([]);
    const [chainInformationDialogVisible, setChainInformationDialogVisible] =
        useState<boolean>(false);
    const [announcementId, setAnnouncementId] = useState<GUIDType>('');
    const [announcementNumber, setAnnouncementNumber] = useState<string>('');
    const [animalsForChainInformation, setAnimalsForChainInformation] = useState<
        IAnimalAnnouncementAnimal[]
    >([]);
    const [isLoadingChainInfos, setIsLoadingChainInfos] = useState<boolean>(false);
    const [announcementIds, setAnnouncementIds] = useState<string[]>(props.data.map((i) => i.Id));
    const [snellmanUser, setSnellmanUser] = useState<boolean>(false);

    const checkChainInfoFound = (announcementId: GUIDType) => {
        let chainInfoFound = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoFound) {
            return false;
        } else {
            return true;
        }
    };

    const checkChainInfoConfirmed = (announcementId: GUIDType) => {
        let chainInfoToCheck = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoToCheck?.FormConfirmation) {
            return true;
        } else {
            return false;
        }
    };

    const checkChainInfoNotify = (announcementId: GUIDType) => {
        let chainInfoToCheck = chainInformations.find((c) => c.AnnouncementId === announcementId);
        if (chainInfoToCheck) {
            if (
                chainInfoToCheck.MedicatedFeed === true ||
                chainInfoToCheck.HealthInformation === true ||
                chainInfoToCheck.MedicalAccounting === true ||
                chainInfoToCheck.AuthorityRegulations === true ||
                chainInfoToCheck.ForeignObject === true ||
                chainInfoToCheck.GmFree === true ||
                chainInfoToCheck.AdditionalInfo.length > 0 ||
                chainInfoToCheck.MedicatedAnimals.length > 0
            ) {
                return true;
            } else {
                return false;
            }
        }
    };

    const openChainInformationDialog = (data: any) => {
        setAnnouncementId(data.Id);
        setAnnouncementNumber(data.Number);
        setAnimalsForChainInformation(getAnnouncementAnimals(data));
        setChainInformationDialogVisible(true);
    };

    const toggleChainInformationReadOnly = (announcementId: GUIDType): boolean => {
        if (announcementId) {
            const selectedAnnouncement = props.data.find((_) => _.Id === announcementId);
            if (selectedAnnouncement !== undefined)
                return isAnimalAnnouncementUpdateRestricted(selectedAnnouncement);
        }
        return false;
    };

    const checkChainInformationAuth = (announcementId: GUIDType) => {
        if (
            auth.canUpdateChainInformationAdmin === true &&
            checkChainInfoFound(announcementId) === false &&
            auth.canViewChainInformation === false
        ) {
            return false;
        } else if (
            auth.canUpdateChainInformationAdmin &&
            checkChainInfoFound(announcementId) === true
        ) {
            return false;
        } else if (auth.canViewChainInformation) {
            return false;
        } else {
            return true;
        }
    };

    const columns: AGridColumns = [
        {
            field: AnimalAnnouncementsListViewStrings.Status,
            headerName: t('AnelmaBovine:1035'),
            renderCell: (params) => <TextWithTooltip text={String(params.value)} />,
            valueGetter: (params, row) =>
                row.Status !== undefined && row.Status !== null ? row.Status : '',
            width: 80,
        },
        {
            field: AnimalAnnouncementsListViewStrings.AnnouncementNumberField,
            headerName: t('AnelmaBovine:1137'),
            renderCell: (params) => {
                const value = params.value as number;

                return value === 0 ? (
                    <ADefaultIconButton
                        icon={<HourglassEmptyTwoTone />}
                        tooltip={t('AnelmaBovine:1159')?.toString() ?? ''}
                        onClick={() => {}}
                        disabled={false}
                    />
                ) : (
                    value
                );
            },
            width: 80,
        },
        {
            field: AnimalAnnouncementsListViewStrings.TypeField,
            headerName: t('AnelmaBovine:1029'),
            renderCell: (params) => <TextWithTooltip text={String(params.value)} />,
            valueGetter: (params, row) => resolveAnnouncementTypeAsText(row.Type),
            width: 110,
        },
        {
            ...gridColumns.dateTime(
                AnimalAnnouncementsListViewStrings.CreatedField,
                t('AnelmaBovine:1138'),
                dayFormat,
                100
            ),
        },
        {
            field: AnimalAnnouncementsListViewStrings.BatchesFields,
            headerName: t('AnelmaBovine:1139'),
            renderCell: (params) => {
                return <TextsWithTooltip texts={params.value as string[]} />;
            },
            valueGetter: (params, row) =>
                getUniqueCollectWeeks(row.Batches as IAnimalAnnouncementBatch[]),
            sortComparator: (x1, x2) =>
                collectWeekComparator(String((x1 as any[])[0]), String((x2 as any[])[0])),
            width: 95,
        },
        {
            field: AnimalAnnouncementsListViewStrings.ChainInformationField,
            headerName: t('AnelmaBovine:1067'),
            renderCell: (params) => {
                switch ((params.row as IAnimalAnnouncement).Type) {
                    case AnnouncementTypeEnum.Slaughter:
                        return (
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <AAttachmentIconButton
                                    key={`${params.id}-attachment`}
                                    onClick={() => {
                                        openChainInformationDialog(params.row);
                                    }}
                                    disabled={
                                        params.row.Number === 0 ||
                                        checkChainInformationAuth(params.row.Id)
                                    }
                                    tooltip={t('AnelmaBovine:1143')}
                                />
                                {checkChainInfoFound(params.row.Id) && (
                                    <AWarningIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1144')}
                                    />
                                )}
                                {checkChainInfoConfirmed(params.row.Id) && (
                                    <ADoneIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1145')}
                                    />
                                )}
                                {checkChainInfoNotify(params.row.Id) && (
                                    <APriorityHighIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1146')}
                                    />
                                )}
                            </Grid>
                        );
                    case AnnouncementTypeEnum.SlaughterWithChainInformation:
                        return (
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <AAttachmentIconButton
                                    key={`${params.id}-attachment`}
                                    onClick={() => {
                                        openChainInformationDialog(params.row);
                                    }}
                                    disabled={checkChainInformationAuth(params.row.Id)}
                                    tooltip={t('AnelmaBovine:1143')}
                                />
                                {checkChainInfoFound(params.row.Id) && (
                                    <AWarningIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1144')}
                                    />
                                )}
                                {checkChainInfoConfirmed(params.row.Id) && (
                                    <ADoneIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1145')}
                                    />
                                )}
                                {checkChainInfoNotify(params.row.Id) && (
                                    <APriorityHighIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1146')}
                                    />
                                )}
                            </Grid>
                        );
                    case AnnouncementTypeEnum.ManualBovineSlaughter:
                        return (
                            <Grid style={{ display: 'flex', alignItems: 'center' }}>
                                <AAttachmentIconButton
                                    key={`${params.id}-attachment`}
                                    onClick={() => {
                                        openChainInformationDialog(params.row);
                                    }}
                                    disabled={checkChainInformationAuth(params.row.Id)}
                                    tooltip={t('AnelmaBovine:1143')}
                                />
                                {checkChainInfoFound(params.row.Id) && (
                                    <AWarningIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1144')}
                                    />
                                )}
                                {checkChainInfoConfirmed(params.row.Id) && (
                                    <ADoneIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1145')}
                                    />
                                )}
                                {checkChainInfoNotify(params.row.Id) && (
                                    <APriorityHighIconButton
                                        onClick={() => {}}
                                        tooltip={t('AnelmaBovine:1146')}
                                    />
                                )}
                            </Grid>
                        );
                    default:
                        return <></>;
                }
            },
            width: 120,
            sortable: false,
        },
        {
            field: AnimalAnnouncementsListViewStrings.AmountField,
            headerName: t('AnelmaBovine:1037'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params, row) => getAnimalCount(row.Batches, row.Type),
            width: 105,
        },
        {
            field: AnimalAnnouncementsListViewStrings.SalesTypeField,
            headerName: t('AnelmaBovine:1019'),
            renderCell: (params) => {
                return <TextWithTooltip text={String(params.value)} />;
            },
            valueGetter: (params, row) => {
                if ((row.Type as AnnouncementTypeEnum) !== AnnouncementTypeEnum.BovinePurchase) {
                    return (
                        row.Batches.slice(0, 1).map((batch: IAnimalAnnouncementBatch) => {
                            return getSalesType(
                                isAnimalsAndSalesType(batch) ? batch.Animals[0].SalesType : 0,
                                bovineSalesTypeParams
                            );
                        })[0] || ''
                    );
                }
                return (
                    row.Batches.slice(0, 1).map((batch: IAnimalAnnouncementBatch) => {
                        return getSalesType(
                            batch.SalesType !== null && batch.SalesType !== undefined
                                ? batch.SalesType
                                : 0,
                            bovineSalesTypeParams
                        );
                    })[0] || ''
                );
            },
            width: 100,
        },
        gridColumns.withTooltip({
            field: AnimalAnnouncementsListViewStrings.HoldingSiteField,
            headerName: t('AnelmaBovine:1140'),
            width: 125,
        }),
        gridColumns.withTooltip({
            field: AnimalAnnouncementsListViewStrings.InfoField,
            headerName: t('AnelmaBovine:1039'),
            width: 160,
        }),
        gridColumns.withTooltip({
            field: AnimalAnnouncementsListViewStrings.AttachmentsField,
            headerName: t('AnelmaBovine:1038'),
            width: 160,
        }),
    ];

    useEffect(() => {
        paramRepository.load(['AnelmaAnimalAnnouncement', 'AnelmaBovine']).then(() => {
            setCollectWeekDayParams(
                paramRepository.resource('AnelmaAnimalAnnouncement', 'CollectWeekDay')
            );
            setBovineSalesTypeParams(paramRepository.resource('AnelmaBovine', 'SalesType'));
        });
    }, []);

    useEffect(() => {
        if (userData) {
            fetchPersonRelations(userData.Logins[0].Login).then((response) => {
                setSnellmanUser(response[0]);
            });
        }
    }, [userData]);

    useEffect(() => {
        setIsLoadingChainInfos(true);
        setAnnouncementIds(props.data.map((i) => i.Id));
        setIsLoadingChainInfos(false);
    }, [props.data]);

    useEffect(() => {
        let ids = props.data.map((i) => i.Id);
        if (ids.length > 0 && isLoadingChainInfos === false) {
            setIsLoadingChainInfos(true);
            chainInformationApi.loadMultipleChainInformations(ids).then((response) => {
                if (response) {
                    let chainInfos: IChainInformationData[] = [];
                    for (let i = 0; i < response.Items.length; i++) {
                        let chainInfo: IChainInformationData = {
                            AnnouncementId: response.Items[i].AnnouncementId,
                            MedicatedFeed: response.Items[i].MedicatedFeed,
                            HealthInformation: response.Items[i].HealthInformation,
                            AuthorityRegulations: response.Items[i].AuthorityRegulations,
                            ForeignObject: response.Items[i].ForeignObject,
                            GmFree: response.Items[i].GmFree,
                            MedicalAccounting: response.Items[i].MedicalAccounting,
                            WaitingTimeOver: response.Items[i].WaitingTimeOver,
                            NasevaInfo: response.Items[i].NasevaInfo,
                            SlaughterHouseInfo: response.Items[i].SlaughterHouseInfo,
                            MedicationInfo: response.Items[i].MedicationInfo,
                            Anomalies: response.Items[i].Anomalies,
                            Regulations: response.Items[i].Regulations,
                            SamplingOrder: response.Items[i].SamplingOrder,
                            VetName: response.Items[i].VetName,
                            AdditionalInfo: response.Items[i].AdditionalInfo,
                            ForeignObjectInfo: response.Items[i].ForeignObjectInfo,
                            AnimalSymptoms: response.Items[i].Symptoms,
                            OtherSymptom: response.Items[i].OtherSymptom,
                            AnimalDiseases: response.Items[i].Diseases,
                            OtherDisease: response.Items[i].OtherDisease,
                            MedicatedAnimals: response.Items[i].Medications,
                            FormConfirmation: response.Items[i].FormConfirmation,
                            Signature: response.Items[i].Signature,
                            Signatory: response.Items[i].Signatory,
                            SignatureDate: response.Items[i].SignatureDate,
                        };
                        chainInfos.push(chainInfo);
                    }
                    setChainInformations(chainInfos);
                    setIsLoadingChainInfos(false);
                }
            });
        }
    }, [
        announcementIds,
        chainInformationDialogVisible,
        props.handleAnimalAnnouncementModification,
    ]);

    useEffect(() => {
        setIsLoading(props.loading);
    }, [props.loading]);

    useEffect(() => {
        if (props.canCreateAnimalAnnouncement !== null && !props.canCreateAnimalAnnouncement) {
            enqueueSnackbar(t('AnelmaBovine:1092'), {
                variant: 'warning',
            });
        }
    }, [props.canCreateAnimalAnnouncement]);

    const handleConfirmDeleteDialogData = (id: string) => {
        setConfirmDialogVisible(true);
        setSelectedAnnouncement(id);
    };

    const deleteAnimalAnnouncement = () => {
        setIsLoading(true);
        const animalAnnouncement = props.data.find((a) => a.Id === selectedAnnouncement);
        if (animalAnnouncement !== undefined) {
            if (animalAnnouncement.Number) {
                animalAnnouncementApi
                    .deleteAnimalAnnouncement(Number(animalAnnouncement.Number))
                    .then((response) => {
                        if (!response) {
                            enqueueSnackbar(`${t('AnelmaGeneral:1019')}`, {
                                variant: 'error',
                            });
                            setIsLoading(false);
                            return;
                        }
                        if (hasAnnouncementResponseErrors(response)) {
                            const errorCodes = Array.from(
                                new Set(response.Summary.Errors.map((_) => _.Code))
                            );
                            for (const errorCode of errorCodes) {
                                enqueueSnackbar(t(`AnelmaTiltu:${errorCode}`), {
                                    variant: 'error',
                                });
                            }
                            setIsLoading(false);
                            return;
                        }
                        setSelectedAnnouncement('');
                        props.handleAnimalAnnouncementDelete(animalAnnouncement.Number);
                        setIsLoading(false);
                        const type = animalAnnouncement.Type;
                        if (
                            type !== AnnouncementTypeEnum.ManualBovineIntermediation &&
                            type !== AnnouncementTypeEnum.ManualBovineSlaughter
                        )
                            chainInformationApi.deleteChainInformation(selectedAnnouncement).then();
                    });
            } else {
                animalAnnouncementApi
                    .deleteAnimalAnnouncementById(animalAnnouncement.Id)
                    .then((response) => {
                        setSelectedAnnouncement('');
                        props.handleAnimalAnnouncementDeleteById(animalAnnouncement.Id);
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        console.log('ERR', err);
                    });
            }
        }
    };

    const resolveAnnouncementTypeAsText = (type: AnnouncementTypeEnum): string => {
        switch (type) {
            case AnnouncementTypeEnum.Slaughter:
            case AnnouncementTypeEnum.SlaughterWithChainInformation:
            case AnnouncementTypeEnum.ManualBovineSlaughter:
                return t('AnelmaBovine:1070');
            case AnnouncementTypeEnum.Intermediation:
            case AnnouncementTypeEnum.ManualBovineIntermediation:
                return t('AnelmaBovine:1071');
            case AnnouncementTypeEnum.BovinePurchase:
                return t('AnelmaBovine:1072');
        }
    };

    const resolveEditTooltipText = (type: AnnouncementTypeEnum): string => {
        switch (type) {
            case AnnouncementTypeEnum.Slaughter:
                return t('AnelmaBovine:1044');
            default:
                return t('AnelmaGeneral:1075');
        }
    };

    const getSalesType = (salesType: number, bovineSalesTypeParams: ParameterValues) => {
        return salesType > 0
            ? bovineSalesTypeParams.find((_) => _.code === String(salesType))?.text
            : '';
    };

    const getAnimalCount = (
        batches: IAnimalAnnouncementBatch[],
        announcementType: AnnouncementTypeEnum
    ): number => {
        switch (announcementType) {
            case AnnouncementTypeEnum.Slaughter:
            case AnnouncementTypeEnum.SlaughterWithChainInformation:
            case AnnouncementTypeEnum.ManualBovineSlaughter:
                return calculateAmountOfAnimals(batches);
            case AnnouncementTypeEnum.Intermediation:
            case AnnouncementTypeEnum.ManualBovineIntermediation:
                return calculateAmountOfAnimals(batches);
            case AnnouncementTypeEnum.BovinePurchase:
                return calculateOrderedAnimalCount(batches);
        }
    };

    const handleEditBovineAnnouncement = (item: IAnimalAnnouncement) => {
        setSelectedRow(item);
        props.handleAnimalAnnouncementModification(item as IAnimalAnnouncement);
    };

    const statusAllowsEdit = (params: GridRenderCellParams) => {
        const item = params.row as unknown as IAnimalAnnouncement;
        if (item.Status == 'LO') return false;
        return true;
    };

    // Row had GridRowData parameter. Please, check the correct type and provide it as a parameter
    const handleAnimalAnnouncementUpdateBtnDisabled = (row: any) => {
        if (props.canCreateAnimalAnnouncement !== null && !props.canCreateAnimalAnnouncement)
            return true;
        return isAnimalAnnouncementUpdateRestricted(row as IAnimalAnnouncement);
    };

    const announcementSearchBtnDisabled = () => {
        if (auth.canViewBovineSlaughterAnnouncementDialog) {
            return false;
        } else {
            return true;
        }
    };

    const toggleAnnouncementReadOnlyMode = (params: GridRenderCellParams): JSX.Element => {
        if (isAnimalAnnouncementUpdateRestricted(params.row as IAnimalAnnouncement))
            return (
                <ASearchIconButton
                    key={`${params.id}-search`}
                    onClick={() => {
                        let item = params.row as unknown as IAnimalAnnouncement;
                        handleEditBovineAnnouncement(item);
                    }}
                    tooltip={t('AnelmaBovine:1163')}
                    disabled={params?.row?.Number === 0 || announcementSearchBtnDisabled()}
                />
            );
        return (
            <AEditIconButton
                key={`${params.id}-edit`}
                onClick={() => {
                    let item = params.row as unknown as IAnimalAnnouncement;
                    handleEditBovineAnnouncement(item);
                }}
                tooltip={resolveEditTooltipText(params.row.Type)}
                disabled={handleAnimalAnnouncementUpdateBtnDisabled(params.row)}
            />
        );
    };

    const dataGridActions: ADataGridActions = [
        (params) => {
            {
                return statusAllowsEdit(params) ? (
                    auth.canDeleteAnelmaBovineBrokerPurchase && (
                        <ADeleteIconButton
                            key={`${params.id}-delete`}
                            onClick={() => {
                                handleConfirmDeleteDialogData(String(params.id));
                            }}
                            tooltip={t('AnelmaGeneral:1012')}
                            disabled={handleAnimalAnnouncementDeletionRestrictions(
                                params.row as IAnimalAnnouncement,
                                snellmanUser
                            )}
                        />
                    )
                ) : (
                    <></>
                );
            }
        },
        (params) => {
            {
                return (
                    auth.canUpdateAnelmaBovineBrokerPurchase &&
                    toggleAnnouncementReadOnlyMode(params)
                );
            }
        },
        (params) => {
            let canPrint =
                auth.canViewAnelmaBovineBrokerPurchase &&
                (params.row as IAnimalAnnouncement).Number !== 0 &&
                !announcementSearchBtnDisabled();

            return (
                <ADefaultIconButton
                    icon={<PictureAsPdf />}
                    onClick={() => {
                        printingApi.printAnimalAnnouncement(
                            (params.row as IAnimalAnnouncement).Number
                        );
                    }}
                    tooltip=''
                    disabled={!canPrint}
                />
            );
        },
    ];

    return (
        <Container id={Strings.AnnouncementListGridContainer}>
            <ADataGrid
                className={'MuiDataGrid-hiddenSortBtns'}
                loading={isLoading}
                actions={dataGridActions}
                columns={columns}
                enablePagination
                enableRowNumbers
                enableFiltering
                rows={props.data
                    .map((i) =>
                        mergeRight(i, {
                            id: i.Id,
                        })
                    )
                    .sort((a, b) => new Date(b.Created).valueOf() - new Date(a.Created).valueOf())}
            />

            {confirmDialogVisible && (
                <ConfirmDialog
                    data-robot-id='animal-announcementlist-confirm-dialog'
                    title={'AnelmaGeneral:1142'}
                    content={'AnelmaGeneral:1012'}
                    actionButtonType={Strings.AnnouncementDeleteConfirmActionType}
                    onClose={() => setConfirmDialogVisible(false)}
                    onConfirm={() => deleteAnimalAnnouncement()}
                />
            )}

            {chainInformationDialogVisible && (
                <ChainInformationDialog
                    data-robot-id={
                        'animal-announcementlist-chain-information-dialog-' + announcementId
                    }
                    onClose={() => {
                        setChainInformationDialogVisible(false);
                    }}
                    announcementData={null}
                    announcementId={announcementId}
                    announcementNumber={announcementNumber}
                    animals={animalsForChainInformation}
                    forceReadOnly={toggleChainInformationReadOnly(announcementId)}
                />
            )}
        </Container>
    );
}
