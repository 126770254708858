// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';

// MUI
import { Grid } from '@mui/material';

// Core
import FormValidator from '../../core/FormValidator';
import { ResourceTextApplication } from '../../core/resources';

// Common
import { ADeleteIconButton } from '../../common/buttons';
import {
    ADialog,
    ADialogTitle,
    ADialogContent,
    ADialogActions,
    ADialogButtonsProp,
} from '../../common/dialog';
import AEUIdentifierInput, { euIdentifierRegExp } from '../../common/inputs/AEUIdentifierInput';

// Interfaces
// Store
// API
// Feature - Feedback
import { SelectorConstants } from './constants/SelectorConstants';

interface IAddEUIdentifiersProps {
    onClose: () => void;
    onSave: (euIdentifier: string[]) => void;
}

/** Component to enable the ability for user to be able to add bovines (basically eu-identifiers) to be displayed in the parent component.
 *  Was created for the feedback feature. Somewhat distinct use case from what the animal announcements already has so decided to create a new component with a more generic touch as the feedback use case also is.
 *  JIRA ticket: AN-2426
 */
export default function AddEUIdentifiers({ onClose, onSave }: IAddEUIdentifiersProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
        'AnelmaBovine',
    ]);

    const formValidator = new FormValidator();

    const [euIdentifierInputs, setEuIdentifierInputs] = useState([{ Id: 1, EuIdentifier: '' }]);

    const handleInputChange = (value: string, idx: number) => {
        let onChangeValue = [...euIdentifierInputs];
        onChangeValue[idx].EuIdentifier = value;
        setEuIdentifierInputs(onChangeValue);
    };

    const handleDeleteInput = (idx: number) => {
        const newInputs = [...euIdentifierInputs];
        newInputs.splice(idx, 1);
        setEuIdentifierInputs(newInputs);
    };

    const isSaveDisabled = () => {
        return (
            formValidator.invalid ||
            euIdentifierInputs.some(
                (_) =>
                    _.EuIdentifier === '' ||
                    euIdentifierInputs.some(
                        (_) => !new RegExp(euIdentifierRegExp).test(_.EuIdentifier)
                    )
            )
        );
    };

    const footerActionButtons: ADialogButtonsProp = {
        left: [
            {
                onClick: onClose,
                type: 'cancel',
            },
        ],
        right: [
            {
                onClick: () =>
                    setEuIdentifierInputs([
                        ...euIdentifierInputs,
                        { Id: euIdentifierInputs.length + 1, EuIdentifier: '' },
                    ]),
                type: 'with-label',
                label: t('AnelmaGeneral:1175'),
                icon: <Add />,
            },
            {
                onClick: () =>
                    onSave(Array.from(new Set(euIdentifierInputs.map((_) => _.EuIdentifier)))),
                type: 'save',
                disabled: isSaveDisabled(),
            },
        ],
    };
    return (
        <ADialog open onClose={onClose}>
            <ADialogTitle>{t('AnelmaCommunication:1170')}</ADialogTitle>
            <ADialogContent size='md'>
                <Grid container>
                    {euIdentifierInputs.map((input, idx) => (
                        <Grid container key={input.Id}>
                            <Grid item sm={8} sx={{ paddingTop: '40px' }}>
                                <AEUIdentifierInput
                                    id={`${SelectorConstants.AddBovineDialog}-eu-identifier-${input.Id}`}
                                    forceValidEmpty={true}
                                    label={t('AnelmaBovine:1005')}
                                    onChange={(_) => handleInputChange(_, idx)}
                                    value={input.EuIdentifier}
                                    validator={formValidator}
                                    required
                                />
                            </Grid>

                            {euIdentifierInputs.length > 1 && (
                                <Grid item sm={2} style={{ paddingTop: '55px' }}>
                                    <ADeleteIconButton onClick={() => handleDeleteInput(idx)} />
                                </Grid>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </ADialogContent>

            <ADialogActions buttons={footerActionButtons} />
        </ADialog>
    );
}
