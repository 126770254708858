//React
import { useState, useEffect } from 'react';

// MUI
import {
    Grid,
    Table,
    Typography,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

// Libraries
import { useTranslation } from 'react-i18next';

// Common
import ViewLoader from '../common/ViewLoader';
import { ADefaultButton } from '../common/buttons';

// Core
import { ParameterValues, paramRepository, ResourceTextApplication } from '../core/resources';
import auth from '../core/authorization';

//API
import BovineVariablesApi from '../api/bovineVariablesApi';

//Feature
import SlaughterVariablesDialog from './SlaughterVariablesDialog';
import { dayFormat } from '../core/constants';
import moment from 'moment';
import {
    calculateOtherSlaughterVariables,
    calculateOtherPurchaseVariables,
} from './bovineVariablesCalculator';

//Interface
import { IVariableTypeEnum, IVariableGroup } from '../interfaces/IBovineVariables';
import { IFarmDetails } from '../interfaces/IBusinessEntities';
import { AnimalTypeEnum, FarmingTypeEnum } from '../interfaces/enums';
import { GUIDType } from '../interfaces/types';
import FormValidator from '../core/FormValidator';

export interface SlaughterVariablesViewProps {
    farmId: GUIDType;
    farmInfo: IFarmDetails | null;
    adminValues: boolean | null;
}

export default function SlaughterVariablesView({
    farmId,
    farmInfo,
    adminValues,
}: SlaughterVariablesViewProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaVariables', 'AnelmaCompany']);
    const [variableParams, setVariableParams] = useState<ParameterValues>([]);
    const [brokerVariableParams, setBrokerVariableParams] = useState<ParameterValues>([]);
    const [slaughterVariableParams, setSlaughterVariableParams] = useState<ParameterValues>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [showSlaughterVariables, setShowSlaughterVariables] = useState<boolean>(
        adminValues ?? false
    );
    const [showPurchaceVariables, setShowPurchaceVariables] = useState<boolean>(
        adminValues ?? false
    );
    const [dialogVariableType, setDialogVariableType] = useState<IVariableTypeEnum>(
        IVariableTypeEnum.Undefined
    );

    const [bovineProductionLines, setBovineProductionLines] = useState<FarmingTypeEnum[]>([]);
    const [slaughterVariables, setSlaughterVariables] = useState<IVariableGroup | null>(null);
    const [purchaseVariables, setPurchaseVariables] = useState<IVariableGroup | null>(null);

    const validator = new FormValidator();

    useEffect(() => {
        setIsLoading(true);

        if ((farmInfo || adminValues) && farmId.length === 36) {
            if (farmInfo) {
                setBovineProductionLines(
                    farmInfo.ProductionLines.find((l) => l.Type === AnimalTypeEnum.Bovine)
                        ?.FarmingTypes ?? []
                );
            }

            BovineVariablesApi.getBovineVariables(farmId).then((result) => {
                let bovineVariables = result.Entity;

                if (
                    bovineVariables &&
                    bovineVariables.VariableGroup &&
                    bovineVariables.VariableGroup.length > 0
                ) {
                    let slaughterVariables = bovineVariables.VariableGroup.find(
                        (v) => v.VariableType === IVariableTypeEnum.Slaughter
                    );

                    let purchaseVariables = bovineVariables.VariableGroup.find(
                        (v) => v.VariableType === IVariableTypeEnum.Purchase
                    );

                    if (slaughterVariables) {
                        setSlaughterVariables({
                            ...slaughterVariables,
                            VariablesList: calculateOtherSlaughterVariables(
                                slaughterVariables.VariablesList
                            ),
                        });
                    }

                    if (purchaseVariables) {
                        setPurchaseVariables({
                            ...purchaseVariables,
                            VariablesList: calculateOtherPurchaseVariables(
                                purchaseVariables.VariablesList
                            ),
                        });
                    }
                } else {
                    setSlaughterVariables(null);
                    setPurchaseVariables(null);
                }
            });
        } else {
            setSlaughterVariables(null);
            setPurchaseVariables(null);
        }

        paramRepository.load(['AnelmaVariables']).then(() => {
            setVariableParams(paramRepository.resource('AnelmaVariables', 'Variable', 'default'));
            setSlaughterVariableParams(
                paramRepository.resource('AnelmaVariables', 'SlaughterVariable', 'default')
            );
            setBrokerVariableParams(
                paramRepository.resource('AnelmaVariables', 'BrokerVariable', 'default')
            );
        });

        setIsLoading(false);
    }, [dialogIsOpen, farmInfo, farmId]);

    useEffect(() => {
        if (farmInfo) {
            setShowSlaughterVariables(checkShowSlaughterVariables());
            setShowPurchaceVariables(checkShowPurchaceVariables());
        }
    }, [bovineProductionLines]);

    const checkShowPurchaceVariables = () => {
        if (adminValues) return true;

        return (
            bovineProductionLines.findIndex(
                (farmingType) => farmingType === FarmingTypeEnum.Transmission
            ) != -1
        );
    };

    const checkShowSlaughterVariables = () => {
        if (adminValues) return true;

        return (
            bovineProductionLines.findIndex(
                (farmingType) =>
                    farmingType === FarmingTypeEnum.Calving ||
                    farmingType === FarmingTypeEnum.Dairy ||
                    farmingType === FarmingTypeEnum.Upbringing
            ) != -1
        );
    };

    const dialogData = () => {
        switch (dialogVariableType) {
            case IVariableTypeEnum.Slaughter:
                return slaughterVariables;
            case IVariableTypeEnum.Purchase:
                return purchaseVariables;
            default:
                return null;
        }
    };

    const renderModificationDetails = (variableType: IVariableTypeEnum): JSX.Element => {
        switch (variableType) {
            case IVariableTypeEnum.Slaughter:
                if (slaughterVariables && !slaughterVariables.IsDefaultVariables)
                    return modificationDetailsElement(
                        slaughterVariables.ModificationDate,
                        slaughterVariables.Modificator
                    );
                else return defaultValuesSignElement();
            case IVariableTypeEnum.Purchase:
                if (purchaseVariables && !purchaseVariables.IsDefaultVariables)
                    return modificationDetailsElement(
                        purchaseVariables.ModificationDate,
                        purchaseVariables.Modificator
                    );
                else return defaultValuesSignElement();
            default:
                return defaultModificationDetailsElement();
        }
    };

    const modificationDetailsElement = (date?: string, modificator?: string): JSX.Element => {
        if (date && modificator) {
            return (
                <Typography variant='h6'>
                    {t('AnelmaVariables:1001')
                        .replace('(date)', moment(date).format(dayFormat))
                        .replace('(username)', modificator)}
                </Typography>
            );
        }

        return defaultModificationDetailsElement();
    };

    const defaultModificationDetailsElement = (): JSX.Element => {
        return (
            <Typography variant='h6'>
                {t('AnelmaVariables:1001').replace('(date),', ' ').replace('(username)', ' ')}
            </Typography>
        );
    };

    const defaultValuesSignElement = () => {
        return <Typography variant='h6'>{t('AnelmaVariables:1018')}</Typography>;
    };

    const getVariableParams = (params: number, groupFilter: number): any => {
        if (groupFilter === IVariableTypeEnum.Slaughter) {
            let slaughterParams = slaughterVariableParams.find(
                (p) => Number(p.code) === Number(params)
            );
            return slaughterParams?.text;
        } else if (groupFilter === IVariableTypeEnum.Purchase) {
            let brokerParams = brokerVariableParams.find((p) => Number(p.code) === Number(params));
            return brokerParams?.text;
        }
    };

    const getSlaughterInfo = (): JSX.Element => {
        return (
            <TableContainer component={Paper} style={{ maxWidth: '600px' }}>
                <Typography variant='h4'>{t('AnelmaVariables:1000')}</Typography>
                <Grid container>
                    <Grid item md={6}>
                        {renderModificationDetails(IVariableTypeEnum.Slaughter)}
                    </Grid>

                    {auth.canCreateVariablesAdmin || auth.canUpdateVariablesAdmin ? (
                        <Grid item md={6}>
                            <ADefaultButton
                                key={'edit-tranmission'}
                                size={'small'}
                                icon={<EditIcon />}
                                onClick={() => {
                                    setDialogVariableType(IVariableTypeEnum.Slaughter);
                                    setDialogIsOpen(true);
                                }}
                                children={
                                    adminValues
                                        ? t('AnelmaVariables:1002')
                                        : t('AnelmaVariables:1016')
                                }
                            />
                        </Grid>
                    ) : auth.canUpdateVariables ? (
                        <Grid item md={6}>
                            <ADefaultButton
                                key={'edit-tranmission'}
                                size={'small'}
                                icon={<EditIcon />}
                                onClick={() => {
                                    setDialogVariableType(IVariableTypeEnum.Slaughter);
                                    setDialogIsOpen(true);
                                }}
                                children={t('AnelmaVariables:1016')}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>{''} </TableCell>
                            <TableCell align='center'>{t('AnelmaVariables:1003')} </TableCell>
                            <TableCell align='center'>{t('AnelmaVariables:1004')} </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {slaughterVariables && slaughterVariables.VariablesList.length > 0
                            ? slaughterVariables.VariablesList.map((row, index) => (
                                  <TableRow key={`slaugter-variables-table-row-${index}`}>
                                      <TableCell align='left'>
                                          {getVariableParams(
                                              row.Variable,
                                              Number(IVariableTypeEnum.Slaughter)
                                          )}
                                      </TableCell>
                                      {row.Item.map((v, i) => {
                                          return (
                                              <TableCell
                                                  key={`slaugter-variables-table-row-${index}-${i}`}
                                              >
                                                  {v.VariableValue}
                                              </TableCell>
                                          );
                                      })}
                                  </TableRow>
                              ))
                            : variableParams.map((value, index) => {
                                  return (
                                      <TableRow key={`slaugter-variables-table-row-${index}`}>
                                          <TableCell align='left'>
                                              {getVariableParams(
                                                  value.code as unknown as number,
                                                  IVariableTypeEnum.Slaughter
                                              )}
                                          </TableCell>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                      </TableRow>
                                  );
                              })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const getPurchaseVariables = (): JSX.Element => {
        return (
            <TableContainer component={Paper} style={{ maxWidth: '600px' }}>
                <Typography variant='h4'>{t('AnelmaVariables:1010')}</Typography>
                <Grid container>
                    <Grid item md={6}>
                        {renderModificationDetails(IVariableTypeEnum.Purchase)}
                    </Grid>
                    {auth.canCreateVariablesAdmin || auth.canUpdateVariablesAdmin ? (
                        <Grid item md={6}>
                            <ADefaultButton
                                key={'edit-purchase'}
                                size={'small'}
                                icon={<EditIcon />}
                                onClick={() => {
                                    setDialogVariableType(IVariableTypeEnum.Purchase);
                                    setDialogIsOpen(true);
                                }}
                                children={
                                    adminValues
                                        ? t('AnelmaVariables:1002')
                                        : t('AnelmaVariables:1016')
                                }
                            />
                        </Grid>
                    ) : auth.canUpdateVariables ? (
                        <Grid item md={6}>
                            <ADefaultButton
                                key={'edit-tranmission'}
                                size={'small'}
                                icon={<EditIcon />}
                                onClick={() => {
                                    setDialogVariableType(IVariableTypeEnum.Purchase);
                                    setDialogIsOpen(true);
                                }}
                                children={t('AnelmaVariables:1016')}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>{''} </TableCell>
                            <TableCell align='center'>{t('AnelmaVariables:1003')} </TableCell>
                            <TableCell align='center'>{t('AnelmaVariables:1004')} </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchaseVariables && purchaseVariables.VariablesList.length > 0
                            ? purchaseVariables.VariablesList.map((row, index) => (
                                  <TableRow key={`slaugter-variables-table-row-${index}`}>
                                      <TableCell align='left'>
                                          {getVariableParams(
                                              row.Variable,
                                              Number(IVariableTypeEnum.Purchase)
                                          )}
                                      </TableCell>
                                      {row.Item.map((v, i) => {
                                          return (
                                              <TableCell
                                                  key={`slaugter-variables-table-row-${index}-${i}`}
                                              >
                                                  {v.VariableValue}
                                              </TableCell>
                                          );
                                      })}
                                  </TableRow>
                              ))
                            : variableParams.map((value, index) => {
                                  return (
                                      <TableRow key={`slaugter-variables-table-row-${index}`}>
                                          <TableCell align='left'>
                                              {getVariableParams(
                                                  value.code as unknown as number,
                                                  IVariableTypeEnum.Purchase
                                              )}
                                          </TableCell>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                      </TableRow>
                                  );
                              })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <>
            {!isLoading ? (
                auth.canViewVariables &&
                (adminValues || farmInfo) && (
                    <Grid container spacing={2}>
                        {showSlaughterVariables && (
                            <Grid item md={6}>
                                {getSlaughterInfo()}
                            </Grid>
                        )}
                        {showPurchaceVariables && (
                            <Grid item md={6}>
                                {getPurchaseVariables()}
                            </Grid>
                        )}
                    </Grid>
                )
            ) : (
                <ViewLoader />
            )}

            {dialogIsOpen && dialogData && (
                <SlaughterVariablesDialog
                    farmId={farmId}
                    variables={dialogData()}
                    variableType={dialogVariableType}
                    validator={validator}
                    onClose={() => {
                        setDialogIsOpen(false);
                    }}
                />
            )}
        </>
    );
}
