import { nasevaUrl } from './apiEndpoints';
import ApiBase from './ApiBase';
import { INasevaResult, INasevaSpecialSituation } from '../interfaces/IAnimal';
import { AnelmaCultureType, GUIDType } from '../interfaces/types';

// Pretty strange models on Snellman.Anelma3.API.Application.Naseva.Queries
export interface ISpecialSituationQuery {
    FarmsGuIds: IFarmGuid[];
    Culture: string;
}

export interface IFarmGuid {
    Id: string;
}

class NasevaApi extends ApiBase {
    getLatestAnnualVisits = (farmGuid: GUIDType, culture: AnelmaCultureType | undefined) => {
        if (culture === 'fi') culture = 'fi-FI';
        return this.getList<INasevaResult>(`${nasevaUrl}/get-visits/${farmGuid}/${culture}`);
    };

    getSpecialSituations(payload: ISpecialSituationQuery) {
        return this.postList<INasevaSpecialSituation>(
            `${nasevaUrl}/get-special-situations`,
            payload
        );
    }
}

export default new NasevaApi();
