// Libraries
import { useTranslation } from 'react-i18next';

// Core
import { ParameterValue, ResourceTextApplication, paramRepository } from '../../core/resources';

// Common
import { ADataGrid, AGridColumns, gridColumns } from '../dataGrid';
import {
    ADialog,
    ADialogActions,
    ADialogButtonsProp,
    ADialogContent,
    ADialogTitle,
} from '../dialog';
import { TextsWithTooltip, TextWithTooltip } from '../typography';
import ViewLoader from '../ViewLoader';

// Interfaces
import {
    IProductionLine,
    IContactRow,
    IContactPersonRow,
} from '../../interfaces/IBusinessEntities';

// Feature
import {
    GetProductionLinesFromIntList,
    GetSpeciesFronIntList,
} from '../../searchParameters/Helpers/Parsers';
import { useEffect, useState } from 'react';

interface IProps {
    onClose: () => any;
    items: IContactRow[];
    titleParameter: 'AnelmaCommunication:1033' | 'AnelmaBulletin:1032' | 'AnelmaCommunication:1150';
    usePersonsConnectedToTheFarm?: boolean;
    showPhoneNumbers?: boolean;
}

export default function Recipients({
    onClose,
    items,
    titleParameter,
    usePersonsConnectedToTheFarm,
    showPhoneNumbers,
}: IProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCommunication',
    ]);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [farmingTypeBovineParams, setFarmingTypeBovineParams] = useState<ParameterValue[]>([]);
    const [farmingTypePigParams, setFarmingTypePigParams] = useState<ParameterValue[]>([]);
    const [speciesParams, setSpeciesParams] = useState<ParameterValue[]>([]);
    const [roleParams, setRoleParams] = useState<ParameterValue[]>([]);

    useEffect(() => {
        paramRepository.load(['AnelmaCompany', 'AnelmaGeneral']).then(() => {
            setFarmingTypeBovineParams(
                paramRepository.resource('AnelmaCompany', 'FarmingTypeBovine')
            );
            setFarmingTypePigParams(paramRepository.resource('AnelmaCompany', 'FarmingTypePig'));
            setSpeciesParams(paramRepository.resource('AnelmaCompany', 'Species'));

            setRoleParams(paramRepository.resource('AnelmaGeneral', 'CommunicationGroupTitles'));

            setIsLoading(false);
        });
    }, []);

    const getTitle = () => {
        switch (titleParameter) {
            case 'AnelmaCommunication:1033':
                return (
                    <ADialogTitle>
                        {t(titleParameter).replace('{count}', items.length.toString())}
                    </ADialogTitle>
                );
            case 'AnelmaCommunication:1150':
                return (
                    <ADialogTitle>
                        {t(titleParameter).replace('{count}', items.length.toString())}
                    </ADialogTitle>
                );
            case 'AnelmaBulletin:1032':
                return (
                    <ADialogTitle>
                        {t(titleParameter, {
                            RecieverCount: items.length.toString(),
                        })}
                    </ADialogTitle>
                );
        }
    };

    const resolveColumns = () => {
        if (showPhoneNumbers) {
            return getColumnsWithPhoneNumbers();
        }

        if (usePersonsConnectedToTheFarm) {
            return getPersonColumns();
        }

        return getFarmColumns();
    };

    const getColumnsWithPhoneNumbers = () => {
        const columns: AGridColumns = [];

        columns.push(
            gridColumns.producerNumber(t('AnelmaCommunication:1078'), 'ProducerNumber', 100)
        );

        columns.push(
            gridColumns.withTooltip({
                field: 'FarmName',
                headerName: t('AnelmaCommunication:1047'),
                valueGetter: (params, row) => {
                    if ((row as IContactRow).EntityType === 'Person')
                        return (row as IContactPersonRow).FarmName;

                    return (row as IContactPersonRow).Name;
                },
                width: 150,
            })
        );

        columns.push(
            gridColumns.withTooltip({
                field: 'Name',
                headerName: t('AnelmaCommunication:1071'),
                valueGetter: (params, row) => {
                    if ((row as IContactRow).EntityType === 'Person')
                        return (row as IContactPersonRow).Name;

                    return '';
                },
                width: 150,
            })
        );

        columns.push({
            field: 'Roles',
            headerName: t('AnelmaCommunication:1149'),
            renderCell: (params) => {
                return <TextsWithTooltip texts={params.value as string[]} />;
            },
            valueGetter: (params, row) => {
                if ((row as IContactRow).EntityType === 'Person')
                    return (row as IContactPersonRow).Roles.map(
                        (r) => roleParams.find((p) => p.code === r.Type.toString())?.text ?? ''
                    );

                return [''];
            },
            width: 150,
        });

        columns.push(gridColumns.companyName(t('AnelmaCommunication:1157'), 'PhoneNumber'));

        columns.push({
            field: 'PhoneNumberType',
            headerName: t('AnelmaCommunication:1151'),
            renderCell: (params) => <TextWithTooltip text={params.value as string} />,
            valueGetter: (params, row) => {
                if ((row as IContactRow).EntityType === 'Person')
                    return (row as IContactPersonRow).PhoneNumberType;

                return '';
            },
            width: 250,
        });

        return columns;
    };

    const getPersonColumns = () => {
        const columns: AGridColumns = [];

        columns.push(
            gridColumns.withTooltip({
                field: 'Name',
                headerName: t('AnelmaCommunication:1148'),
                width: 150,
            }),

            {
                field: 'Roles',
                headerName: t('AnelmaCommunication:1149'),
                renderCell: (params) => {
                    return <TextsWithTooltip texts={params.value as string[]} />;
                },
                valueGetter: (params, row) =>
                    (row as IContactPersonRow).Roles.map(
                        (r) => roleParams.find((p) => p.code === r.Type.toString())?.text ?? ''
                    ),
                width: 150,
            }
        );

        columns.push(
            gridColumns.producerNumber(t('AnelmaCommunication:1078'), 'ProducerNumber', 100),

            {
                field: 'FarmName',
                headerName: t('AnelmaCommunication:1047'),
                renderCell: (params) => <TextWithTooltip text={params.value as string} />,
                width: 200,
            },

            {
                field: 'FarmingTypes',
                headerName: t('AnelmaCommunication:1048'),
                renderCell: (params) => {
                    return <TextsWithTooltip texts={params.value as string[]} />;
                },
                valueGetter: (params, row) =>
                    GetProductionLinesFromIntList(
                        row.ProductionLines,
                        farmingTypeBovineParams,
                        farmingTypePigParams
                    ),
                width: 200,
            }
        );

        if (titleParameter === 'AnelmaBulletin:1032') {
            columns.push({
                field: 'CommunicationGroupName',
                headerName: t('AnelmaCommunication:1036'),
                renderCell: (params) => {
                    return <TextWithTooltip text={params.row.CommunicationGroupName as string} />;
                },
                width: 200,
            });
        }

        columns.push({
            field: 'Type',
            headerName: t('AnelmaCommunication:1079'),
            renderCell: (params) => {
                return <TextsWithTooltip texts={params.value as string[]} />;
            },
            valueGetter: (params, row) =>
                row.ProductionLines.map((_: IProductionLine) =>
                    GetSpeciesFronIntList([_.Type], speciesParams)
                ),
            width: 150,
        });

        return columns;
    };

    const getFarmColumns = () => {
        const columns: AGridColumns = [];

        columns.push(
            gridColumns.producerNumber(t('AnelmaCommunication:1078'), 'ProducerNumber', 100),
            {
                field: 'Name',
                headerName: t('AnelmaCommunication:1047'),
                renderCell: (params) => <TextWithTooltip text={params.value as string} />,
                width: 200,
            },
            {
                field: 'FarmingTypes',
                headerName: t('AnelmaCommunication:1048'),
                renderCell: (params) => {
                    return <TextsWithTooltip texts={params.value as string[]} />;
                },
                valueGetter: (params, row) =>
                    GetProductionLinesFromIntList(
                        row.ProductionLines,
                        farmingTypeBovineParams,
                        farmingTypePigParams
                    ),
                width: 200,
            }
        );

        if (titleParameter === 'AnelmaBulletin:1032') {
            columns.push({
                field: 'CommunicationGroupName',
                headerName: t('AnelmaCommunication:1036'),
                renderCell: (params) => {
                    return <TextWithTooltip text={params.row.CommunicationGroup as string} />;
                },
                width: 200,
            });
        }

        columns.push({
            field: 'Type',
            headerName: t('AnelmaCommunication:1079'),
            renderCell: (params) => {
                return <TextsWithTooltip texts={params.value as string[]} />;
            },
            valueGetter: (params, row) =>
                row.ProductionLines.map((_: IProductionLine) =>
                    GetSpeciesFronIntList([_.Type], speciesParams)
                ),
            width: 150,
        });

        return columns;
    };

    const buttons: ADialogButtonsProp = {
        left: [
            {
                type: 'default',
                label: t('AnelmaGeneral:1009'),
                onClick: onClose,
            },
        ],
        right: [],
    };

    return isLoading ? (
        <ViewLoader />
    ) : (
        <ADialog open onClose={onClose} disableEnforceFocus>
            {getTitle()}

            <ADialogContent size={usePersonsConnectedToTheFarm ? 'xl' : 'lg'}>
                <ADataGrid
                    columns={resolveColumns()}
                    enableFiltering
                    enableRowNumbers
                    rows={items}
                />
            </ADialogContent>

            <ADialogActions buttons={buttons} />
        </ADialog>
    );
}
