// Libraries
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

// MUI
import { Grid } from '@mui/material';

// Core
import { ResourceTextApplication } from '../core/resources';
import { IFormValidator } from '../core/FormValidator';

// Common
import {
    AAddressesInputList,
    AAutocomplete,
    ABusinessCodeInput,
    ADropdown,
    AEmailAddressInput,
    ALanguageInput,
    ANumberInput,
    APhoneNumbersInputList,
    ATextInput,
    strToIntValue,
} from '../common/inputs';

// Interfaces
import {
    ICompanyDetails,
    IPhoneNumber,
    IAddress,
    ICompanyName,
} from '../interfaces/IBusinessEntities';
import {
    CompanyEntityTypeEnum,
    AliasTypeEnum,
    CompanyNameTypeEnum,
    BusinessFormTypeEnum,
    PhoneNumberTypeEnum,
} from '../interfaces/enums';
import { LanguageShortCodeType } from '../interfaces/types';

// Store
import { useAppSelector } from '../store/hooks';

// Feature - Company
import CompanyInactivationInfo from './CompanyInactivationInfo';
import { isInReadOnlyModeAndTrafficContractorOrDriver } from './helpers/userHelper';

export interface CompanyFormProps {
    data: ICompanyDetails;
    onChange: (data: ICompanyDetails) => void;
    validator: IFormValidator;
    readonly?: boolean;
}

export default function CompanyForm(props: CompanyFormProps) {
    const { t } = useTranslation<ResourceTextApplication[]>(['AnelmaCompany']);

    const userData = useAppSelector((state) => state.userData);

    const [companyDetails, setCompanyDetails] = useState<ICompanyDetails>(props.data);

    useEffect(() => {
        setCompanyDetails(props.data);
    }, [props.data]);

    useEffect(() => {
        const timeOutId = setTimeout(() => props.onChange(companyDetails), 500);
        return () => clearTimeout(timeOutId);
    }, [
        companyDetails.Names,
        companyDetails.LanguageShortCode,
        companyDetails.BusinessForm,
        companyDetails.BusinessCode,
        companyDetails.MunicipalityCode,
        companyDetails.County,
        companyDetails.Addresses,
        companyDetails.PhoneNumbers,
        companyDetails.EmailAddresses,
    ]);

    const setBusinessCode = (value: string) => {
        setCompanyDetails((prev) => {
            return {
                ...prev,
                BusinessCode: {
                    Type: 1, // seems to be always 1 or at least interface says so
                    Value: value,
                },
            };
        });
    };

    const setName = (value: string) => {
        const names: ICompanyName[] = [...companyDetails.Names];
        const primaryNameIdx = names.findIndex((i) => i.Type === CompanyNameTypeEnum.Primary);

        primaryNameIdx === -1
            ? names.push({ IsDefault: true, Type: CompanyNameTypeEnum.Primary, Value: value })
            : (names[primaryNameIdx].Value = value);

        setCompanyDetails((prev) => {
            return {
                ...prev,
                Names: names,
            };
        });
    };

    const setLanguage = (value: LanguageShortCodeType) => {
        setCompanyDetails((prev) => {
            return {
                ...prev,
                LanguageShortCode: value,
            };
        });
    };

    const setBusinessForm = (value: string) => {
        setCompanyDetails((prev) => {
            return {
                ...prev,
                BusinessForm: {
                    Type: parseInt(value, 10) as BusinessFormTypeEnum,
                },
            };
        });
    };

    const setMunicipalityCode = (value: number | null) => {
        setCompanyDetails((prev) => {
            return {
                ...prev,
                MunicipalityCode: value,
            };
        });
    };

    const setCounty = (value: string) => {
        setCompanyDetails((prev) => {
            return {
                ...prev,
                County: value,
            };
        });
    };

    const setAddresses = (value: IAddress[]) => {
        setCompanyDetails((prev) => {
            return {
                ...prev,
                Addresses: value,
            };
        });
    };

    const setPhoneNumbers = (value: IPhoneNumber[]) => {
        setCompanyDetails((prev) => {
            return {
                ...prev,
                PhoneNumbers: value,
            };
        });
    };

    const setEmailAddress = (value: string) => {
        const emails = [...companyDetails.EmailAddresses];
        const defaultEmailIdx = emails.findIndex((_) => _.IsDefault === true);

        defaultEmailIdx === -1
            ? emails.push({ IsDefault: true, Value: value })
            : (emails[defaultEmailIdx].Value = value);

        setCompanyDetails((prev) => {
            return {
                ...prev,
                EmailAddresses: [
                    {
                        IsDefault: true,
                        Value: value,
                    },
                ],
            };
        });
    };

    /** Hide address input's add btn if conditions apply.
     *
     * @returns boolean, defaults to false
     */
    const deduceIfToHideAddBtn = () => {
        if (isInReadOnlyModeAndTrafficContractorOrDriver(userData.data, props.readonly))
            return true;

        return false;
    };

    return (
        <>
            {companyDetails.Inactivated && (
                <CompanyInactivationInfo
                    companyType={companyDetails.CompanyType}
                    effectiveTo={companyDetails.EffectiveTo}
                    inactivationDetails={companyDetails.InactivationDetails}
                />
            )}

            <Grid container>
                {companyDetails.CompanyType === CompanyEntityTypeEnum.Farm && (
                    <Grid item sm={6}>
                        <ANumberInput
                            id='producer-number'
                            label={t('AnelmaCompany:1003')}
                            mode='int'
                            onChange={() => false}
                            validator={props.validator}
                            value={strToIntValue(
                                companyDetails.Aliases.find(
                                    (i) => i.Type === AliasTypeEnum.ProducerNumber
                                )?.Value
                            )}
                            disabled // AN-501, generates in API
                        />
                    </Grid>
                )}
                <Grid item sm={6}>
                    <ABusinessCodeInput
                        id='business-code'
                        label={t('AnelmaCompany:1004')}
                        onChange={(v) => setBusinessCode(v)}
                        required
                        validator={props.validator}
                        value={companyDetails.BusinessCode.Value || ''}
                        disabled={props.readonly}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <ATextInput
                        lengthBoundaryName='AnelmaCompany:CustomerNameLength'
                        id='name'
                        label={t('AnelmaCompany:1005')}
                        onChange={(v) => setName(v)}
                        required
                        validator={props.validator}
                        value={
                            companyDetails.Names.find((i) => i.Type === CompanyNameTypeEnum.Primary)
                                ?.Value || ''
                        }
                        disabled={props.readonly}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item sm={6}>
                    <ALanguageInput
                        id='lang'
                        label={t('AnelmaCompany:1006')}
                        onChange={(v) => setLanguage(v)}
                        validator={props.validator}
                        value={companyDetails.LanguageShortCode || ''}
                        disabled={props.readonly}
                    />
                </Grid>
                <Grid item sm={6}>
                    <ADropdown
                        id='business-form'
                        label={t('AnelmaCompany:1007')}
                        onChange={(v) => setBusinessForm(v as string)}
                        parameterName='AnelmaCompany:CompanyType'
                        validator={props.validator}
                        value={
                            companyDetails.BusinessForm?.Type
                                ? `${companyDetails.BusinessForm.Type}`
                                : ''
                        }
                        disabled={props.readonly}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item sm={6}>
                    <AAutocomplete
                        id='municipality'
                        label={t('AnelmaCompany:1008')}
                        onChange={(v) =>
                            setMunicipalityCode(v === null ? null : parseInt(v as string, 10))
                        }
                        parameterName='AnelmaGeneral:Municipality'
                        required
                        validator={props.validator}
                        value={
                            companyDetails.MunicipalityCode
                                ? `000${companyDetails.MunicipalityCode}`.slice(-3)
                                : ''
                        }
                        disabled={props.readonly}
                    />
                </Grid>

                <Grid item sm={6}>
                    <AAutocomplete
                        id='regions'
                        label={t('AnelmaCompany:1009')}
                        onChange={(v) => setCounty(v === null ? '' : `${v}`)}
                        parameterName='AnelmaGeneral:Regions'
                        required
                        validator={props.validator}
                        value={companyDetails.County || ''}
                        disabled={props.readonly}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <AAddressesInputList
                        addressTypeParam='AnelmaGeneral:AddressType'
                        data={companyDetails.Addresses}
                        id='address-list'
                        onChange={(v) => setAddresses(v)}
                        required
                        validator={props.validator}
                        disabled={props.readonly}
                        hideAdditionalElements={deduceIfToHideAddBtn()}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item sm={6}>
                    <APhoneNumbersInputList
                        data={companyDetails.PhoneNumbers}
                        defaultPhoneNumberType={PhoneNumberTypeEnum.CompanyOffice}
                        id='phone-number-list'
                        onChange={(v) => setPhoneNumbers(v)}
                        phoneNumberTypeParam='AnelmaGeneral:PhoneNumberType'
                        required
                        validator={props.validator}
                        disabled={props.readonly}
                        hideAdditionalElements={deduceIfToHideAddBtn()}
                    />
                </Grid>
                <Grid item sm={6}>
                    <AEmailAddressInput
                        id='email'
                        label={t('AnelmaCompany:1018')}
                        onChange={(_) => setEmailAddress(_)}
                        required
                        validator={props.validator}
                        value={
                            companyDetails.EmailAddresses.find((_) => _.IsDefault === true)
                                ?.Value || ''
                        }
                        disabled={props.readonly}
                    />
                </Grid>
            </Grid>
        </>
    );
}
