// Libraries
import { TFunction } from 'i18next';
import { isNil } from 'ramda';

// Common
import { AGridColumns, gridColumns } from '../common/dataGrid';

// Interfaces
import { IBulletinItemDetails } from '../interfaces/ICommunication';

// Feature
import { BulletinEvent } from './BulletinView';
import { IBulletinGridItem } from './bulletinInterfaces';

export interface EventHandler {
    (): void;
}

export interface BulletinEventHandler {
    (e: BulletinEvent, m: IBulletinItemDetails | null): EventHandler;
}

function getDuration(duration: number | null, t: TFunction<'AnelmaBulletin'>) {
    return isNil(duration)
        ? t('1003', '#Autom.')
        : `${(duration / 1000).toFixed(0)} ${t('1004', '#sek')}`;
}

export function createColumnDefs(
    data: IBulletinGridItem[],
    t: TFunction<'AnelmaBulletin'>
): AGridColumns {
    const defs: AGridColumns = [
        {
            field: 'Group',
            filterable: false,
            headerName: 'Id',
            type: 'number',
            width: 70,
        },
        gridColumns.dateTime('ValidFromDate', t('AnelmaBulletin:1006'), 'DD.MM.YYYY HH:mm'),
        gridColumns.dateTime('ValidDueDate', t('AnelmaBulletin:1007'), 'DD.MM.YYYY HH:mm'),
        gridColumns.singleSelectText('Visibility', data, t('AnelmaBulletin:1008'), 120),
        {
            field: 'Durability',
            filterable: false,
            headerName: t('AnelmaBulletin:1009'),
            sortable: false,
            width: 80,
            renderCell: (params) => `${getDuration(params.row.Duration as null | number, t)}`,
        },
        gridColumns.singleSelectText('Culture', data, t('AnelmaBulletin:1010'), 100),
        gridColumns.withTooltip({
            field: 'MessageText',
            headerName: t('AnelmaBulletin:1011'),
            width: 430,
        }),
    ];

    return defs;
}
