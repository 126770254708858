import { ParameterValues } from '../../core/resources';
import { AnimalTypeEnum, FarmingTypeEnum } from '../../interfaces/enums';
import { IProductionLine } from '../../interfaces/IBusinessEntities';

/** By farming types of a company provide an array of production lines
 * @param prodLines IProductionLine[]
 * @param farmingTypes ParameterValues
 */
export const combineProductionLines = (
    prodLines: IProductionLine[],
    farmingTypes: ParameterValues
) => {
    const combinedProdLines: string[] = [];

    farmingTypes.forEach((_) => {
        switch (_.code) {
            case 'FarmingTypeBovine:01':
                if (
                    prodLines.find(
                        (l) =>
                            l.Type === AnimalTypeEnum.Bovine &&
                            l.FarmingTypes.find((t) => t === FarmingTypeEnum.Calving)
                    )
                )
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypeBovine:02':
                if (
                    prodLines.find(
                        (l) =>
                            l.Type === AnimalTypeEnum.Bovine &&
                            l.FarmingTypes.find((t) => t === FarmingTypeEnum.Dairy)
                    )
                )
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypeBovine:03':
                if (
                    prodLines.find(
                        (l) =>
                            l.Type === AnimalTypeEnum.Bovine &&
                            l.FarmingTypes.find((t) => t === FarmingTypeEnum.Upbringing)
                    )
                )
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypeBovine:04':
                if (
                    prodLines.find(
                        (l) =>
                            l.Type === AnimalTypeEnum.Bovine &&
                            l.FarmingTypes.find((t) => t === FarmingTypeEnum.Transmission)
                    )
                )
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypePig:01':
                if (
                    prodLines.find(
                        (l) =>
                            l.Type === AnimalTypeEnum.Pork &&
                            l.FarmingTypes.find((t) => t === 1)
                    )
                )
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypePig:02':
                if (
                    prodLines.find(
                        (l) =>
                            l.Type === AnimalTypeEnum.Pork &&
                            l.FarmingTypes.find((t) => t === 2)
                    )
                )
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypePig:03':
                if (
                    prodLines.find(
                        (l) =>
                            l.Type === AnimalTypeEnum.Pork &&
                            l.FarmingTypes.find((t) => t === 3)
                    )
                )
                    combinedProdLines.push(_.text);
                break;
            case 'FarmingTypePig:04':
                if (
                    prodLines.find(
                        (l) =>
                            l.Type === AnimalTypeEnum.Pork &&
                            l.FarmingTypes.find((t) => t === 4)
                    )
                )
                    combinedProdLines.push(_.text);
                break;
        }
    });

    return combinedProdLines;
};
