import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ASaveButton } from '../../../common/buttons';
import { ASwitch } from '../../../common/inputs';
import { yearMonthDateTimeFormat } from '../../../core/constants';
import { IFormValidator } from '../../../core/FormValidator';
import { ResourceTextApplication } from '../../../core/resources';
import { ProductionPlanType } from '../../../interfaces/enums';
import { IProductionPlan, IProductionPlanAccess } from '../../../interfaces/IProductionPlan';
import { useAppSelector } from '../../../store/hooks';

interface IProps {
    data: IProductionPlan;
    access: IProductionPlanAccess;
    readonly?: boolean;
    validator: IFormValidator;
    onSave: () => void;
}

export default function Confirmation({ data, access, readonly, validator, onSave }: IProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaLayout',
        'AnelmaCattleProductionPlan',
    ]);
    const loginStatus = useAppSelector((state) => state.loginStatus.data);

    const now = () => {
        return `${moment().format(yearMonthDateTimeFormat)}Z`;
    };

    const [showConfirm] = useState<boolean>(
        data.Type == ProductionPlanType.Draft || data.Type == ProductionPlanType.Completed
    );

    const [showSaveWithoutConfirm] = useState<boolean>(data.Type == ProductionPlanType.Draft);

    const [showApprove] = useState<boolean>(
        access.IsAdmin &&
            (data.Type == ProductionPlanType.Completed || data.Type == ProductionPlanType.Approved)
    );

    const setCreator = () => {
        data.Creator = loginStatus.name;
        data.ValidFrom = now();
    };

    const setConfirmer = () => {
        data.Confirmer = loginStatus.name;
        data.ConfirmedAt = now();
    };

    const setApprover = () => {
        data.Approver = loginStatus.name;
        data.ApprovedAt = now();
    };

    const onConfirm = () => {
        if (data.Type === ProductionPlanType.Draft) data.Type = ProductionPlanType.Completed;
        setCreator();
        setConfirmer();
        onSave();
    };

    const onSaveWithoutConfim = () => {
        setCreator();
        onSave();
    };

    const onApprove = () => {
        data.Type = ProductionPlanType.Approved;
        setApprover();
        onSave();
    };

    return (
        <Grid container direction='row' style={{ paddingLeft: 40 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* <Typography variant='h4'>{t('AnelmaLayout:1074')}</Typography> */}
                {/* <ASwitch 
                    id={'is-contact-requested'}
                    label={t('AnelmaCattleProductionPlan:1049')}
                    checked={data.ContactRequested}
                    disabled={readonly}
                    validator={validator}
                    onChange={(checked) => {
                        data.ContactRequested = checked;
                    }}
                /> */}
            </Grid>
            {!readonly && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    {showConfirm && (
                        <ASaveButton onClick={onConfirm}>
                            {t('AnelmaCattleProductionPlan:1050')}
                        </ASaveButton>
                    )}
                    {showSaveWithoutConfirm && (
                        <ASaveButton onClick={onSaveWithoutConfim}>
                            {t('AnelmaCattleProductionPlan:1062')}
                        </ASaveButton>
                    )}
                    {showApprove && (
                        <ASaveButton onClick={onApprove}>
                            {t('AnelmaCattleProductionPlan:1051')}
                        </ASaveButton>
                    )}
                </Grid>
            )}
        </Grid>
    );
}
