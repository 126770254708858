// Core
import { IFormValidator } from '../../core/FormValidator';

// Common
import { ARadioButtons } from '../../common/inputs';

// Interfaces
import { LanguageShortCodeType } from '../../interfaces/types';

export interface ALanguageInputProps {
    id: string;
    label?: string;
    onChange: (value: LanguageShortCodeType) => any;
    required?: boolean;
    unselectable?: boolean;
    validator: IFormValidator;
    value: LanguageShortCodeType;
    withoutContainer?: boolean;
    disabled?: boolean;
}

export default function ALanguageInput(props: ALanguageInputProps) {
    return (
        <ARadioButtons
            id={props.id}
            label={props.label}
            onChange={(v) => props.onChange(v as LanguageShortCodeType)}
            parameterName='AnelmaGeneral:CultureLanguage'
            validator={props.validator}
            value={props.value || ''}
            disabled={props.disabled}
        />
    );
}
