// Libraries
import { createContext } from 'react';

// MUI
// Core
// Common
// Interfaces
import { FormMode } from '../../../interfaces/IForm';

// Store
// API
// Feature - Feedback

interface IReclamationFeedbackContext {
    loading: boolean;
    formMode: FormMode;
    selectedReason: string | null;
    bovineOptions: string[];
    euIdentifiers: string[];
    setSelectedReason: (value: string) => void;
    setEuIdentifiers: (value: string[]) => void;
    selectedSubject: number;
    selectedCompany?: string;
}

export const ReclamationFeedbackContext = createContext<IReclamationFeedbackContext>({
    loading: false,
    formMode: 'create',
    selectedReason: null,
    bovineOptions: [],
    euIdentifiers: [],
    setSelectedReason: (value: string) => {},
    setEuIdentifiers: (values: string[]) => {},
    selectedSubject: 0,
    selectedCompany: '',
});
