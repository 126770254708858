import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import companyApi from '../../../api/companyApi';
import ADeleteIconButton from '../../../common/buttons/ADeleteIconButton';
import { ADropdown, ANumberInput, ASwitch, ATextInput } from '../../../common/inputs';
import ViewLoader from '../../../common/ViewLoader';
import { IFormValidator } from '../../../core/FormValidator';
import { ResourceTextApplication } from '../../../core/resources';
import { ICompanySummary } from '../../../interfaces/IBusinessEntities';
import { IFarmSupplier } from '../../../interfaces/IProductionPlan';
import { newRandomGuid } from '../helpers/FarmSupplierHelper';

interface IExternalSellerProps {
    readonly?: boolean;
    validator: IFormValidator;
    sellerFarm: IFarmSupplier;
    enableRowDelete?: boolean;
    handleSellerFarmsChange?: (_: IFarmSupplier) => void;
    handleRowRemove?: (id: string) => void;
}

let inputDelayMs = 250;
let lastInput: Date = new Date();

export default function ExternalSeller({
    readonly,
    validator,
    sellerFarm,
    enableRowDelete,
    handleSellerFarmsChange,
    handleRowRemove,
}: IExternalSellerProps) {
    const { t } = useTranslation<ResourceTextApplication[]>([
        'AnelmaGeneral',
        'AnelmaCattleProductionPlan',
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [farmNotFound, setFarmNotFound] = useState<boolean>();
    const [sellerDetails, setSellerDetails] = useState<IFarmSupplier>({
        Id: sellerFarm.Id,
        ValidId: sellerFarm.ValidId,
        canEdit: sellerFarm.canEdit,
        FarmId: sellerFarm.FarmId,
        GMFreedomValidated: sellerFarm.GMFreedomValidated,
        YearlyAmount: sellerFarm.YearlyAmount,
        Flags: sellerFarm.Flags,
        SellingFarmName: sellerFarm.SellingFarmName,
        SellingFarmTiltu: sellerFarm.SellingFarmTiltu,
        SnellmanQuestion: sellerFarm.SnellmanQuestion,
    });
    const [sellerOptions, setSellerOptions] = useState<IFarmSupplier[] | null>(null);

    useEffect(() => {
        if (handleSellerFarmsChange) {
            handleSellerFarmsChange({
                Id: sellerDetails.Id,
                ValidId: sellerDetails.ValidId,
                canEdit: sellerFarm.canEdit,
                FarmId: sellerDetails.FarmId,
                GMFreedomValidated: sellerDetails.GMFreedomValidated,
                YearlyAmount: sellerDetails.YearlyAmount,
                Flags: sellerFarm.Flags,
                SellingFarmName: sellerDetails.SellingFarmName,
                SellingFarmTiltu: sellerDetails.SellingFarmTiltu,
                SnellmanQuestion: sellerDetails.SnellmanQuestion,
            });
        }
    }, [sellerDetails]);

    const setInputDelay = (f: () => void) => {
        lastInput = new Date();
        window.setTimeout(() => {
            const now = new Date();
            if (now.getTime() - lastInput.getTime() >= inputDelayMs) {
                f();
            }
        }, inputDelayMs);
    };

    const mapCompanyItem = (id: string, x: ICompanySummary): IFarmSupplier => {
        return {
            Id: sellerDetails.Id,
            ValidId: x.Id,
            canEdit: sellerFarm.canEdit,
            FarmId: id,
            GMFreedomValidated: false,
            YearlyAmount: 0,
            Flags: 0,
            SellingFarmName: x.Name,
            SellingFarmTiltu: id,
            SnellmanQuestion: false,
        };
    };

    const setSelectedSeller = (seller: IFarmSupplier | null | undefined) => {
        if (seller) {
            setSellerOptions(null);
            setSellerDetails({
                Id: seller.Id,
                ValidId: seller.ValidId,
                canEdit: seller.canEdit,
                FarmId: seller.FarmId,
                GMFreedomValidated: sellerDetails.GMFreedomValidated,
                YearlyAmount: sellerDetails.YearlyAmount,
                Flags: sellerFarm.Flags,
                SellingFarmName: seller.SellingFarmName,
                SellingFarmTiltu: seller.SellingFarmTiltu,
                SnellmanQuestion: sellerDetails.SnellmanQuestion,
            });

            validator.setState('other-farm-id', true);
            setFarmNotFound(false);
        } else {
            setSellerDetails({
                Id: newRandomGuid(),
                ValidId: '',
                canEdit: true,
                FarmId: '',
                GMFreedomValidated: false,
                YearlyAmount: 0,
                Flags: 0,
                SellingFarmName: t('AnelmaGeneral:1151'),
                SellingFarmTiltu: '',
                SnellmanQuestion: false,
            });
            validator.setState('other-farm-id', false);
            setFarmNotFound(true);
        }
    };

    const fetchFarm = (id: string) => {
        if (!id) return;
        if (id.length < 9) {
            setSelectedSeller({
                Id: sellerDetails.Id,
                ValidId: sellerDetails.ValidId,
                canEdit: sellerDetails.canEdit,
                FarmId: '',
                GMFreedomValidated: sellerDetails.GMFreedomValidated,
                YearlyAmount: sellerDetails.YearlyAmount,
                Flags: sellerFarm.Flags,
                SellingFarmName: sellerDetails.SellingFarmName,
                SellingFarmTiltu: id,
                SnellmanQuestion: sellerDetails.SnellmanQuestion,
            });

            return;
        }
        if (id.length > 9) {
            id = id.substring(0, 9);
        }

        setInputDelay(() => {
            setIsLoading(true);
            companyApi
                .getCompanies('FarmId', id, false)
                .then((res) => {
                    if (!res) {
                        enqueueSnackbar('500', {
                            variant: 'error',
                        });
                        setSelectedSeller(null);
                        return;
                    }
                    if (res.Items.length === 0) {
                        setSelectedSeller({
                            Id: sellerDetails.Id,
                            ValidId: sellerDetails.ValidId,
                            canEdit: sellerDetails.canEdit,
                            FarmId: '',
                            GMFreedomValidated: sellerDetails.GMFreedomValidated,
                            YearlyAmount: sellerDetails.YearlyAmount,
                            Flags: sellerFarm.Flags,
                            SellingFarmName: sellerDetails.SellingFarmName,
                            SellingFarmTiltu: id,
                            SnellmanQuestion: sellerDetails.SnellmanQuestion,
                        });
                    } else if (res.Items.length > 1) {
                        setSellerOptions(res.Items.map((x) => mapCompanyItem(id, x)));
                    } else {
                        const seller = mapCompanyItem(id, res.Items[0]);
                        setSelectedSeller(seller);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        });
    };

    return sellerOptions ? (
        <Grid container direction='row' style={{ paddingLeft: 70 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <ADropdown
                    id={'other-farm-id-dropdown'}
                    label={t('AnelmaCattleProductionPlan:1034')}
                    validator={validator}
                    onChange={(id) => {
                        setSelectedSeller(sellerOptions.find((x) => x.Id === id));
                    }}
                    value={'default'}
                    items={[
                        { text: t('AnelmaGeneral:1149'), value: 'default', disabled: true },
                        ...sellerOptions.map((x) => {
                            return { text: x.SellingFarmName, value: x.Id };
                        }),
                    ]}
                    style={{ padding: 5 }}
                    withoutContainer={true}
                />
            </Grid>
        </Grid>
    ) : (
        <Grid
            container
            direction='row'
            style={enableRowDelete ? { paddingLeft: 40 } : { paddingLeft: 70 }}
        >
            <Grid item xs={6} sm={6} md={6} lg={6} style={{ display: 'inline-flex' }}>
                {enableRowDelete ? (
                    <ADeleteIconButton
                        key={sellerFarm.Id}
                        onClick={() => (handleRowRemove ? handleRowRemove(sellerFarm.Id) : null)}
                        tooltip={t('AnelmaCattleProductionPlan:1077')}
                        disabled={readonly}
                    />
                ) : null}
                <ATextInput
                    id={'other-farm-id'}
                    key={'other-farm-id'}
                    label={t('AnelmaCattleProductionPlan:1034')}
                    onChange={(_) => fetchFarm(_)}
                    validator={validator}
                    error={farmNotFound}
                    value={
                        sellerDetails.FarmId?.length === 9
                            ? sellerDetails.FarmId ?? ''
                            : sellerDetails.SellingFarmTiltu ?? ''
                    }
                    minLength={0}
                    maxLength={9}
                    style={{ padding: 5 }}
                    disabled={!sellerDetails.canEdit || readonly}
                    withoutContainer={true}
                />
                {isLoading ? (
                    <ViewLoader />
                ) : (
                    <ATextInput
                        id={'other-farm-name'}
                        label={t('AnelmaCattleProductionPlan:1035')}
                        onChange={(_) => {
                            setSellerDetails({
                                Id: sellerDetails.Id,
                                ValidId: sellerDetails.ValidId,
                                canEdit: sellerDetails.canEdit,
                                FarmId: sellerDetails.FarmId,
                                GMFreedomValidated: sellerDetails.GMFreedomValidated,
                                YearlyAmount: sellerDetails.YearlyAmount,
                                Flags: sellerFarm.Flags,
                                SellingFarmName: _,
                                SellingFarmTiltu: sellerDetails.SellingFarmTiltu,
                                SnellmanQuestion: sellerDetails.SnellmanQuestion,
                            });
                        }}
                        validator={validator}
                        error={farmNotFound}
                        value={sellerDetails.SellingFarmName}
                        style={{ padding: 5 }}
                        disabled={!sellerDetails.canEdit || sellerDetails.FarmId !== '' || readonly}
                        withoutContainer={true}
                    />
                )}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
                <ANumberInput
                    id={'other-farm-annual-amount'}
                    key={'other-farm-annual-amount'}
                    label={t('AnelmaCattleProductionPlan:1036')}
                    onChange={(_) => {
                        setSellerDetails({
                            Id: sellerDetails.Id,
                            ValidId: sellerDetails.ValidId,
                            canEdit: sellerDetails.canEdit,
                            FarmId: sellerDetails.FarmId,
                            GMFreedomValidated: sellerDetails.GMFreedomValidated,
                            YearlyAmount: Number(_) ?? Number(_),
                            Flags: sellerFarm.Flags,
                            SellingFarmName: sellerDetails.SellingFarmName,
                            SellingFarmTiltu: sellerDetails.SellingFarmTiltu,
                            SnellmanQuestion: sellerDetails.SnellmanQuestion,
                        });
                    }}
                    validator={validator}
                    value={sellerDetails.YearlyAmount ?? 0}
                    mode={'int'}
                    style={{ padding: 5 }}
                    disabled={farmNotFound || readonly}
                    withoutContainer={true}
                    min={0}
                />
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5}>
                <ASwitch
                    id={'gm-freedom-has-been-validated'}
                    label={t('AnelmaCattleProductionPlan:1037')}
                    checked={sellerDetails.GMFreedomValidated}
                    validator={validator}
                    disabled={farmNotFound || readonly}
                    onChange={(_) =>
                        setSellerDetails({
                            Id: sellerDetails.Id,
                            ValidId: sellerDetails.ValidId,
                            canEdit: sellerDetails.canEdit,
                            FarmId: sellerDetails.FarmId,
                            GMFreedomValidated: _,
                            YearlyAmount: sellerDetails.YearlyAmount,
                            Flags: sellerFarm.Flags,
                            SellingFarmName: sellerDetails.SellingFarmName,
                            SellingFarmTiltu: sellerDetails.SellingFarmTiltu,
                            SnellmanQuestion: sellerDetails.SnellmanQuestion,
                        })
                    }
                />

                {/* <ASwitch // Moved to 2026 do not enable for now.
                    id={'production-plan-external-seller-snellman-question'}
                    label={t('AnelmaCattleProductionPlan:1088')}
                    checked={sellerDetails.SnellmanQuestion}
                    validator={validator}
                    disabled={farmNotFound || readonly}
                    onChange={(_) =>
                        setSellerDetails({
                            Id: sellerDetails.Id,
                            ValidId: sellerDetails.ValidId,
                            canEdit: sellerDetails.canEdit,
                            FarmId: sellerDetails.FarmId,
                            GMFreedomValidated: sellerDetails.GMFreedomValidated,
                            YearlyAmount: sellerDetails.YearlyAmount,
                            Flags: sellerFarm.Flags,
                            SellingFarmName: sellerDetails.SellingFarmName,
                            SellingFarmTiltu: sellerDetails.SellingFarmTiltu,
                            SnellmanQuestion: _,
                        })
                    }
                /> */}
            </Grid>
        </Grid>
    );
}
